import React from 'react';
import { Grid, Image, Header, List, Button, Icon } from 'semantic-ui-react';
import ShoppingImage from '../media/shopping.jpg';

export default class Shopping extends React.Component {

  render() {
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Image src={ShoppingImage} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Header as="h1" color="blue">Create Free Shopping Site to Showcase Your Products</Header>
          <br />
          <List divided relaxed>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>List Unlimited Products</List.Header>
                <List.Description>Add as many of your products in your website</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Automatic Categorization</List.Header>
                <List.Description>Our Engine will categorize your products automatically</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Payments and Orders</List.Header>
                <List.Description>Users can order your products from online and we will deliver the cash to you</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>No need of IT Knowledge</List.Header>
                <List.Description>Just browsing knowledge is enough to create and edit your products</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Showcase Offers</List.Header>
                <List.Description>Offer the price in your products and we will showcase it in your site</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Share your site</List.Header>
                <List.Description>You can share your shopping site if people ask for your products</List.Description>
              </List.Content>
            </List.Item>
          </List>
          <a href="https://you.strackit.com?redirectto=https://business.strackit.com">
            <Button color='red' inverted icon labelPosition='right'>
              Create Now
              <Icon name='right arrow' />
            </Button>
          </a>
        </Grid.Column>
      </Grid>
    );
  }

}
