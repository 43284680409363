import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Title from '../product/add_product/title';
import View from './view';

export default class Purchases extends React.Component {

  render() {
    let bills = (
      <Link to="/billing">
        <Button variant="contained" color="primary">Billing</Button>
      </Link>
    );
    let purchases = (
      <Link to="/purchases">
        <Button variant="contained" color="primary">Purchase Info</Button>
      </Link>
    );
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Statement"} menu={true}
          button1={bills} button2={purchases}/>
        <View data={this.props.data} {...this.props}/>
      </div>
    );
  }

}
