import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import MButton from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Item from './item';
import Add from '../add';

export default class View extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
  }

  componentDidMount() {
    this.getAllPurchases();
  }

  updateItem = (id, name, mobile) => {
    let { out } = this.state;
    let newOut = [];
    for (var i = 0; i < out.length; i++) {
      let x = out[i];
      if (x.id === id) {
        x.purchaser_name = name;
        x.purchaser_mobile = mobile;
      }
      newOut.push(x);
    }
    this.setState({out: newOut});
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  getAllPurchases = () => {
    let context = this;
    let url = server() + '/purchases/getallpurchasesbydate.php';
    let data = {id: this.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({
        out: out,
        load: false
      });
    });
  }

  render() {
    let x = (<div></div>);
    let { data } = this.props;
    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let count = 0;
      x = this.state.out.map((temp) => {
        return (<Item data={context.props.data} item={temp}
                  updateItem={context.updateItem}
                  data={data} count={++count}/>);
      });
    }
    return (
      <div>
        <div>
          <div style={{margin: 20, float: 'left'}}>
            <TextField style={{marginLeft: 20}}
              label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
              defaultValue={this.state.fromDate} />
            <TextField style={{marginLeft: 20}}
              label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
              defaultValue={this.state.toDate} />
            <Button variant="contained" color="primary" style={{marginLeft:20}} onClick={this.getAllPurchases}>
              Go
            </Button>
          </div>
          <div style={{margin: 20, float: 'right'}}>
            <Link to="/purchases/new">
              <Button icon labelPosition='right' positive style={{marginLeft: 20}}>
                New Purchase
                <Icon name='add' />
              </Button>
            </Link>
            <Button icon labelPosition='right' negative style={{marginLeft: 20}}
               onClick={this.getAllPurchases}>
              Refresh
              <Icon name='undo' />
            </Button>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Purchase.Voucher</Table.HeaderCell>
              <Table.HeaderCell>Purchased From</Table.HeaderCell>
              <Table.HeaderCell>Purchase Date</Table.HeaderCell>
              <Table.HeaderCell>Total Products</Table.HeaderCell>
              <Table.HeaderCell>Total Stock</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell>View</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {x}
          </Table.Body>
        </Table>
        <Load open={this.state.load}/>
      </div>
    );
  }

}
