import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Input, Dropdown } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import MButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Export from "./export"

import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Item from './item';
import Add from './add';

export default class View extends React.Component {

  state = {
    customerName: '', filter: 'All',
    checked: this.props.checked,
    toDay: this.getFormatedDate(new Date()),
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getAllCustomers();
    this.getType();
  }

  updateItem = (id, name, mobile) => {
    let { out } = this.state;
    let newOut = [];
    for (var i = 0; i < out.length; i++) {
      let x = out[i];
      if (x.id === id) {
        x.customer_name = name;
        x.customer_mobile = mobile;
      }
      newOut.push(x);
    }
    this.setState({out: newOut});
  }

  getTodayDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    }
    if(mm<10){
        mm='0'+mm
    }
    today = yyyy+'-'+mm+'-'+dd;
    this.setState({ dueDate: today })
  }

  getAllCustomers = () => {
    let context = this;
    let dueDate = this.getTodayDate();
    let type = this.getType1();
    let url = server() + '/customers/getallcustomers.php';
    let data = {id: this.props.data.id, customerName: this.state.customerName, toDay: this.state.toDay};
    if (type == 'Logistics Vendor') {
      url = server() + '/customers/getallvendor.php';
    }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) { }

      context.setState({ out: out, load: false });
    });
  }

  onChangeHandler = name => event => {
    this.setState({ [name]: event.target.value })
  }

  onChangeCheckBox = name => event => {
    this.setState({ checked: event.target.checked })
  }

  handleChange = (key) => (e, { value }) => { this.setState({ [key]: value }) }

  getType() {
    let filter = 'All';
    var url = new URL(window.location.href);
    filter = url.searchParams.get("iscustomer");
    if (filter == null) {
      this.setState({ filter: 'All' })
    }
    else {
      this.setState({ filter: filter+'', dropdownFilterOption: true })
    }
  }

  getType1() {
    if (window.location.pathname == '/customers') {
      return "Customer";
    }
    if (window.location.pathname == '/logistics-customers') {
      return "Logistics Customer";
    }
    if (window.location.pathname == '/logistics-vendor') {
      return "Logistics Vendor";
    }
  }

  render() {
    let type = this.getType1();
    let x = (<div></div>);
    let { data } = this.props;
    let total = 0;
    let filter = this.state.filter;
    let filterOption = [
      {key: 'All', text: 'All', value: 'All'},
      {key: 'Customer', text: 'Customer', value: '0'},
      {key: this.props.data.category == 'Transportation And Services' ? 'Vendor' : 'Purchaser', text: this.props.data.category == 'Transportation And Services' ? 'Vendor' : 'Purchaser', value: '1'},
    ];
    let head1 = 'PURCHASER NAME';
    let head2 = 'PURCHASER MOBILE';
    let head3 = 'PURCHASER ADDRESS';
    let buttonName = 'PURCHASER';
    if (this.state.filter == 'All') {
      head1 = 'CUST./PURCHASER NAME';
      head2 = 'CUST./PURCHASER MOBILE';
      head3 = 'CUST./PURCHASER ADDRESS';
      buttonName = 'Customer/Purchaser';
    }
    if (this.state.filter == '0') {
      head1 = 'CUSTOMER NAME';
      head2 = 'CUSTOMER MOBILE';
      head3 = 'CUSTOMER ADDRESS';
      buttonName = 'CUSTOMER';
    }
    if (type == 'Logistics Vendor') {
      head1 = 'VENDOR NAME';
      head2 = 'VENDOR MOBILE';
      head3 = 'VENDOR ADDRESS';
      buttonName = 'VENDOR';
      filter = '1';
    }
    if (type == 'Logistics Customer') {
      head1 = 'CUSTOMER NAME';
      head2 = 'CUSTOMER MOBILE';
      head3 = 'CUSTOMER ADDRESS';
      buttonName = 'CUSTOMER';
      filter = '0';
    }

    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let count = 0;
      x = this.state.out.map((temp) => {
        if (filter == temp.is_customer) {
          if (this.state.checked) {
            if (this.state.dueDate >= temp.duedate && temp.balance != 0 && temp.balance != null) {
              total += isNaN(temp.total_price) ? 0 : temp.total_price;
              return (<Item data={context.props.data} item={temp}
                        updateItem={context.updateItem} refresh={context.getAllCustomers}
                        data={data} count={++count} isCustomer={Number(temp.is_customer)}/>);
            }
          }
          else {
            total += isNaN(temp.total_price) ? 0 : temp.total_price;
            return (<Item data={context.props.data} item={temp}
                      updateItem={context.updateItem} refresh={context.getAllCustomers}
                      data={data} count={++count} isCustomer={Number(temp.is_customer)} />);
          }
        }
        else if (filter == 'All') {
          if (this.state.checked) {
            if (this.state.dueDate >= temp.duedate && temp.balance != 0 && temp.balance != null) {
              total += isNaN(temp.total_price) ? 0 : temp.total_price;
              return (<Item data={context.props.data} item={temp}
                        updateItem={context.updateItem} refresh={context.getAllCustomers}
                        data={data} count={++count} isCustomer={Number(temp.is_customer)}  />);
            }
          }
          else {
            total += isNaN(temp.total_price) ? 0 : temp.total_price;
            return (<Item data={context.props.data} item={temp}
                      updateItem={context.updateItem} refresh={context.getAllCustomers}
                      data={data} count={++count} isCustomer={Number(temp.is_customer)} />);
          }
        }
      });
    }

    return (
      <div>
        <div style={{margin: 10, float: 'left'}}>
          <Input placeholder='Search...' value={this.state.customerName}
            onChange={this.onChangeHandler('customerName')} />
          <Button color='primary' onClick={this.getAllCustomers}>Search</Button>
        </div>
        <div>
          <div style={{margin: 10, float: 'right'}}>
            {
              type == 'Logistics Customer' || type == 'Logistics Vendor' ?
              null :
              <Dropdown selection onChange={this.handleChange('filter')} options={filterOption}
                placeholder='Filter' value={this.state.filter} style={{width: 10}} />          
            }
            {
              type != 'Logistics Vendor' ? 
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.checked} color="primary"
                    onChange={this.onChangeCheckBox('checked')} />
                }
                label={"DueDate "+buttonName}
              /> :
                null
            }
            
            <Add buttonName={"Add "+buttonName} style={{marginLeft: 5}} isCustomer={filter} data={this.props.data} refresh={this.getAllCustomers}/>
            <Button negative style={{marginLeft: 10}}
               onClick={this.getAllCustomers}>
              Refresh
            </Button>
            <div style={{margin: 9, float: 'right'}}>
              <Export buttonName={buttonName} id={this.props.data.id} customerName={this.state.customerName} toDay={this.state.toDay} />
            </div>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.NO</Table.HeaderCell>
              {
                this.props.shopDetails.category == 'Transportation And Services' ? null :
                (
                  <Table.HeaderCell>{'IDS'}</Table.HeaderCell>
                )
              }
              <Table.HeaderCell style={{width: 100}}>{head1}</Table.HeaderCell>
              <Table.HeaderCell>{head2}</Table.HeaderCell>
              <Table.HeaderCell>{head3 == 'Vendor Address' ? 'Vendor Place' : head3 }</Table.HeaderCell>
              {
                type == 'Logistics Vendor' ? null :
                (
                  <Table.HeaderCell>GSTIN</Table.HeaderCell>
                )
              }
              <Table.HeaderCell>BALANCE</Table.HeaderCell>
              {
                type == 'Logistics Vendor' ? null :
                (
                  <Table.HeaderCell>DUEDATE</Table.HeaderCell>
                )
              }
              <Table.HeaderCell>STATEMENT</Table.HeaderCell>
              <Table.HeaderCell>EDIT</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {x}
          </Table.Body>
        </Table>
        <Load open={this.state.load}/>
      </div>
    );
  }

}
