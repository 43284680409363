import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Title from '../product/add_product/title';
import View from './view';

export default class Purchases extends React.Component {

  render() {
    let stocks = (
      <Link to="/stocks">
        <Button variant="contained" color="primary">View / Update Stocks</Button>
      </Link>
    );
    let bills = (
      <Link to="/billing">
        <Button variant="contained" color="primary">Billing</Button>
      </Link>
    );
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Purchase Details"} menu={true}
          button1={stocks} button2={bills}/>
        <View data={this.props.data}/>
      </div>
    );
  }

}
