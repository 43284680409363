import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export default class Customer extends React.Component {

  state = {open: false}

  render() {
    let { item } = this.props;
    return (
      <div>
        <p onClick={() => this.setState({open: true})}>
          {item.customer_name + " " + item.customer_mobile}
        </p>
        <Modal open={this.state.open} basic size='small'>
          <Header content={item.customer_name} />
          <Modal.Content>
            <h3>
              <br />
              Mobile: {item.customer_mobile}
              <br /><br />
              Address: {item.customer_address}
              <br /><br />
              GSTIN: {item.customer_gstin}
              <br /><br />
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' inverted onClick={() => this.setState({open: false})}>
              <Icon name='checkmark' /> OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

}
