import React from 'react';
import $ from 'jquery';
import { Image, Table, Button, Icon } from 'semantic-ui-react';

import server from '../../functions/server';
import userId from '../../functions/userid';

export default class Item extends React.Component {

  render() {
    let { item } = this.props;
    let info = "Order";
    let infoId = item.order_id;
    let credit = ""; let debit = "";
    if (item.purchase_id > 0) {
      info = "Purchase";
      infoId = item.purchase_id;
      if (item.amount < 0) {
        credit = 0 - item.amount;
      } else {
        debit = item.amount;
      }
    } else {
      if (item.amount >= 0) {
        debit = item.amount;
      } else {
        credit = 0 - item.amount;
      }
    }
    return (
      <Table.Row positive={credit > 0} negative={debit > 0}>
        <Table.Cell>{this.props.count}</Table.Cell>
        {
          this.props.data.category == 'Transportation And Services' ? null : 
          <Table.Cell>{item.sid}</Table.Cell>
        }
        {
          this.props.data.category == 'Transportation And Services' ? null : 
          <Table.Cell>{info}</Table.Cell>
        }
        
        
        <Table.Cell>{infoId}</Table.Cell>
        <Table.Cell>{item.name + " " + item.mobile}</Table.Cell>
        <Table.Cell>{credit}</Table.Cell>
        <Table.Cell>{debit}</Table.Cell>
        <Table.Cell>{item.statement_date}</Table.Cell>
      </Table.Row>
    );
  }
}
