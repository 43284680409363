import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Header, Icon, Grid, Table, Dropdown, Input, Button } from 'semantic-ui-react';
import NotificationsIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Call';
import domain from '../../functions/domain';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import $ from 'jquery';
import server from '../../functions/server';
import Slide from '@material-ui/core/Slide';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import userId from '../../functions/userid';
import MappingShop from './mapping'
const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: '2%',
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Mapping extends React.Component {
  state = {
    users: [],
    search: '',
    shopOption: [],
    mappedshops: [],
    updateSaveData: []
  };

  componentDidMount() {
    if (userId() === '2N/VNQz6bkLZwzSqajc2cQ==' || userId() === 'LHdZPzD5B6tvT2JcgeWmYw==') {
      this.getMappingUser();
    }
  }

  save() {
    let context = this;
    let data = this.state.updateSaveData;
    data = JSON.stringify(data);
    $.ajax({
      url: server()+'/admin/updatemappedshopusers.php',
      data: { 'data': data },
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      console.log(data);
      if (data == 1) {
        context.setState({ updateSaveData: [] })
      }
    });
  }

  getMappingUser() {
    let context = this;
    let url = 'https://you.strackit.com/ALUMNI/getallusers.php';
    $.ajax({
      url: url,
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        context.getShops(data);
      } catch(e) {}
      context.setState({
        data: data,
        load: false
      });
    });
  }

  getShops(users) {
    let context = this;
    let out = [];
    let data = { 'shop_name': '' }
    let url = server() + '/admin/getshopsandmappedshops.php';
    $.ajax({
      url: url, data,
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      try {
        out = JSON.parse(data);
        context.setShops(out, users);
      } catch(e) {}
      context.setState({
        out: out,
        load: false
      });
    });
  }

  setShops(shops, users) {
    let shopOption = [];
    let mappedshops = [];
    shops.map((c) => {
      if (c.type == 'shops') {
        shopOption.push({ key: c.shop_name, text: c.shop_name, value: c.id });
      }
      else {
        mappedshops.push(c);
      }
    })
    this.setState({ shopOption, mappedshops })
    this.mergerUsersAndShops(mappedshops, users);
  }

  mergerUsersAndShops(mappedshops, users) {
    users.map((c) => {
      let maps = [];
      mappedshops.map((m) => {
        if (m.id == c.user_id) {
          maps.push(m.shop_name)
        }
      })
      c.map = maps;
    })
    this.setState({ users })
  }

  updateData(type, data) { this.setState({ [type]: data }) }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <div style={{color: 'white'}}>Mapping</div>
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{padding: '1%'}}>
          <Grid>
            <Grid.Column movile={16} tablet={16} computer={8}>
              <Input onChange={ (event) => { this.setState({ search: event.target.value }) }}
                placeholder='Search User Or Email' style={{width: '53%'}}/>
            </Grid.Column>
            <Grid.Column movile={16} tablet={16} computer={8}>
              <div style={{paddingLeft: '84%'}}>
                <Button primary onClick={this.save.bind(this)}>SAVE</Button>
              </div>
            </Grid.Column>
          </Grid>
          <Table celled width='100%'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{width: 10}}>ID</Table.HeaderCell>
                <Table.HeaderCell style={{width: 250}}>Name <br/>
                  <div style={{fontSize: 10}}>(email)</div>
                </Table.HeaderCell>
                <Table.HeaderCell>Mapping Shop(s)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { this.state.users.map((c) => {
                  let email = c.email;
                  let user = c.user_name;
                  let emailvalue = email.includes(this.state.search);
                  let uservalue = user.includes(this.state.search);
                  if (emailvalue == true || uservalue == true) {
                    return (
                      <Table.Row>
                        <Table.Cell>{c.user_id}</Table.Cell>
                        <Table.Cell>{c.user_name}<br/><div style={{fontSize: 11}}>{c.email}</div></Table.Cell>
                        <Table.Cell>
                          <MappingShop values={c.map} userId={c.user_id} users={this.state.users}
                            shopOption={this.state.shopOption} updateData={this.updateData.bind(this)}
                            updateSaveData={this.state.updateSaveData} />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }
                })
              }
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

Mapping.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default(withStyles(styles)(Mapping));
