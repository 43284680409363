import React from 'react';
import { Grid, Table } from 'semantic-ui-react';

export default class Tax extends React.Component {


  taxableAmount() {
    let value = 0;
    this.props.items.map((c) => {
      let taxableAmount = this.convertToNumber(c.taxable);
      let unit = this.convertToNumber(c.unit);
      taxableAmount = taxableAmount * unit;
      let rate = this.convertToNumber(c.rate);
      value = value + (taxableAmount * rate);
    });
    return value;
  }

  taxAmount() {
    let value = 0;
    this.props.items.map((c) => {
      let taxableAmount = this.convertToNumber(c.taxable);
      let unit = this.convertToNumber(c.unit);
      taxableAmount = taxableAmount * unit;
      let tax = this.convertToNumber(c.tax);
      let rate = this.convertToNumber(c.rate);
      taxableAmount = taxableAmount * rate;
      value = value + ((taxableAmount * tax)/100);
    });
    return value;
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimalFixed(value) {
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }


  render() {
    let taxableAmount = this.taxableAmount();
    let taxAmount = this.taxAmount();
    let totalAmount = taxableAmount + taxAmount;
    return (
      <div>
        <Table celled structured>
          <Table.Body>
            <Table.Row>
              <Table.Cell><b>Tax Amount</b></Table.Cell>
              <Table.Cell><b>{this.makeDecimalFixed(taxAmount)}</b></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Taxable Amount</b></Table.Cell>
              <Table.Cell><b>{this.makeDecimalFixed(taxableAmount)}</b></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Total Amount</b></Table.Cell>
              <Table.Cell><b>{this.makeDecimalFixed(totalAmount)}</b></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
