import React from 'react';
import Title from '../product/add_product/title';
import server from '../functions/server';
import $ from 'jquery';
import Load from '../loads';
import { Table, Button, Modal, Input } from 'semantic-ui-react';
import View from './view';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';

import Validation from '../Others/Validation';

export default class PNSService extends React.Component {

  state = {
    load: false, data: [], olddata: [{}]
  }

  componentDidMount() {
    this.getPNSNumbers();
  }

  getPNSNumbers = () => {
    let context = this;
    let url = server()+'/pnsservice/getpnsnumbers.php';
    let data = { 'shopId': context.props.shopId }
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Getting PNS Number!!!' });
      }
    }).done((data) => {
      context.setState({ load: false });
      try {
        data = JSON.parse(data);
        context.setState({ data });
      } catch(e) { }
    });
  }

  addItem = () => { this.setState({ open: true }) }

  findDuplicate() {
    let mobile= this.state.mobile;
    let value = 1;
    let data = this.state.data;
    data.map((s) => {
      if (mobile == s.number) {
        value = "Mobile is Duplicate Entry"
      }
    })
    return value;
  }

  onChangeHandle = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  addLedger() {
    let context = this;
    let duplicateIdentifier = this.findDuplicate();
    if (duplicateIdentifier != 1) { alert(duplicateIdentifier); return; }
    let url = server()+'/pnsservice/addmobilenumber.php';
    let data = { 'mobile': this.state.mobile, 'shopId': this.props.shopId, 'name': this.state.name };
    let { value, error } = Validation.pnsService.validate(data);
    if (error) {
      alert(error);
    }
    else {
      $.ajax({
        url, data, type: 'POST',
        beforeSend: function(x) {
          context.setState({ load: true, message: 'Adding PNS Number!!!' });
        }
      }).done((data) => {
        context.setState({ load: false });
        if (data == 1) {
          alert('Added Successfully!!!');
          context.setState({ name: '', mobile: '', open: false })
          context.getPNSNumbers();
        }
      });
    }
  }
  
  updateItem(data) { this.setState({ data }) }

  render() {
    let i = 1;
    let x = this.state.data.map((v) => {
      return (
        <View item={v} data={this.state.data} getPNSNumbers={this.getPNSNumbers.bind(this)} sino={i++} updateItem={this.updateItem.bind(this)} />
      )
    })
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - PNS Service"} menu={true}/>\
          <div style={{margin: '0.5%'}}>
            <center style={{fontSize: 18, fontWeight: 'bold'}}>PNS SERVICE</center>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{width: 20}}>SiNo</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Mobile</Table.HeaderCell>
                  <Table.HeaderCell style={{width: 20}}>Edit</Table.HeaderCell>
                  <Table.HeaderCell style={{width: 20}}>Delete</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {x}
              </Table.Body>
            </Table>
            <center>
              { this.state.data.length >= 4 ?
                (<Button disabled positive>ADD</Button>):
                (<Button onClick={this.addItem} positive>ADD</Button>)
              }
            </center>
          </div>
        
        <Load open={this.state.load} message={this.state.message} />
        <Dialog fullWidth={true}
        maxWidth={'md'} open={this.state.open} onClose={ () => { this.setState({ open: false }) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">PNS SERVICE</DialogTitle>
          <DialogContent>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Name</b><br/>
                    <Input fluid placeholder="Name" value={this.state.name} onChange={this.onChangeHandle('name')} />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>Mobile Number</b><br/>
                    <Input fluid type={'number'} placeholder="Mobile Number" value={this.state.mobile} onChange={this.onChangeHandle('mobile')} />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button negative onClick={ () => { this.setState({ open: false }) }}>Close</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={this.addLedger.bind(this)}/>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
