import React from 'react';
import { Table } from 'semantic-ui-react';


export default class ModeOfPayment extends React.Component {

  convertTONumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimal(value) {
    try {
      value = Number(value);
      value = value.toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    let amount = 0;
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Ledger</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.props.modeofpayment.map((c) => {
                amount = this.convertTONumber(c.amount);
                return (
                  <Table.Row>
                    <Table.Cell>{c.ledger}</Table.Cell>
                    <Table.Cell>{this.makeDecimal(amount)}</Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </div>
    );
  }

}
