import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Card, Icon, Button, Input } from 'semantic-ui-react';
import RazorPayment from '../razorpayment';
import server from '../functions/server';
import { Link } from "react-router-dom";

export default class Sms extends React.Component {

  state = {
    amount: 0,
    withTax: 0
  }


  updateItem = (key) => (e, { value }) => {
    if (value == '') {
      this.setState({ message: '', amount: '', withTax: '',  })
    }
    else {
      value = parseInt(value);
      let amount = value * 0.30;
      let withTax = amount + ((amount * 18)/100);
      this.setState({ [key]: value, amount, withTax })
    }
  }

  updateData() {
    this.props.getShopDetails();
    return;
    document.getElementById('linkButton').click();
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    let amount = this.convertToNumber(this.state.amount).toFixed(2);
    let withTax = this.convertToNumber(this.state.withTax).toFixed(2);
    let taxAmount, noofmessage, razorpayment;
    let balance = this.props.balance;
    amount = this.convertToNumber(amount).toFixed(2);
    withTax = this.convertToNumber(withTax).toFixed(2);
    taxAmount = withTax - amount;
    taxAmount = this.convertToNumber(taxAmount).toFixed(2);
    razorpayment = withTax * 100;
    noofmessage = this.convertToNumber(balance).toFixed(2);
    noofmessage = noofmessage/0.30;
    noofmessage = Math.round(noofmessage);
    let smsBalance = this.convertToNumber(this.props.balance).toFixed(2);
    return (
      <div>
        <Link to="/sms">
          <Button id='linkButton' style={{display: 'none'}}>None</Button>
        </Link>
        <Grid>
          <Grid.Column mobile={16} computer={4} tablet={16}>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8} tablet={16}>
            <Grid>
              <Grid.Column mobile={8} computer={8} tablet={8}>
                <div style={{color: 'green', fontSize: 18, fontWeight: 'bold'}}><b>{'Balance: ₹ '+smsBalance}</b></div>
              </Grid.Column>
              <Grid.Column mobile={8} computer={8} tablet={8}>
                <div style={{textAlign: 'right', color: 'green', fontSize: 18, fontWeight: 'bold'}}>
                  <div><b>{'SMS Available: '+noofmessage}</b></div>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} tablet={16}>
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} tablet={16}>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8} tablet={16}>
            <Paper style={{padding: '2%'}}>
              <Grid>
                <Grid.Column mobile={8} computer={8} tablet={8}>
                  <div style={{textAlign: 'right'}}><b>Enter the SMS Count</b></div>
                </Grid.Column>
                <Grid.Column mobile={8} computer={8} tablet={8}>
                  <div style={{flexDirection: 'row'}}>
                    <div>
                      <Input placeholder='Message Count' value={this.state.message} onChange={this.updateItem('message')} />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={8} computer={8} tablet={8}>
                  <div style={{textAlign: 'right'}}>
                    <b>
                      <br/>No of Message
                      <br/><br/>Amount
                      <br/><br/>Tax
                      <br/><br/>Total
                    </b>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={8} computer={8} tablet={8}>
                  <div><br/>
                    <b>
                      {this.state.message}<br/><br/>
                      {'₹ '+amount}<br/><br/>
                      {'₹ '+taxAmount+' (18%)'}<br/><br/>
                      {'₹ '+withTax}
                    </b>
                  </div>
                </Grid.Column>
              </Grid>
              <br/><br/>
              { amount >= 100 ?
                (
                  <RazorPayment amount={razorpayment} url={server()+'/sms/payment.php'}
                  shopId={this.props.shopId} originalAmount={amount}
                  description={'SMS RECHARGE'} updateData={this.updateData.bind(this)} />
                ):
                (
                  <Button disabled fluid primary>Minimum Pay ₹100</Button>
                )
              }
            </Paper>
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} tablet={16}>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
