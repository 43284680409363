import React from 'react';
import Edit from '../edit';
import { Image, Table, Checkbox } from 'semantic-ui-react';
import Attachment from '../../media/attachment.jpg';
import server from '../../functions/server';
import $ from 'jquery';

export default class Item extends React.Component {

  state = {
    image: Attachment
  };

  componentDidMount() {
    this.setIdInState();
  }

  handleFile = e => {
    let context = this;
    var formData = new FormData();
    formData.append('path', this.props.item.feature_image);
    formData.append('file', e.target.files[0]);
    return;
    $.ajax({
      type: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      url: server() + '/stocks/imageupdate.php',
      success: function(data)
      {
        console.log(data);
      },
      error: function(error)
      {
        alert(error)
        alert('Try again!!!');
        console.log(error);
      }
    });
  }

  toggle = (id) => {
    let value = 1;
    if (!this.props.item.description) { value = 0; }
    if (!this.props.item.feature_image) { value = 0; }
    if (value == 0) {
      alert('Enter All Details!!!\n\nSelect Edit All Button!!!');
      return
    }
    if (this.state.is_online) {
      this.setState({ is_online: false })
      this.props.getItemidForUpdateOnlineProducts(id, 0);
    }
    else {
      this.setState({ is_online: true })
      this.props.getItemidForUpdateOnlineProducts(id, 1);
    }
  }

  setIdInState() {

    let is_online = this.props.item.is_online;
    if (is_online == "1") {
      this.setState({ is_online: true })
    }
    else {
      this.setState({ is_online: false })
    }
  }

  render() {
    let feature = "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + this.props.item.feature_image;
    let dnp = this.props.item.dnp;
    let prime = this.props.data.prime;
    let prize = this.props.item.prize;
    let tax = this.props.item.tax;
    let publish = this.props.item.publish;
    let color = '#FFFFFF';
    if (publish == "0") { color = '#FED4CC'; }
    try {
      dnp = Number(dnp);
      prize = Number(prize);
      tax = Number(tax);
      dnp = Number(dnp + ((dnp * tax)/100)).toFixed(2);
      prize = Number(prize + ((prize * tax)/100)).toFixed(2);
    } catch (e) {
      prize = 0;
      dnp = 0;
    }
    return (
      <Table.Row style={{backgroundColor: color}}>
        <Table.Cell>{this.props.count}</Table.Cell>
        <Table.Cell>{this.props.item.item_number}</Table.Cell>
        <Table.Cell className="tamilFont">{this.props.item.product_name}</Table.Cell>
        <Table.Cell>{this.props.item.no_stock}</Table.Cell>
        <Table.Cell>{this.props.item.min_stock}</Table.Cell>
        <Table.Cell>{dnp}</Table.Cell>
        <Table.Cell>{prize}</Table.Cell>
        <Table.Cell>{this.props.item.tax}</Table.Cell>
        <Table.Cell>
          <center style={{marginTop: '50%'}}>
            <Checkbox disabled={this.props.data.prime == '1' ? true : false} onChange={this.toggle.bind(this, this.props.item.id)}  
            checked={this.state.is_online} />
          </center>
        </Table.Cell>
        {/* <Table.Cell>
          <Image src={feature} rounded size='tiny' />
        </Table.Cell> */}
        <Table.Cell>
          <Edit item={this.props.item} out={this.props.out} data={this.props.data} updateItem={this.props.updateItem}/>
        </Table.Cell>
      </Table.Row>
    );
  }

}
