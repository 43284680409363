import React from 'react';
import QRCode from 'qrcode.react';
import { Grid, Image, Header, Icon } from 'semantic-ui-react';

export default class QRcode extends React.Component {

  render() {
    return (
      <div style={{marginTop: 50}}>
        <center>
          <Header as='h2' color='teal'>
            <Icon name='cube' />
            <Header.Content>
              QR Code
              <Header.Subheader>Scan in Strackit Shops App</Header.Subheader>
            </Header.Content>
          </Header>
        </center>
        <QRCode style={{marginTop: 50}} size={256} level={'H'} value={this.props.data.shop_name} />
        <center style={{marginTop: 50}}>
          <a href={'https://play.google.com/store/apps/details?id=com.strackit.gstbilling&hl=en'} target="_blank">
            <Header as='h3' icon='arrow right' content='Download Strackit Shops App ' color='teal'/>
          </a>
        </center>
      </div>
    );
  }

}
