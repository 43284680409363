import React from 'react';
import { Table } from 'semantic-ui-react';

export default class Total extends React.Component {

  getTaxValue(stock) {
    if (stock.returned == 1) {
      return 0;
    }
    let tax = stock.tax;
    let price = stock.price * stock.quantity;
    return (price * tax / 100);
  }

  getTotalTax() {
    let x = 0;
    for (var i = 0; i < this.props.stocks.length; i++) {
      let stock = this.props.stocks[i];
      if (stock.returned == 1) {
        continue;
      }
      x += this.getTaxValue(stock);
    }
    return x;
  }

  getTotalPrice() {
    let x = 0;
    for (var i = 0; i < this.props.stocks.length; i++) {
      let stock = this.props.stocks[i];
      if (stock.returned == 1) {
        continue;
      }
      let tax = stock.tax;
      let price = stock.price * stock.quantity;
      x += price + (price * tax / 100);
    }
    x = Number(x).toFixed(2);
    return x;
  }

  getAllTaxes() {
    let taxes = {};
    for (var i = 0; i < this.props.stocks.length; i++) {
      let stock = this.props.stocks[i];
      if (taxes[stock.tax]) {
        taxes[stock.tax].price += stock.price * stock.quantity;
        taxes[stock.tax].tax += this.getTaxValue(stock);
      } else {
        taxes[stock.tax] = {};
        taxes[stock.tax].price = stock.price * stock.quantity;
        taxes[stock.tax].tax = this.getTaxValue(stock);
      }
    }
    return taxes;
  }

  toFixedValue(value) {
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  getTaxStructure(type, taxes, taxValues) {
    let count = 0;
    let totalTax = this.getTotalTax();
    return taxValues.map((taxValue) => {
      if (taxValue) {
        let tax = taxes[taxValue];
        let price = this.toFixedValue(tax.price);
        let taxAmount = this.toFixedValue(tax.tax);
        totalTax = this.toFixedValue(totalTax);
        return (
          <Table.Row>
            {count === 0 ? <Table.Cell rowSpan={taxValues.length}><b>{type}</b></Table.Cell> : ""}
            <Table.Cell>{"Rs." + Number(price/2).toFixed(2)}</Table.Cell>
            <Table.Cell>{taxValue / 2 + "%"}</Table.Cell>
            <Table.Cell>{"Rs." + Number(taxAmount / 2).toFixed(2)}</Table.Cell>
            {count++ === 0 ? <Table.Cell rowSpan={taxValues.length}>
              <b>{"Rs." + Number(totalTax / 2).toFixed(2)}</b>
            </Table.Cell> : ""}
          </Table.Row>
        );
      }
    });
  }

  render() {
    let taxes = this.getAllTaxes();
    if (!taxes) {
      return (<div></div>);
    }
    let taxValues = Object.keys(taxes);
    return (
      <div>
        <Table celled style={{float: 'right', margin: 10}}>
          <Table.Body>
            {this.getTaxStructure('CGST', taxes, taxValues)}
            {this.getTaxStructure('SGST', taxes, taxValues)}
            <Table.Row>
              <Table.Cell colSpan={4}><b>Total + CGST + SGST</b></Table.Cell>
              <Table.Cell><b>{"Rs." + this.getTotalPrice()}</b></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }

}
