import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import { Table, Grid, Input } from 'semantic-ui-react';
import $ from 'jquery';

import Load from '../../load';
import Add from '../add';
import server from '../../functions/server';

export default class View extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
    report: [], search: '',
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getStatement();
  }

  getStatement = () => {
    let id = 0;
    let context = this;
    let url = url = server() + '/statements/getalllogisticvendorstatements.php';
    try {
      id = this.props.match.params.id;
    } catch (error) {
      id = 0;
    }
    let data = {'id': this.props.data.id, 'fromDate': this.state.fromDate, 'toDate': this.state.toDate, search: this.state.search, 'customer_id': id };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
        out.map((c) => { c.amount = Number(c.amount); })
      } catch(e) {
        console.log(data);
      }
      context.setState({ 'report': out, load: false });
    });
  }

  calculateTotal = () => {
    let report = this.state.report;
    let amount = 0;
    report.map((c) => {
      amount = amount + c.amount;
    })
    return amount;
  }

  render() {
    let { out } = this.state;
    let i = 1;
    let total = this.calculateTotal();
    return (
      <div>
        <Load open={this.state.load}/>
        <div style={{display: 'flex', width: '100%'}}>
          <TextField
            label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
            defaultValue={this.state.fromDate} />
          <TextField style={{marginLeft: 10}}
            label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
            defaultValue={this.state.toDate} />
          <Input placeholder='Search Vendor' value={this.state.search}  style={{marginLeft: 10, marginTop: 10, height: '10%'}}
                onChange={(e) => this.setState({search: e.target.value})} />
          <Button variant="contained" color="primary" style={{marginLeft:10, marginTop: 10, height: '10%'}}
            onClick={ () => this.getStatement()}>
            Go
          </Button>
          <div style={{width: '40%'}}></div>
          <b style={{marginTop: '1.5%', fontSize: 14}}>{"Balance. "+total}</b>
          <div><Add getStatement={this.getStatement} data={this.props.data} /></div>
          
        </div>
        
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>VENDOR</Table.HeaderCell>
              <Table.HeaderCell>POL</Table.HeaderCell>
              <Table.HeaderCell>POD</Table.HeaderCell>
              <Table.HeaderCell>IMPORT/EXPORT</Table.HeaderCell>
              <Table.HeaderCell>WEIGHT</Table.HeaderCell>
              <Table.HeaderCell>CBM</Table.HeaderCell>
              <Table.HeaderCell>PAID</Table.HeaderCell>
              <Table.HeaderCell>PAYABLE</Table.HeaderCell>
              <Table.HeaderCell>STATEMENT DATE</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.report.map((c) => {
                return (
                  <Table.Row error={c.amount < 0 ? true : false}>
                      <Table.Cell>{i++}</Table.Cell>
                      <Table.Cell>{c.customer_name}</Table.Cell>
                      <Table.Cell>{c.poi}</Table.Cell>
                      <Table.Cell>{c.pod}</Table.Cell>
                      <Table.Cell>{c.type}</Table.Cell>
                      <Table.Cell>{c.weight}</Table.Cell>
                      <Table.Cell>{c.cbm}</Table.Cell>
                      <Table.Cell>{c.amount > 0 ? c.amount : null}</Table.Cell>
                      <Table.Cell>{c.amount < 0 ? (-1 * c.amount) : null}</Table.Cell>
                      <Table.Cell>{c.timestamp}</Table.Cell>
                  </Table.Row>
                )
              })
            }
            <Table.Row>
              
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }

}
