import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Input } from 'semantic-ui-react';

import Item from './item';
import Update from './update';
import Title from '../../product/add_product/title';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Load from '../../load';

export default class View extends React.Component {

  state = {
    out: null,
    designations: [],
  }

  componentDidMount() {
    this.getAllEmployees();
    this.getDesignations();
  }

  getDesignations() {
    let context = this;
    let url = server() + '/designations/getall.php';
    let data = { id: this.props.data.id };
    $.ajax({
      url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {  }
      context.setState({ designations: out, load: false });
    });
  }

  getAllEmployees() {
    let context = this;
    let url = server() + '/employees/getall.php';
    console.log(this.props)
    let data = {id: this.props.data.id};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({ out: out, load: false });
    });
  }

  render() {
    let x = (<div></div>);
    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let search = this.state.search;
      let count = 0;
      x = this.state.out.map((temp) => {
        if (search) {
          search = search.toLowerCase();
          if ((temp.custom_id && temp.custom_id.toLowerCase().includes(search))
                || (temp.name && temp.name.toLowerCase().includes(search))
                || (temp.city && temp.city.toLowerCase().includes(search))
                || (temp.mobile && temp.mobile.toLowerCase().includes(search))) {
            return (<Item data={context.props.data} item={temp}
              updateItem={context.updateItem} id={temp.id} out={this.state.out}
              count={++count} getAllEmployees={this.getAllEmployees.bind(this)}
              designations={this.state.designations}/>);
          }
        } else {
          return (<Item data={context.props.data} item={temp}
            id={temp.id} out={this.state.out} getAllEmployees={this.getAllEmployees.bind(this)}
            updateItem={context.updateItem} count={++count}
            designations={this.state.designations}/>);
        }
      });
    }
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Employees"} menu={true}/>
        <div style={{margin: 10}}>
          <div style={{display: 'flex'}}>
            <Input placeholder='Search...' value={this.state.search}
              onChange={(e, {value}) => {this.setState({search: value})}}/>
            <Button icon labelPosition='right' negative
              style={{marginLeft: 10}} 
              onClick={this.getAllEmployees.bind(this)}>
              Refresh
              <Icon name='undo' />
            </Button>
            <Update getAllEmployees={this.getAllEmployees.bind(this)}
              out={this.state.out} data={this.props.data}
              designations={this.state.designations}/>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Emp Code</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Designation</Table.HeaderCell>
              <Table.HeaderCell>Mobile</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>Gender</Table.HeaderCell>
              <Table.HeaderCell>Salary</Table.HeaderCell>
              <Table.HeaderCell>Edit</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {x}
          </Table.Body>
        </Table>
        <Load open={this.state.load}/>
      </div>
    );
  }

}
