import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import userId from '../../functions/userid';
import Steps from './steps';
import domain from '../../functions/domain';
import $ from 'jquery';
import server from '../../functions/server';
import Load from '../../loads';
import View from '../view';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
};

class New extends React.Component {

  state = {
    shopDetails: [], type: '1',
  }

  componentDidMount() {
    this.checkShopName();
  }


  checkShopName() {
    
    let context = this;
    let url = server()+'/shop/shopnamefromurl.php';
    let urls = window.location.href;
    
    let shopnameFromUrl = urls.split('/')[3];
    if (!shopnameFromUrl && urls.split('/')[4]) {
      
      window.location.replace(domain());
      return;
    }

    
    
    try {
      shopnameFromUrl = shopnameFromUrl.replace(/-/g, ' ');
      
      if (!shopnameFromUrl) {
       
        this.checkUserId();
        return;
      }
      let data = { 'shopnameFromUrl': shopnameFromUrl };
      context.setState({ load: false })
      $.ajax({
        url: url,
        data, type: 'POST',
      }).done((data) => {
        context.setState({ load: false })
        try {
          data = JSON.parse(data);
          var size = Object.keys(data).length;
          size = Number(size);
          if (size != 0) {
            context.setState({ type: '2', shopDetails: data, load: false  })
          }
          else {
            alert('Shop Not Founded!!!');
            window.location.replace(domain());
          }
          return;
        } catch (e) {
          context.setState({ shopFound: false, load: false })
        }
      });
    } catch (error) {
      
    }
    
  }


  checkUserId() {
    let context = this;
    
    let userIds = userId();
    
    if (userIds == null || !userIds) {
      this.setState({ load: false, type: '3' })
    }
    else {
      console.log(userIds);
      userIds = encodeURI(userIds);
      console.log(userIds);
      $.ajax({
        data: { 'userId': userIds },
        method: 'POST',
        url: 'https://you.strackit.com/ALUMNI/checkuserid.php',
        beforeSend: function(x) {
          context.setState({ load: false });
        }
      }).done((data) => {
        console.log(data);
        console.log(userId())
        context.setState({ load: false });
        if (data == userId()) {
          context.setState({ type: '3' })
        }
        else {
          context.setState({ error: 'Welcome To Strackit Bussiness!!!', load: false })
        }
      });
    }
  }

  getTodayDateold() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd < 10){
      dd = '0'+dd;
    }
    if(mm < 10){
        mm='0'+mm
    }
    today = yyyy+'-'+mm+'-'+dd;
    return today;
  }

  render() {
    console.log(this.state.type)
    const { classes } = this.props;
    let view = (<div></div>);
    if (this.state.type == '1') {
      view = (<div><Load open={true}/></div>);
    }
    else if (this.state.type == '2') {
      view = (<Router><View data={this.state.shopDetails} from={'view'} /></Router>);
    }
    else if (this.state.type == '3') {
      view = (<center><Steps /></center>);
    }
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar className={classes.grow}>
            <Typography variant="h6" color="inherit">
              My Business
            </Typography>
          </Toolbar>
        </AppBar>
        {view}
        <Load open={this.state.load}/>
      </div>
    );
  }
}

export default withStyles(styles)(New);
