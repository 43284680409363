import React, { Component } from 'react';
import { Table, Button, Icon, Input } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import $ from "jquery";

import serverName from '../../functions/server';

class Specification extends Component {

  state = {
    specification: []
  }

  getSpecification() {
    let context = this;
    let categorys = [];
    let category = this.props.category;
    var url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shopId");
    if (category.length == 0) {
      alert('Select Category!!!'); return;
    }
    category.map((v) => {
      let mastercategory = v.split('->')[1];
      let secondarycategory = v.split('->')[0];
      categorys.push({ mastercategory, secondarycategory  })
    })
    let data = { mastercategory: this.props.mastercategory, category: categorys, shopId, productId: this.props.productId };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/specification/getspecifications.php',
      success: function(data) {
        try {
          data = JSON.parse(data);
          context.setState({ specification: data, addSpecificationDialog: true });
        } catch (e) {
          alert('Error!!!')
        }
      },
    });
  }

  addSpecificationRow() {
    let specification = this.state.specification;
    let categoryId = 0;
    let id = 0;
    specification.map((c) => { categoryId = c.category_id; id = c.id; });
    id = id + 1;
    specification.push({ category_id: categoryId, specification: '', value: '', type: 'add', id: id });
    console.log(specification);
    this.setState({ specification })
  }

  removeSpecification(id) {
    let specifications = this.state.specification;
    let specification = [];
    specifications.map((v) => { if (v.id != id) { specification.push(v); } })
    this.setState({ specification })
  }

  saveSpecification() {
    let context = this;
    let specification = this.state.specification;
    specification.map((v) => {
      if (!v.value) {
        v.value = '';
      }
    })
    console.log(specification);
    let data = { specification: specification, productId: this.props.productId };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/specification/savespecificationvalue.php',
      success: function(data) {
        console.log(data);
        if (data == 1) {
          context.setState({ addSpecificationDialog: false })
        }
        else {
          alert('Error!!!')
        }
      },
    });
  }

  textHandle = (type, id) => event => {
    let specification = this.state.specification;
    specification.map((v) => {
      if (v.id == id) {
         v.value = event.target.value;
         v.type = 'valueChanged';
      }
    })
    console.log(specification);
    this.setState({ specification })
  }

  render() {
    return (
      <div>
        <Button primary onClick={this.getSpecification.bind(this)} fluid>
          Edit Specifications
        </Button>
        <Dialog onClose={ () => { this.setState({ addSpecificationDialog:false }) }} aria-labelledby="simple-dialog-title"  open={this.state.addSpecificationDialog}>
          <DialogTitle style={{width: '400px'}} id="simple-dialog-title">Specification</DialogTitle>
          <DialogContent>
            <Table>
              <Table.Row>
                <Table.Cell>Specification</Table.Cell>
                <Table.Cell>Value</Table.Cell>
              </Table.Row>
              {
                this.state.specification.map((v) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{v.name}</Table.Cell>
                      <Table.Cell><Input onChange={this.textHandle('value', v.id)} value={v.value} /></Table.Cell>
                    </Table.Row>
                  )
                })
              }
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({ addSpecificationDialog:false }) }} color="primary">Cancel</Button>
            <Button onClick={this.saveSpecification.bind(this)} color="primary" autoFocus>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Specification;
