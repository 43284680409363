import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import Uploadimage from './uploadimage';
import Avatar from '@material-ui/core/Avatar';

import Attachment from '../../media/attachment.jpg';

class Images extends Component {
  state = {
    file: 0, i1: Attachment, i2: Attachment,
    i3: Attachment, i4: Attachment, i5: Attachment,
  };

  setImage(name, file) {
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        context.setState({
          [name]: e.target.result
        });
      };
    })(file);
    reader.readAsDataURL(file);
  }

  emptyImages() {
    this.setState({
      file: 0, i1: Attachment, i2: Attachment,
      i3: Attachment, i4: Attachment, i5: Attachment,
    });
  }

  onChange()
  {
    var image1 = document.getElementById("image1").files;
    var image2 = document.getElementById("image2").files;
    var image3 = document.getElementById("image3").files;
    var image4 = document.getElementById("image4").files;
    var image5 = document.getElementById("image5").files;
    let x = {};
    if (image1.length > 0) { this.setImage('i1', image1[0]); }
    if (image2.length > 0) { this.setImage('i2', image2[0]); }
    if (image3.length > 0) { this.setImage('i3', image3[0]); }
    if (image4.length > 0) { this.setImage('i4', image4[0]); }
    if (image5.length > 0) { this.setImage('i5', image5[0]); }
    let get_image = this.props.get_image;
    get_image(image1, image2, image3, image4, image5);
  }

  updateimage(a)
  {
    var image1 = document.getElementById("image1").files;
    let x = {};
    if (image1.length > 0) {
      this.setImage('i1', image1[0]);
    }
  }
  render() {
    let y = (<div></div>);
    let x = (<div></div>);
    if (this.props.from == 'edit') {

      let p_id;
      x = this.props.get_data.map((z) => {
        if (z.type == 'images') {
          p_id = z.id;
          return (
            <div>
              <br />
              <Uploadimage getProduct={this.props.getProduct} src={z.product_name} id={z.prize} p_id={z.id} edit='yes' />
            </div>
          )
        }
      });
      let loop = 6 - x.length;
      for (var i = 0; i < loop; i++) {
        x.push(
          <div>
            <br />
            <Uploadimage src={null} id={"imageid" + i} p_id={p_id} edit="no"/>
          </div>
        );
      }
    }
    else {
      y = (
        <div>
          <Grid>
          <Grid.Column mobile={2} tablet={2} computer={2}>
            <input id="image1" type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
            <label htmlFor="image1">
              <Avatar src={this.state.i1} id='size' style={{cursor: 'pointer'}} />
            </label>
          </Grid.Column>
          <Grid.Column mobile={2} tablet={2} computer={2}>
            <input accept="image/*" id="image2" multiple type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
            <label htmlFor="image2">
              <Avatar src={this.state.i2} id='size' style={{cursor: 'pointer'}} />
            </label>
          </Grid.Column>
          <Grid.Column mobile={2} tablet={2} computer={2}>
            <input accept="image/*" id="image3" multiple type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
            <label htmlFor="image3">
              <Avatar src={this.state.i3} id='size' style={{cursor: 'pointer'}} />
            </label>
          </Grid.Column>
          <Grid.Column mobile={2} tablet={2} computer={2}>
            <input accept="image/*" id="image4" multiple type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
            <label htmlFor="image4">
              <Avatar src={this.state.i4} id='size' style={{cursor: 'pointer'}} />
            </label>
          </Grid.Column>
          <Grid.Column mobile={2} tablet={2} computer={2}>
            <input accept="image/*" id="image5" multiple type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
            <label htmlFor="image5">
              <Avatar src={this.state.i5} id='size' style={{cursor: 'pointer'}} />
            </label>
          </Grid.Column>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Grid>
          {x}
        </Grid>
        <br/>{y}
      </div>
    );
  }
}

export default Images;
