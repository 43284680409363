import React from 'react';
import { Button, Header, Image, Modal, Grid, Input } from 'semantic-ui-react'
import $ from 'jquery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import server from '../functions/server';
import Load from '../loads';



export default class PNSService extends React.Component {

  state = {
    open: false
  }

  onChangeText = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  submit() {
    let context = this;
    let { bank, beneficiaryName, accNo, ifsc } = this.state;
    if (!bank || !beneficiaryName || !accNo || !ifsc) {
      alert('Enter All Details!!!');
      return;
    }
    let url;
    let data = [];
    if (this.props.title == "Add Bank Deatils") {
      url = server() + '/bankdetails/insert.php';
      data = { shop_id: this.props.shopId, ifsc_code: ifsc, acc_no: accNo, beneficiary_name: beneficiaryName, bank: bank };
    }
    else {
      url = server() + '/bankdetails/update.php';
      data = { id: this.props.item.id, ifsc_code: ifsc, acc_no: accNo, beneficiary_name: beneficiaryName, bank: bank };
    }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Updating!!!' });
      }
    }).done((data) => {
      if (data == 1) {
        alert('Success');
        context.setState({ open: false, bank: '', beneficiaryName: '', accNo: '', ifsc: '', load: false });
        this.props.getBankDetails();
      }
      else {
        this.setState({ load: false })
      }
    });
  }

  edit() {
    this.setState({ bank: this.props.item.bank, beneficiaryName: this.props.item.beneficiary_name,
      accNo: this.props.item.acc_no, ifsc: this.props.item.ifsc_code, editId: this.props.item.id, open: true
    })
  }

  render() {
    let { bank, beneficiaryName, accNo, ifsc } = this.state
    return (
      <div>
        { this.props.title == "Add Bank Deatils" ?
          (<Button onClick={ () => { this.setState({ open: true }) }}>ADD</Button>):
          (<div style={{color: 'blue', cursor: 'pointer'}} onClick={this.edit.bind(this)}>Edit</div>)
        }
        <Load load={this.state.load} message={this.state.message} />
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Bank Name
                <Input fluid placeholder="Bank Name" value={bank} onChange={this.onChangeText('bank')} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Beneficiary Name
                <Input fluid placeholder="Beneficiary Name" value={beneficiaryName} onChange={this.onChangeText('beneficiaryName')} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Acc No
                <Input fluid placeholder="Acc No" value={accNo} onChange={this.onChangeText('accNo')} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                IFSC CODE
                <Input fluid placeholder="IFSC CODE" value={ifsc} onChange={this.onChangeText('ifsc')} />
              </Grid.Column>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({ open: false }) } } color="negative">Cancel</Button>
            <Button color="positive" onClick={this.submit.bind(this)}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
