import React from 'react';
import $ from 'jquery';
import { Table } from 'semantic-ui-react';
import Title from '../product/add_product/title';
import server from '../functions/server';
import Load from '../load';

export default class ProductEnquiry extends React.Component {

  state = {
    enquiries: []
  }

  componentDidMount() {
    let context = this;
    let url = server() + '/enquiry/get.php';
    let data = {'shop_id': this.props.data.id };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Getting Enquires' });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        context.setState({ load: false, enquiries: data })
      } catch(e) {
        alert('Error')
        context.setState({ load: false });
      }
    });
  }

  render() {
    let i = 1;
    return (
      <div>
        <Load open={this.state.load} message={this.state.message} />
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Product Enquiry"} menu={true}/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Product Name</Table.HeaderCell>
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Mobile</Table.HeaderCell>
              <Table.HeaderCell>Date & Time</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          {
            this.state.enquiries.map((v) => {
              return (
                <Table.Row>
                  <Table.Cell>{i++}</Table.Cell>
                  <Table.Cell>{v.product_name}</Table.Cell>
                  <Table.Cell>{v.user_name}</Table.Cell>
                  <Table.Cell>{v.user_mobile}</Table.Cell>
                  <Table.Cell>{this.timeSeprate(v.timestamp)}</Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table>
      </div>
    );
  }
  timeSeprate(value) {
    let date = value.split(' ')[0];
    date = date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0]+' '+value.split(' ')[1];
    return date;
  }
}
