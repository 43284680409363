import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const MyRichTextEditor = (props) => {
  const [dialog, setDialog] = useState(false);
  const [text, setText] = useState(false);

  useEffect(() => {
    setText(props.value);
  });

  return (
    <div>
      <u style={{color: 'blue'}} onClick={() => setDialog(true)}>Preview</u>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
        {
            text ?
            <p
                dangerouslySetInnerHTML={{
                __html: text.includes("http-equiv")
                    ? ""
                    : text
                    ? text
                    : "",
                }}
            />: null
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyRichTextEditor;
