import React, { Component } from 'react';
import { Grid, Image, Button, Form, TextArea, Dropdown, Input } from 'semantic-ui-react';
import $ from "jquery";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Images from './image';
import './add_product.css';
import serverName from '../../functions/server';
import Specification from './specification';
import CategoryDropdown from './category_dropdown';
import MasterCategory from './mastercategory';
import Feature from '../../media/addimage.jpg';
import userId from '../../functions/userid';
import domain from '../../functions/domain';
import Loader from '../../load';
import EditFeatureImage from './editfeatureimage';
import Preview from './preview';

class Addproduct extends Component {

  state = {
    validation_error: '', image1: '', image2: '', image3: '', image4: '', image5: '', feature: Feature, discountPercentage: 0,
    loading: false, name: '', price: '', delete: '', no_stocks: '', description: '', category: [], item_number: '',
    tax: '', discount: '', offer_stocks: '', localname: '', min_stock: 0, hsn_code: '', viewPrice: true, specification: []
  };

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(props) {
    if (props.data != this.props.data) {
      this.setData();
    }
  }

  setData = () => {
    if (this.props.from == 'edit') {
      let tax = this.convertToNumber(this.props.tax);
      let price = this.convertToNumber(this.props.price);
      let discount = this.convertToNumber(this.props.discount);
      let discountPercentage = ((discount / price) * 100).toFixed(1);
      let rate = this.convertToNumber(this.props.dnp);
      let priceWithTax = this.convertToNumber(price + ((price * tax) / 100)).toFixed(2);
      let discountWithTax = this.convertToNumber(discount + ((discount * tax) / 100)).toFixed(2);
      let rateWithTax = this.convertToNumber(rate + ((rate * tax) / 100)).toFixed(2);
      let viewPrice = this.props.view_price == 1 ? true : false;
      this.setState({
        discountPercentage, feature: this.props.featureImage, category: this.props.category, image1: this.props.image1, image2: this.props.image2, min_stock: this.props.min_stock,
        image3: this.props.image3, image4: this.props.image4, image5: this.props.image5, longdescription: this.props.specification, loading: false, name: this.props.name,
        price: priceWithTax, delete: '', dnp: rateWithTax, no_stocks: this.props.noStocks, description: this.props.description, category: this.props.category,
        item_number: this.props.itemNumber, mastercategory: this.props.mastercategory, hsn_code: this.props.hsnCode, tax: this.props.tax, discount: discountWithTax,
        offer_stocks: this.props.offerStocks, localname: this.props.localname, viewPrice: viewPrice, secondaryAndMasterCategory: this.props.category + '->' + this.props.mastercategory,
        seo_keyword: this.props.seo_keyword, howToUse: this.props.how_to_use, otherInformation: this.props.other_information
      })
    }
  }

  get_image(image1, image2, image3, image4, image5) {
    this.setState({ image1, image2, image3, image4, image5 });
  }

  clear_error() { this.setState({ validation_error: '' }); }

  checkFeatureImage() {
    if (this.state.feature == Feature && !this.state.file) {
      return;
    }
    let context = this;
    let reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        if (context.state.feature == e.target.result) {
          return;
        }
        context.setState({ 'feature': e.target.result });
      };
    })(this.state.file);
    try {
      reader.readAsDataURL(this.state.file);
    } catch (e) { }
  }

  saveFeatureImage = (file) => {
    this.setState({ file });
    let changeFeatureImage = this.props.changeFeatureImage;
    changeFeatureImage(file, this.props.id, this.props.feature_image, (receivedFile) => {
    });
  }

  deletedropdown(dropdown) { this.setState({ delete: dropdown, }); }

  onChangeHandle = name => event => {
    if (name == 'name' || name == 'localname') {
      if (event.target.value.match(/[^A-Za-z0-9_\s]/)) {
        alert('Enter Only Characters, Underscore, Numbers, and Space!!!!'); return;
      }
      this.setState({ [name]: event.target.value }); return;
    }
    if (name == 'discountPercentage') {
      if (event.target.value < 0) {
        return
      }
      if (!this.state.price) { alert('Enter the Price!!!'); return; }
      let value = this.convertToNumber(event.target.value);
      let discount = (value / 100) * this.state.price;
      if (discount < 0 || discount == "") {
        discount = 0;
      }
      this.setState({ discountPercentage: event.target.value, discount })
      return;
    }
    if (name == 'discount') {
      if (!this.state.price) { alert('Enter the Price!!!'); return; }
      let value = this.convertToNumber(event.target.value);
      let price = this.convertToNumber(this.state.price);
      let rate = this.convertToNumber(this.state.dnp);
      if (price <= value) {
        alert('Discount Price is More Then Price!!!'); return;
      }
      let discountPercentage = (event.target.value / price) * 100;
      discountPercentage = discountPercentage.toFixed(1);
      this.setState({ discountPercentage })
    }
    if (name == 'offer_stocks') {
      let value = this.convertToNumber(event.target.value);
      let no_stocks = this.convertToNumber(this.state.no_stocks);
      if (no_stocks < value) {
        alert('Offer Stock is More Then Stock!!!'); return;
      }
    }
    this.setState({ [name]: event.target.value })
  }

  convertToNumber(value) {
    try { value = Number(value); }
    catch (e) { value = 0; }
    return value;
  }

  editProducts() {
    let context = this;
    let url_string = window.location.href; var url = new URL(url_string);
    var shopId = url.searchParams.get("shop"); var productId = url.searchParams.get("id");
    let userIds = userId(); let value = this.conditionCheck();
    if (value != 1) { alert(value); return; };
    let tax = this.convertToNumber(this.state.tax); let price = this.convertToNumber(this.state.price);
    let discount = this.convertToNumber(this.state.discount); let rate = this.convertToNumber(this.state.dnp);
    let priceWithoutTax, discountWithoutTax, rateWithOutTax;
    if (tax < 10) { tax = '1.0' + tax; }
    else { tax = '1.' + tax; }
    tax = this.convertToNumber(tax); priceWithoutTax = price / tax; discountWithoutTax = discount / tax;
    rateWithOutTax = rate / tax; let view_price = 1;
    if (!this.state.viewPrice) {
      view_price = 0;
    }
    let data = {
      productName: this.state.name, localname: this.state.localname, price: priceWithoutTax, no_stocks: this.state.no_stocks, description: this.state.description,
      longdescription: this.state.longdescription, category: this.state.category, mastercategory: this.state.mastercategory, dnp: rateWithOutTax, min_stock: this.state.min_stock,
      item_number: this.state.item_number, tax: this.state.tax, discount: discountWithoutTax, hsn_code: this.state.hsn_code, view_price: view_price, offer_stocks: this.state.offer_stocks,
      productId: productId, shopId: shopId, owner: userIds, seo_keyword: this.state.seo_keyword, 
      how_to_use: this.state.howToUse, other_information: this.state.otherInformation
    };
    let urls = serverName() + '/products/edit.php';
    if (this.props.userFrom == 'admin') {
      urls = serverName() + '/admin/editproduct.php';
    }
    context.setState({ load: true, message: 'Editing Product!!!' });
    $.ajax({
      type: 'POST', data: data, url: urls,
      success: function (data) {
        context.setState({ load: false, open: false });
        if (data == 1) {
          alert("Product Edited Successfully");
          if (context.props.userFrom == 'admin') {
            window.location.replace(domain() + '/admin');
          }
        }
        else { alert(data); }
      },
      error: function (data, status) {
        context.setState({ load: false });
        alert("Failed to Add Product");
      }
    });
  }

  conditionCheck() {
    if (!this.state.name) { return 'Enter Product Name'; } if (!this.state.localname) { return 'Enter Local Name'; }
    if (!this.state.price) { return 'Enter Price'; } if (!this.state.no_stocks) { return 'Enter Stock'; }
    if (!this.state.offer_stocks) { return 'Enter Offer Stock'; }
    if (!this.state.dnp) { return 'Enter DNP' } if (this.state.tax == '' || this.state.tax == null || this.state.tax == undefined) { return 'Enter Tax'; }
    if (!this.state.item_number) { return 'Enter Item Number'; }
    if (!this.state.description) { return 'Enter Description'; }
    if (!this.state.hsn_code) { return 'Enter HSN CODE'; }
    if (this.props.from != 'edit') { if (!this.state.file) { return 'Select Feature Image'; } }
    return 1;
  }

  save() {
    let context = this;
    if (this.props.from == 'edit') {
      this.editProducts(); return;
    }
    let tax = this.convertToNumber(this.state.tax);
    let price = this.convertToNumber(this.state.price);
    let rate = this.convertToNumber(this.state.dnp);
    let discount = this.convertToNumber(this.state.discount);
    let priceWithoutTax, discountWithoutTax, rateWithOuttax;
    if (tax < 10) { tax = '1.0' + tax; }
    else { tax = '1.' + tax; }
    tax = this.convertToNumber(tax); priceWithoutTax = price / tax; discountWithoutTax = discount / tax; rateWithOuttax = rate / tax;
    let url_string = window.location.href; var url = new URL(url_string);
    var shopId = this.props.shop; var formData = new FormData();
    let userIds = userId();
    let value = this.conditionCheck(); if (value != 1) { alert(value); return; };
    let viewPrice = 1;
    if (!this.state.viewPrice) { viewPrice = 0; }
    const { name, localname, mastercategory, no_stocks, description, specification, category, file, hsn_code, item_number,
      offer_stocks, min_stock, discountPercentage, seo_keyword } = this.state;
    formData.append('products_name', name); formData.append('localname', localname); formData.append('owner', userIds);
    formData.append('price', priceWithoutTax); formData.append('mastercategory', mastercategory); formData.append('no_stocks', no_stocks);
    formData.append('description', description); formData.append('specification', specification); formData.append('category', category);
    formData.append('shop_id', shopId); formData.append('file', file); formData.append('hsn_code', hsn_code);
    formData.append('discountPercentage', discountPercentage);
    formData.append('item_number', item_number); formData.append('tax', tax); formData.append('discount', discountWithoutTax);
    formData.append('offer_stocks', offer_stocks); formData.append('publish', 1); formData.append('dnp', rateWithOuttax);
    formData.append('min_stock', min_stock); formData.append('viewPrice', viewPrice); formData.append('seo_keyword', seo_keyword); 
    formData.append('how_to_use', this.state.howToUse); formData.append('other_information', this.state.otherInformation);
    if (this.props.from == 'edit') { formData.append('id', this.props.productId); }
    if (this.state.image1) { formData.append('image[]', this.state.image1[0]); }
    if (this.state.image2 && this.state.image2.length) { formData.append('image[]', this.state.image2[0]); }
    if (this.state.image3 && this.state.image3.length) { formData.append('image[]', this.state.image3[0]); }
    if (this.state.image4 && this.state.image4.length) { formData.append('image[]', this.state.image4[0]); }
    if (this.state.image5 && this.state.image5.length) { formData.append('image[]', this.state.image5[0]); }
    let server = serverName() + '/products/addproduct.php';
    if (this.props.from == 'admin') {
      server = serverName() + '/admin/addproduct.php';
    }
    context.setState({ load: true, message: 'Product Adding!!!' });

    $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: server,
      success: function (data) {
        context.setState({ load: false, open: false });
        if (data == 1) {
          if (context.props.from == 'edit') {
            alert('Product Edited Successfully!!!');
            let url_string1 = window.location.href;
            var url1 = new URL(url_string1);
            var froms = url1.searchParams.get("from");
            window.location.replace('https://shop.strackit.com');
          }
          else if (context.props.from == 'admin') {
            alert('Product Added Successfully!!!');
            window.location.replace(domain() + 'admin');
          }
          else if (context.props.from == 'stocks') {
            alert('Product Added Successfully!!!');
            window.location.replace(domain() + 'stocks');
          }
          else {
            alert('Product Added Successfully!!!');
            window.location.replace(domain());
          }
        }
        else { alert(data); console.log(data); }
      },
      error: function (data, status) {
        context.setState({ load: false });
        alert("Failed to Add Product");
      }
    });
  }

  handleCheckbox = (value) => event => { this.setState({ [value]: event.target.checked }) }

  category(type, category) {
    if (type == 'mastercategory') {
      this.refs.child.clearSecoundaryCategory();
      this.setState({ [type]: category, category: '' });
    }
    else {
      this.setState({ [type]: category });
    }
  }

  handleEditorChange = (html) => {
    this.setState({ longdescription: html })
  }

  handleEditorChange1 = (html) => {
    this.setState({ description: html })
  }

  handleEditorChangeHotToUse = (html) => {
    this.setState({ howToUse: html })
  }

  handleEditorChangeotherInformation = (html) => {
    this.setState({ otherInformation: html })
  }

  render() {
    this.checkFeatureImage();
    return (
      <div id="padding">
        <Loader open={this.state.load} message={this.state.message} />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <h3>Product Image</h3>
            {this.props.from == 'edit' ?
              (<div><EditFeatureImage image={this.state.feature} /></div>) :
              (
                <div>
                  <input type="file" accept=".jpg, .png, .webp" name="fileToUpload" id="fileToUpload" onChange={(e) => { console.log(e.target.files[0]); this.setState({ file: e.target.files[0] }); }} style={{ display: 'none' }} />
                  <label htmlFor="fileToUpload"><Image src={this.state.feature} fluid id='image' style={{ cursor: 'pointer' }} /><br /></label>
                </div>
              )
            }
            <Images getProduct={this.props.getProduct} setData={this.setData} ref="images" get_image={this.get_image.bind(this)} from={this.props.from} get_data={this.props.data} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={10}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Products Name <Input fluid onChange={this.onChangeHandle('name')} value={this.state.name} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Local Name <Input fluid onChange={this.onChangeHandle('localname')} value={this.state.localname} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                DNP <Input onChange={this.onChangeHandle('dnp')} value={this.state.dnp} fluid type="number" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                Price <Input onChange={this.onChangeHandle('price')} value={this.state.price} fluid type="number" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Available Stocks <Input fluid type="number" onChange={this.onChangeHandle('no_stocks')} value={this.state.no_stocks} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                Min. Stocks <Input fluid type="number" onChange={this.onChangeHandle('min_stock')} value={this.state.min_stock} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                HSN CODE<Input fluid onChange={this.onChangeHandle('hsn_code')} value={this.state.hsn_code} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                Tax<Input fluid type="number" onChange={this.onChangeHandle('tax')} value={this.state.tax} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                Discount % <Input fluid type="number" onChange={this.onChangeHandle('discountPercentage')} value={this.state.discountPercentage} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Discount Price <Input fluid type="number" onChange={this.onChangeHandle('discount')} value={this.state.discount} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                Offer Stocks <Input onChange={this.onChangeHandle('offer_stocks')} value={this.state.offer_stocks} fluid type="number" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6} style={{ display: 'none' }}>
                Master Category
                <MasterCategory shopId={this.props.shop} add_product={this.category.bind(this)} category={this.state.mastercategory} edit={this.props.edit}
                  from={this.props.from} deletedropdown={this.deletedropdown.bind(this)} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={12}>
                Category
                <CategoryDropdown secondaryAndMasterCategory={this.state.secondaryAndMasterCategory} ref="child" shopId={this.props.shop} add_product={this.category.bind(this)} category={this.state.category} mastercategory={this.state.mastercategory} edit={this.props.edit}
                  from={this.props.from} deletedropdown={this.deletedropdown.bind(this)} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                {'Item Number'}<Input fluid onChange={this.onChangeHandle('item_number')} value={this.state.item_number} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {'SEO Keyword'}<Input fluid onChange={this.onChangeHandle('seo_keyword')} value={this.state.seo_keyword} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {/* <Form>Description
                    <TextArea autoHeight value={this.state.description} onChange={this.onChangeHandle('description')} />
                  </Form> */}
                <b>Description</b>
                <ReactQuill style={{ height: '140px' }}
                  value={this.state.description}
                  onChange={this.handleEditorChange1}
                  placeholder="Write something about product..."
                />
                <br /><br />
                <Preview value={this.state.description} />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={16} computer={8}>
                {/* <Form>Long Description
                    <TextArea autoHeight value={this.state.longdescription} onChange={this.onChangeHandle('longdescription')} />
                  </Form> */}
                <b>Long Description</b>
                <ReactQuill style={{ height: '140px' }}
                  value={this.state.longdescription}
                  onChange={this.handleEditorChange}
                  placeholder="Write something about product..."
                />
                <br /><br />
                <Preview value={this.state.longdescription} />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={16} computer={8}>
                {/* <Form>Long Description
                    <TextArea autoHeight value={this.state.longdescription} onChange={this.onChangeHandle('longdescription')} />
                  </Form> */}
                <br/><b>How to use?</b>
                <ReactQuill style={{ height: '140px' }}
                  value={this.state.howToUse}
                  onChange={this.handleEditorChangeHotToUse}
                  placeholder="Write something about product..."
                />

              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {/* <Form>Long Description
                    <TextArea autoHeight value={this.state.longdescription} onChange={this.onChangeHandle('longdescription')} />
                  </Form> */}
                <br/><b>Other Information</b>
                <ReactQuill style={{ height: '140px' }}
                  value={this.state.otherInformation}
                  onChange={this.handleEditorChangeotherInformation}
                  placeholder="Write something about product..."
                />

              </Grid.Column>

              <Grid.Column mobile={16} tablet={16} computer={8}>
                <br />
                <Preview value={this.state.howToUse} />
                <FormControlLabel
                  control={
                    <Checkbox toggle checked={this.state.viewPrice} color="primary"
                      onChange={this.handleCheckbox('viewPrice')} name="checkedA" />
                  }
                  label="Display Price on Web" />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <br />
                <Preview value={this.state.otherInformation} />
                <Specification productId={this.props.productId} getValue={(name, value) => { this.setState({ [name]: value }) }} from={this.props.from} specification={this.state.specification} mastercategory={this.state.mastercategory} category={this.state.category} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <center><br /><Button color='positive' onClick={this.save.bind(this)}>Save All Changes</Button></center>
      </div>
    );
  }
}

export default Addproduct;
