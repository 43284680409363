import React from 'react';
import { Grid, Image, Header, Icon, Card } from 'semantic-ui-react';

export default class Payment extends React.Component {

  render() {
    let subdomain = "http://" + this.props.name.split(" ").join("").toLowerCase() + ".strackit.com";
    let domain = "http://" + this.props.name.split(" ").join("").toLowerCase() + ".com";
    let subdomainDescription = (
      <div>
        <p>Create a sub domain for your site so that people will easily remember your site.</p>
        <p>You can Access from:</p>
        <a target="_blank" href={subdomain}>{subdomain}</a>
      </div>
    );
    let domainDescription = (
      <div>
        <p>Create a domain for your site so that people will easily remember your site.</p>
        <p>You can Access from:</p>
        <a target="_blank" href={domain}>{domain}</a>
      </div>
    );
    let diamondDescription = (
      <div>
        <p>All the features from Gold.</p>
        <p>Seperate Android App for your Shop and users can order from there easily</p>
        <a>Unique Android App</a>
      </div>
    );
    let platinumDescription = (
      <div>
        <p>All the features from Gold and Diamond.</p>
        <p>Seperate Android and iOS App for your Shop and users can order from there easily</p>
        <a>Unique iOS and Android App</a>
      </div>
    );
    return (
      <div style={{paddingBottom: 50, display: 'none'}}>
        <center>
          <Header as='h2' color='teal'>
            <Icon name='cube' />
            <Header.Content>
              More Options
              <Header.Subheader>Sub-Domain and Domains</Header.Subheader>
            </Header.Content>
          </Header>
        </center>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div>
              <center>
                <Card>
                 <Card.Content header='Silver' />
                 <Card.Content description={subdomainDescription} /><br/>
                 <Card.Content extra>
                   <Icon name='rupee sign' />
                   <strike>5000/Year</strike>1,250/year
                   <br /><br />
                   <div class='pm-button'>
                     <a target="_blank"
                       href='https://www.payumoney.com/paybypayumoney/#/B12456B6F84166E579FF7D6CA16F1640'>
                       <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/23.png' />
                     </a>
                   </div>
                 </Card.Content>
               </Card>
              </center>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div>
              <center>
                <Card>
                 <Card.Content header='Gold' />
                 <Card.Content description={domainDescription} /><br/>
                 <Card.Content extra>
                   <Icon name='rupee sign' />
                   <strike>20,000/Year</strike>11,899/year
                   <br /><br /><br />
                   <div class='pm-button'>
                     <a target="_blank"
                       href='https://www.payumoney.com/paybypayumoney/#/EE39423445C1780EBD69BD976CEC0D0F'>
                       <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/23.png' />
                     </a>
                   </div>
                 </Card.Content>
               </Card>
              </center>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div>
              <center>
                <Card>
                 <Card.Content header='Diamond' />
                 <Card.Content description={diamondDescription} /><br/>
                 <Card.Content extra>
                   <Icon name='rupee sign' />
                   <strike>40,000</strike>24,899
                   <br /><br /><br />
                   <div class='pm-button'>
                     <a target="_blank"
                       href='https://www.payumoney.com/paybypayumoney/#/C409FF85C5AB6A229668A1F4B51A7C37'>
                       <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/23.png' />
                     </a>
                   </div>
                 </Card.Content>
               </Card>
              </center>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div>
              <center>
                <Card>
                 <Card.Content header='Platinum' />
                 <Card.Content description={platinumDescription} />
                 <Card.Content extra>
                   <Icon name='rupee sign' />
                   <strike>75,000</strike>48,799
                   <br /><br />
                   <div class='pm-button'>
                     <a target="_blank"
                       href='https://www.payumoney.com/paybypayumoney/#/A3F45C08C625A3671350B74298E2CE19'>
                       <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/23.png' />
                     </a>
                   </div>
                 </Card.Content>
               </Card>
              </center>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
