import React from 'react';
import $ from 'jquery';
import { Table } from 'semantic-ui-react';

import AddLedger from '../ledgertransaction/add';
import Title from '../product/add_product/title';
import server from '../functions/server';
import Load from '../load';
import View from './view';

export default class Ledger extends React.Component {

  state = { load: false, ledger: [] }

  componentDidMount() {
    this.getLedger();
  }

  getLedger() {
    let context = this;
    let url = server() + '/ledger/getallledgervalue.php';
    let data = { shopId: this.props.data.id };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      let out = [];
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({ load: false, ledger: out });
    });
  }

  updateItem(type, id, name) {
    if (type == 'Update') {
      let data = this.state.ledger;
      let newData = [];
      data.map((c) => { if (c.id == id) { c.status = name; } })
      this.setState({ ledger: data })
    }
    else if (type == 'Delete') {
      let data = this.state.ledger;
      let newData = [];
      data.map((c) => { if (c.id != id) { newData.push((c)); } })
      this.setState({ ledger: newData })
    }
    else {
      let data = this.state.ledger;
      data.push({ 'id': id, 'ledger': name, 'status': 1 })
      this.setState({ ledger: data })
    }
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    x = this.state.ledger.map((c) => {
      return (
        <View item={c} sino={i++} updateItem={this.updateItem.bind(this)} />
      )
    })
    return (
      <div>
        <Load open={this.state.load} message='Getting Ledgers' />
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Ledger"} menu={true}/>
        <div style={{padding: '10%'}}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>SINO</Table.HeaderCell>
                <Table.HeaderCell width={5}>LEDGER NAME</Table.HeaderCell>
                <Table.HeaderCell width={3}>STATUS</Table.HeaderCell>
                <Table.HeaderCell width={1}>DELETE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {x}
            </Table.Body>
          </Table>
          <center><AddLedger updateItem={this.updateItem.bind(this)} shopId={this.props.shopId} /></center>
        </div>
      </div>
    );
  }
}
