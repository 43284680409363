import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";

import Drawer from './drawer';

const styles = {
  flex: {
    flex: 1,
    marginLeft: 15
  },
};

class Title extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {
              this.props.menu && (
                <Drawer shopDetails={this.props.shopDetails} shopId={this.props.shopId} />
              )
            }
            <Typography variant="title" color="inherit" className={classes.flex}
              onClick={() => (this.props.history.push("/"))}>
              <div style={{cursor: 'pointer'}}>{this.props.title}</div>
            </Typography>
            {this.props.button1}
            {this.props.button2}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Title));
