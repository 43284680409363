import React from 'react';
import $ from 'jquery';
import { Image, Table, Form, Button, Icon, Dropdown } from 'semantic-ui-react';

export default class Item extends React.Component {

  componentDidMount() {
    let context = this;
    $('#quantity' + this.props.item.id).bind('keypress', function(e) {
      if(e.keyCode === 13){
          context.props.addNewProduct();
      }
    });
    $('#discount' + this.props.item.id).bind('keypress', function(e) {
      if(e.keyCode === 13){
          context.props.addNewProduct();
      }
    });
  }

  updateItem = (key) => (e, { value }) => {
    try {
      if (key === 'quantity' || key === 'discount' || key === 'discountrs' || key === 'totprice') {
        if (value < 0) {
          alert('Negative Value!!!');
          return;
        }
        value = Number(value);
        let no_stock = Number(this.props.item.no_stock);
        if (value > no_stock) {
          alert("Your Entered Quantity is More than the Stock Remaining \nAvailable Stocks : "+this.props.item.no_stock);
          return;
        }
      }
    } catch (e) { return; }
    this.props.updateItem(this.props.item.id, key, value);
  }

  updateItem1 = (key) => (e, { value }) => {
    try {
      if (key === 'discount' || key === 'discountrs' || key === 'totprice') {
        if (isNaN(value)) {
          alert('Invalid Value!!!')
          return;
        }
        else {
          if (value < 0) {
            alert('Negative Value!!!');
            return;
          }
          else {
            this.props.updateItem(this.props.item.id, key, value);
          }
        }
      }
    } catch (e) { return; }
  }

  getTotalPrice = () => {
    let price = this.props.item.price * this.props.item.quantity;
    let tax = this.props.item.tax;
    return Number(price + ((price * tax) / 100)).toFixed(2);
  }

  render() {
    let { item } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell style={{width: '30%'}}>
            <Dropdown placeholder='Select Item' fluid selection value={item.item_id}
              ref={(input) => { this.nameInput = input }} search
              options={this.props.stockOptions} onChange={this.updateItem('item_id')}
           />
        </Table.Cell>
        <Table.Cell>
        <Form.Input fluid placeholder={item.no_stock} min={0} type={'number'} value={item.quantity} id={"quantity" + item.id}
              onChange={this.updateItem('quantity')} />
      </Table.Cell>
        <Table.Cell>{Number(item.price).toFixed(2)}</Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder="Disc(%)" value={item.discount} id={"discount" + item.id}
            onChange={this.updateItem1('discount')} min={0} type={'number'} 
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder="Disc(Rs)" value={item.discountrs} id={"discountrs" + item.id}
            onChange={this.updateItem1('discountrs')} min={0} type={'number'} 
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
        </Table.Cell>
        <Table.Cell>{item.tax}</Table.Cell>
        <Table.Cell>{Number(item.price * item.quantity).toFixed(2)}</Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder="Total Price" value={item.finalPrice} id={"totprice" + item.id}
            onChange={this.updateItem1('totprice')} 
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} />
        </Table.Cell>
        <Table.Cell>
          <Button animated='vertical' negative onClick={() => this.props.removeItem(this.props.item.id)}>
            <Button.Content hidden>Remove</Button.Content>
            <Button.Content visible>
              <Icon name='remove' />
            </Button.Content>
          </Button>
          {
            (item.id == this.props.total) ? (
              <Button animated='vertical' positive onClick={() => this.props.addNewProduct()}>
                <Button.Content hidden>Add</Button.Content>
                <Button.Content visible><Icon name='add' /></Button.Content>
              </Button>
            ) : ""
          }
        </Table.Cell>
      </Table.Row>
    );
  }

}
