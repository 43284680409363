import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Load from '../../../load';
import server from '../../../functions/server';
import userId from '../../../functions/userid';
import Total from '../../add/total';
import Item from './item';
import AddBilling from '../../add';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Orders extends React.Component {

  state = { open: false, out: [] }
  
  componentDidMount() {
  
  }

  getOrder = () => {
    let context = this;
    let url = server() + '/orders/getorderdetails.php';
    let data = {id: this.props.order.id};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let out = 1;
      let open = false;
      try {
        out = JSON.parse(data);
        console.log(out);
        open = true;
      } catch (e) { }
      context.setState({ out: out, load: false, open });
    });
  }

  openDialog(out, customerName) { this.setState({ open: true, out, customerName }) }

  editOrder() {
    this.setState({ editDialog: true })
    console.log(this.state.out);
  }

  render() {
    let body = (<div></div>);
    if (this.state.out) {
      let count = 0;
      body = this.state.out.map((order) => {
        return (
          <Item item={order} count={++count} refresh={this.getOrder}/>
        );
      });
    }

    return (
      <div>
        { this.props.from != 'offline' ?
          (
            <Button animated='vertical' color='blue'
              onClick={this.getOrder}>
              <Button.Content hidden>Bill</Button.Content>
              <Button.Content visible>
                <Icon name='unordered list' />
              </Button.Content>
            </Button>
          ):''
        }
        <Dialog fullScreen open={this.state.open}
          onClose={this.handleClose} TransitionComponent={Transition}>
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({open: false})} aria-label="Close">
                <CloseIcon style={{color: 'white'}} />
              </IconButton>
              { this.props.from == 'offline' ?
                (<Typography variant="h6" color="inherit" style={styles.flex}>{this.state.customerName}</Typography>):
                (
                  <Typography variant="h6" color="inherit" style={styles.flex}>
                    Bill Number: {this.props.order.id}
                  </Typography>
                )
              }
            </Toolbar>
          </AppBar>
          <Table>
            <Table.Header>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Tax</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Tax Price</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {body}
            </Table.Body>
          </Table>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Total stocks={this.state.out}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <center><Button style={{width: '100px'}} onClick={this.editOrder.bind(this)}>Edit</Button></center>
          <Load open={this.state.load}/>
        </Dialog>

        <Dialog fullScreen open={this.state.editDialog}
          onClose={ () => { this.setState({ editDialog: false }) }} TransitionComponent={Transition}>
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({editDialog: false})} aria-label="Close">
                <CloseIcon style={{color: 'white'}} />
              </IconButton>
              { this.props.from == 'offline' ?
                (<Typography variant="h6" color="inherit" style={styles.flex}>{this.state.customerName}</Typography>):
                (
                  <Typography variant="h6" color="inherit" style={styles.flex}>
                    Bill Number: {this.props.order.id}
                  </Typography>
                )
              }
            </Toolbar>
          </AppBar>
          <AddBilling data={this.props.data} dialogClose={ () => { this.setState({ editDialog: false, open: false }); this.getOrder(); } } type={'edit'} stocks={this.state.out} />
        </Dialog>
      </div>
    );
  }
}
