import React from 'react';
import { Table } from 'semantic-ui-react';

import Return from './return';

export default class Item extends React.Component {

  makeDecimalPoint(value) {
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0
    }
    return value;
  }

  render() {
    let { item } = this.props;
    let total_price = this.makeDecimalPoint(item.total_price);
    let rate = this.convertToNumber(item.price);
    let tax = this.convertToNumber(item.tax);
    return (
      <Table.Row negative={item.returned == 1}>
        <Table.Cell>{this.props.count}</Table.Cell>
        <Table.Cell>{item.product_name}</Table.Cell>
        <Table.Cell>{this.makeDecimalPoint(rate + (rate*tax)/100)}</Table.Cell>
        <Table.Cell>{item.quantity}</Table.Cell>
        <Table.Cell>{item.tax}</Table.Cell>
        <Table.Cell>{this.makeDecimalPoint((rate * item.quantity))}</Table.Cell>
        <Table.Cell>{this.makeDecimalPoint(((rate * tax)/100) * item.quantity)}</Table.Cell>
        <Table.Cell>{this.makeDecimalPoint(item.total_price)}</Table.Cell>
        <Table.Cell>
          {item.returned == 0 && (<Return item={item} refresh={this.props.refresh}/>)}
        </Table.Cell>
      </Table.Row>
    );
  }
}
