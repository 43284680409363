import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import Buttons from '@material-ui/core/Button';
import $ from "jquery";
import domain from '../functions/domain';
import server from '../functions/server';
import userId from '../functions/userid';
import Cookies from '../functions/cookie';

class Checkout extends Component {
   constructor(props) {
       super(props);
       this.state = { checked: false,  payment_amount: 0, amount: 0 };
       this.handleChange = this.handleChange.bind(this);
       this.paymentHandler = this.paymentHandler.bind(this);
    }

   componentDidMount () {
       const script = document.createElement("script");
       script.src = "https://checkout.razorpay.com/v1/checkout.js";
       script.async = true;
       document.body.appendChild(script);
   }

   handleChange() {
      this.setState({ checked: !this.state.checked })
   }

   getOrderId = (e) => {
     let amount = this.props.amount;
     this.setState({ loading: true });
     $.ajax({
       type: 'POST', data: {amount},
       url: server() + '/razorpay/createorder.php',
       success: (data) => {
         console.log(data);
         if (data) {
           this.paymentHandler(e, data);
         }
         this.setState({ loading: false });
       },
       error: (err) => {
         this.setState({ loading: false });
       }
     });
   }

  paymentHandler(e, orderId) {
    let context = this;
    let user = userId();
    // live: "rzp_live_H5PLsQ5XB0nes8"
    // test: "rzp_test_YRooja23Qaxkw2"
    let mobie = "", email = "";
    let cookie = Cookies();
    if (cookie && cookie.mobile) {
      mobie = cookie.mobile;
    }
    if (cookie && cookie.email) {
      email = cookie.email;
    }
    
    let status;
    e.preventDefault();
    let options = {
      "key": "rzp_live_H5PLsQ5XB0nes8",
      "amount": this.props.amount,
      "name": "Strackit Business",
      "description": this.props.description,
      "prefill.email": email,
      "mobile": mobie,
      "order_id": orderId,
      "image": "https://lh3.googleusercontent.com/gwosqikk3a2ufrRr08_-QSxeXo39vBnmjZdniLMN6vn2GfA1ipYq6cYUysFNVjcD8O4=s180-rw",
      "currency": "INR",
      "payment_capture": true,
      "handler": function (response){
        if (typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1) {
          status = 0;
        } else {
          status = 1;
        }
        $.ajax({
          type: 'POST',
          data: { 'payment_id': response.razorpay_payment_id, 'shop_id': context.props.shopId,
                  'amount': context.props.originalAmount, 'mode': 'Razorpay', 'duration': context.props.duration,
                  'status': status, 'user': user, 'description': context.props.description},
          url: context.props.url,
          success: function(data) {
            if (data == 1) {
              alert('Your Payment was Success!!!');
              if (context.props.description == 'SMS RECHARGE') {
                context.props.updateData();
              }
              else {
                let url = window.location.href;
                url = url.split('/')[3];
                window.location = domain()+url;
              }
            }
            else {
              alert('Payment UnSuccess \n\n Error \n\n Contact: +91 98945 71739');
              return;
            }
          },
          error: function(err) {
            console.log(err);
          }
        });
      },
      "notes": {
        "address": "Hello World"
      },
      "theme": {
        "color": "#4A88D1"
      }
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  }

   render() {
       return (
         <div>
           <div className="bg">
              <div className="payments-form">
                { this.props.from == "package" ?
                  (
                    <Button fluid onClick={this.getOrderId} color="primary">
                      Pay
                    </Button>
                  ):
                  (
                    <Button onClick={this.getOrderId} fluid primary>Pay</Button>
                  )
                }
              </div>
            </div>
         </div>
       );
   }
}
export default Checkout;
