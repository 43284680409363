import React from 'react';
import $ from 'jquery';
import server from '../functions/server';
import { Delete } from '@material-ui/icons/';
import { Button } from 'semantic-ui-react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const remove = () => {
    $.ajax({type: "POST", url: server() + '/categories/deletemaster.php', data: { id: props.data.id }, success: (data) => {
      console.log(data);
      console.log('delete error');
      if (data === "1") {
        setOpen(false);
        props.refresh();
      } else {
        alert("Failed");
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  return (
    <span style={{marginLeft: 10}}>
      <Icon color="secondary" onClick={handleClickOpen}>
        delete
      </Icon>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete {props.data.category}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={remove} color="danger" startIcon={<Delete />}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
