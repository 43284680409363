import React from 'react';
import { Grid } from 'semantic-ui-react';

import Title from '../product/add_product/title';
import Category from './category';
import Products from './products';

export default class CategoryIndex extends React.Component {

  state = {category: ""}

  handleChange = (type, value) => {
    this.setState({[type]: value});
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Category Alignment"} menu={true}/>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Category data={this.props.data} handleChange={this.handleChange}/>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Products data={this.props.data} category={this.state.category}/>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
