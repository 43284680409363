import React from 'react';
import { Button, Menu, Header, Segment, Icon, Image, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import AddImage from '../../media/addimage.jpg';
import AddIcon from '../../media/addicon.png';
import DeleteIcon from '@material-ui/icons/Call';
import QRCode from '../description/qrcode';

import Products from '../products';
import Map from './map';

const styles = {
  center: {
    maxWidth: 700
  },
  leftpad: {
    paddingLeft: 20
  },
  topPad: {
    marginTop: 30
  },

  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20
  }
}

class View extends React.Component {

  state = {
    auth: false,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  setMeta() {
    let title = this.props.data.shop_name;
    if (title.length < 30) {
      title = title + " - " + this.props.data.category + " Company Located in " + this.props.data.city;
    }
    document.title = title;
    let desc = this.props.data.description;
    if (!desc) {
      desc = title + ", " + this.props.data.state + ", " + this.props.data.country;
    }
    try {
      document.getElementsByTagName("META")[3].content=desc;
    } catch(e) {}
  }

  render() {
    const { classes } = this.props;
    let icon = (this.props.data.icon) ? this.props.data.icon : AddIcon;
    let feature_image = (this.props.data.feature_image) ? this.props.data.feature_image : AddImage;
    this.setMeta();
    let mapOptions = {center: { lat: 0, lng: 0},zoom: 2};
    if (this.props.data.latitude != 0 || this.props.data.longitude != 0) {
      let latLng = { lat: parseFloat(this.props.data.latitude), lng: parseFloat(this.props.data.longitude)};
      mapOptions = {center: latLng,zoom: 12};
    }
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {this.props.data.shop_name}
            </Typography>
            {this.state.auth && (
              <div>
                <IconButton
                  aria-owns={this.state.open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                  <MenuItem onClick={this.handleClose}>My account</MenuItem>
                </Menu>
              </div>
            )}
            {this.props.data.view && (
              <a href={"tel:" + this.props.data.phone}>
                <Button as='div' labelPosition='right'>
                  <Button color='red'>
                    <Icon name='phone' />
                    Call
                  </Button>
                  <Label as='a' basic color='red' pointing='left'>
                    {this.props.data.phone}
                  </Label>
                </Button>
              </a>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <div style={{ marginLeft: '1%', marginRight: '1%'}}>
            <center style={{paddingBottom: 50}}>
              <Image src={icon} size='small' circular style={{cursor: 'pointer', marginTop: 70}}/>
              <Header as='h1' textAlign='center' color='blue' style={styles.topPad}>
                {this.props.data.shop_name}
              </Header>
              <Segment padded style={{...styles.center, ...styles.topPad}}
                onClick={() => this.setState({editdescription: true})}>
                <Label attached='top' color='blue'>About</Label>
                <br />
                <b>{this.props.data.description}</b>
              </Segment>
              <br /><br />
              <Image src={feature_image} size='massive' style={{cursor: 'pointer', ...styles.topPad}}/>
              <QRCode data={this.props.data}/>
              <Products data={this.props.data} from={this.props.from}/>
              { (this.props.data.latitude != 0 || this.props.data.longitude != 0) &&
                <Map
                id="myMap"
                options={mapOptions}
                onMapLoad={map => {
                  var marker = new window.google.maps.Marker({
                    position: { lat: parseFloat(this.props.data.latitude), lng: parseFloat(this.props.data.longitude) },
                    map: map,
                    title: 'Hello Istanbul!'
                  });
                }} />
              }
              <Segment padded style={{width:'50%', marginTop:50}}>
                <Label attached='top'>Address</Label>
                <br /><br />
                <b>
                  {this.props.data.address},<br />
                  {this.props.data.city}, {this.props.data.state},<br />
                  {this.props.data.country} - {this.props.data.pincode}<br />
                  GSTIN: {this.props.data.gstin}<br /><br />
                  Contact Number: <a href={"tel:" + this.props.data.phone}>{this.props.data.phone}</a>
                </b>
              </Segment>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

View.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(View);
