import React from 'react';
import { Button, Card, Grid, Image, Input, Form, TextArea, } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import $ from 'jquery';
import server from '../functions/server';
import userId from '../functions/userid';

export default class AddCoupon extends React.Component {

    state = {
        addCoupon: false,
        name: null,
        description: null,
        code: null,
        validityFrom: null,
        validityTo: null,
        priceRange: null,
        discount: null,
        status: null,
    }

    addCouponCode = () => {
        let context = this;
        let shopId = context.props.shopId;
        let { name, code, description, validityFrom, validityTo, priceRange, discount, status } = this.state;
        if (!name?.trim()) {
            alert("Please Add Name")
            return
        }
        if (!code?.trim()) {
            alert("Please Add code")
            return
        }
        if (!description?.trim()) {
            alert("Please Add Description")
            return
        }
        if (!validityFrom?.trim()) {
            alert("Please Add validityFrom")
            return
        }
        if (!validityTo?.trim()) {
            alert("Please Add validityTo")
            return
        }
        if (!priceRange?.trim()) {
            alert("Please Add priceRange")
            return
        } if (!discount?.trim()) {
            alert("Please Add discount")
            return
        }
        let url = server() + '/couponcode/add.php';
        let data = {
            shop_id: shopId, name, code, description, validity_from: validityFrom,
            validity_to: validityTo, price_range: priceRange, discount: discount, status: 1,
            user_id: userId(),
        };
        $.ajax({
            url, data, type: 'POST',
        }).done((data) => {
            if (data == 1 || data == '1') {
                alert("Success");
                this.props.getCoupons();
                this.setState({ name: '', code: '', description: '', validityFrom: '', validityTo: '', 
                priceRange: '', discount: '', load: false, addCoupon: false })
            }
            else {
                alert('Failed to save');
                this.setState({ load: false })
            }
        });
    }

    render() {
        return (<div>
            <Button positive onClick={() => { this.setState({ addCoupon: true }) }}>Create Coupon</Button>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={this.state.addCoupon}
                onClose={() => { this.setState({ addCoupon: false }) }}
                aria-labelledby="max-width-dialog-title"
            >
                {/* <DialogTitle id="max-width-dialog-title">{this.props.types}</DialogTitle> */}
                <DialogContent>
                    <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Name</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                value={this.state.name}
                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Coupon Code</div>
                            <Input style={{ width: '100%' }} placeholder='Coupon Code' fluid
                                value={this.state.code}
                                onChange={(e) => { this.setState({ code: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Purchase Range</div>
                            <Input style={{ width: '100%' }} placeholder='Purchase Range' fluid
                                value={this.state.priceRange}
                                onChange={(e) => { this.setState({ priceRange: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Discount</div>
                            <Input style={{ width: '100%' }} placeholder='Purchase Range' fluid
                                value={this.state.discount}
                                onChange={(e) => { this.setState({ discount: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>From Date</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                type='date'
                                value={this.state.validityFrom}
                                onChange={(e) => { this.setState({ validityFrom: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>To Date</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                type='date'
                                value={this.state.validityTo}
                                onChange={(e) => {
                                    console.log("**", e.target.value);
                                    this.setState({ validityTo: e.target.value })
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Form fluid>
                                <TextArea maxlength="999" style={{ minHeight: 150 }} placeholder='Description' fluid
                                    value={this.state.description}
                                    onChange={(e) => { this.setState({ description: e.target.value }) }}
                                />
                            </Form><br />
                        </Grid.Column>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button negative onClick={() => { this.setState({ addCoupon: false }) }}>Cancel</Button>
                    <Button positive content="Create" loading={this.state.loading}
                        onClick={() => { this.addCouponCode() }}
                    />
                </DialogActions>
            </Dialog>
        </div >)
    }
}