import React from 'react';
import { Header, Icon, Dimmer, Segment, Loader } from 'semantic-ui-react';
import $ from 'jquery';
import Map from './map';
import userId from '../../../functions/userid';
import server from '../../../functions/server';

export default class EditMap extends React.Component {

  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    this.setState({
      latitude: this.props.data.latitude,
      longitude: this.props.data.longitude
    });
  }

  updateLocation = (position) => {
    let context = this;
    let shop = this.props.data.id;
    let data = {id: shop, owner: userId(), lat: position.lat, lng: position.lng};
    $.ajax({
      type: 'POST',
      url: server() + "/shop/updatelocation.php",
      data: data,
      beforeSend: function(x) {
          context.setState({
            dimmer: true
          });
      },
      success: function(data, status) {
        let editaddress = true;
        if (data == "1") {
          alert("Location Updated Successfully");
        } else {
          alert("Failed to Update Location");
        }
        context.setState({
          dimmer: false
        });
      },
      error: function(data, status) {
        alert(data);
        context.setState({
          dimmer: false
        });
      }
    });
  }

  render() {
    let mapOptions = {center: { lat: 0, lng: 0},zoom: 2};
    let lat = this.state.latitude ? this.state.latitude : 0;
    let lng = this.state.longitude ? this.state.longitude : 0;
    if (lat != 0 || lng != 0) {
      let latLng = { lat: parseFloat(lat), lng: parseFloat(lng)};
      mapOptions = {center: latLng,zoom: 12};
    }
    console.log(mapOptions);
    return (
      <center style={{marginTop:50}}>
        <Header as='h2' color='teal'>
          <Icon name='map' />
          <Header.Content>
            Shop Location
            <Header.Subheader>Click To Edit</Header.Subheader>
          </Header.Content>
        </Header>

        <Map id="myMap" options={mapOptions} data={this.props.data} updateLocation={this.updateLocation} />

        <Dimmer active={this.state.dimmer} page>
          <Header as='h2' inverted>
            <Loader />
            <div style={{marginTop: 100}}>Updating Location...</div>
          </Header>
        </Dimmer>
      </center>
    );
  }
}
