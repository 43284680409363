import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Title from '../product/add_product/title';
import { Grid } from 'semantic-ui-react';
import PurchaseReport from './purchase';
import SalesReport from './sales';
import Prime from '../prime';
import StockReport from './stockreport';
import GrossProfitReport from './grossprofit';

export default class Billing extends React.Component {

  render() {

    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Reports"} menu={true}/>
        { this.props.data.prime == 0 ?
          (
            <div><Prime data={this.props.data}/></div>
          ):
          (
            <Grid style={{paddingTop: '1.5%', paddingLeft: '1%', paddingRight: '1%'}}>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Paper>
                  <Typography variant="h5" component="h3" style={{padding: '1%', color: '#2261A1'}}>
                    Sales
                  </Typography>
                  <SalesReport data={this.props.data} />
                </Paper>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Paper>
                  <Typography variant="h5" component="h3" style={{padding: '1%', color: '#2261A1'}}>
                    Purchase
                  </Typography>
                  <PurchaseReport data={this.props.data} />
                </Paper>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <center style={{display: 'flex', marginLeft: '38%'}}>
                <StockReport data={this.props.data} />
                <GrossProfitReport data={this.props.data} />
                </center>

              </Grid.Column>
            </Grid>
          )
        }
      </div>
    );
  }

}
