import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Load from '../loads';
import server from '../functions/server';

const styles = {
  appBar: { position: 'relative' },
  flex: { flex: 1 },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Orders extends React.Component {

  state = {
    open: false, data: [],
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  getReport = () => {
    let context = this;
    let url = server() + '/reports/stockreport.php';
    let data = {shopId: context.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Getting Stock Report!!!' });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        console.log(data);
        context.setState({ data: data, open: true, load: false })
      } catch(e) { alert('Error to get stock report!!!'); context.setState({ load: false })  }
    });
  }

  handleChange = (name) => event => {
    this.setState({ [name]: event.target.value })
  }

  converttoNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimalPoints(value, tax) {
    value = this.converttoNumber(value);
    tax = this.converttoNumber(tax);
    try {
      value = Number(value + ((value * tax)/100)).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  grossProfit(purchaseAmount, salesAmount) {
    let total = 0;
    try {
      total = (salesAmount - purchaseAmount).toFixed(2);
    } catch (e) {
      total = 0;
    }
    return total;
  }

  makesDescimal(value) {
    try {
      value = value.toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  export() {
    let downloadLink;
    let filename = 'GstReport';
    let dataType = 'application/vnd.ms-excel';
    let tableSelect = document.getElementById('export');
    let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename?filename+'.xls':'excel_data.xls';
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if(navigator.msSaveOrOpenBlob) {
      let blob = new Blob(['\ufeff', tableHTML], {
          type: dataType
      });
      navigator.msSaveOrOpenBlob( blob, filename);
    }
    else {
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

  render() {
    let salesAmount = 0;
    let purchaseAmount = 0;
    let i = 1;
    return (
      <div>
        <Button color='blue' onClick={this.getReport}>
          Stock Report
        </Button>



        <Dialog fullScreen open={this.state.open}
          onClose={this.handleClose} TransitionComponent={Transition} >
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({open: false})} aria-label="Close">
                <CloseIcon style={{color: 'white'}} />
              </IconButton>
              <Typography variant="h6" color="inherit" style={styles.flex}>
                Stock Report
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{padding: '2%'}}>
            <div style={{display: 'row'}}>
              <input type="date" value={this.state.fromDate} onChange={this.handleChange('fromDate')} style={{height: 35 }} />
              <input type="date" value={this.state.toDate} onChange={this.handleChange('toDate')} style={{height: 35 }} />
              <Button onClick={this.getReport}>Submit</Button>
              <Button color='blue' onClick={this.export.bind(this)}>Export</Button>
            </div>
            <Table celled striped id="export">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell colSpan={2}><center><b style={{color: 'blue'}}>SALES</b></center></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2}><center><b style={{color: 'blue'}}>PURCHASE</b></center></Table.HeaderCell>
                  <Table.HeaderCell><center><b style={{color: 'blue'}}>GROSS PROFIT</b></center></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan={2}><center>sino</center></Table.HeaderCell>
                  <Table.HeaderCell rowSpan={2}><center>Item Code</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Product Name</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Stock</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Amount</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Stock</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Amount</center></Table.HeaderCell>
                  <Table.HeaderCell><center>Amount</center></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.state.data.map((r) => {
                    salesAmount = salesAmount + this.converttoNumber(this.makeDecimalPoints(r.salesAmount, r.tax));
                    purchaseAmount = purchaseAmount + this.converttoNumber(this.makeDecimalPoints(r.purchaseAmount, r.tax));
                    return (
                      <Table.Row>
                        <Table.Cell>{i++}</Table.Cell>
                        <Table.Cell>{r.item_number}</Table.Cell>
                        <Table.Cell>{r.product_name}</Table.Cell>
                        <Table.Cell><center>{r.salesStock}</center></Table.Cell>
                        <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.salesAmount, r.tax)}</div></Table.Cell>
                        <Table.Cell><center>{r.purchaseStock}</center></Table.Cell>
                        <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.purchaseAmount, r.tax)}</div></Table.Cell>
                        <Table.Cell><div style={{textAlign: 'right'}}>{this.grossProfit(this.makeDecimalPoints(r.purchaseAmount, r.tax), this.makeDecimalPoints(r.salesAmount, r.tax))}</div></Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell>{}</Table.HeaderCell>
                  <Table.HeaderCell><b style={{color: 'blue'}}>{''}</b></Table.HeaderCell>
                  <Table.HeaderCell><div style={{color: 'blue', textAlign: 'right', fontWeight: 'bold'}}>{this.makesDescimal(salesAmount)}</div></Table.HeaderCell>
                  <Table.HeaderCell><b style={{color: 'blue'}}>{}</b></Table.HeaderCell>
                  <Table.HeaderCell><div style={{color: 'blue', textAlign: 'right', fontWeight: 'bold'}}>{this.makesDescimal(purchaseAmount)}</div></Table.HeaderCell>
                  <Table.HeaderCell><div style={{color: 'blue', textAlign: 'right', fontWeight: 'bold'}}>{this.makesDescimal(salesAmount - purchaseAmount)}</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
          </Table>
          </div>
          <Load open={this.state.load}/>
        </Dialog>
      </div>
    );
  }

}
