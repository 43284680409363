import $ from 'jquery';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Form, Grid, Radio, Dropdown, Input, Select } from 'semantic-ui-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Load from '../../load';
import server from '../../functions/server';

export default class Add extends React.Component {

    state = {
        data: { 'volumetype': 'CBM', 'amounttype': 'Payable' }, 
        toDay: this.getFormatedDate(new Date()),
    }

    getFormatedDate(dates, interval) {
        if (interval > 0) {
            dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
        }
        let month = dates.getMonth() + 1; let date = dates.getDate();
        month = (month >= 10) ? month : "0" + month;
        date = (date >= 10) ? date : "0" + date;
        let x = dates.getFullYear() + "-" + month + "-" + date;
        return x;
    }

    componentDidMount() {
        this.getAllCustomers();
    }

    getAllCustomers = () => {
        let context = this;
        let url = server() + '/customers/getallcustomers.php';
        let data = {id: this.props.data.id, customerName: '', toDay: this.state.toDay};
        $.ajax({
            url, data, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = [];
            try {
                data = JSON.parse(data);
                data.map((c) => {
                    if (c.is_customer == '1') {
                        out.push({ key: c.name+"->"+c.mobile, text: c.name+"->"+c.mobile, value: c.id })
                    }
                })
            } catch(e) {
                alert('Failed');
            }
            context.setState({ out, load: false });
        });
    }

    submit = () => {
        let context = this;
        let url = server() + '/orders/createlogisticsvendorbill.php';
        let { data } = this.state;
        data["shop_id"] = this.props.data.id;
        data["cbm"] = data["volume"]+" "+data["volumetype"];
        if (data["amounttype"] == 'Paid') {
            data["amount"] =  Number(data["amount"]);
        }
        else {
            data["amount"] =  -1 * Number(data["amount"]);
        }
        
        
        $.ajax({
            url, data, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            this.setState({ load: false })
            if (data == 1) {
                this.props.getStatement();
                this.setState({ data: { 'volumetype': 'CBM', 'amounttype': 'Payable' }, open: false })
            }
            else {
                alert('Failed!!!')
            }
        });

    }

    handleChange = (key) => (e, { value }) => {
        this.setState({ 
            data: {
                ...this.state.data,
                [key]: value
            }
        })
    }

    render() {
        let { data, out } = this.state;
        let options = [
            { key: '* 40FT', text: '* 40FT', value: '* 40FT' },
            { key: '* 20FT', text: '* 20FT', value: '* 20FT' },
            { key: 'CBM', text: 'CBM', value: 'CBM' }
        ];
        let amountoptions = [
            { key: 'Paid', text: 'Paid', value: 'Paid' },
            { key: 'Payable', text: 'Payable', value: 'Payable' }
        ]
        return (
            <div>
                <Button variant="contained" color="primary" style={{marginLeft:10, marginTop: 10, width: 100}} 
                    onClick={() => this.setState({ open: true })}>
                    Add
                </Button>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={this.state.open}
                    onClose={() => {this.setState({open: false})}}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">Add Receipt</DialogTitle>
                    <DialogContent>
                        <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column>
                                    <div>Choose Vendor</div>
                                    <Dropdown placeholder='Select Vendor' fluid search style={{marginTop: '2.5%'}}
                                        onChange={this.handleChange('customer')} selection options={out}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input fluid label='POL' placeholder='Enter POL' 
                                        value={data.pol} onChange={this.handleChange("pol")}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input fluid label='POD' placeholder='Enter POD' 
                                        value={data.pod} onChange={this.handleChange("pod")}/>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <br/>
                                    <div style={{display: 'flex'}}>
                                        <Radio
                                            label='Import' style={{marginLeft: '4%'}}
                                            name='radioGroup' value='import'
                                            checked={this.state.data.type === 'import'}
                                            onChange={this.handleChange('type')}/><br />
                                        <Radio
                                            label='Export' style={{marginLeft: '4%'}}
                                            name='radioGroup' value='export'
                                            checked={this.state.data.type === 'export'}
                                            onChange={this.handleChange('type')} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input fluid label='Weight' placeholder='Enter Weight' 
                                        value={data.weight} onChange={this.handleChange("weight")}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <div>Volume</div>
                                    <Input type='text' label='Volume' placeholder='Volume' defaultValue={data.volume} fluid 
                                        onChange={this.handleChange("volume")} action>
                                        <input />
                                        <Select compact options={options} onChange={this.handleChange('volumetype')} defaultValue={data.volumetype} />
                                    </Input>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <div>Amount</div>
                                    <Input type='text' label='Amount' placeholder='Amount' defaultValue={data.amount} fluid 
                                        onChange={this.handleChange("amount")} action style={{width: '100%'}}>
                                        <Select compact options={amountoptions} onChange={this.handleChange('amounttype')} 
                                            defaultValue={data.amounttype} style={{width: '15%'}} />
                                        <input style={{width: '70%'}} />
                                    </Input>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br/><br/><br/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" style={{marginLeft:10, marginTop: 10}} 
                            onClick={() => this.setState({ open: false })}>
                            Close
                        </Button>
                        {
                            !this.state.load && 
                            <Button variant="contained" color="primary" style={{marginLeft:10, marginTop: 10}} 
                                onClick={() => this.submit()}>
                                Submit
                            </Button>
                        }
                        {<Load type="circle" open={this.state.load}/>}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}
