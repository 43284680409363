import React from 'react';
import $ from 'jquery';
import { Form, Button, Modal, Header, Icon, Checkbox } from 'semantic-ui-react';
import domain from '../../functions/domain';
import server from '../../functions/server';
import Load from '../../load';
import BarCode from '../../utils/barcode';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Edit extends React.Component {

  state = {open: false}

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    let item = this.props.item; let mrp = item.prize;
    let dnp = item.dnp; let tax = item.tax;
    try {
      mrp = Number(mrp); dnp = Number(dnp); tax = Number(tax);
      mrp = (mrp+((mrp*tax)/100)).toFixed(2);
      dnp = (dnp+((dnp*tax)/100)).toFixed(2);
    } catch (e) {
    }
    let publish = item.publish;
    if (publish == '0') { publish = false; }
    else { publish = true; }
    this.setState({
      code: item.item_number, name: item.product_name,
      local_name: item.local_name, stock: item.no_stock,
      price: mrp, tax: item.tax, shop_id: item.shop_id,
      dnp: dnp, min_stock: item.min_stock, hsn_code: item.hsn_code,
      publish, barcode: item.barcode
    });
  }

  handleOpen = () => { window.open(domain()+"edit-product?id="+this.props.item.id+"&from=stocks"+"&shopId="+this.state.shop_id); }

  handleClose = () => { this.setState({ addPost: false }); }

  handleChange = (key) => (e, { value }) => { let state = this.state; state[key] = value; this.setState(state); };

  checkboxChanges(value) {
    if (!value) {
      this.setState({ publish: true })
    }
    else {
      this.setState({ publish: false })
    }
  }
  
  checkData() {
    let out = this.props.out;
    let value = 1;
    out.map((v) => {
      let { code, name, barcode } = this.state;
      if (v.id !== this.props.item.id) {
        if (!code) {
          alert('Please Enter Item Code!!!');
        }
        if (code === v.item_number) {
          value = "Item Code is Already Registered";
        }
        if (name === v.product_name) {
          value = "Product Name is Already Registred";
        }
        if (barcode && barcode !== "0" && barcode === v.barcode) {
          value = "Barcode is already there";
        }
      }
      return null;
    })
    return value;
  }

  update = () => {
    let context = this;
    let checkData = this.checkData();
    if (checkData !== 1) {
      alert(checkData);
      return;
    }
    let url = server() + '/stocks/updatestock.php';
    let tax = this.state.tax;
    let mrp = this.state.price;
    let dnp = this.state.dnp;
    let dnpWithOuttax, mrpWithoutTax;
    try {
      tax = Number(tax);
      mrp = Number(mrp);
      dnp = Number(dnp);
      if (tax < 10) { tax = '1.0'+tax; }
      else { tax = '1.'+tax; }
      mrpWithoutTax = mrp/tax;
      dnpWithOuttax = dnp/tax;
    } catch (e) {
      mrpWithoutTax = 0;
      dnpWithOuttax = 0;
    }
    let publish = 1;
    if (!this.state.publish) {
      publish = 0;
    }
    let data = {
      id: this.props.item.id, item_number: this.state.code, name: this.state.name,
      local_name: this.state.local_name, stock: this.state.stock, price: mrpWithoutTax,
      tax: this.state.tax, dnp: dnpWithOuttax, min_stock: this.state.min_stock,
      hsn_code: this.state.hsn_code, publish: publish, barcode: this.state.barcode
    };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data1) => {
      console.log(data1);
      if (data1 === "1") {
        context.props.updateItem(data);
        context.handleClose();
      } else { alert("Error Saving"); }
      context.setState({ load: false });
    });
  }

  render() {
    return (
      <div>
        <Button.Group>
          <Button positive onClick={ () => { this.setState({ addPost: true }) }}>Basic</Button>
          <Button.Or />
          <Button primary onClick={this.handleOpen}>Ecommerce</Button>
        </Button.Group>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.addPost}
          onClose={ () => { this.setState({ addPost: false }) }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Edit Stock</DialogTitle>
          <DialogContent>
            <Form>
              <Form.Group widths='equal'>
                <Form.Input fluid label='Item Code' placeholder='Item Code'
                  value={this.state.code} onChange={this.handleChange('code')}/>
                <Form.Input fluid label='Product Name' placeholder='Product Name'
                  value={this.state.name}  onChange={this.handleChange('name')}/>
                <Form.Input fluid label='Local Name' placeholder='Local Name'
                  value={this.state.local_name}  onChange={this.handleChange('local_name')}/>
              </Form.Group>
              <Form.Group widths='equal'>

                <Form.Input fluid label='Stock' placeholder='Stock' id="ei_stock" 
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min={0}
                  value={this.state.stock}  onChange={this.handleChange('stock')}/>

                <Form.Input fluid label='Price' placeholder='Price' id="ei_price" 
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min={0} 
                  value={this.state.price}  onChange={this.handleChange('price')}/>

                <Form.Input fluid label='Tax' placeholder='Tax' id="ei_tax"
                  value={this.state.tax}  onChange={this.handleChange('tax')} 
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min={0} />

                <Form.Input fluid label='HSN CODE' placeholder='HSN CODE' id="ei_dnp"
                  value={this.state.hsn_code}  onChange={this.handleChange('hsn_code')}/>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label='DNP' placeholder='DNP' id="ei_dnp" 
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min={0}
                  value={this.state.dnp}  onChange={this.handleChange('dnp')}/>
                { this.props.data.prime == 1 ?
                  (
                    <Form.Input fluid label='Min Stock' placeholder='Min Stock' id="ei_min_stock" min={0} 
                      value={this.state.min_stock} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} 
                      onChange={this.handleChange('min_stock')}/>
                  ):
                  (
                    <Form.Input fluid label='Min Stock' placeholder='Min Stock' id="ei_min_stock" min={0}
                      value={this.state.min_stock} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} 
                      disabled  onChange={this.handleChange('min_stock')}/>
                  )
                }
                <Form.Input fluid label='Bar Code' placeholder='Bar Code' id="ei_barcode"
                  value={this.state.barcode}  onChange={this.handleChange('barcode')} 
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} min={0} />
              </Form.Group>
            </Form>
            <Checkbox label='Publish' checked={this.state.publish} onChange={this.checkboxChanges.bind(this, this.state.publish)} />
            <Load open={this.state.load}/>
            <BarCode onScan={(text) => this.setState({ "barcode": text })}/>
          </DialogContent>
          <DialogActions>
            <Button  negative onClick={this.handleClose}>Close</Button>
            <Button loading={this.state.load} positive icon='checkmark' labelPosition='right' content='Save' onClick={this.update}/>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
