import React from 'react';
import $ from 'jquery';
import { Image, Table, Form, Button, Icon, Dropdown } from 'semantic-ui-react';

export default class Item extends React.Component {

  componentDidMount() {
    let context = this;
    $('#price' + this.props.item.id).bind('keypress', function(e) {
      if(e.keyCode === 13){
          context.props.addNewProduct();
      }
    });
  }

  updateItem = (key) => (e, { value }) => {
    this.props.updateItem(this.props.item.id, key, value);
  }

  getTotalPrice = () => {
    let price = this.props.item.price * this.props.item.quantity;
    let tax = this.props.item.tax;
    let value = price + price * tax / 100;
    value = Number(value).toFixed(2);
    return value;
  }

  render() {
    let { item } = this.props;
    let price = Number(item.price * item.quantity).toFixed(2);
    return (
      <Table.Row>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>
          <Dropdown autoFocus placeholder='Select Item' fluid search selection value={item.item_id}
            ref={(input) => { this.nameInput = input }}
            options={this.props.stockOptions} onChange={this.updateItem('item_id')}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder={isNaN(item.no_sock) ? item.no_stock : "Quantity"}
            value={item.quantity} id={"quantity" + item.id} 
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            onChange={this.updateItem('quantity')}/>
        </Table.Cell>
        <Table.Cell style={{width: '10%'}}>
          <Form.Input fluid placeholder={isNaN(item.no_sock) ? item.no_stock : "Price"}
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            value={item.price} id={"price" + item.id}
            onChange={this.updateItem('price')}/>
        </Table.Cell>
        <Table.Cell>{item.tax}</Table.Cell>
        <Table.Cell style={{width: '10%'}}>{price}</Table.Cell>
        <Table.Cell>{this.getTotalPrice()}</Table.Cell>
        <Table.Cell>
          <Button animated='vertical' negative onClick={() => this.props.removeItem(this.props.item.id)}>
            <Button.Content hidden>Remove</Button.Content>
            <Button.Content visible>
              <Icon name='remove' />
            </Button.Content>
          </Button>
          {
            (item.id == this.props.total) ? (
              <Button animated='vertical' positive onClick={() => this.props.addNewProduct()}>
                <Button.Content hidden>Add</Button.Content>
                <Button.Content visible>
                  <Icon name='add' />
                </Button.Content>
              </Button>
            ) : ""
          }
        </Table.Cell>
      </Table.Row>
    );
  }

}
