import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export default class Billing extends React.Component {

  shop = (f, { value }) => {
    let users = this.props.users;
    let oldValue = [];
    users.map((v) => {
      if (v.user_id == this.props.userId) {
        oldValue = v.map;
        v.map = value;
      }
    })
    this.props.updateData('users', users);
    this.check(value, oldValue);
  }

  check(value, oldValue) {
    let length = value.length;
    if (length > oldValue.length) {
      let x = (value[value.length - 1]);
      this.add(x);
    } else if (length < oldValue.length) {
      let array1 = oldValue.filter(val => !value.includes(val));
      this.remove(array1[0]);
    }
  }

  add(value) {
    let updateSaveData = this.props.updateSaveData;
    updateSaveData.push({ type: 'add', userId: this.props.userId, shopId: value })
    this.props.updateData('updateSaveData', updateSaveData);
  }

  remove(value) {
    let updateSaveData = this.props.updateSaveData;
    updateSaveData.push({ type: 'remove', userId: this.props.userId, shopId: value })
    this.props.updateData('updateSaveData', updateSaveData);
  }

  render() {
    return (
      <div>
        <Dropdown fluid multiple
          id="Control" onChange={this.shop}
          search selection options={this.props.shopOption}
          value={this.props.values} fluid
          style={{ borderColor: '#5A9CBB', fontSize: 12 }} />
      </div>
    );
  }

}
