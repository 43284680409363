import React from 'react';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/Business';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { Message } from 'semantic-ui-react';

class Name extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      err: ""
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      err: ""
    });
    this.props.handleValues(name, event.target.value);
  };

  validate() {
    let err = "";
    if (this.state.name.trim().length < 2) {
      err = "Name must be minimum 2 character long"
    } else if (!/^[a-zA-Z ]*$/g.test(this.state.name.trim())) {
      err = "Please remove special characters like dot(.), comma(,), etc.,"
    }
    this.setState({
      err
    });
    if (err.length >= 1) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div>
        <h3>What's the Name of Your Business?</h3>
        <br />
        <Grid container spacing={24}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <TextField
              id="input-with-icon-textfield"
              label="Name"
              fullWidth
              value={this.state.name}
              onChange={this.handleChange('name')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}/>
            {
              this.props.err.length > 0 ? (<Message color='red'>{this.props.err}</Message>) : (<div></div>)
            }
            {
              this.state.err.length > 0 ? (<Message color='red'>{this.state.err}</Message>) : (<div></div>)
            }
            <br /><br />
            <p>Enter your Business Name so that people around you can refer it easily</p>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <br /><br />
      </div>
    );
  }
}

export default Name;
