import React from 'react';
import $ from 'jquery';
import { Dropdown, Grid, Checkbox, Input, Label } from 'semantic-ui-react';

import server from '../../functions/server';
import userId from '../../functions/userid';

export default class ChooseCustomer extends React.Component {

  state = {
    out: []
  }

  componentDidMount() {
    this.getAllCustomers();
  }

  getAllCustomers = () => {
    let context = this;
    let url = server() + '/customers/getallcustomers.php';
    let data = {id: this.props.data.id, customerName: '', toDay: ''};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({ out: out, load: false });
    });
  }

  handleChange = (e, { value }) => {
    let out = this.state.out;
    let obj = {};
    out.map((c) => {
      if (c.id == value) {
        obj = c;
      }
    })
    this.props.updateItem('customer', obj);
  }

  getType1() {
    let path = window.location.pathname.split("/")[1];
    if (path == 'statements') {
      return "Statement";
    }
    if (path == 'logistics-customers-statement') {
      return "Logistics Customer Statement";
    }
    if (path == 'logistics-vendor-statement') {
      return "Logistics Vendor Statement";
    }
  }

  render() {
    let type = this.getType1();
    let options = [];
    if (this.state.out) {
      this.state.out.map((temp) => {
          if (temp.is_customer == '0' && type == 'Logistics Customer Statement') {
            options.push({ key: temp.name + "->" + temp.mobile, value: temp.id, text: temp.name + "->" + temp.mobile });
          }
          if (temp.is_customer == '1' && type == 'Logistics Vendor Statement') {
            options.push({ key: temp.name + "->" + temp.mobile, value: temp.id, text: temp.name + "->" + temp.mobile });
          }
          if (type == 'Statement') {
            options.push({ key: temp.name + "->" + temp.mobile, value: temp.id, text: temp.name + "->" + temp.mobile });
          }
      });
    }
    return (
      <div style={{marginLeft: 50, marginRight: 50, marginTop: 10}}>
        <Dropdown
          placeholder='Select Customer' onChange={this.handleChange}
          fluid search selection options={options}/>
      </div>
    );
  }

}
