import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newPosition: {}
    };
    this.marker = null;
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    let marker = this.marker;
    const map = new window.google.maps.Map( document.getElementById(this.props.id), this.props.options);
    window.google.maps.event.addListener(map, 'click', (e) => {
      let latLng = e.latLng;
      if (latLng) {
        let position = { lat: latLng.lat(), lng: latLng.lng() };
        if (marker != null) {
          marker.setMap(null);
        }
        marker = new window.google.maps.Marker({
          position: position,
          map: map,
          title: 'Click Anywhere to Edit'
        });
        this.setState({
          newPosition: position,
          modalOpen: true
        });
      }
    });
    this.onMapLoad(map);
  }

  onMapLoad(map) {
    let data = this.props.data;
    let position = { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) };
    console.log(position);
    console.log(position);
    this.marker = new window.google.maps.Marker({
      position: position,
      map: map,
      title: 'Click Anywhere to Edit'
    });
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyBCdwh_uwFIEs9vhdI7jcgeJ2az9L2juuE`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }

  render() {
    return (
      <div>
        <div
          style={{ marginTop:50, width: '90%', height: 500 }}
          id={this.props.id} />
        <Modal open={this.state.modalOpen} basic size='small'>
          <Header icon='save' content='Update Location' />
          <Modal.Content>
            <p>New Latitude: {this.state.newPosition.lat}</p><br />
            <p>New Longitude: {this.state.newPosition.lng}</p><br />
            <h4>Do you want to save this as new coordinates of your shop?</h4>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color='red' inverted onClick={() => {this.setState({modalOpen: false})}}>
              <Icon name='remove' /> No
            </Button>
            <Button color='green' inverted onClick={ () => {
                this.props.updateLocation(this.state.newPosition);
                this.setState({modalOpen: false});
              }}>
              <Icon name='checkmark' /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default Map
