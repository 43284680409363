import React from 'react';
import Title from '../product/add_product/title';
import TextField from '@material-ui/core/TextField';
import { Button, Modal, Input, Dropdown, Table } from 'semantic-ui-react';
import Load from '../load';
import server from '../functions/server';
import $ from 'jquery';

export default class Ledger extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
    addLedgerDialog: false, transaction: [],
    width: 'md', ledgerOption: [], closingBalance: 0
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getLedger();
  }

  getLedger() {
    let context = this;
    let url = server() + '/ledger/getallledger.php';
    let data = {shopId: this.props.shopId };
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = [];
      let ledgerOption = [];
      try {
        out = JSON.parse(data);
        out.map((c) => { ledgerOption.push({ key: c.ledger, value: c.id, text: c.ledger }); })
      } catch(e) {}
      context.setState({ load: false, ledgerOption });
    });
  }

  getLedgerTransactions = () => {
    let context = this;
    let ledger = this.state.ledger;
    if (!ledger) {
      alert('Select Ledger!!!'); return;
    }
    if (!this.state.fromDate && !this.state.toDate) {
      alert('Select From and To Date!!!'); return;
    }
    let data = { 'ledger': this.state.ledger, 'fromDate': this.state.fromDate,
                 'toDate': this.state.toDate, 'shopId': this.props.shopId };
    let url = server()+'/ledger/getallledgertransaction.php'
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let closingBalance = 0;
      try {
        data = JSON.parse(data);
        data.map((c) => {
          let amount = context.convertToNumber(c.amount);
          if (amount < 0) { amount = Math.abs(amount); }
          let creditAmount = '';
          let debitAmount = '';
          if (c.order_id == 0 || c.order_id == '0') {
            closingBalance = closingBalance - amount;
            creditAmount = amount;
          }
          else {
            closingBalance = closingBalance + amount;
            debitAmount = amount;
          }
          c.amount = amount;
          c.creditAmount = creditAmount;
          c.debitAmount = debitAmount;
          c.closingBalance = closingBalance;
        })
        data.sort(function(a,b){
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
      } catch (e) {
        alert('Error to get data');
        data = [];
      }
      context.setState({ transaction: data, load: false, closingBalance })
    });
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  handleChange1 = (e, { value }) => { this.setState({ ledger: value }); }

  dateSeprate(timestamp) {
    let date = timestamp.split(' ')[0];
    try {
      date = date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0]+' '+timestamp.split(' ')[1];
    } catch (e) {
    }
    return date;
  }

  render() {
    let i = 1;
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Ledger Transaction"} menu={true}/>
        <div style={{padding: '0.5%', display: 'flex'}}>
          <TextField
            label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
            defaultValue={this.state.fromDate} />
          <TextField style={{marginLeft: 20}}
            label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
            defaultValue={this.state.toDate} />
          <Dropdown placeholder='Select Ledger' search selection
            onChange={this.handleChange1}
            style={{marginLeft: 20}} options={this.state.ledgerOption} />
          <Button style={{marginTop: 5, marginLeft: 2}} onClick={this.getLedgerTransactions}>Submit</Button>
          <div style={{marginTop: '2%', fontSize: 18, textAlign: 'right'}}>{'Closing Balance: '+this.state.closingBalance}</div>
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{'Si.no'}</Table.HeaderCell>
              <Table.HeaderCell width={3}>Date</Table.HeaderCell>
              <Table.HeaderCell width={6}>Other Ledger</Table.HeaderCell>
              <Table.HeaderCell>Credit</Table.HeaderCell>
              <Table.HeaderCell>Debit</Table.HeaderCell>
              <Table.HeaderCell>Closing</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { this.state.transaction.map((c) => {
                let date = this.dateSeprate(c.timestamp);
                return (
                  <Table.Row>
                    <Table.Cell>{i++}</Table.Cell>
                    <Table.Cell>{date}</Table.Cell>
                    <Table.Cell>{c.other_ledger}</Table.Cell>
                    <Table.Cell>{c.creditAmount}</Table.Cell>
                    <Table.Cell>{c.debitAmount}</Table.Cell>
                    <Table.Cell>{c.closingBalance}</Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Load load={this.state.load} message={this.state.message} />
      </div>
    );
  }
}
