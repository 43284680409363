import React from 'react';
import { Table } from 'semantic-ui-react';

import Update from './update';

export default class Item extends React.Component {

  render() {
    let {
      custom_id, name, designation, mobile, city, gender, salary, salary_type
    } = this.props.item;
    return (
      <Table.Row>
        <Table.Cell>{this.props.count}</Table.Cell>
        <Table.Cell>{custom_id}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{designation}</Table.Cell>
        <Table.Cell>{mobile}</Table.Cell>
        <Table.Cell>{city}</Table.Cell>
        <Table.Cell>{gender}</Table.Cell>
        <Table.Cell>{`Rs.${salary} per ${salary_type}`}</Table.Cell>
        <Table.Cell>
          <Update edit item={this.props.item} 
            out={this.props.out} 
            data={this.props.data} 
            updateItem={this.props.updateItem}
            getAllEmployees={this.props.getAllEmployees}
            designations={this.props.designations}/>
        </Table.Cell>
      </Table.Row>
    );
  }

}
