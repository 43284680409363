import React from 'react';
import { Table, Input, Modal, Button } from 'semantic-ui-react';
import DeleteIcon from '@material-ui/icons/Delete';
import $ from 'jquery';
import Load from '../loads';
import server from '../functions/server';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Validation from '../Others/Validation';

export default class PNSService extends React.Component {

  state = {

  }

  updateItem = (key) => event => {
    this.props.updateItem(this.props.item.id, key, event.target.value);
  }

  deletePNSNumber() {
    let context = this;
    let url = server()+'/pnsservice/deletepnsnumbers.php';
    let data = { 'id': this.props.item.ids }
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Deleteing PNS Number!!!' });
      }
    }).done((data) => {
      context.setState({ load: false })
      if (data == 1) {
        context.props.getPNSNumbers();
      }
    });
  }

  editPNSNumber() {
    this.setState({
      name: this.props.item.name,
      mobile: this.props.item.number,
      edit: true
    })
  }

  onChangeHandle = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  findDuplicate() {
    let mobile= this.state.mobile;
    let value = 1;
    let data = this.props.data;
    data.map((s) => {
      if (s.ids != this.props.item.ids) {
        if (mobile == s.number) {
          value = "Mobile is Duplicate Entry"
        }
      }
    })
    return value;
  }

  updatePNSNumber() {
    let context = this;
    let duplicateIdentifier = this.findDuplicate();
    if (duplicateIdentifier != 1) { alert(duplicateIdentifier); return; }
    let url = server()+'/pnsservice/editmobilenumber.php';
    let data = { 'mobile': this.state.mobile, 'id': this.props.item.ids, 'name': this.state.name };
    let { value, error } = Validation.EditPNSService.validate(data);
    if (error) {
      alert(error)
    }
    else {
      $.ajax({
        url, data, type: 'POST',
        beforeSend: function(x) { context.setState({ load: true, message: 'Updating PNS Number!!!' }); }
      }).done((data) => {
        context.setState({ load: false, message: 'Updating PNS Number!!!' });
        if (data == 1) {
          context.setState({ edit: false })
          alert('Edited Successfully!!!');
          context.props.getPNSNumbers();
        }
      });
    }
  }

  render() {
    return (
      <Table.Row>
        <Table.Cell><center><Load open={this.state.load} message={this.state.message} />{this.props.sino}</center></Table.Cell>
        <Table.Cell>{this.props.item.name}</Table.Cell>
        <Table.Cell>{this.props.item.number}</Table.Cell>
        <Table.Cell>
            <center>
            <EditIcon style={{cursor: 'pointer'}} onClick={this.editPNSNumber.bind(this)} />
          </center>
        </Table.Cell>
        <Table.Cell>
          <center>
            <DeleteIcon style={{cursor: 'pointer'}} onClick={this.deletePNSNumber.bind(this)} />
          </center>


          <Dialog fullWidth={true}
          maxWidth={'md'} open={this.state.edit} onClose={ () => { this.setState({ edit: false }) }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">PNS SERVICE</DialogTitle>
            <DialogContent>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell><b>Name</b><br/><Input fluid placeholder="Name" value={this.state.name} onChange={this.onChangeHandle('name')} /></Table.HeaderCell>
                    <Table.HeaderCell><b>Mobile Number</b><br/><Input fluid placeholder="Mobile Number" type={'number'} value={this.state.mobile} onChange={this.onChangeHandle('mobile')} /></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button negative onClick={ () => { this.setState({ edit: false }) }}>Close</Button>
              <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={this.updatePNSNumber.bind(this)}/>
            </DialogActions>
          </Dialog>


        </Table.Cell>
      </Table.Row>
    );
  }

}
