import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/LocationOn';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Message } from 'semantic-ui-react';
import $ from 'jquery';

class Info extends React.Component {
  constructor() {
    super();
    this.state = {
      load: false,
      country: "",
      state: "",
      city: "",
      street: "",
      pincode: "",
      gstin: "",
      referal: "",
      area: "",
      err: ""
    }
  }

  componentDidMount() {
    this.getIpInfo();
  }

  validate() {
    let err = "";
    if (this.state.country.trim().length < 2) {
      err = "Invalid Country"
    } else if (this.state.state.trim().length < 2) {
      err = "Invalid State"
    } else if (this.state.city.trim().length < 2) {
      err = "Invalid City"
    } else if (this.state.area.trim().length < 2) {
      err = "Invalid Area"
    } else if (this.state.street.trim().length < 2) {
      err = "Invalid Address"
    } else if (this.state.pincode.trim().length < 2) {
      err = "Invalid Pin Code"
    }
    this.setState({ err, street: this.state.street });
    if (err.length >= 1) { return false; }
    return true;
  }

  getIpInfo() {
    let context = this;
    $.ajax({
      url: 'https://ipapi.co/json',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let country = null;
      let state = null;
      let city = null;
      try {
        country = data.country_name;
        state = data.region;
        city = data.city;
        context.props.handleValues('country', country);
        context.props.handleValues('state', state);
        context.props.handleValues('city', city);
      } catch(e) {}
      context.setState({ load: false, country, state, city });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.handleValues(name, event.target.value);
  };

  render() {
    let x = (<div></div>);
    if (this.state.load) {
      x = (<LinearProgress />);
    }
    return (
      <div>
        <h3>Can you please share your Location?</h3>
        <br />
        <Grid container spacing={24}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <TextField fullWidth id="input-with-icon-textfield"
              label="Country" value={this.state.country}
              onChange={this.handleChange('country')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"><AccountCircle /></InputAdornment>
                ),
              }}/>
            <br /><br />
            <TextField
              fullWidth
              value={this.state.state + ""}
              onChange={this.handleChange('state')}
              id="input-with-icon-textfield"
              label="State"/>
            <br /><br />
            <TextField
              fullWidth
              value={this.state.city}
              onChange={this.handleChange('city')}
              id="input-with-icon-textfield"
              label="City"/>
            <br /><br />
              <TextField
                fullWidth
                value={this.state.area}
                onChange={this.handleChange('area')}
                id="input-with-icon-textfield"
                label="Area"/>
              <br /><br />
            <TextField
              fullWidth
              value={this.state.address}
              onChange={this.handleChange('street')}
              id="input-with-icon-textfield"
              label="Address"/>
            <br /><br />
            <TextField
              fullWidth
              type="number"
              value={this.state.pincode}
              onChange={this.handleChange('pincode')}
              id="input-with-icon-textfield"
              label="Pin Code"/>
            <br /><br />
            <TextField
              fullWidth
              value={this.state.gstin}
              onChange={this.handleChange('gstin')}
              id="input-with-icon-textfield"
              label="GSTIN"/>
            <br /><br />
            <TextField
              fullWidth
              value={this.state.reference}
              onChange={this.handleChange('reference')}
              id="input-with-icon-textfield"
              label="Reference(optional)"/>
            <br /><br />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {x}
        {
          this.state.err.length > 0 ? (<Message color='red'>{this.state.err}</Message>) : (<div></div>)
        }
      </div>
    );
  }
}

export default Info;
