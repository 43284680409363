import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';

import server from '../functions/server';
import $ from 'jquery';
import ModeOfPayment from './modepayment';

export default class Sales extends React.Component {

  state = {
    today: undefined, yesterday: undefined, thisMonth: undefined, thisYear: undefined, thisFinancialYear: undefined,
    todaymodeofpayment: [], yesterdaymodeofpayment: [], thisMonthmodeofpayment: [], thisYearmodeofpayment: [], thisFinancialYearmodeofpayment: [],
  }

  componentDidMount() {
    this.getDates();
  }

  getDates() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd < 10) { dd = '0' + dd;
    }
    if(mm < 10) { mm = '0' + mm; }
    this.getPurchaseDetail(dd, mm, yyyy);
  }

  getPurchaseDetail(date, month, year) {
    let context = this;
    let shopId = context.props.data.id;
    let url = server()+'/reports/purchase.php';
    let data = { 'shopId': shopId, 'date': date, 'month': month, 'year': year };
    $.ajax({
      url: url, data, type: 'POST',
    }).done((data1) => {
      console.log(data1);
      console.log('ererere');
      try {
        data1 = JSON.parse(data1);
        let today = undefined; let yesterday = undefined; let thisMonth = undefined; let thisYear = undefined; let thisFinancialYear = undefined;
        let todaymodeofpayment = []; let yesterdaymodeofpayment = []; let thisMonthmodeofpayment = []; let thisYearmodeofpayment = []; let thisFinancialYearmodeofpayment = [];
        data1.map((x) => {
          today = x.today; yesterday = x.yesterday; thisMonth = x.thisMonth; thisYear = x.thisYear; thisFinancialYear = x.thisFinancialYear;
          todaymodeofpayment = x.todaymodeofpayment; yesterdaymodeofpayment = x.yesterdaymodeofpayment; thisMonthmodeofpayment = x.thisMonthmodeofpayment;
          thisYearmodeofpayment = x.thisYearmodeofpayment; thisFinancialYearmodeofpayment = x.thisFinancialYearmodeofpayment;
        });
        if (today == null) { today = 0; }
        if (yesterday == null) { yesterday = 0; }
        if (thisMonth == null) { thisMonth = 0; }
        if (thisYear == null) { thisYear = 0; }
        if (thisFinancialYear == null) { thisFinancialYear = 0; }
        context.setState({ today, yesterday, thisMonth, thisYear, thisFinancialYear, todaymodeofpayment, yesterdaymodeofpayment, thisMonthmodeofpayment, thisYearmodeofpayment, thisFinancialYearmodeofpayment });
      } catch (e) {
        alert('Try Again!!!');
      }
    });
  }

  render() {
    let { today, yesterday, thisMonth, thisYear, thisFinancialYear } = this.state;
    if (today == undefined) {
      today = (<Loader size='small' active inline />);
    }
    if (yesterday == undefined) {
      yesterday = (<Loader size='small' active inline />);
    }
    if (thisMonth == undefined) {
      thisMonth = (<Loader size='small' active inline />);
    }
    if (thisYear == undefined) {
      thisYear = (<Loader size='small' active inline />);
    }
    if (thisFinancialYear == undefined) {
      thisFinancialYear = (<Loader size='small' active inline />);
    }
    try {
      thisFinancialYear = Number(thisFinancialYear).toFixed(2);
      thisYear = Number(thisYear).toFixed(2);
      thisMonth = Number(thisMonth).toFixed(2);
      yesterday = Number(yesterday).toFixed(2);
      today = Number(today).toFixed(2);
    } catch (e) {
    }

    return (
      <div style={{padding: '2%'}}>
        <Grid style={{marginLeft: '2.5%'}}>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <center>
              <h5>Today</h5>
              <h3 style={{color: '#2261A1'}}>{'₹ '+today}</h3>
              <ModeOfPayment modeofpayment={this.state.todaymodeofpayment} />
            </center>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <center>
              <h5>Yesterday</h5>
              <h3 style={{color: '#2261A1'}}>{'₹ '+yesterday}</h3>
              <ModeOfPayment modeofpayment={this.state.yesterdaymodeofpayment} />
            </center>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <center>
              <h5>This Month</h5>
              <h3 style={{color: '#2261A1'}}>{'₹ '+thisMonth}</h3>
              <ModeOfPayment modeofpayment={this.state.thisMonthmodeofpayment} />
            </center>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <center>
              <h5>This Year</h5>
              <h3 style={{color: '#2261A1'}}>{'₹ '+thisYear}</h3>
              <ModeOfPayment modeofpayment={this.state.thisYearmodeofpayment} />
            </center>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3}>
            <center>
              <h5>This Financial Year</h5>
              <h3 style={{color: '#2261A1'}}>{'₹ '+thisFinancialYear}</h3>
              <ModeOfPayment modeofpayment={this.state.thisFinancialYearmodeofpayment} />
            </center>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
