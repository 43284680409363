import React from 'react';
import $ from 'jquery';
import { Image, Table, Button, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import Add from './add';

export default class Item extends React.Component {

  state = {}


  editClosingBalance = () => {
    if (this.props.data.category == 'Transportation And Services') {
        this.setState({ openClosingEditingDialog: true })
    }
  }

  getType1() {
    if (window.location.pathname == '/customers') {
      return "Customer";
    }
    if (window.location.pathname == '/logistics-customers') {
      return "Logistics Customer";
    }
    if (window.location.pathname == '/logistics-vendor') {
      return "Logistics Vendor";
    }
  }

  render() {
    let type = this.getType1();
    let { item } = this.props;
    let balance = item.balance;
    try {
      balance = Number(balance).toFixed(2);
    } catch (e) {
      balance = 0;
    }
    let color = "white";
    if (this.props.isCustomer == 0) {
      color = "#F1FCE8";
    }

    return (
      <Table.Row style={{backgroundColor: color}}>
        <Table.Cell>{this.props.count}</Table.Cell>
        {
                this.props.data.id == '199' || this.props.data.id == '278' ? null :
                (<Table.Cell>{item.id}</Table.Cell>)
        }
        
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.mobile}</Table.Cell>
        <Table.Cell>{item.address}</Table.Cell>
        
        {
                type == 'Logistics Vendor' ? null :
                (<Table.Cell>{item.gstin}</Table.Cell>)
        }
        
        <Table.Cell>
          <u style={{color: 'blue'}} onClick={this.editClosingBalance}>{'₹ '+balance}</u>
        </Table.Cell>
        {
                type == 'Logistics Vendor' ? null :
                (<Table.Cell>{item.duedate.split('-')[2]+'-'+item.duedate.split('-')[1]+'-'+item.duedate.split('-')[0]}</Table.Cell>)
        }
        <Table.Cell>
          <Link to={type == 'Logistics Vendor' ? '/logistics-vendor-statement/'+ item.id : "/statements/" + item.id}>
            <Button animated='vertical' color='red'>
              <Button.Content hidden>Statement</Button.Content>
              <Button.Content visible>
                <Icon name='exchange' />
              </Button.Content>
            </Button>
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Add customer={item} refresh={this.props.refresh} isCustomer={this.props.isCustomer}/>
        </Table.Cell>
      </Table.Row>
    );
  }

}
