import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { Header, Icon, Grid } from 'semantic-ui-react';
import NotificationsIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Call';
import domain from '../../functions/domain';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import $ from 'jquery';
import server from '../../functions/server';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Product from './product';
import AddProducts from '../../product';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import Addstock from './addstock';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: '2%',
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Products extends React.Component {
  state = {
    products: [],
    productName: '',
    openDialog: false,
    search: ''
  };

  componentDidMount() {
    this.getProducts(this.state.search);
  }

  getProducts(productName) {
    let url_string = window.location.href;
    var urld = new URL(url_string);
    var shopId = urld.searchParams.get("shop");
    let context = this;
    let out = [];
    let data = { 'shopId': shopId, 'productName': productName }
    let url = server() + '/admin/getshopproducts.php';
    $.ajax({
      url: url, data,
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true,
            shopId :shopId
          });
      }
    }).done((data) => {
      try {
        out = JSON.parse(data);
        context.setState({ products: out, openDialog: true })
      } catch(e) {}
      context.setState({
        out: out,
        load: false
      });
    });
  }

  onChangeHandle = name => event => {
    this.setState({ [name]: event.target.value })
    this.getProducts(event.target.value);
  }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <div style={{color: 'white'}}>Products</div>
            </Typography>
            <InputBase
              placeholder="Search Product…"
              onChange={this.onChangeHandle('search')}
              value={this.state.search}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          <Link to={"/admin/add-product?shop="+this.state.shopId}>
              <Button>
                Add Product
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
        <div style={{padding: '2%'}}>
          <Grid>
            { this.state.products.map((c) => {
                return (
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <Product product={c} shopId={this.props.shopId} />
                  </Grid.Column>
                )
              })
            }
          </Grid>
        </div>
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default(withStyles(styles)(Products));
