// key=AIzaSyBCdwh_uwFIEs9vhdI7jcgeJ2az9L2juuE
import React, { Component } from 'react';
import { render } from 'react-dom';

class Map extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this)
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options);
    window.google.maps.event.addListener(map, 'click', function(e) {
          if (e.latLng) {
            console.log(e.latLng.lat());
          }
        });
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyBCdwh_uwFIEs9vhdI7jcgeJ2az9L2juuE`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }

  render() {
    return (
      <div
        style={{ marginTop:50, width: '90%', height: 500 }}
        id={this.props.id} />
    );
  }
}

export default Map
