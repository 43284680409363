import React from 'react';
import Title from '../product/add_product/title';
import $ from 'jquery';
import server from '../functions/server';
import Attachments from '../media/attachment.jpg';
import Load from '../loads';
import { Button, Card, Modal, Image, Header, Input, Form, TextArea, Grid, Dropdown } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SocialMedia extends React.Component {

  state = {
    addPost: false,
    image: Attachments,
    url: '', 
    type: 'URI'
  }

  create() {
    let context = this;
    if (this.props.type == 'Update') {
      this.updatePost();
      return;
    }
    if (!this.state.title) { alert('Enter the Title'); return; }
    if (!this.state.description) { alert('Enter the Description'); return; }
    if (!this.state.sendImage) { alert('Enter the Image'); return; }
    var formData = new FormData();
    formData.append('title', this.state.title);
    formData.append('description', this.state.description);
    formData.append('shopid', this.props.shopId);
    formData.append('image', this.state.sendImage);
    formData.append('type', this.state.type);
    formData.append('url', this.state.url);
    this.setState({ load: true, message: 'Creating post' })
    $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: server()+'/socialmedia/createpost.php',
      success: function(data) {
        console.log(data);
        if (data == 1) {
          context.props.getData();
          context.setState({ title: '', description: '', image: Attachments,
              sendImage: '', load: false, addPost: false })
        }
        else {
          context.setState({ load: false })
        }
      },
      error: function(e) {
        context.setState({ load: false })
        alert(JSON.stringify(e))
      }
    });
  }


  updatePost() {
    let context = this;
    if (!this.state.title) { alert('Enter the Title'); return; }
    if (!this.state.description) { alert('Enter the Description'); return; }
    var formData = new FormData();
    formData.append('title', this.state.title);
    formData.append('description', this.state.description);
    formData.append('type', this.state.type);
    formData.append('url', this.state.url);
    formData.append('id', this.state.updateId);
    this.setState({ load: true, message: 'updating post' })
    $.ajax({
    type: 'POST', processData: false, contentType: false,
    data: formData, url: server()+'/socialmedia/updatepost.php',
    success: function(data) {
      if (data == 1) {
        let posts = context.props.posts;
         posts.map((c) => {
           if (c.id == context.state.updateId) {
             c.title = context.state.title;
             c.description = context.state.description;
             c.url = context.state.url;
           }
         })
        context.props.updateData(posts);
        context.setState({ title: '', description: '', addPost: false, load: false })
      }
      else {
        context.setState({ load: false })
      }
    },
    error: function(e) {
      context.setState({ load: false })
      alert(e)
    }
    });
  }

  onChangeHandle = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  onChange() {
    var image = document.getElementById('1').files;
    if (this.props.type == 'Update') {
      this.updateImage(image);
    }
    if (image.length > 0) {
      this.setImage('image', image[0]);
    }
  }


  updateImage(image) {
    let oldName = this.state.image;
    oldName = oldName.split('strackit.com/')[1];
    let context = this;
    var formData = new FormData();
    formData.append('image', image[0]);
    formData.append('id', this.state.updateId);
    formData.append('oldname', oldName);
    this.setState({ load: true, message: 'Updating Image' })
    $.ajax({
    type: 'POST', processData: false, contentType: false,
    data: formData, url: server()+'/socialmedia/updateimage.php',
    success: function(data) {
      if (data == 1) {
        alert('success');
        context.setState({ load: false })
        context.props.updateData(context.props.posts);
      }
      else {
        context.setState({ load: false })
      }
    },
    error: function(e) {
      context.setState({ load: false })
      alert(e)
    }
    });
  }

  setImage(name, file) {
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        context.setState({ [name]: e.target.result, sendImage: file });
      };
    })(file);
    reader.readAsDataURL(file);
  }

  update() {
    let item = this.props.item;
    this.setState({
      title: item.title,
      description: item.description,
      image: item.image,
      updateId: item.id,
      url: item.url,
      type: item.type,
      addPost: true
    })
  }

  handleChange1 = (e, { value }) => { this.setState({ type: value }) }

  handleChange2 = (e, { value }) => { this.setState({ url: value }) }

  render() {
    let dropdownOption = [
      { key: 'Blog Redirect to URI', text: 'Blog Redirect to URI', value: 'URI' },
      { key: 'Blog Redirect to Product', text: 'Blog Redirect to Product', value: 'PRODUCT' }
    ]
    return (
      <div>
        { this.props.type == 'Update' ?
          (<Button primary onClick={this.update.bind(this)}>Update</Button>):
          (<Button positive onClick={ () => { this.setState({ addPost: true }) }}>Create Post</Button>)
        }

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={this.state.addPost}
        onClose={ () => { this.setState({ addPost: false }) }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{this.props.types}</DialogTitle>
        <DialogContent>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={5}>
              <input id={'1'} type="file" accept="image/*" onChange={this.onChange.bind(this)} style={{display: 'none'}}/>
              <label htmlFor={'1'}>
                <Image style={{height: 250, width: '100%'}} src={this.state.image} />
              </label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={11}>
                <Input style={{width: '100%'}} placeholder='Title' fluid value={this.state.title}
                  onChange={this.onChangeHandle('title')} /><br/>
                <Form fluid>
                  <TextArea maxlength="999" style={{minHeight: 150}} placeholder='Description' fluid value={this.state.description}
                  onChange={this.onChangeHandle('description')}  />
                </Form><br/>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '49%' }}>
                    <Dropdown onChange={this.handleChange1} placeholder='Blog Redirect To' 
                      value={this.state.type} fluid selection options={dropdownOption} />
                  </div>
                  <div style={{ width: '49%', marginLeft: '2%' }}>
                  {
                    this.state.type == "URI" ? 
                    <Input style={{width: '100%'}} placeholder='Enter URL' fluid value={this.state.url}
                    onChange={this.onChangeHandle('url')} /> :
                    <Dropdown onChange={this.handleChange2} placeholder='Select Product' 
                      value={this.state.url} fluid selection options={this.props.products} />
                  }
                    
                  </div>
                </div>
                
              </Grid.Column>
            </Grid>
            <br/><br/><br/><br/>
          </DialogContent>
          <DialogActions>
            <Button negative onClick={ () => { this.setState({ addPost: false }) }}>Cancel</Button>
            <Button positive onClick={this.create.bind(this)} content={this.props.type} />
          </DialogActions>
        </Dialog>
        <Load open={this.state.load} message={this.state.message} />
      </div>
    );
  }

}
