import React from "react";
import { Grid, Divider, Button, Radio } from "semantic-ui-react";
import { Paper } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import $ from "jquery";
import RazorPayment from "../razorpayment";
import Load from "../load";
import server from "../functions/server";
import domain from "../functions/domain";
import Title from "../product/add_product/title";

export default class Package extends React.Component {
  state = {
    value: 21999,
  };

  componentDidMount() {
    let date = this.getDate();
    if (date == 1) {
    }
    else {
      this.updateFreeTrial(3);
      alert('Thanks for signing up.\n\nWe are activating 30 days trial plan for your account.')
    }
  }

  updateData() {}

  getDate() {
    let value = 1;
    let tillDate = this.props.data.till_date;
    if (tillDate == "0000-00-00 00:00:00") {
      value = 0;
    }
    return value;
  }

  updateFreeTrial(value) {
    let context = this;
    let url = server() + "/prime/trialpack.php";
    let data = { shop_id: this.props.data.id, prime: value };
    $.ajax({
      url: url, data,
      type: "POST",
      beforeSend: function(x) {
        context.setState({ load: true });
      },
    }).done((data) => {
      if (data == 1) {
        alert("Your Free Trial is Successfully Activated...");
        window.location = domain();
      }
      context.setState({ load: false });
    });
  }

  handleChange = (value, duration) => this.setState({ value, duration });

  render() {
    let date = this.getDate();
    return (
      <div>
        <Load open={this.state.load} />
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              {this.props.data.shop_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />


        <Grid columns={3} style={{margin: '0.5%'}}>
          <Grid.Column>
            <Paper elevation={3}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4} />
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Basic</div>
                    <div id="Amount">
                      <Radio
                        value={250}
                        checked={this.state.value === 250}
                        onChange={(e, { value }) => {
                          this.handleChange(value, "month");
                        }}
                      />
                      ₹ 250 / Per Month
                    </div>
                    <div id="Amount">
                      <Radio
                        value={2499}
                        checked={this.state.value === 2499}
                        onChange={(e, { value }) => {
                          this.handleChange(value, "year");
                        }}
                      />
                      ₹ 2499 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4} />
              </Grid>
              <ul>
                <li>Unlimited Stocks</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Unlimited Bills</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Unlimited Customers</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Analyse Report For Sales</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Analyse Report For Purchase</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Analyse GST Report For Sales</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Analyse GST Report For Purchase</li>
              </ul>
              {date == 1 ? (
                <center style={{ padding: "5%" }}>
                  <RazorPayment
                    from="package"
                    shopId={this.props.data.id}
                    description={"Silver"}
                    url={server() + "/prime/payment.php"}
                    updateData={this.updateData.bind(this)}
                    duration={this.state.duration}
                    amount={this.state.value * 100}
                    originalAmount={this.state.value}
                  />
                </center>
              ) : (
                <center style={{ padding: "5%" }}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <RazorPayment
                        from="package"
                        shopId={this.props.data.id}
                        description={"Silver"}
                        url={server() + "/prime/payment.php"}
                        updateData={this.updateData.bind(this)}
                        duration={this.state.duration}
                        amount={this.state.value * 100}
                        originalAmount={this.state.value}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <Button
                        onClick={this.updateFreeTrial.bind(this, 1)}
                        fluid
                        color="google plus"
                      >
                        Free Trial For 30 Days
                      </Button>
                    </Grid.Column>
                  </Grid>
                </center>
              )}
            </Paper>
          </Grid.Column>
          <Grid.Column>
            <Paper elevation={3}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4} />
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center>
                    <div id="package">
                      <div id="PackageName">Business</div>
                      <div id="Amount">
                        <Radio
                          value={850}
                          checked={this.state.value === 850}
                          onChange={ (e, { value }) => { this.handleChange(value, 'month') }}
                        />
                        ₹ 850 / Per Month
                      </div>
                      <div id="Amount">
                        <Radio
                          value={8499}
                          checked={this.state.value === 8499}
                          onChange={ (e, { value }) => { this.handleChange(value, 'year') }}
                        />
                        ₹ 8499 / Per Year
                      </div>
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4} />
              </Grid>

              <ul>
                <li>All from Basic Plan</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>E-Commerce Shopping Portal</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>SEO and Google Ranking</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Multiple Reports</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Employee Management</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Order and Feedback Management</li>
                <Divider
                  fitted
                  style={{ marginRight: "10%", marginTop: "3%" }}
                />
                <li>Online Blogs</li>
              </ul>

              {date == 1 ? (
                <center style={{ padding: "5%" }}>
                  <RazorPayment
                    from="package"
                    shopId={this.props.data.id}
                    description={"Gold"}
                    url={server() + "/prime/payment.php"}
                    updateData={this.updateData.bind(this)}
                    duration={this.state.duration}
                    amount={this.state.value * 100}
                    originalAmount={this.state.value}
                  />
                </center>
              ) : (
                <center style={{ padding: "5%" }}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <RazorPayment
                        from="package"
                        shopId={this.props.data.id}
                        description={"Gold"}
                        url={server() + "/prime/payment.php"}
                        updateData={this.updateData.bind(this)}
                        duration={this.state.duration}
                        amount={this.state.value * 100}
                        originalAmount={this.state.value}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <Button
                        onClick={this.updateFreeTrial.bind(this, 2)}
                        fluid
                        color="google plus"
                      >
                        Free Trial For 30 Days
                      </Button>
                    </Grid.Column>
                  </Grid>
                </center>
              )}
            </Paper>
          </Grid.Column>
          <Grid.Column>
            <Paper elevation={3}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4} />
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Professional</div>
                    <div id="Amount">
                      <Radio
                        value={3799}
                        checked={this.state.value === 3799}
                        onChange={(e, { value }) => {
                          this.handleChange(value, "month");
                        }}
                      />
                      ₹ 3799 / Per Month
                    </div>
                    <div id="Amount">
                      <Radio
                        value={37999}
                        checked={this.state.value === 37999}
                        onChange={(e, { value }) => {
                          this.handleChange(value, "year");
                        }}
                      />
                      ₹ 37999 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                  <img
                    alt=""
                    width={40}
                    height={50}
                    src={require("../media/No_1_Sales.png")}
                  />
                </Grid.Column>
              </Grid>
              <ul>
                <li>All from Business Plan</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Platform in your business name</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Dedicated Relationship Manager</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Premium Shopping Site</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Google Ranking Expert Tips</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Customization Helps</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Much More coming soon</li>
              </ul>
              {date == 1 ? (
                <center style={{ padding: "5%" }}>
                  <RazorPayment
                    from="package"
                    shopId={this.props.data.id}
                    url={server() + "/prime/payment.php"}
                    description={"Diamond"}
                    updateData={this.updateData.bind(this)}
                    duration={this.state.duration}
                    amount={this.state.value * 100}
                    originalAmount={this.state.value}
                  />
                </center>
              ) : (
                <center style={{ padding: "5%" }}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <RazorPayment
                        from="package"
                        shopId={this.props.data.id}
                        url={server() + "/prime/payment.php"}
                        description={"Diamond"}
                        updateData={this.updateData.bind(this)}
                        duration={this.state.duration}
                        amount={this.state.value * 100}
                        originalAmount={this.state.value}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <Button
                        onClick={this.updateFreeTrial.bind(this, 3)}
                        fluid
                        color="google plus"
                      >
                        Free Trial For 30 Days
                      </Button>
                    </Grid.Column>
                  </Grid>
                </center>
              )}
            </Paper>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
