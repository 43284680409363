import React from 'react';
import $ from 'jquery';
import { Image, Table, Button, Icon } from 'semantic-ui-react';

import Update from './update';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Purchases from './purchases';
import Purchaser from './purchaser';

export default class Item extends React.Component {

  render() {
    let { item } = this.props;
    let isPurchaser = 0;
    let price = item.price
    let purchaser = "";
    if (item.purchaser_name && item.purchaser_name.length > 0) {
      purchaser = item.purchaser_name; ++isPurchaser;
    }
    if (item.purchaser_mobile && item.purchaser_mobile.length > 2) {
      purchaser += " (" + item.purchaser_mobile + ")"; ++isPurchaser;
    }
    try {
      price = Number(price).toFixed(2);
    } catch (e) {
      price = 0;
    }
    return (
      <Table.Row>
        <Table.Cell>{this.props.count}</Table.Cell>
        <Table.Cell>{item.voucher_no}</Table.Cell>
        <Table.Cell><Purchaser item={item}/></Table.Cell>
        <Table.Cell>{item.timestamp}</Table.Cell>
        <Table.Cell>{item.parts}</Table.Cell>
        <Table.Cell>{item.stocks}</Table.Cell>
        <Table.Cell>{"Rs." + price}</Table.Cell>
        <Table.Cell>
          <Purchases purchase={item}/>
        </Table.Cell>
      </Table.Row>
    );
  }

}
