import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Load from '../loads';
import server from '../functions/server';

const styles = {
  appBar: { position: 'relative' },
  flex: { flex: 1 },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Orders extends React.Component {

  state = {
    open: false, data: [],
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  getReport = () => {
    let context = this;
    let url = server() + '/reports/grossprofit.php';
    let data = {shopId: context.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Getting Stock Report!!!' });
      }
    }).done((data) => {
      console.log(data);
      console.log('kkkkkkkkkkk');
      try {
        data = JSON.parse(data);
        console.log(data);
        data.sort(function(a, b){return b.sales - a.sales});

        context.setState({ data: data, open: true, load: false })
      } catch(e) { alert('Error to get stock report!!!'); context.setState({ load: false })  }
    });
  }

  handleChange = (name) => event => {
    this.setState({ [name]: event.target.value })
  }

  converttoNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimalPoints(value) {
    value = this.converttoNumber(value);
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  grossProfit(purchaseAmount, salesAmount) {
    let total = 0;
    try {
      total = (salesAmount - purchaseAmount).toFixed(2);
    } catch (e) {
      total = 0;
    }
    return total;
  }

  makesDescimal(value) {
    try {
      value = value.toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    let salesAmount = 0;
    let profitAmount = 0;
    let discountAmount = 0;
    let i = 1;
    return (
      <div>
        <Button color='blue' onClick={this.getReport}>
          Profit Report
        </Button>

        <Dialog fullScreen open={this.state.open}
          onClose={this.handleClose} TransitionComponent={Transition} >
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({open: false})} aria-label="Close"><CloseIcon style={{color: 'white'}} /></IconButton>
              <Typography variant="h6" color="inherit" style={styles.flex}>Gross Profit Report</Typography>
            </Toolbar>
          </AppBar>
          <div style={{padding: '2%'}}>
            <div style={{display: 'row'}}>
              <input type="date" value={this.state.fromDate} onChange={this.handleChange('fromDate')} style={{height: 35 }} />
              <input type="date" value={this.state.toDate} onChange={this.handleChange('toDate')} style={{height: 35 }} />
              <Button onClick={this.getReport}>Submit</Button>
            </div>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{'SINO'}</Table.HeaderCell>
                  <Table.HeaderCell>{'ITEM CODE'}</Table.HeaderCell>
                  <Table.HeaderCell>{'PRODUCT NAME'}</Table.HeaderCell>
                  <Table.HeaderCell>{'TAX'}</Table.HeaderCell>
                  <Table.HeaderCell>{'DNP'}</Table.HeaderCell>
                  <Table.HeaderCell>{'MRP'}</Table.HeaderCell>
                  <Table.HeaderCell>{'QTY'}</Table.HeaderCell>
                  <Table.HeaderCell>{'DISCOUNT'}</Table.HeaderCell>
                  <Table.HeaderCell>{'SALES VALUE'}</Table.HeaderCell>
                  <Table.HeaderCell>{'PROFIT'}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              { this.state.data.map((r) => {
                  profitAmount = profitAmount + this.converttoNumber(r.profit);
                  salesAmount = salesAmount + this.converttoNumber(r.sales);
                  discountAmount = discountAmount + this.converttoNumber(r.discount);
                  return (
                    <Table.Row>
                      <Table.Cell>{i++}</Table.Cell>
                      <Table.Cell>{r.item_number}</Table.Cell>
                      <Table.Cell>{r.product_name}</Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.tax)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.dnp)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.prize)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.qty)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.discount)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.sales)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoints(r.profit)}</div></Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell>{''}</Table.Cell>
                <Table.Cell><div style={{textAlign: 'right'}}><b>Total</b></div></Table.Cell>
                <Table.Cell><div style={{textAlign: 'right'}}><b>{this.makeDecimalPoints(discountAmount)}</b></div></Table.Cell>
                <Table.Cell><div style={{textAlign: 'right'}}><b>{this.makeDecimalPoints(salesAmount)}</b></div></Table.Cell>
                <Table.Cell><div style={{textAlign: 'right'}}><b>{this.makeDecimalPoints(profitAmount)}</b></div></Table.Cell>
              </Table.Row>
            </Table>
          </div>
          <Load open={this.state.load}/>
        </Dialog>
      </div>
    );
  }

}
