import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import $ from "jquery";

import Load from '../../load';
import serverName from '../../functions/server';


class SecondaryCategory extends Component {
  state = {
    categoryOption: [],
    value: [],
    removeCategory: [],
    addCategory: [],
  };

  componentDidMount() {
    this.getcategory();
  }

  componentDidUpdate(props) {
    if (props.mastercategory != this.props.mastercategory) {
      this.getcategory();
    }
    if (props.category != this.props.category) {
      this.setState({ value: [] })
    }
  }

  clearSecoundaryCategory() {
    this.setState({ value: [] })
  }

  getcategory() {
    let context = this;
    var url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shopId");
    var productId = url.searchParams.get("id");
    let data = { shopId, productId };
    let c = 0;
    this.setState({ load: true, message: 'Getting Scondary Category!!!' })
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/products/getmasternadsecondarycategory.php',
      success: function(data) {
        console.log(data);
        let value = [];
        try {
          data = JSON.parse(data);
          let x = []; let c = 0;
          data.map((y) => {
            if (y.categoryType == 'all') {
              if (y.category == context.props.category) { c = 1; }
              x.push({key: y.category+'->'+y.masterCategory, text: y.category+'->'+y.masterCategory, value: y.category+'->'+y.masterCategory})
            }
            else {
              value.push(y.category+'->'+y.masterCategory)
            }
          })
          context.props.add_product('category', value);
          context.setState({ categoryOption: x, load: false, allCategory: data, value });
        } catch (e) {
          alert('Error!!!')
        }
      },
    });
  }

  onCategoryChange = (f, { value }) => {
    let values = this.state.value;
    if (values.length > value.length) {
      this.removeCategory(value);
    }
    else {
      this.addCategory(value);
    }
    this.setState({ value });
  }

  removeCategory(catgoeries) {
    let context = this;
    let removeCategory = this.state.removeCategory;
    let values = this.state.value;
    values.map((v) => {
      let i = 0;
      catgoeries.map((c) => {
        if (v == c) {
          i = 1;
        }
      })
      if (i == 0) {
        let url_string = window.location.href; var url = new URL(url_string);
        var shopId = url.searchParams.get("shopId"); var productId = url.searchParams.get("id");
        let allCategory = this.state.allCategory;
        let mastercategory = v.split('->')[1];
        let secondaryCategory = v.split('->')[0];
        this.setState({ load: true, message: 'Removing!!!' })
        let data = { mastercategory, secondaryCategory, shopId, productId };
        $.ajax({
          type: 'POST', data: data, url: serverName() + '/categoryandproductmapping/removecategoryandprouctmapping.php',
          success: function(data) {
            context.setState({ load: false })
            context.getcategory();
          },
        });
      }
    })
  }




  addCategory(catgoeries) {
    let context = this;
    let addCategory = this.state.addCategory;
    let values = this.state.value;
    if (typeof values != 'object') {
      values = [];
    }
    catgoeries.map((v) => {
      let i = 0;
      values.map((c) => {
        if (c == v) {
          i = 1;
        }
      })
      if (i == 0) {
        let url_string = window.location.href; var url = new URL(url_string);
        var shopId = url.searchParams.get("shopId"); var productId = url.searchParams.get("id");
        let allCategory = this.state.allCategory;
        let mastercategory = v.split('->')[1];
        let secondaryCategory = v.split('->')[0];
        this.setState({ load: true, message: 'Inserting!!!' })
        let data = { mastercategory, secondaryCategory, shopId, productId };
        $.ajax({
          type: 'POST', data: data, url: serverName() + '/categoryandproductmapping/insert.php',
          success: function(data) {
            context.setState({ load: false })
            context.getcategory();
          },
        });
      }
    })
    this.setState({ addCategory })
  }

  render() {
    const { value } = this.state
    return (
      <div>
        <Load open={this.state.load} message={this.state.message} />
        <Dropdown multiple fluid placeholder='Category' onChange={this.onCategoryChange} search selection 
        options={this.state.categoryOption} value={this.state.value}/>
      </div>
    );
  }
}

export default SecondaryCategory;
