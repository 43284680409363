import React from 'react';
import $ from 'jquery';
import { Button, Icon, Form, Modal } from 'semantic-ui-react';

import Load from '../../load';
import server from '../../functions/server';

export default class Update extends React.Component {

  state = { open: false }

  componentDidMount() {
    let purchaserName = "";
    let purchaserMobile = "";
    if (this.props.item.purchaser_name) {
      purchaserName = this.props.item.purchaserName;
    }
    if (this.props.item.purchaser_mobile) {
      purchaserMobile = this.props.item.purchaser_mobile;
    }
    this.setState({purchaserName, purchaserMobile});
  }

  handleChange = (key) => (e, { value }) => {
    this.setState({[key]: value});
  }

  update = () => {
    let { purchaserName } = this.state;
    let { purchaserMobile } = this.state;
    if (!purchaserName || purchaserName.length < 1) {
      alert("Invalid Purchaser Name");
      return;
    }
    if (!purchaserMobile || isNaN(purchaserMobile) || purchaserMobile.length !== 10) {
      alert("Purchaser Mobile Number should be 10 Characters and It Should only have numbers");
      return;
    }
    let context = this;
    let url = server() + '/purchases/updatepurchaserdetails.php';
    let data = {id: this.props.item.id, name: purchaserName, mobile: purchaserMobile};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      if (data === "1") {
        context.props.updateItem(context.props.item.id, purchaserName, purchaserMobile);
        context.setState({open: false, load: false}); return;
      }
      alert("Failed");
      context.setState({load: false});
    });
  }

  render() {
    let { item } = this.props;
    return (
      <span>
        <Button animated='vertical' negative onClick={() => this.setState({open: true})}>
          <Button.Content hidden>Send SMS</Button.Content>
          <Button.Content visible>
            <Icon name='mail' />
          </Button.Content>
        </Button>
        <Modal
          open={this.state.open}
          closeOnEscape={false}
          closeOnDimmerClick={false}>
          <Modal.Header>Update Before Sending SMS</Modal.Header>
          <Modal.Content>
            <Form>
             <Form.Group widths='equal'>
               <Form.Input fluid label='Purchaser Name' onChange={this.handleChange("purchaserName")}
                  placeholder='Purchaser Name' />
                <Form.Input fluid label='Purchaser Mobile' onChange={this.handleChange("purchaserMobile")}
                  placeholder='Purchaser Mobile' />
             </Form.Group>
            </Form>
            <Load open={this.state.load}/>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => {this.setState({open: false})}} negative>
              No
            </Button>
            <Button
              onClick={this.update}
              positive
              labelPosition='right'
              icon='checkmark'
              content='Update'
            />
          </Modal.Actions>
        </Modal>
      </span>
    );
  }

}
