import React from 'react';
import $ from 'jquery';
import { Table } from 'semantic-ui-react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import Title from '../product/add_product/title';
import server from '../functions/server';
import Load from '../load';
import { Popup } from 'semantic-ui-react';

export default class ProductEnquiry extends React.Component {

  state = {
    enquiries: []
  }

  componentDidMount() {
    let context = this;
    let url = server() + '/enquiry/getcustomerenquiry.php';
    let data = {'shop_id': 222 };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Getting Enquires' });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        context.setState({ load: false, enquiries: data })
      } catch(e) {
        alert('Error')
        context.setState({ load: false });
      }
    });
  }

  render() {
    let i = 1;
    return (
      <div>
        <Load open={this.state.load} message={this.state.message} />
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Customer Enquiry"} menu={true}/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Customer Mobile</Table.HeaderCell>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Date & Time</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          {
            this.state.enquiries.map((v) => {
              return (
                <Table.Row>
                  <Table.Cell>{i++}</Table.Cell>
                  <Table.Cell>{v.name}</Table.Cell>
                  <Table.Cell>{v.mobile}</Table.Cell>
                  <Popup content={v.subject} trigger={<Table.Cell onClick={ () => this.setState({ dialog: true, value: v.subject }) }>{v.subject.length > 30 ? v.subject.substr(0, 30)+"...." : v.subject}</Table.Cell>} />
                  <Popup content={v.message} trigger={<Table.Cell onClick={ () => this.setState({ dialog: true, value: v.message }) }>{v.message.length > 30 ? v.message.substr(0, 30)+"...." : v.message}</Table.Cell>} />
                  <Table.Cell>{this.timeSeprate(v.addedon)}</Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table>
        <Dialog open={this.state.dialog} onClose={() => this.setState({ dialog: false })} aria-labelledby="form-dialog-title">
            <DialogContent style={{ width: '40vw' }}>
                {this.state.value}
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => { this.setState({ dialog: false }) }} color="danger">Close</Button>
            </DialogActions>
        </Dialog>
      </div>
    );
  }
  timeSeprate(value) {
    let date = value.split(' ')[0];
    date = date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0]+' '+value.split(' ')[1];
    return date;
  }
}
