import React from 'react';
import $ from 'jquery';
import server from '../functions/server';
import { Button, Modal, Rating, Form, TextArea } from 'semantic-ui-react'

export default class Stocks extends React.Component {

  state = {
    rating: 0,
    feedback: '',
  }

  handleRating = (e, { rating }) => { this.setState({ rating: rating }); }

  handleChange = event => { this.setState({ feedback: event.target.value }) }

  submitRatingAndFeedback() {
    let context = this;
    let url = server()+'/feedback/insert.php';
    if (this.state.feedback.length < 30) {
      alert('Feedback must be morethan 30 characters');
      return;
    }
    let data = { 'order_id': this.props.orderId, 'rating': this.state.rating, 'feedback': this.state.feedback };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
       if (data == 1) {
         context.props.update(context.state.rating, context.state.feedback);
       }
    });
  }

  render() {
    return (
      <div>
        <Modal size={'tiny'} open={this.props.openFeedBack} onClose={this.props.close}>
          <Modal.Header>Your Feedback</Modal.Header>
          <Modal.Content>
            <b>Rating</b><br/>
            <Rating maxRating={5} icon="star" defaultRating={0} onRate={this.handleRating} /><br/><br/>
            <b>Feedback</b><br/>
            <Form>
              <TextArea placeholder='Feedback' value={this.state.feedback} onChange={this.handleChange} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.close} negative>Cancel</Button>
            <Button positive icon='checkmark' onClick={this.submitRatingAndFeedback.bind(this)} labelPosition='right' content='Yes' />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

}
