import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Loader } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  appBar: { position: 'relative' },
  flex: { flex: 1 },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Add extends React.Component {

  state = {
    open: false,
  };

  render() {
    return (
      <div>
        <Dialog fullScreen open={this.props.open} onClose={() => this.setState({open: false})}
          TransitionComponent={Transition}>
          <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
            <center>
              <CircularProgress disableShrink />
              <br/>
              <h3 style={{color: '#2196f3'}}>{this.props.message}</h3>
            </center>
          </div>
        </Dialog>
      </div>
    );
  }

}
