import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dropdown } from 'semantic-ui-react';
import $ from 'jquery';
import { Message } from 'semantic-ui-react';
import server from '../../functions/server';

class More extends React.Component {
  constructor() {
    super();
    this.state = {
      load: false,
      deliver: true,
      address: false,
      website: "",
      phone: "",
      distance: "",
      options: [],
      err: "",
      category: ''
    }
  }

  validate() {
    let err = "";
    let website = 'http://'+this.state.website;
    if (this.state.website.length > 0 && !this.state.website.includes(".")) {
      err = "Invalid website"
    } else if (this.state.category.trim().length < 2 || this.state.category == undefined) {
      err = "Please Choose Category"
    } else if (this.state.phone.trim().length < 5) {
      err = "Invalid Phone Number"
    } else if (this.state.deliver && this.state.distance.trim().length < 1) {
      err = "Invalid Delivery Distance"
    }
    this.setState({
      err
    });
    if (err.length >= 1) {
        return false;
    }
    return true;
  }

  handleAddition = (e, { value }) => {
    this.setState({
      options: [{ text: value, value }, ...this.state.options],
    })
  }

  componentDidMount() {
    this.props.handleValues('deliver', true);
    this.props.handleValues('address', false);
    this.getCategories();
  }

  getCategories() {
    let context = this;
    $.ajax({
      url: server() + '/shop/allcategories.php',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let x = JSON.parse(data);
      x.map((value) => {
        console.log(value);
        context.setState({
          options: [{ text: value, value }, ...this.state.options],
        })
      });
      context.setState({
        load: false
      })
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.handleValues(name, event.target.value);
  };

  handleChangeDrop(e, x) {
    if (x) {
      this.setState({ 'category': x.value });
      this.props.handleValues('category', x.value);
    }
  }

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked });
    this.props.handleValues(name, event.target.checked);
  };

  render() {
    let x = (<div></div>);
    if (this.state.load) {
      x = (<LinearProgress />);
    }
    let y = (<div></div>);
    if (this.state.deliver) {
      y = (
        <TextField
          label="Delivery Distance"
          id="delivery_distance"
          value={this.state.distance}
          onChange={this.handleChange("distance")}
          input="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">Kms</InputAdornment>,
          }}
        />
      );
    }
    return (
      <div>
        <h3>Can you please share your Location?</h3>
        <br />
        <Grid container spacing={24}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Dropdown
              options={this.state.options}
              placeholder='Choose Category'
              search
              selection
              fluid
              allowAdditions
              value={this.state.category}
              onAddItem={this.handleAddition}
              onChange={this.handleChangeDrop.bind(this)}
            />
          <br /><br />
            <TextField
              fullWidth
              value={this.state.website}
              onChange={this.handleChange('website')}
              id="input-with-icon-textfield"
              label="Website"
              InputProps={{
                startAdornment: <InputAdornment position="start">http://</InputAdornment>,
              }}/>
            <br /><br />
            <TextField
              fullWidth
              value={this.state.phone}
              onChange={this.handleChange('phone')}
              type="number"
              id="input-with-icon-textfield"
              label="Phone Number"/>
            <br /><br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.address}
                  value="address"
                  onChange={this.handleCheckChange('address')}
                  color="primary"
                />
              }
              label="Hide My Address (Only Show Region)"/>
            <br /><br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.deliver}
                  value="deliver"
                  onChange={this.handleCheckChange('deliver')}
                  color="primary"
                />
              }
              label="I deliver goods and services to my customers"/>
            <br /><br />
            {y}
            <br /><br />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {x}
        {
          this.state.err.length > 0 ? (<Message color='red'>{this.state.err}</Message>) : (<div></div>)
        }
      </div>
    );
  }
}

export default More;
