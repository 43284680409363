import React from 'react';
import $ from 'jquery';
import { Button, Icon, Form, Modal } from 'semantic-ui-react';

import Load from '../../load';
import server from '../../functions/server';

export default class Update extends React.Component {

  state = { open: false }

  componentDidMount() {
    let customerName = "";
    let customerMobile = "";
    if (this.props.item.customer_name) {
      customerName = this.props.item.customerName;
    }
    if (this.props.item.customer_mobile) {
      customerMobile = this.props.item.customer_mobile;
    }
    this.setState({customerName, customerMobile});
  }

  handleChange = (key) => (e, { value }) => {
    this.setState({[key]: value});
  }

  update = () => {
    let { customerName } = this.state;
    let { customerMobile } = this.state;
    if (!customerName || customerName.length < 1) {
      alert("Invalid Customer Name");
      return;
    }
    if (!customerMobile || isNaN(customerMobile) || customerMobile.length !== 10) {
      alert("Customer Mobile Number should be 10 Characters and It Should only have numbers");
      return;
    }
    let context = this;
    let url = server() + '/orders/updatecustomerdetails.php';
    let data = {id: this.props.item.id, name: customerName, mobile: customerMobile};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      if (data === "1") {
        context.props.updateItem(context.props.item.id, customerName, customerMobile);
        context.setState({open: false, load: false});
        return;
      }
      alert("Failed");
      context.setState({load: false});
    });
  }

  render() {
    let { item } = this.props;
    return (
      <span>
        <Button animated='vertical' negative onClick={() => this.setState({open: true})}>
          <Button.Content hidden>Send SMS</Button.Content>
          <Button.Content visible>
            <Icon name='mail' />
          </Button.Content>
        </Button>
        <Modal
          open={this.state.open}
          closeOnEscape={false}
          closeOnDimmerClick={false}>
          <Modal.Header>Update Before Sending SMS</Modal.Header>
          <Modal.Content>
            <Form>
             <Form.Group widths='equal'>
               <Form.Input fluid label='Customer Name' onChange={this.handleChange("customerName")}
                  placeholder='Customer Name' />
                <Form.Input fluid label='Customer Mobile' onChange={this.handleChange("customerMobile")}
                  placeholder='Customer Mobile' />
             </Form.Group>
            </Form>
            <Load open={this.state.load}/>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => {this.setState({open: false})}} negative>
              No
            </Button>
            <Button
              onClick={this.update}
              positive
              labelPosition='right'
              icon='checkmark'
              content='Update'
            />
          </Modal.Actions>
        </Modal>
      </span>
    );
  }

}
