import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';
import {Input, Form} from 'semantic-ui-react';
import $ from 'jquery';
import Server from '../../functions/server';



export default class LineChart extends Component {

  state = {
    data: [], dates: [], amounts: []
  }

  componentDidMount() {
    let context = this;
    let url = Server() + '/reports/salesanalysisreport.php';
    let data = {shopId: this.props.shopId };
    $.ajax({url, data, method: 'POST', beforeSend: () => {
        context.setState({ load: true });
      }, success: (data) => {
        data = JSON.parse(data);
        if (data.length != 0) {
          try {
            let dates = [];
            let amounts = [];
            let  number = 1;
            data.map((v) =>{ v.id = number++; })
            
            number = number - 2;
            
            for (var i = number; i != 0; i--) {
              try {
                let amount = this.convertToNumber(data[i].amount);
                amount = this.makeFixed(amount);
                let split = data[i].date.split(" ")[0];
                dates.push(split);
                amounts.push(amount)
              } catch (error) {
              }
            }
            
            this.setState({data, dates, amounts});
          } catch (e) { console.log(data); }
          
        }
        this.setState({load: false});
      }, error: (e) => {
        console.log(e);
        this.setState({load: false});
      }});
  }

  makeFixed(value) {
    try {
      value = value.toFixed(2);
    } catch (e) {
      value = 0.00;
    }
    return value;
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    const data = {
      labels: this.state.dates,
      datasets: [
        {
          label: 'Sales Report (Last 30 Days)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'red',
          borderColor: '#007bff',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: '#335BFF',
          pointHoverBorderWidth: 10,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.amounts
        }
      ]
    };
    return (
      <div>
        <Line ref="chart" data={data} options={{ maintainAspectRatio: true,  }}  />
        <Form style={{display: 'none'}}>
          <Form.Group>
            <Form.Input  label='Start date' placeholder='Select Start Date' type="date"
              width={4}/>
            <Form.Input  label='To Date' placeholder='Select To Date' type="date"
              width={4}/>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
