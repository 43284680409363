import React from 'react';
import { Table } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';

class Complete extends React.Component {

  render() {
    const { values } = this.props;
    let color = "white";
    let web = "http://"+values.website;
    let website = values.website ? <div>{web}</div> : <div></div>
    return (
      <div>
      <Table celled structured color={color} key={color} inverted>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell rowSpan='5' textAlign='center'><h3>Basic Info</h3></Table.Cell>
            <Table.Cell><b>Name</b></Table.Cell>
            <Table.Cell>{values.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Area</b></Table.Cell>
            <Table.Cell>{values.area}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Address</b></Table.Cell>
            <Table.Cell>{values.street + ",\n" + values.city + ", " + values.state + ",\n" + values.country + " - " + values.pincode}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>GSTIN</b></Table.Cell>
            <Table.Cell>{values.gstin}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Category</b></Table.Cell>
            <Table.Cell>{values.category}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell rowSpan='5' textAlign='center'><h3>Other Info</h3></Table.Cell>
            <Table.Cell><b>Website</b></Table.Cell>
            <Table.Cell>{website}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Shop Phone Number</b></Table.Cell>
            <Table.Cell>{values.phone}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Delivery</b></Table.Cell>
            {
              values.deliver ? <Table.Cell positive>{"Yes " + values.distance + " Kms"}</Table.Cell> : <Table.Cell negative>No</Table.Cell>
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Address Hidden</b></Table.Cell>
              {
                values.address ? <Table.Cell positive>Yes</Table.Cell> : <Table.Cell negative>No</Table.Cell>
              }
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>{'Reference'}</b></Table.Cell>
            <Table.Cell>{values.reference}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      {
        this.props.err.length > 0 ? (<Message color='red'>{this.props.err}</Message>) : (<div></div>)
      }
      </div>
    );
  }
}

export default Complete;
