import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Input, Table, Button, Dropdown } from 'semantic-ui-react';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import AddBill from './add';
import domain from '../functions/domain';
import Load from '../load';
import server from '../functions/server';
import Title from '../product/add_product/title';


export default class LogisticsBilling extends React.Component {

  state = {
    items: [ { id: 1 } ], load: false,
    customerName: '', customer: [],
    choosedCustomer: 0, iecNo: '', contactPerson: '', shipperName: '', mode: '', tinNo: '', gstNo: '',
    consignee: '', BLNo: '', billOfEntry: this.getFormatedDate(new Date()), loading: '', destination: '', container: '',
    volumn: '', overAllDescription: '', bankDetails: [], ledger: [],
    overAllQuantity: '', grossWeight: '',
    date: this.getFormatedDate(new Date()),
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
  }

  componentDidMount() {
    if (this.props.data.category != 'Transportation And Services' ) {
      window.location.href = domain();
    }
    // if (this.props.data.id != 12) {
    //   window.location.href = domain();
    // }
    this.getAllCustomers();
    this.getBankDetails();
    this.getLedger();
    this.getAllOrders();
  }

  getLedger() {
    let context = this;
    let todaysOrder;
    let url = server() + '/ledger/getallledger.php';
    let data = {shopId: this.props.data.id };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      context.setState({ load: false });
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {  }
      context.setState({ ledger: out });
      console.log(out);
      console.log('all ledger');
    });
  }

  getBankDetails() {
    let context = this;
    let todaysOrder;
    let url = server() + '/bankdetails/get.php';
    let data = { shop_id: this.props.data.id };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      context.setState({ load: false });
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {  }
      context.setState({ bankDetails: out });
    });
  }

  getAllOrders = () => {
    let context = this;
    let todaysOrder;
    let url = server() + '/orders/getalllogisticsordersbydate.php';
    let data = { id: this.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      context.setState({ load: false });
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {  }
      context.setState({ out: out });
    });
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  handleChange = (e, { value }) => { this.setState({ choosedCustomer: value }) }

  getAllCustomers = () => {
    let context = this;
    let url = server() + '/customers/getallcustomers.php';
    let data = {id: this.props.data.id, customerName: this.state.customerName, toDay: this.state.toDay, filter: 0 };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      context.setState({ load: false });
      let out = 1;
      try {
        out = JSON.parse(data);
        let items = JSON.stringify(out);
        context.setState({ customer: out, load: false });
      } catch(e) { alert('Error to get Customer!!!') }
    });
  }

  addItem = () => {
    let id = 0;
    this.state.items.map((c) => { id = c.id; });
    let items = this.state.items;
    id = id + 1;
    items.push({ id: id });
    this.setState({ items })
  }

  deleteItem(deleteId) {
    let item = this.state.items;
    let items = [];
    item.map((v) => {
      if (v.id != deleteId) {
        items.push(v);
      }
    });
    this.setState({ items })
  }

  onChangeText = (key) => event => { this.setState({ [key]: event.target.value }) }

  onChangeText1 = (key, id) => (e, { value }) => {
    let items = this.state.items;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.id === id) {
        item[key] = value;
      }
    }
    this.setState({ items })
  }

  makeDecimalFixed(value) {
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    let sino = 1;
    let i = 1;
    let options = [];
    if (this.state.customer) {
      let i = 0;
      options = this.state.customer.map((temp) => {
        return ({ key: i, value: i++, text: temp.name + "->" + temp.mobile });
      });
    }
    let x = (<div></div>);
    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let count = 0;
      x = this.state.out.map((v) => {
        let url = "https://business.strackit.com/business/orders/viewbill/?s=" + this.props.data.id + "&o=" + v.mid;
        return (
          <Table.Body>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.voucher_no}</Table.Cell>
            <Table.Cell>{v.customer_name}</Table.Cell>
            <Table.Cell>{v.customer_mobile}</Table.Cell>
            <Table.Cell>{v.date}</Table.Cell>
            <Table.Cell>{v.loading}</Table.Cell>
            <Table.Cell>{v.destination}</Table.Cell>
            <Table.Cell>{this.makeDecimalFixed(v.price)}</Table.Cell>
            <Table.Cell><AddBill getAllOrders={this.getAllOrders} ledger={this.state.ledger} bankDetails={this.state.bankDetails} customer={this.state.customer} title="View Bill" data={this.props.data} item={v} /></Table.Cell>
            <Table.Cell><a href={url} target="_blank">view</a></Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Load open={this.state.load}/>
        <Title shopDetails={this.props.data} menu={true} shopId={this.props.data.id} title={this.props.data.shop_name + " - Logistics Billing"} /><br/>
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={14}>
            <TextField style={{marginLeft: 20}}
                label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
                defaultValue={this.state.fromDate} />
              <TextField style={{marginLeft: 20}}
                label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
                defaultValue={this.state.toDate} />
              <Button variant="contained" color="primary" style={{marginLeft:20}} onClick={this.getAllOrders}>
                Go
              </Button>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <AddBill getAllOrders={this.getAllOrders} ledger={this.state.ledger} bankDetails={this.state.bankDetails} customer={this.state.customer} title="New Bill" data={this.props.data} />
            </Grid.Column>
          </Grid>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.NO</Table.HeaderCell>
              <Table.HeaderCell>BILL.NO</Table.HeaderCell>
              <Table.HeaderCell>CONTACT PERSON</Table.HeaderCell>
              <Table.HeaderCell>CONTACT MOBILE</Table.HeaderCell>
              <Table.HeaderCell>BILL DATE</Table.HeaderCell>
              <Table.HeaderCell>LOADING</Table.HeaderCell>
              <Table.HeaderCell>DESTINATION</Table.HeaderCell>
              <Table.HeaderCell>TOTAL PRICE</Table.HeaderCell>
              <Table.HeaderCell>VIEW</Table.HeaderCell>
              <Table.HeaderCell>INVOICE</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          {x}
        </Table>
      </div>
    );
  }

}
