import React from 'react';
import $ from 'jquery';
import { Dropdown, Grid, Checkbox, Input, Label } from 'semantic-ui-react';

import server from '../../functions/server';
import Load from '../../load';
import userId from '../../functions/userid';
import Add from '../../customers/view/add';

export default class ChooseCustomer extends React.Component {

  state = {
    customerName: '', ledgerOption: [],
    toDay: this.getFormatedDate(new Date()),
    choosedCustomer: '', choosedLedger: '',
    promotion: 0
  }

  clearData() { this.setState({ choosedCustomer: '', choosedLedger: '' }) }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getAllCustomersLocal();
    this.getLedgerLocal();
    this.getAllCustomers();
    this.getLedger();
  }

  getAllCustomersLocal() {
    let out = localStorage.getItem('customers');
    if (!out) { return; }
    out = JSON.parse(out);
    this.setState({ out })
  }

  getLedgerLocal() {
    let out = localStorage.getItem('ledgers');
    if (!out) { return; }
    out = JSON.parse(out);
    let ledgerOption = [];
    out.map((c) => {
      ledgerOption.push({ key: c.ledger, value: c.id, text: c.ledger });
    })
    this.setState({ ledgerOption })
  }

  getLedger() {
    let context = this;
    let url = server() + '/ledger/getallledger.php';
    let data = {shopId: this.props.data.id };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = [];
      let ledgerOption = [];
      try {
        out = JSON.parse(data);
        let items = JSON.stringify(out);
        localStorage.setItem('ledgers', items);
        out.map((c) => {
          ledgerOption.push({ key: c.ledger, value: c.id, text: c.ledger });
        })
        context.setState({ load: false, ledgerOption });
      } catch(e) { alert('Error to get Ledger!!!') }

    });
  }

  getAllCustomers = () => {
    let context = this;
    let url = server() + '/customers/getallcustomers.php';
    let data = {id: this.props.data.id, customerName: this.state.customerName, toDay: this.state.toDay, filter: 0 };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
        let items = JSON.stringify(out);
        localStorage.setItem('customers', items);
        context.setState({ out, load: false });
      } catch(e) { alert('Error to get Customer!!!') }
    });
  }

  checkedChange = (e, data) => {
    if (data.checked) { this.props.updateItem("receivedAmount", "0"); }
    else { this.props.updateItem("receivedAmount", "-1"); }
  }

  handleAmount = (e, { value }) => { this.props.updateItem("receivedAmount", value); }

  handleChange = (e, { value }) => {
    try {
      let obj = this.state.out[value];
      this.props.updateItem("customerName", obj.name);
      this.props.updateItem("customerMobile", obj.mobile);
      this.props.updateItem("customerAddress", obj.address);
      this.props.updateItem("customerGSTIN", obj.gstin);
      this.props.updateItem("customerID", obj.id);
      this.props.updateItem("promotion", obj.promotion);
      this.setState({ choosedCustomer: value, promotion: obj.promotion })
    } catch (e) {
      this.props.updateItem("customerName", "");
      this.props.updateItem("customerMobile", "");
      this.props.updateItem("customerAddress", "");
      this.props.updateItem("customerGSTIN", "");
      this.props.updateItem("customerID", "");
      this.props.updateItem("promotion", "");
    }
  }

  handleChange1 = (e, { value }) => { this.setState({ choosedLedger: value }); this.props.updateItem("ledger", value); }

  checkHandleChange = (e, data) => { this.setState({ promotion: data.checked }); this.props.updateItem("promotion", data.checked); }

  render() {
    let options = [];
    if (this.state.out) {
      let i = 0;
      options = this.state.out.map((temp) => {
        return ({ key: i, value: i++, text: temp.name + "->" + temp.mobile });
      });
    }
    let promotion = this.state.promotion;
    if (promotion == 0) { promotion = false; }
    else { promotion = true; }
    return (
      <div style={{marginLeft: 50, marginRight: 50, marginTop: 10}}>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Dropdown value={this.state.choosedCustomer}
              placeholder='Select Customer' onChange={this.handleChange}
              fluid search selection options={options}/>
            
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Add style={{marginLeft: 20}} buttonName={"Add Customer/Purchaser"}
              data={this.props.data} refresh={this.getAllCustomers} isCustomer={0} />
          </Grid.Column>
        </Grid><br />
      <Checkbox label='Received Amount' onChange={this.checkedChange}
        defaultChecked={this.props.amount >= 0} />
      {
        this.props.amount >= 0 && (
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <b>Enter Amount</b>
              <Input labelPosition='right' type='text' placeholder='Amount'
                onChange={this.handleAmount} value={this.props.amount}>
                <Label basic>₹</Label> <input />
              </Input>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <b>Select Ledger</b>
              <Dropdown placeholder='Select Ledger' search selection
                value={this.state.choosedLedger}
                onChange={this.handleChange1} options={this.state.ledgerOption} />
            </Grid.Column>
          </Grid>
        )
      }
      <Load open={this.state.load}/>
      </div>
    );
  }
}
