import React from 'react';
import server from '../../functions/server';
import $ from 'jquery';
import { Button } from 'semantic-ui-react';
import Load from '../../load';

export default class UpdateOnlineProducts extends React.Component {

  state = {
    data: [],
    originaldata: this.props.out
  };

  updateItems = (id, value) => {
    let data = this.state.data;
    data.push({ id: id, value: value })
    this.setState({ data })
  }

  updateOnlineProducts = () => {
    let context = this;
    let data = {shopId: this.props.shopId, data: this.state.data};
    let url = server() + '/stocks/updateonlineproducts.php';
    context.setState({ load: true })
    $.ajax({
      type: 'POST',
      data: data,
      url: url,
      success: function(data)
      {
        context.setState({ load: false })
        console.log(data);
        if (data == 1) {
          context.setState({ data: [] })
          alert('Updated Successfully!!!');
          return;
        }
        else {
          alert('failed to update!!!');
        }
      },
      error: function(error) {
        console.log(error);
        context.setState({ load: false });
      }
      });
  }

  render() {
    return (
      <div>
        { this.state.data.length > 0 ?
          (
            <Button positive onClick={this.updateOnlineProducts}>Update Online Products</Button>
          ):
          (
            <Button positive disabled>Update Online Products</Button>
          )
        }
        <Load open={this.state.load}/>
      </div>
    );
  }

}
