import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Addproducts from '../../product/add_product/addproduct';

export default class Addstock extends React.Component {

  render() {
    return (
      <div>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">
              <div style={{color: 'white'}}>Add Products</div>
            </Typography>
          </Toolbar>
        </AppBar><br/><br/><br/>
      <Addproducts from={'admin'} />
      </div>
    );
  }
}
