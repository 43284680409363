import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import $ from "jquery";
import serverName from '../../functions/server';
import Attachments from '../../media/attachment.jpg';
import { Button, Dimmer, Header, Loader, Icon } from 'semantic-ui-react';

import userId from '../../functions/userid';
const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);
class Uploadimage extends Component {

  state = {
    file: 0,
    i1: Attachments
  };

  componentDidMount()
  {
    if (this.props.edit == 'yes') {
      let x = this.props.src + "?" + new Date().getTime();
      this.setState({
        src: this.props.src
      });
    }
  }

  onChange(old) {
    let context = this;
    var image1 = document.getElementById(this.props.id).files;
    if (image1[0] == undefined) {
      return;
    }
    let x = {};
    if (image1.length > 0) {
      this.setImage('i1', image1[0]);
    }
    let url_string = window.location.href;
    var urls = new URL(url_string);
    var productId = urls.searchParams.get("id");

    var formData = new FormData();
    if (!this.props.src) {
      formData.append('productId', productId);
      formData.append('owner', userId());
      formData.append('file', image1[0]);
      $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: serverName()+'/products/insertimagefromedit.php',
      success: function(data)
      {
        console.log(data);
        if (data && context.props.edit == "no") {
          // window.location.reload();
        }
        context.setState({ loading: false });
        context.setImage('i1', image1[0]);
      },
      error: function(e) {
        alert(e)
      }
      });
    }
    else {

    }
  }

  setImage(name, file) {
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        context.setState({ [name]: e.target.result });
      };
    })(file);
    reader.readAsDataURL(file);
  }

  deleteImage = (id) => {
    let url = serverName() + '/products/deleteimage.php';
    let data = { id, path: this.props.src };
    $.ajax({
      url: url,
      data, type: 'POST'
    }).done((data) => {
      try {
        data = data.trim();
        if (data == 1) {
          alert('Image Deleted Successfully');
          this.setState({ deleted: true })
          window.location.reload();
        }
        else {
          alert("Error to delete a image");
        }
      } catch(e) {
        console.log(e);
        // alert('Error')
      }
    });
  }

  updateFeatureImage(file, path) {
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        if (context.state.feature == e.target.result) {
          return;
        }
        context.setState({ 'src': e.target.result });
      };
    })(this.state.file);
    try {
      reader.readAsDataURL(file);
    } catch(e) {}
    let url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shop");
    var productId = url.searchParams.get("id");
    let userIds = userId();
    var formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('owner', userIds);
    formData.append('p_id', productId);
    let urls = serverName() + '/products/updateimage.php';
    $.ajax({
    type: 'POST', processData: false, contentType: false,
    data: formData, url: urls,
    success: function(data)
    {
      console.log(data);
      console.log('error here');
      if (data == 1) {
      }
    },
    error: function(data, status) {
      context.setState({ loading: false });
      alert("Failed to Add Product");
    }
    });
  }

  render() {
    return (
      <div>
        { this.props.edit == 'yes' ?
          (
            <Grid.Column mobile={2} tablet={2} computer={2}>

              {
                this.state.deleted ? null : 
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<SmallAvatar onClick={ () => this.deleteImage(this.props.id)} style={{ cursor: 'pointer' }}><Icon style={{ marginLeft: '5px' }} disabled name='trash' size='tiny' /></SmallAvatar>}
                >
                  <input id={this.props.id} type="file" accept=".jpg, .png, .webp"
                    onChange={(e) => { this.updateFeatureImage(e.target.files[0], this.props.src) }} style={{display: 'none'}}/>
                  <label htmlFor={this.props.id}>
                    <Avatar src={this.state.src} id='size' style={{cursor: 'pointer'}} />
                  </label>
                </Badge>
              }
              
              <Dimmer active={this.state.loading} page>
                <Header as='h2' icon inverted>
                  <Loader />
                  <p style={{marginTop: 100}}>Loading</p>
                </Header>
              </Dimmer>
            </Grid.Column>
          ) :
          (
            <Grid.Column mobile={2} tablet={2} computer={2}>
              <input id={this.props.id} type="file" accept=".jpg, .png, .webp" onChange={this.onChange.bind(this, this.state.i1)} style={{display: 'none'}}/>
              <label htmlFor={this.props.id}>
                <Avatar src={this.state.i1} id='size' style={{cursor: 'pointer'}} />
              </label>
              <Dimmer active={this.state.loading} page>
                <Header as='h2' icon inverted>
                  <Loader />
                  <p style={{marginTop: 100}}>Loading</p>
                </Header>
              </Dimmer>
            </Grid.Column>
          )

        }

      </div>
    );
  }
}

export default Uploadimage;
