import React from 'react';
import { Button, Card, Grid, Image, Input, Form, TextArea, Dropdown } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import $ from 'jquery';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import server from '../functions/server';
import userId from '../functions/userid';
import Load from '../load';

export default class EditCoupon extends React.Component {

    state = {
        editCoupon: false,
        name: this.props.coupon.name,
        description: this.props.coupon.description,
        code: this.props.coupon.code,
        validityFrom: this.props.coupon.validity_from.split(" ")[0],
        validityTo: this.props.coupon.validity_to.split(" ")[0],
        priceRange: this.props.coupon.price_range,
        discount: this.props.coupon.discount,
        status: this.props.coupon.status,
        userId: userId(),
    }

    editCouponCode = () => {
        let { name, code, description, validityFrom, validityTo, priceRange, discount, status, userId } = this.state;
        let url = server() + '/couponcode/edit.php';
        let data = {
            id: this.props.coupon.id, name, code, description, validity_from: validityFrom,
            validity_to: validityTo, price_range: priceRange, discount: discount, status,
            user_id: userId,
        };
        this.setState({ loading: true, message: 'Editing Coupon Code' })
        $.ajax({
            url, data, type: 'POST',
        }).done((data) => {
            if (data == 1 || data == '1') {
                this.props.getCoupons()
                alert("Success");
                this.setState({ loading: false, editCoupon: false })
            }
            else {
                alert('Failed to save');
                this.setState({ loading: false })
            }
        });
    }

    render() {
        return (<div>
        <Load open={this.state.loading}/>
            <div style={{ cursor: "pointer" }} onClick={() => { this.setState({ editCoupon: true }) }} >Edit</div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={this.state.editCoupon}
                onClose={() => { this.setState({ editCoupon: false }) }}
                aria-labelledby="max-width-dialog-title"
            >
                {/* <DialogTitle id="max-width-dialog-title">{this.props.types}</DialogTitle> */}
                <DialogContent>
                    <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Name</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                value={this.state.name}
                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Coupon Code</div>
                            <Input style={{ width: '100%' }} placeholder='Coupon Code' fluid
                                value={this.state.code}
                                onChange={(e) => { this.setState({ code: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Purchase Range</div>
                            <Input style={{ width: '100%' }} placeholder='Purchase Range' fluid
                                value={this.state.priceRange}
                                onChange={(e) => { this.setState({ priceRange: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div>Discount</div>
                            <Input style={{ width: '100%' }} placeholder='Purchase Range' fluid
                                value={this.state.discount}
                                onChange={(e) => { this.setState({ discount: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={6} computer={4}>
                            <div>From Date</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                type='date'
                                value={this.state.validityFrom}
                                onChange={(e) => { this.setState({ validityFrom: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={6} computer={4}>
                            <div>To Date</div>
                            <Input style={{ width: '100%' }} placeholder='Name' fluid
                                type='date'
                                value={this.state.validityTo}
                                onChange={(e) => { this.setState({ validityTo: e.target.value }) }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={4} computer={8}>
                            <Select fullWidth label="Position" labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.status} onChange={(e) => { this.setState({ status: e.target.value }) }}>
                                <MenuItem value={0}>Inactive</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                            </Select>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Form fluid>
                                <TextArea maxlength="999" style={{ minHeight: 150 }} placeholder='Description' fluid
                                    value={this.state.description}
                                    onChange={(e) => { this.setState({ description: e.target.value }) }}
                                />
                            </Form><br />
                        </Grid.Column>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button negative onClick={() => { this.setState({ editCoupon: false }) }}>Cancel</Button>
                    <Button positive content="Update"
                        onClick={() => {
                            this.editCouponCode()
                        }}
                    />
                </DialogActions>
            </Dialog>
        </div >)
    }
}