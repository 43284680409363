import React, { useState } from 'react';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Table, Button, Dropdown } from 'semantic-ui-react';

import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Title from '../../product/add_product/title';

export default function FilteredReports(props) {

    const getFormatedDate = (dates, interval) => {
        if (interval > 0) {
            dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
        }
        let month = dates.getMonth() + 1;
        let date = dates.getDate();
        month = (month >= 10) ? month : "0" + month;
        date = (date >= 10) ? date : "0" + date;
        let x = dates.getFullYear() + "-" + month + "-" + date;
        return x;
    };

    const [fromDate, setFromDate] = useState(getFormatedDate(new Date(), 7));
    const [toDate, setToDate] = useState(getFormatedDate(new Date()));
    const [load, setLoad] = useState(false);
    const [report, setReport] = useState([]);
    const [group, setGroup] = useState("hour");
    const [orderBy, setOrderBy] = useState("category");
    const [order, setOrder] = useState(" ");

    const getReport = () => {
        let url = server() + '/reports/filteredreport.php';
        let data = {
            shopId: props.data.id,
            fromDate: fromDate,
            toDate: toDate,
            groupBy: group,
            orderBy: orderBy + ' ' + order,
        };
        $.ajax({
            url,
            data,
            type: 'POST',
            beforeSend: function (x) {
                setLoad(true);
            }
        }).done((data) => {
            let out = [];
            try {
                out = JSON.parse(data);
            } catch (e) {
                console.log(data);
            }
            setReport(out);
            setLoad(false);
        });
    }

    const handleChange = (set) => (e, { value }) => {
        set(value);
    }

    let total = 0, totalBills = 0, totalStocks = 0;
    let x = report.map((temp, index) => {
        total += parseFloat(temp.total);
        totalBills += parseInt(temp.total_bills);
        totalStocks += parseInt(temp.total_stocks);
        return (
            <Table.Row>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{temp.category}</Table.Cell>
                <Table.Cell>{temp.total_bills}</Table.Cell>
                <Table.Cell>{temp.total_stocks}</Table.Cell>
                <Table.Cell>{"Rs." + parseFloat(temp.total).toFixed(2)}</Table.Cell>
            </Table.Row>
        );
    });

    return (
        <div>
            <Title shopDetails={props.data} shopId={props.data.id}
                title={props.data.shop_name + " - Performance Report"} menu={true} />
            <div style={{ margin: 20, float: 'left' }}>
                <TextField style={{ marginLeft: 20 }}
                    label="From" type="date" onChange={(e) => setFromDate(e.target.value)}
                    defaultValue={fromDate} />
                <TextField style={{ marginLeft: 20 }}
                    label="To" type="date" onChange={(e) => setToDate(e.target.value)}
                    defaultValue={toDate} />
                <Dropdown value={group} placeholder='Select Group'
                    style={{ marginLeft: 20 }}
                    onChange={handleChange(setGroup)} selection
                    options={[
                        { text: "Customer", key: "customer", value: "customer" },
                        { text: "Stock", key: "stock", value: "stock" },
                        { text: "Hour", key: "hour", value: "hour" },
                        { text: "Day", key: "day", value: "day" },
                        { text: "Date", key: "date", value: "date" },
                    ]} />
                <Dropdown value={orderBy} placeholder='Select Order'
                    style={{ marginLeft: 20 }}
                    onChange={handleChange(setOrderBy)} selection
                    options={[
                        { text: "Category", key: "category", value: "category" },
                        { text: "Bills", key: "total_bills", value: "total_bills" },
                        { text: "Stocks", key: "total_stocks", value: "total_stocks" },
                        { text: "Amount", key: "total", value: "total" },
                    ]} />
                <Dropdown value={order} placeholder='Select Order'
                    style={{ marginLeft: 20 }}
                    onChange={handleChange(setOrder)} selection
                    options={[
                        { text: "Ascending", key: "ascending", value: " " },
                        { text: "Descending", key: "descending", value: "DESC" }
                    ]} />
                <Button variant="contained" color="primary" style={{ marginLeft: 20 }} onClick={getReport}>
                    Go
                </Button><Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>S.No</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Bills</Table.HeaderCell>
                            <Table.HeaderCell>Stocks</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {x}
                        <Table.Row>
                            <Table.Cell>{" "}</Table.Cell>
                            <Table.HeaderCell>{"Total"}</Table.HeaderCell>
                            <Table.Cell>{totalBills}</Table.Cell>
                            <Table.Cell>{totalStocks}</Table.Cell>
                            <Table.Cell>{parseFloat(total).toFixed(2)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Load open={load} />
            </div>
        </div>
    );

}