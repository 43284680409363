import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import { List, Segment } from 'semantic-ui-react';

import Title from '../../product/add_product/title';
import WeekOff from './weekoff';
import Add from './add';
import server from '../../functions/server';

export default class Holiday extends React.Component {

    state = {
        weekoff: [0],
        holidays: [],
    };

    componentDidMount() {
        this.getHolidays();
    }

    getHolidays = () => {
        let context = this;
        let id = this.props.data.id;
        let url = server() + '/employees/holidays/get.php';
        let data = { id };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            },
        }).done((data) => {
            let holidays = [], weekoff = [];
            try {
                data = JSON.parse(data);
                for (const holiday of data) {
                    if (holiday.date.startsWith("0000")) {
                        let off = holiday.weekoff.split('');
                        for (const day of off) {
                            weekoff.push(parseInt(day));
                        }
                        continue;
                    }
                    holidays.push(holiday);
                }
            } catch (e) { }
            context.setState({ holidays, weekoff, load: false });
        });
    }

    updateWeekOff = (isAdd, value) => {
        let { weekoff } = this.state;
        if (isAdd) weekoff.push(value);
        else weekoff.splice(weekoff.indexOf(value), 1);
        this.setState({ weekoff });
    }

    addOrUpdate = (date, weekoff, remarks, callback) => {
        let context = this;
        let id = this.props.data.id;
        let url = server() + '/employees/holidays/edit.php';
        let data = { id, weekoff, date, remarks };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            },
        }).done((data) => {
            context.setState({ weekoff: [], load: false });
            context.getHolidays();
            if (callback) callback();
        });
    }

    render() {
        return (
            <div>
                <Title shopId={this.props.data.id} shopDetails={this.props.data} title={this.props.data.shop_name + " - Holidays"} menu={true} />
                <div style={{ margin: 10 }}>
                    <WeekOff weekoff={this.state.weekoff}
                        update={this.updateWeekOff}
                        sync={this.addOrUpdate} />
                </div>
                <div style={{ margin: 10 }}>
                    <Add sync={this.addOrUpdate} />
                </div>
                <div style={{ margin: 10 }}>
                    <Segment.Group>
                        <Segment clearing>
                            <b>Holidays</b>
                        </Segment>
                        <Segment>
                            <List horizontal relaxed='very'>
                                {this.state.holidays.map((hol) => {
                                    return (
                                        <List.Item>
                                            <List.Content>
                                                <List.Header>
                                                    {hol.remarks}
                                                </List.Header>
                                                {moment(hol.date, 'YYYY-MM-DD').format('DD MMM YY')}
                                            </List.Content>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Segment>
                    </Segment.Group>
                </div>
            </div>
        );
    }

}