import React from 'react';
import { Button, Grid, Input } from 'semantic-ui-react'
import $ from 'jquery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import server from '../../functions/server';
import Load from '../../loads';



export default class AddDesignation extends React.Component {

  state = {
    open: false
  }

  onChangeText = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  submit() {
    let context = this;
    let { designation } = this.state;
    if (!designation || designation.length < 1) {
      alert('Enter Designation!!!');
      return;
    }
    let url;
    let data = [];
    if (this.props.title !== "Edit") {
      url = server() + '/designations/add.php';
      data = { id: this.props.shopId, designation };
    }
    else {
      url = server() + '/designations/edit.php';
      data = { id: this.props.item.id, designation };
    }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true, message: 'Updating!!!' });
      }
    }).done((data) => {
      if (data == 1) {
        alert('Success');
        context.setState({ open: false, bank: '', beneficiaryName: '', accNo: '', ifsc: '', load: false });
        this.props.getDesignations();
      }
      else {
        this.setState({ load: false })
      }
    });
  }

  edit() {
    this.setState({ designation: this.props.item.designation, open: true
    })
  }

  render() {
    let { designation } = this.state
    return (
      <div>
        { this.props.title !== "Edit" ?
          (<Button primary onClick={ () => { this.setState({ open: true }) }}>ADD</Button>):
          (<div style={{color: 'blue', cursor: 'pointer'}} onClick={this.edit.bind(this)}>Edit</div>)
        }
        <Load load={this.state.load} message={this.state.message} />
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                Designation
                <Input fluid placeholder="Designation" value={designation} onChange={this.onChangeText('designation')} />
              </Grid.Column>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({ open: false }) } } color="negative">Cancel</Button>
            <Button color="positive" onClick={this.submit.bind(this)}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
