import React from 'react';
import AddProduct from './add_product/products';

export default class Product extends React.Component {

  render = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shop");
    let froms = 'add';
    try {
      froms = url.searchParams.get("from");
    } catch (e) {
    }
    return (
      <AddProduct shop={shopId} from={froms}/>
    );
  }

}
