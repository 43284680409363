
import React from "react";

import ShopProfile from './views/ShopProfile.js';


import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";


class Dashboard extends React.Component {

  render() {
    return (
      <div style={{marginTop: '5%'}}>
          <ShopProfile data={this.props.data} shopId={this.props.shopId} />
      </div>
    );
  }
}

export default Dashboard;
