import React, { useState } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Button, Table } from 'semantic-ui-react';

import server from '../../functions/server';
import Load from '../../load';

export default function Item({ transaction, index, getAccounts }) {

    const [load, setLoad] = useState(false);

    const del = () => {
        let context = this;
        let url = server() + '/employees/transactions/delete.php';
        let data = { id: transaction.id };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                setLoad(true);
            }
        }).done((data) => {
            setLoad(false);
            getAccounts();
        }).catch((err) => {
            setLoad(false);
            context.setState({ load: false });
        });;
    }

    return (
        <Table.Row>
            <Table.Cell>{index}</Table.Cell>
            <Table.Cell>{transaction.amount}</Table.Cell>
            <Table.Cell>{moment(transaction.date, 'YYYY-MM-DD').format('DD MMM YY')}</Table.Cell>
            <Table.Cell>{transaction.remarks}</Table.Cell>
            <Table.Cell>
                {
                    moment(transaction.timestamp, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YY hh:mm:ss')
                }
            </Table.Cell>
            <Table.Cell>
                <Button color="red" icon="delete"
                    onClick={() => { 
                        let x = window.confirm("Are you sure? Do you want to delete this entry?");
                        if (x) {
                            del();
                        }
                    }}
                    content="Delete"/>
                <Load open={load} />
            </Table.Cell>
        </Table.Row>
    );
}