import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import { Header, Grid, Image } from 'semantic-ui-react';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10
  }
};

class Shop extends React.Component {
  state = {

  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div style={{marginLeft: '0.5%', marginTop: '2%', marginBottom: '0.5%'}}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                  <Paper style={{height: '100vh'}}>
                  <Image src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg"
                  size='medium' style={{width: '52%', height: '30%', marginLeft: 'auto', marginRight: 'auto'}}  circular />
                  </Paper>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

Shop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Shop));
