import React, { Component } from 'react';
import userId from '../../functions/userid';
import $ from "jquery";
import serverName from '../../functions/server';
import { Image } from 'semantic-ui-react';

import Attachment from '../../media/attachment.jpg';

class Images extends Component {

  state = {

  }

  updateFeatureImage(file, path) {
    let context = this;
    let url_string = window.location.href;
    var url = new URL(url_string);
    var productId = url.searchParams.get("id");
    let reader = new FileReader();
    let userIds = userId();
    var formData = new FormData();
    reader.onload = (function(theFile) {
      return function(e) {
        if (context.state.feature == e.target.result) { return; }
        context.setState({ 'image': e.target.result });
      };
    })(this.state.file);
    try {
      reader.readAsDataURL(file);
    } catch(e) {}
    if (!path) {
      formData.append('file', file);
      formData.append('owner', userIds);
      formData.append('p_id', productId);
      let urls = serverName() + '/products/updateimagefromedit.php';
      $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: urls,
      success: function(data) {
        alert('Success')
      },
      error: function(data, status) {
        context.setState({ loading: false });
        alert("Failed to Add Product");
      }
      });
    }
    else {
      formData.append('file', file); formData.append('path', path);
      formData.append('owner', userIds); formData.append('p_id', productId);
      let urls = serverName() + '/products/updateimage.php';
      $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: urls,
      success: function(data) {
        alert('Success')
      },
      error: function(data, status) {
        context.setState({ loading: false });
        alert("Failed to Add Product");
      }
      });
    }
  }

  render() {
    let image = this.props.image;
    if (!image || image.length < 3) {
      image = Attachment;
    }
    return (
      <div>
        { this.state.image ?
          (
            <div>
              <input type="file" accept=".jpg, .png, .webp" name="fileToUpload" id="fileToUploads"
                onChange={(e) => { this.updateFeatureImage(e.target.files[0], this.props.image) }}
                style = {{display: 'none'}} />
              <label htmlFor="fileToUploads">
                <Image src={this.state.image} fluid id='image' style={{cursor: 'pointer'}} /><br/>
              </label>
            </div>
          ):
          (
            <div>
              <input type="file" accept=".jpg, .png, .webp" name="fileToUpload" id="fileToUploads"
                onChange={(e) => { this.updateFeatureImage(e.target.files[0], this.props.image) }}
                style = {{display: 'none'}} />
              <label htmlFor="fileToUploads">
                <Image src={image} fluid id='image' style={{cursor: 'pointer'}} /><br/>
              </label>
            </div>
          )
        }
      </div>
    );
  }
}

export default Images;
