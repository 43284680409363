import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Header, Form, Input, TextArea, Icon } from 'semantic-ui-react';
import server from '../../functions/server';
import userId from '../../functions/userid';

import $ from 'jquery';

export default class Profile extends React.Component {
  state = {
    open: false,
    description: "", address: "", city: "", country: "", state: "", pincode: "", facebook: "", twitter: "", instagram: ""
  };

  componentDidMount() {
    this.setState({description: this.props.data.description, address: this.props.data.address, city: this.props.data.city,
      country: this.props.data.country, state: this.props.data.state, pincode: this.props.data.pincode,
      facebook: this.props.data.facebook_link, twitter: this.props.data.twitter_link, instagram: this.props.data.instagram_link});
  }

  handleClose = () => {
    this.props.handleState({editprofile: false});
  };

  componentDidCatch(error, info) {
    console.log(info);
  }

  UpdateProfile = () => {
    let { description, address, city, state, country, pincode, facebook, twitter, instagram   } = this.state;
    let data = { id: this.props.data.id, owner: userId(), description, address, city, state, country, pincode, facebook, twitter, instagram};
    let url = server() + "/shop/editprofile.php";
    $.ajax({url, method: "POST", data, success: (data) => {
      if(data == "1" || data == "true") {
        alert("Updated Successfully" );
        this.setState({ editprofile: false });
        window.location.reload();
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return (
      <div>
        <Icon name="edit" style={{cursor: 'pointer'}} onClick={() => this.setState({editprofile: true})}/>
        <Dialog open={this.state.editprofile} onClose={ () => { this.setState({editprofile: false}) }}  aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
          <DialogContent>
            <Form>
              <Form.TextArea  margin="dense" id="descriptionText"
                fullWidth value={this.state.description}
                onChange={(e) => {this.setState({description: e.target.value})}}
                label="Description" />
              <Form.Group>
                <Form.Input value={this.state.address}
                onChange={(e) => {this.setState({address: e.target.value})}} label="Address"/>
                <Form.Input value={this.state.city}
                onChange={(e) => {this.setState({city: e.target.value})}} label="City"/>
                <Form.Input value={this.state.state}
                onChange={(e) => {this.setState({state: e.target.value})}} label="State"/>
              </Form.Group>
              <Form.Group>
                <Form.Input value={this.state.country}
                onChange={(e) => {this.setState({country: e.target.value})}}label="Country" width={10}/>
                <Form.Input value={this.state.pincode} type="number"
                onChange={(e) => {this.setState({pincode: e.target.value})}} label="Pincode" width={10}/>
              </Form.Group>
              <Form.Group>
                <Form.Input value={this.state.facebook}
                onChange={(e) => {this.setState({facebook: e.target.value})}}label="Facebook" />
              <Form.Input value={this.state.twitter}
                onChange={(e) => {this.setState({twitter: e.target.value})}}label="Twitter" />
              <Form.Input value={this.state.instagram}
                onChange={(e) => {this.setState({instagram: e.target.value})}}label="Instagram" />
              </Form.Group>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({editprofile: false}) } } color="primary">
              Cancel
            </Button>
            <Button onClick={this.UpdateProfile} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
