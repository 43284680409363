import React from 'react';
import { Link } from "react-router-dom";
import { Card, Icon, Image } from 'semantic-ui-react';
import AddProduct from '../../media/addproduct.png';

const style = {
  image: {
    position: 'relative',
    float: 'left',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: 200,
    maxHeight: 200
  },
  card: {
    textAlign: 'left',
    minHeight: 325,
    maxHeight: 325
  },
  description: {
    maxHeight: '2.6em',
    lineHeight: '1.3em'
  }
}

export default class Add extends React.Component {
  constructor() {
    super();
  }

  render() {
    let link = "/stocks";
    return (
      <center>
        <Link to={link}>
          <Card style={style.card}>
            <img style={style.image} src={AddProduct}/>
            <Card.Content>
              <Card.Header>Add New Product</Card.Header>
              <Card.Meta>
                <span className='date'> </span>
              </Card.Meta>
              <Card.Description style={style.description}>Add products show that people will order online</Card.Description>
            </Card.Content>
          </Card>
        </Link>
      </center>
    );
  }
}
