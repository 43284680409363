import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Header } from 'semantic-ui-react';
import server from '../../functions/server';
import userId from '../../functions/userid';
import $ from 'jquery';

export default class EditCountry extends React.Component {
  state = {
    open: false,
    country: ""
  };

  handleClose = () => {
    this.props.handleState({editcountry: false});
  };

  handleUpdate = () => {
    let country = this.state.country.trim();
    if (country.length < 2) {
      this.setState({
        message: "Invalid Country"
      });
      return;
    }
    let data = {
      id: this.props.id,
      owner: userId(),
      type: 'country',
      value: country
    }
    console.log(data);
    let context = this;
    $.ajax({
      type: 'POST',
      url: server() + "/shop/update.php",
      data: data,
      beforeSend: function(x) {
          context.setState({
            dimmer: true,
            loadText: "Updating Mobile Number"
          });
      },
      success: function(data, status) {
        let editcountry = true;
        if (data == "1") {
          context.props.updateProps('country', country);
          editcountry = false;
        }
        context.props.handleState({
          dimmer: false,
          loadText: "", editcountry

        });
      },
      error: function(data, status) {
        context.props.handleState({
          dimmer: false,
          loadText: "",
          message: data
        });
      }
    });
  }

  render() {
    if (!this.state.country) {
      this.setState({
        country: this.props.country
      })
    }
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Update country Number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              country Number
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="descriptionText"
              value={this.state.country}
              onChange={(e) => {this.setState({country: e.target.value, message:""})}}
              fullWidth
            />
            <Header as='h4' color='red'>
              {this.state.message}
            </Header>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
