import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import MButton from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Item from './item';
import Add from '../add';

export default class View extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
    toDay: this.getFormatedDate(new Date()),
    todaysOrder: 0,
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1;
    let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getAllOrders();
  }

  getAllOrders = () => {
    let context = this;
    let todaysOrder;
    let url = server() + '/orders/getallordersbydate.php';
    let data = {id: this.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate, toDay: this.state.toDay};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      let date = this.getTodayDate();
      let out = 1;
      try {
        out = JSON.parse(data);
        out.map((c) => { todaysOrder = c.todaysOrder; })
        if (!todaysOrder) { todaysOrder = 0; }
        else {
          todaysOrder = Number(todaysOrder);
        }
      } catch(e) { todaysOrder = 0; }
      context.setState({ out: out, load: false, todaysOrder });
    });
  }

  updateItem = (id, name, mobile) => {
    let { out } = this.state;
    let newOut = [];
    for (var i = 0; i < out.length; i++) {
      let x = out[i];
      if (x.id === id) {
        x.customer_name = name;
        x.customer_mobile = mobile;
      }
      newOut.push(x);
    }
    this.setState({out: newOut});
  }

  getTodayDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd < 10) { dd = '0' + dd; }
    if(mm < 10) { mm = '0' + mm; }
    today = yyyy+'-'+mm+'-'+dd;
    return today;
  }

  downloadApp = () => {
    window.location.href = "https://business.strackit.com/StrackitBusinessBillingAPK.apk";
  }

  render() {
    let x = (<div></div>);
    let { data } = this.props;
    let total = 0;


    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let count = 0;
      x = this.state.out.map((temp) => {
        total += isNaN(temp.total_price) ? 0 : temp.total_price;
        return (<Item data={context.props.data} item={temp} updateItem={context.updateItem}
                  data={data} count={++count}/>);
      });
    }


    let prime = this.props.data.prime;
    let limitedOrder = this.props.data.primeLimitation;
    let todaysOrder = this.state.todaysOrder;
    return (
      <div>
        <div>
          <div style={{margin: 20, float: 'left'}}>
            <TextField style={{marginLeft: 20}}
              label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
              defaultValue={this.state.fromDate} />
            <TextField style={{marginLeft: 20}}
              label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
              defaultValue={this.state.toDate} />
            <Button variant="contained" color="primary" style={{marginLeft:20}} onClick={this.getAllOrders}>
              Go
            </Button>
          </div>
          <div style={{margin: 20, float: 'right'}}>
              <Button onClick={ () => this.downloadApp() } icon labelPosition='right' positive style={{marginLeft: 20}}>
                Download App
              <Icon name='download' />
            </Button>
            { todaysOrder <= limitedOrder ?
              (
                <Link to="/billing/new">
                  <Button icon labelPosition='right' positive style={{marginLeft: 20}}>
                    New Bill
                    <Icon name='add' />
                  </Button>
                </Link>
              ):
              (
                <Button icon labelPosition='right' onClick={ () => { alert('Please upgrade Your Prime Pack!!!') } } positive style={{marginLeft: 20}}>
                  New Bill
                  <Icon name='add' />
                </Button>
              )
            }
            <Button icon labelPosition='right' negative style={{marginLeft: 20}}
               onClick={this.getAllOrders}>
              Refresh
              <Icon name='undo' />
            </Button>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Bill.No</Table.HeaderCell>
              <Table.HeaderCell>Ordered By</Table.HeaderCell>
              <Table.HeaderCell>Bill Date</Table.HeaderCell>
              <Table.HeaderCell>Total Products</Table.HeaderCell>
              <Table.HeaderCell>Total Stock</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell>Payment Mode</Table.HeaderCell>
              <Table.HeaderCell>View</Table.HeaderCell>
              <Table.HeaderCell>Invoice</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {x}
          </Table.Body>
        </Table>
        <Load open={this.state.load}/>
      </div>
    );
  }

}
