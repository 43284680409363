import React from 'react';
import { Grid, Image, Header, List, Button, Icon } from 'semantic-ui-react';
import BusinessSiteImage from '../media/bussite.png';

export default class Business extends React.Component {

  render() {
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Header as="h1" color="blue">Create Free Business Site and Share with your Customers</Header>
          <br />
          <List divided relaxed>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Create within few seconds</List.Header>
                <List.Description>Simply Fill a Form and We will create it for you</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Edit Easily</List.Header>
                <List.Description>Edit your site any time at free of cost</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Free Business Link</List.Header>
                <List.Description>We will give you a unique link which you can share with your customers</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>No need of IT Knowledge</List.Header>
                <List.Description>Just browsing knowledge is enough to create and edit your site</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Categorize your products</List.Header>
                <List.Description>Show the types of products available in your business site</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='blue' name='arrow right' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Share your site instead of contacts</List.Header>
                <List.Description>People love to visit the sites instead of conrtacts. So we do it for you</List.Description>
              </List.Content>
            </List.Item>
          </List>
          <a href="https://you.strackit.com?redirectto=https://business.strackit.com">
            <Button color='red' inverted icon labelPosition='right'>
              Create Now
              <Icon name='right arrow' />
            </Button>
          </a>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Image src={BusinessSiteImage} />
        </Grid.Column>
      </Grid>
    );
  }

}
