import React from 'react';
import $ from 'jquery';
import { Grid } from 'semantic-ui-react';
import { Edit } from '@material-ui/icons/';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import server from '../functions/server';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog(props) {

  const [open, setOpen] = React.useState(false);
  const [openC, setOpenC] = React.useState(false);
  const [masterCategory, setMasterCategory] = React.useState(props.data.mid);
  const [category, setCategory] = React.useState(props.data.category);
  const [position, setPosition] = React.useState(props.data.position);
  const [status, setStatus] = React.useState(props.data.secondaryCategoryStatus);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const edit = () => {
    if (!category.trim().length || masterCategory < 1) {
      alert('Entry Category Name!!!')
      return ;
    }
    if (!position.trim().length) {
      alert('Entry Position!!!')
      return ;
    }
    $.ajax({type: "POST", url: server() + '/categories/update.php',
      data: { id: props.data.id, mid: masterCategory, category, position, status },
      success: (data) => {
        console.log(data);
        if (data === "1") {
          setOpen(false);
          props.refresh(props.choosedCategory);
        } else {
          alert("Duplicate Entry OR Failed");
        }
      }, error: (e) => {
        console.log(e);
        alert("Error");
      }});
  }

  return (
    <span>
      <div onClick={handleClickOpen} style={{cursor: 'pointer'}}>Edit</div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{width: '300px'}}>Update Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update Category to use in Stock.
          </DialogContentText>
          <TextField autoFocus value={category} margin="dense" id="category" label="Category" type="name" onChange={(e) => setCategory(e.target.value)} fullWidth />
          <Grid>
            <Grid.Column mobile={8} computer={8} tablet={8}>
              Master Category<br/>
              <Select open={openC} onClose={() => setOpenC(false)} onOpen={() => setOpenC(true)} value={masterCategory} fullWidth onChange={(e) => setMasterCategory(e.target.value)}>
                {
                  props.masterCategories.map((data) => {
                    return (<MenuItem value={data.id}>{data.category}</MenuItem>)
                  })
                }
              </Select>
            </Grid.Column>
            <Grid.Column mobile={8} computer={8} tablet={8}>
              Status<br/>
              <Select fullWidth label="Position" labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </Grid.Column>
          </Grid>
          <TextField autoFocus value={position} margin="dense" id="position" label="Position" type="number" onChange={(e) => setPosition(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">Cancel</Button>
          <Button onClick={edit} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
