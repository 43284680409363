import React from 'react';
import { Grid } from 'semantic-ui-react';

import Title from '../product/add_product/title';
import View from './view';
import Prime from '../prime';
import Report from './report';

export default class GstReport extends React.Component {

  state = {  }

  componentDidMount() { this.toDate(); }

  toDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd < 10) {  dd = '0' + dd; }
    if(mm < 10) { mm = '0' + mm; }
    today = yyyy+'-'+mm+'-'+dd;
    let firstDay = yyyy+'-'+mm+'-01';
    this.setState({
      toDate: today,
      fromDate: firstDay
    });
  }

  handleChange = (name) => event => {
    this.setState({ [name]: event.target.value })
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - GST REPORT"} menu={true}/>
        { this.props.data.prime == 0 ?
          (<Prime data={this.props.data} />):
          (<div style={{margin: 10}}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <div style={{display: 'row'}}>
                    <input type="date" value={this.state.fromDate} onChange={this.handleChange('fromDate')} style={{height: 35, width: '50%' }} />
                    <input type="date" value={this.state.toDate} onChange={this.handleChange('toDate')} style={{height: 35, width: '50%' }} />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <Report fromDate={this.state.fromDate} data={this.props.data} toDate={this.state.toDate} />
              </Grid.Column>
            </Grid>

            <br/>
            <View fromDate={this.state.fromDate} data={this.props.data} toDate={this.state.toDate} />
          </div>)
        }
      </div>
    );
  }

}
