import React from 'react';
import Title from '../product/add_product/title';
import server from '../functions/server';
import $ from 'jquery';
import Load from '../load';
import { Table, Dropdown } from 'semantic-ui-react';

export default class Ledger extends React.Component {

  state = {
    load: false, ledger: []
  }

  delete(status) {
    let context = this;
    let url = server() + '/ledger/deleteledger.php';
    let data = {id: this.props.item.id, status: status };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      if (data == 1) {
        context.props.updateItem('Update', context.props.item.id, status);
        context.setState({ load: false })
      }
      else {
        alert('Error to delete Data');
      }
    });
  }

  handleChange1 = (e, { value }) => {
    if (value == 'Active') { value = 1; }
    else { value = 0; }
    this.delete(value);
  }

  deleteData() {
    let context = this;
    let url = server() + '/ledger/deleteledgerdata.php';
    let data = {id: this.props.item.id };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      if (data == 1) {
        context.props.updateItem('Delete', context.props.item.id);
        context.setState({ load: false })
      }
      else {
        alert('Error to delete Data');
      }
    });
  }


  render() {
    let status = this.props.item.status;
    if (this.props.item.status == '1' || this.props.item.status == 1) {
      status = 'Active';
    }
    else {
      status = 'Deactive';
    }
    let Options = [
      { key: 'Active', text: 'Active', value: 'Active' },
      { key: 'Deactive', text: 'Deactive', value: 'Deactive' }
    ]
    return (
      <Table.Row>
        <Table.Cell><Load open={this.state.load} />{this.props.sino}</Table.Cell>
        <Table.Cell>{this.props.item.ledger}</Table.Cell>
        <Table.Cell>
          <Dropdown placeholder='Select Ledger' search selection
            fluid
            onChange={this.handleChange1} value={status}
            options={Options} />
        </Table.Cell>
        <Table.Cell onClick={this.deleteData.bind(this)} style={{cursor: 'pointer'}}>
          <b>Delete</b>
        </Table.Cell>
      </Table.Row>
    );
  }

}
