import React from 'react';
import $ from 'jquery';
import { Dropdown, Table } from 'semantic-ui-react';

import Add from './add';
import server from '../../functions/server';
import Load from '../../load';
import Title from '../../product/add_product/title';
import Item from './item';

export default class Accounts extends React.Component {

    state = {
        employee: null,
        employees: [],
        transactions: []
    }

    componentDidMount() {
        this.getAllEmployees();
    }

    getAllEmployees() {
        let context = this;
        let url = server() + '/employees/getall.php';
        let data = { id: this.props.data.id };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = 1;
            try {
                out = JSON.parse(data);
            } catch (e) { }
            context.setState({ employees: out, load: false });
        }).catch((err) => {
            console.log(err);
            context.setState({ load: false });
        });
    }

    getAccounts = () => {
        let context = this;
        let url = server() + '/employees/transactions/get.php';
        let data = { id: this.state.employee };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            let out = 1;
            try {
                out = JSON.parse(data);
            } catch (e) { }
            context.setState({ transactions: out, load: false });
        }).catch((err) => {
            console.log(err);
            context.setState({ load: false });
        });
    }

    render() {
        return ( 
            <div>
                <Title shopId={this.props.data.id} shopDetails={this.props.data} title={this.props.data.shop_name + " - Accounts"} menu={true} />
                <Load open={this.state.load} />
                <Dropdown
                    placeholder='Select Employee'
                    style={{ margin: 10 }}
                    selection
                    value={this.state.employee}
                    onChange={(e, { value }) => { 
                        this.setState({ employee: value }, () => {
                            this.getAccounts();
                        });
                    }}
                    options={this.state.employees.map((emp) => {
                        return {
                            key: emp.id,
                            text: emp.custom_id + " - " + emp.name,
                            value: emp.id,
                        }
                    })}
                />
                <div style={{ margin: 10 }}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>S.No</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Remarks</Table.HeaderCell>
                                <Table.HeaderCell>Created On</Table.HeaderCell>
                                <Table.HeaderCell>Delete</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.transactions.map((transaction, index) => {
                                    return (
                                        <Item
                                            transaction={transaction}
                                            getAccounts={this.getAccounts}
                                            index={index + 1}/>
                                    );
                                })
                            }
                        </Table.Body>
                    </Table>
                </div>
                <div style={{ margin: 10 }}>
                    <Add employee={this.state.employee} getAccounts={this.getAccounts}/>
                </div>
            </div>
        );
    }

}