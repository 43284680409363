import React, { Component } from 'react';
import $ from "jquery";

import Title from './title';
import Addproduct from '../add_product/addproduct';
import server from '../../functions/server';
import userId from '../../functions/userid';
import domain from '../../functions/domain';
import AddProduct from '../add_product/addproduct';
import Load from '../../load';

class Edit_product extends Component {
  state = {
    product: []
  };

  componentDidMount() {
    this.getProduct();
  }

  getProduct() {
    let context = this;
    let x = window.location.href;
    var url = new URL(x);
    var edit = url.searchParams.get("id");
    this.setState({ load: true, message: 'Getting Data!!!' })
    $.ajax({
      type: 'POST',
      data: { 'product_id': edit },
      url: server() + '/products/get_edit_product.php',
      success: function(data) {
        try {
          data = JSON.parse(data);
          context.setState({ product: data, productId: edit, load: false })
        } catch (e) {
          context.setState({ load: false })
        }
      },
      error: function(err) { console.log(err); }
    });
  }

  render() {
    let image1, image2, image3, image4, image5;
    let data = this.state.product;
    let id = 1;
    data = data.map((c) => {
      if (c.type == 'images') {
        if (id == 1) { image1 = c.product_name; }
        else if (id == 2) { image2 = c.product_name; }
        else if (id == 3) { image3 = c.product_name; }
        else if (id == 4) { image4 = c.product_name; }
        else if (id == 5) { image5 = c.product_name; }
        id = id + 1
      }
    })
    return (
      <div>
        <Title shopDetails={{}} title="Edit Product"/>
        <Load open={this.state.load} message={this.state.message} />
        { this.state.product.length > 0 ?
          (
            <div>
              {
                this.state.product.map((c) => {
                  if (c.type == 'products') {
                    console.log(c);console.log('-----item------e--e-e-e--');
                    return (
                      <AddProduct from='edit' localname={c.local_name} offerStocks={c.offerends} discount={c.discount}
                        min_stock={c.min_stock} tax={c.tax} itemNumber={c.item_number} category={c.category}
                        description={c.description} noStocks={c.no_stock} price={c.prize} name={c.product_name}
                        dnp={c.dnp} featureImage={c.feature_image} mastercategory={c.mastercategory} image3={image3}
                        specification={c.specification} image1={image1} image2={image2} image4={image4} image5={image5} 
                        seo_keyword={c.seo_keyword} how_to_use={c.how_to_use} other_information={c.other_information} 
                        getProduct={this.getProduct}
                        data={this.state.product} hsnCode={c.hsn_code} view_price={c.view_price} productId={this.state.productId} />
                    )
                  }
                })
              }
            </div>
          ): (<div></div>)
        }
      </div>
    );
  }
}

export default Edit_product;
