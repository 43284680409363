import React, { useState } from 'react';
import $ from 'jquery';
import { Segment, Button, Input } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';

import server from '../../functions/server';
import Load from '../../load';

export default function Add({ employee, getAccounts }) {

    const [load, setLoad] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState(null);

    const add = () => {
        if (!employee) {
            alert("Please choose employee");
            return;
        }
        let url = server() + '/employees/transactions/add.php';
        let data = { id: employee, remarks, amount, date };
        $.ajax({
            url: url,
            data, type: 'POST',
            beforeSend: function (x) {
                setLoad(true);
            }
        }).done((data) => {
            setRemarks('');
            setAmount(0);
            getAccounts();
            setLoad(false);
        });
    }

    return (
        <div>
            <Load open={load} />
            <Segment.Group>
                <Segment clearing>
                    <b>Add Transaction</b>
                    <Button color='green' icon='check' 
                        labelPosition='left'
                        content='Add'
                        floated='right'
                        onClick={() => add()}/>
                </Segment>
                <Segment>
                    <TextField type="date"
                        onChange={(e) => setDate(e.target.value)}
                        defaultValue={date} />
                    <Input style={{ marginLeft: 20, minWidth: 250 }}
                        placeholder="Amount"
                        value={amount}
                        onChange={(e, { value }) => setAmount(value)}/>
                    <Input style={{ marginLeft: 20, minWidth: 250 }}
                        placeholder="Remarks"
                        value={remarks}
                        onChange={(e, { value }) => setRemarks(value)}/>
                </Segment>
            </Segment.Group>
        </div>
    );

}