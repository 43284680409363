import React from 'react';
import Title from '../product/add_product/title';
import SmsRecharge from './smsrecharge';

export default class Sms extends React.Component {

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - SMS"} menu={true}/>
        <center>
          <div style={{color: 'green', fontSize: 20, paddingTop: '5%'}}>SMS BALANCE</div><br/>
        </center>
        <SmsRecharge getShopDetails={this.props.getShopDetails} shopId={this.props.data.id} balance={this.props.data.balance} />
      </div>
    );
  }

}
