import React, { Component } from 'react';
import Title from './title';
import Addproduct from './addproduct';
class Addproducts extends Component {
  render() {
    return (
      <div>
        { this.props.from == 'stocks' ?
          (
            <Addproduct shop={this.props.shop} from={this.props.from}/>
          ):
          (
            <div>
              <Title shopDetails={this.props.shop} title="Add New Product"/>
              <Addproduct shop={this.props.shop} from={this.props.from}/>
            </div>
          )
        }
      </div>
    );
  }
}

export default Addproducts;
