import React from 'react';
import shop from '../shops/shop.jpg';
import { Card } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const style = {
  image: {
    position: 'relative',
    float: 'left',
    width:  '100%',
    minHeight: 200,
    maxHeight: 200
  },
  card: {
    textAlign: 'left',
  },
  description: {
    maxHeight: '2.6em',
    lineHeight: '1.3em',
    textOverflow: 'ellipsis',
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden'
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
    cursor: 'pointer'
  }
}

class Product extends React.Component {

  render() {
    let { product } = this.props;
    let name = product.product_name;
    let id = product.id;
    let description = product.description;
    try {
      description = description.substring(0, 60);
      description = description+' .....';
    } catch (e) {

    } finally {

    }
    let price = product.prize;
    let image = product.feature_image;
    let shop_id = product.shop_id;
    return (
      <div>
        <Link to={'/admin/edit-product?id='+id}>
          <Card>
            { image ?
              (<img style={style.image} src={image}/>)
              :
              (<img style={style.image} src={require('../shops/shop.jpg')}/>)
            }
            <Card.Content style={style.content} onClick={ () => { this.setState({ open: true }) }}>
              <Card.Header>{name}</Card.Header>
              <Card.Description style={style.description}>{description}</Card.Description><br/>
            </Card.Content>
            <Card.Content extra>
              {price}
            </Card.Content>
          </Card>
        </Link>
      </div>
    );
  }
}

export default Product;
