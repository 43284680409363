import React from 'react';
import $ from 'jquery';
import server from '../functions/server';
import { Button, Grid } from 'semantic-ui-react'

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export default function FormDialog(props) {

  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(props.data.title);
  const [priority, setPriority] = React.useState(props.data.priority);
  const [status, setStatus] = React.useState(props.data.status);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const edit = () => {
    if (!title.length) {
      alert('Enter Title!!');
      return;
    }
    if (!priority.length) {
      alert('Enter Position!!');
      return;
    }
    $.ajax({type: "POST", url: server() + '/banners/update.php',
      data: { id: props.data.id, title, priority, status },
      success: (data) => {
        console.log(data);
        if (data === "1") {
          setOpen(false);
          props.refresh();
        } else {
          alert("Failed");
        }
      }, error: (e) => {
        console.log(e);
        alert("Error");
      }
    });
  }

  return (
    <span>
      <div onClick={handleClickOpen} style={{cursor: 'pointer'}}>Edit</div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Banner</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit this Banner.</DialogContentText>
          <TextField autoFocus value={title} margin="dense" id="title" label="Title" type="name" onChange={(e) => setTitle(e.target.value)} fullWidth />
          <Grid>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <br/>
              <Select fullWidth label="Position" labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                <MenuItem value={0}>Inactive</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
              </Select>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <TextField autoFocus value={priority} margin="dense" id="position" label="Position" type="number" onChange={(e) => setPriority(e.target.value)} fullWidth />
            </Grid.Column>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">Cancel</Button>
          <Button onClick={edit} color="primary">Edit</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
