import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from 'semantic-ui-react';

import Online from './online';
import Business from './business';
import Shopping from './shopping';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};


function Intro(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Enroll and Market your Business for Free
          </Typography>
          <a href="https://you.strackit.com?redirectto=https://business.strackit.com">
            <Button color='red'>Start Now</Button>
          </a>
        </Toolbar>
      </AppBar>
      <div style={{padding:'5%'}}>
        <Online />
      </div>
      <div style={{padding:'5%'}}>
        <Business />
      </div>
      <div style={{padding:'5%'}}>
        <Shopping />
      </div>
    </div>
  );
}

Intro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Intro);
