import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarCode({ onScan }) {

  return (
    <center>
      <BarcodeScannerComponent
        width={300}
        height={200}
        onUpdate={(err, result) => {
          console.log(result)
          if (result) onScan(result.text);
        }}
      />
    </center>
  );
}

export default BarCode;