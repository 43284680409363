import React from 'react';
import $ from 'jquery';
import { Button, Icon, Modal, Form, Input } from 'semantic-ui-react';

import Load from '../../../load';
import server from '../../../functions/server';

export default class Return extends React.Component {

  state = {open: false, checked: 'all', quantity: ""}

  handleCheck = (key) => (e, { value }) => {
    let { item } = this.props;
    value = value.trim();
    if (key === 'quantity') {
      if (isNaN(value)) {
        return;
      } else if (parseInt(value) == item.quantity) {
        alert("Please Choose All if Return Quantity is equal to Order Quantity"); return;
      } else if (parseInt(value) > item.quantity) {
        alert("Return Quantity is greater than Order Quantity" + item.quantity); return;
      }
    }
    this.setState({[key]: value})
  }

  submit = () => {
    let { item } = this.props;
    let quantity = (this.state.checked === 'custom' ? this.state.quantity : item.quantity);
    let notall = (this.state.checked === 'custom' ? 1 : 0);
    try {
      quantity = quantity.trim();
      if (parseInt(quantity) <= 0 || parseInt(quantity) > item.quantity) {
          alert("Quantity Should Be Greter Than 0 and Less Than Order Quantity");
          return;
      }
    } catch(e) {
      alert("Invalid Quantity");
      return;
    }
    let data = {
      quantity, notall, stock_id: item.stock_id, master_id: item.master_id,
      tax: item.tax, price: item.price, id: item.item_id,
      new_quantity: item.quantity - quantity
    }
    let context = this;
    let url = server() + '/orders/updatereturndetails.php';
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {context.setState({load: true});}
    }).done((data) => {
      if (data === "1") {
        context.props.refresh();
        context.setState({open: false});
      } else {
        alert("Failed");
      }
      context.setState({load: false});
    });
  }

  render() {
    let { item } = this.props;
    return (
      <div>
        { (this.state.open) ? (
            <Form>
              <Form.Group inline>
                <label>Option</label>
                <Form.Radio
                  label='All' value = 'all'
                  checked={this.state.checked === 'all'}
                  onChange={this.handleCheck('checked')}
                />
                <Form.Radio
                  label='Custom' value = 'custom'
                  checked={this.state.checked === 'custom'}
                  onChange={this.handleCheck('checked')}
                />
              <Button onClick={this.submit} negative>Submit Return</Button>
              </Form.Group>
              {
                this.state.checked === 'custom'
                &&
                <Input label='Quantity' placeholder={item.quantity} value={this.state.quantity}
                  onChange={this.handleCheck('quantity')}/>
              }
            </Form>
          ) : (
            <Button animated='vertical' negative onClick={() => this.setState({open: true})}>
              <Button.Content hidden>Return</Button.Content>
              <Button.Content visible>
                <Icon name='redo' />
              </Button.Content>
            </Button>
          )
        }
      </div>
    );
  }

}
