import React from 'react';
import Title from '../product/add_product/title';
import AddCoupon from './add';
import EditCoupon from './edit';
import $ from 'jquery';
import { Popup, Table } from 'semantic-ui-react';
import EditIcon from '@material-ui/icons/Edit';
import server from '../functions/server';
import Load from '../load';

export default class CouponMenu extends React.Component {

    state = {
        couponDetails: []
    }

    componentDidMount() {
        this.getCoupons()
    }

    getCoupons = () => {
        let context = this;
        let shopId = context.props.shopId;
        let url = server() + '/couponcode/get.php';
        let data = { shop_id: shopId };
        this.setState({ loading: true, message: 'Getting Coupon Code' })
        $.ajax({
            url, data, type: 'POST',
        }).done((data) => {
            if (data) {
                this.setState({ couponDetails: JSON.parse(data), loading: false })
            }
            else {
                alert('Failed to save');
                this.setState({ loading: false })
            }
        });
    }

    delete = (id) => {
        let context = this;
        let shopId = context.props.shopId;
        let url = server() + '/couponcode/delete.php';
        let data = { id };
        this.setState({ loading: true, message: 'Deleting Coupon Code' })
        $.ajax({
            url, data, type: 'POST',
        }).done((data) => {
            if (data == 1) {
                alert('Success');
                this.getCoupons();
                this.setState({ loading: false })
            }
            else {
                alert('Failed to save');
                this.setState({ loading: false })
            }
        });
    }

    render() {
        return (
            <div >
                <Load open={this.state.loading}/>
                <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Social Media"} menu={true} />
                <div style={{ margin: "1%" }}>
                    <AddCoupon getCoupons={this.getCoupons} shopDetails={this.props.data} shopId={this.props.shopId} />
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Coupon Code</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Discription</Table.HeaderCell>
                                <Table.HeaderCell>Price Range</Table.HeaderCell>
                                <Table.HeaderCell>Discount</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Edit</Table.HeaderCell>
                                <Table.HeaderCell>Delete</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {
                            this.state?.couponDetails?.map((coupon, i) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{++i}</Table.Cell>
                                        <Table.Cell>{coupon.code}</Table.Cell>
                                        <Table.Cell>{coupon.name}</Table.Cell>
                                        <Table.Cell>{coupon.description.length >= 100 ?
                                            <Popup content={coupon.description} trigger={`${coupon.description.slice(0, 100)}...`} />
                                            // `${coupon.description.slice(0, 100)}...` 
                                            : coupon.description}</Table.Cell>
                                        <Table.Cell>{coupon.price_range}</Table.Cell>
                                        <Table.Cell>{coupon.discount}</Table.Cell>
                                        <Table.Cell>{coupon.status == 1 ? "Active" : "Inactive"}</Table.Cell>
                                        <Table.Cell>
                                            <EditCoupon coupon={coupon} getCoupons={this.getCoupons} shopDetails={this.props.data} shopId={this.props.data.id} />
                                        </Table.Cell>
                                        <Table.Cell onClick={ () => this.delete(coupon.id)}>
                                            <u>Delete</u>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table>
                </div>

            </div >)
    }
}