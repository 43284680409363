import React from 'react';
import $ from 'jquery';
import { Dropdown, Button, Table, Image } from 'semantic-ui-react';

import server from '../functions/server';
import Title from '../product/add_product/title';
import Add from './add';
import Edit from './edit';
import AddImage from '../media/addproduct.png';
import Specification from './specification';
import Load from '../load';

export default class SecoundaryCategory extends React.Component {

  state = {
    mastercategoryOption: [], choosedCategory: 0, category: []
  }

  componentDidMount() {
    this.getMasterCategories();
  }

  getMasterCategories = () => {
    let context = this;
    let url = server() + "/categories/getmaster.php";
    let data = {shop_id: this.props.data.id};
    context.setState({ load: true, message: 'Getting MasterCategory!!!' })
    $.ajax({
      url: url, data: data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      let mastercategoryOption = [];
      try {
        data = JSON.parse(data);
        data.map((c) => { mastercategoryOption.push({ key: c.category, value: c.id, text: c.category }) });
        context.setState({masterCategories: data, mastercategoryOption, load: false});
      } catch(e) {}
    });
  }

  getCategories = (masterCategory) => {
    $.ajax({method: "POST", url: server() + "/categories/getbymaster.php", data: {id: masterCategory, shop_id: this.props.data.id}, success: (data) => {
      console.log(data);
      try {
        data = JSON.parse(data);
        console.log(data);
        console.log('lololoolo');
        this.setState({ category: data })
      } catch (e) {
      }
    }});
  }

  handleChange = (e, { value }) => { this.setState({ choosedCategory: value }); this.getCategories(value); }


  updateImage(file, id, masterCategoryId) {
    let context = this;
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('type', 'category');
    $.ajax({
    type: 'POST', processData: false, contentType: false,
    data: formData, url: server()+ "/categories/updateimage.php",
    success: function(data) {
      if (data == 1) {
        context.getCategories(masterCategoryId);
      }
    },
    error: function(data, status) {
      context.setState({ load: false });
      console.log(data);
      console.log(status);
      alert("Failed to Add Product");
    }
    });
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    if (this.state.category && this.state.category !== 1) {
      x = this.state.category.map((v) => {
        console.log(v);
        let color = '#FFFFFF';
        if (v.secondaryCategoryStatus == '0' || v.secondaryCategoryStatus == 0) { color = '#FED4CC'; }
        if (v.masterCategoryStatus == '0' || v.masterCategoryStatus == 0) { color = '#FED4CC'; }
        return (
          <Table.Body style={{backgroundColor: color}}>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.category}</Table.Cell>
            <Table.Cell>
            { !v.image ?
              (<div>
                <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id, v.mid); }} style={{display: 'none'}}/>
                <label htmlFor={v.id}>
                  <Image src={AddImage} style={{width: '60px', height: '60px', cursor: 'pointer'}} />
                </label>
                </div>):
              (
                <div>
                  <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id, v.mid); }} style={{display: 'none'}}/>
                  <label htmlFor={v.id}>
                    <Image src={v.image} style={{width: '60px', height: '60px', cursor: 'pointer'}} />
                  </label>
                  </div>
              )
            }
            </Table.Cell>
            <Table.Cell>{v.stock_count}</Table.Cell>
            <Table.Cell>
              <Specification masterCategories={this.state.choosedCategory} secondaryCategory={v.id} />
            </Table.Cell>
            <Table.Cell>{v.addedon}</Table.Cell>
            <Table.Cell>
                <Edit masterCategories={this.state.masterCategories} data={v} choosedCategory={this.state.choosedCategory} refresh={this.getCategories}/>
            </Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Secondary Category"} menu={true}/>
        <div style={{margin: '0.5%'}}>
          <div style={{display: 'flux'}}>
            <Dropdown value={this.state.choosedCategory} placeholder='Select Customer' onChange={this.handleChange} search selection options={this.state.mastercategoryOption}/>
            <Add choosedCategory={this.state.choosedCategory} refresh={this.getCategories} shopId={this.props.data.id} />
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Stocks</Table.HeaderCell>
              <Table.HeaderCell>Specification</Table.HeaderCell>
              <Table.HeaderCell>Added On</Table.HeaderCell>
              <Table.HeaderCell>Edit</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          {x}
        </Table>
        <Load open={this.state.load} message={this.state.message} />
      </div>
    );
  }

}
