import React from 'react';
import $ from 'jquery';
import { Grid, Input, Form, TextArea, Button, Image } from 'semantic-ui-react';
import { Checkbox } from '@material-ui/core';
import Load from '../../load';
import server from '../../functions/server';

const style = {
  image: {
    position: 'relative',
    float: 'left',
    width:  '100%',
    minHeight: 300,
    maxHeight: 300
  },
  image1: {
    position: 'relative',
    float: 'left',
    width:  '100%',
    minHeight: 200,
    maxHeight: 200
  },
  card: {
    textAlign: 'left',
  },
  description: {
    maxHeight: '2.6em',
    lineHeight: '1.3em',
    textOverflow: 'ellipsis',
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden'
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
    cursor: 'pointer'
  }
}

export default class ShopDetails extends React.Component {

  state = {
    shop_name: ''
  }

  componentDidMount() {
    this.setValues();
  }

  componentDidUpdate(props) {
    if (props.shopId != this.props.shopId) {
      this.setValues();
    }
  }

  setValues() {
    let data = this.props.shop;
    this.setState({
      prime: data.prime,
      shop_name: data.shop_name,
      category: data.category,
      phone: data.phone,
      website: data.website,
      owner_id: data.owner_id,
      gstin: data.gstin,
      delivery: data.delivery,
      hide_address: data.hide_address,
      description: data.description,
      address: data.address,
      city: data.city,
      country: data.country,
      state: data.state,
      pincode: data.pincode,
      id: data.id,
      latitude: data.latitude,
      longitude: data.longitude,
      feature: data.feature_image,
      icon: data.icon,
    })
  }

  onChangeHandle = (name) => event => {
    this.setState({ [name]: event.target.value })
  }

  changeCheckBox = (name) => event => {
    if (event.target.checked == true) {
      this.setState({ [name]: 1 })
    }
    else {
      this.setState({ [name]: 0 })
    }
  }

  submit() {
    let context = this;
    let data = { 'shop_name': this.state.shop_name, 'category': this.state.category,
      'phone': this.state.phone, 'website': this.state.website,
      'owner_id': this.state.owner_id, 'gstin': this.state.gstin,
      'delivery': this.state.delivery, 'hide_address': this.state.hide_address,
      'description': this.state.description, 'address': this.state.address,
      'city': this.state.city, 'country': this.state.country,
      'state': this.state.state, 'pincode': this.state.pincode, 'id': this.state.id,
      'latitude': this.state.latitude, 'longitude': this.state.longitude,
      'prime': this.state.prime };
    let url = server() + '/admin/editshop.php';
    $.ajax({
      url: url, data,
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      context.setState({ load: true });
      if (data == 1) {
        context.props.close();
        context.props.getShops(context.props.search);
      }
    });
  }


  checkIconImage() {
    if (!this.state.file1) {
      return;
    }
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        if (context.state.icon == e.target.result) {
          return;
        }
        context.setState({
          'icon': e.target.result
        });
      };
    })(this.state.file1);
    try {
      reader.readAsDataURL(this.state.file1);
    } catch(e) {}
  }

  checkFeatureImage() {
    if (!this.state.file) {
      return;
    }
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        if (context.state.feature == e.target.result) {
          return;
        }
        context.setState({
          'feature': e.target.result
        });
      };
    })(this.state.file);
    try {
      reader.readAsDataURL(this.state.file);
    } catch(e) {}
  }

  updateFeatureImage(file, path) {
    return;
    let context = this;
    let reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        if (context.state.feature_image == e.target.result) {
          return;
        }
        context.setState({
          'feature_image': e.target.result
        });
      };
    })(this.state.file);
    try {
      reader.readAsDataURL(this.state.feature_image);
    } catch(e) {}
    let url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shop");
    var productId = url.searchParams.get("id");
    var formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('p_id', this.state.id);
    let urls = server() + '/products/updateimage.php';
    $.ajax({
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    url: urls,
    success: function(data)
    {
      console.log(data);
      if (data == 1) {

      }
    },
    error: function(data, status) {
      context.setState({
        loading: false,
      });
      alert("Failed to Add Product");
    }
    });
  }

  changeImage(file, path) {
    let context = this;
    var formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    let urls = server() + '/admin/updateimage.php';
    $.ajax({
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    url: urls,
    success: function(data)
    {
      console.log(data);
      if (data == 1) {

      }
    },
    error: function(data, status) {
      context.setState({
        loading: false,
      });
      alert("Failed to Add Product");
    }
    });
  }

  newImage(file, type) {
    let context = this;
    let path, imagename;
    let shopId = this.state.id;
    if (type == 'Icon') {
      path = "https://s3.ap-south-1.amazonaws.com/business.strackit.com/shop/icon/"+shopId+".jpg";
      imagename = "shop/icon/"+shopId+".jpg";
    }
    else {
      path = "https://s3.ap-south-1.amazonaws.com/business.strackit.com/shop/feature_image/"+shopId+".jpg";
      imagename = "shop/feature_image/"+shopId+".jpg";
    }
    var formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('imagename', imagename);
    formData.append('type', type);
    formData.append('shopId', shopId);
    let urls = server() + '/admin/uploadimage.php';
    $.ajax({
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    url: urls,
    success: function(data)
    {

      let shopData = context.props.shopData;
      if (type == 'Icon') {
        shopData.map((c) => {
          if (c.id == shopId) {
            c.icon =  path;
          }
        })
      }
      else {
        shopData.map((c) => {
          if (c.id == shopId) {
            c.feature_image = path;
          }
        })
      }
      context.props.updateDate(shopData);
    },
    error: function(data, status) {
      context.setState({
        loading: false,
      });
      alert("Failed to Add Product");
    }
    });
  }

  render() {
    this.checkFeatureImage();
    this.checkIconImage();
    let hide_address = this.state.hide_address;
    if (hide_address == 1) {
      hide_address = true;
    }
    else {
      hide_address = false;
    }
    let prime = this.state.prime;
    if (prime == 1) {
      prime = true;
    }
    else {
      prime = false;
    }
    let feature_image = (<div>
      <input type="file" name="fileToUpload1" id="fileToUpload11"
        onChange={(e) => {this.setState({file: e.target.files[0]});
        this.newImage(e.target.files[0], 'feature_image')}} style = {{display: 'none'}} />
      <label htmlFor="fileToUpload11">
        <Image src={require('./shop.jpg')} fluid id='image' style={{cursor: 'pointer'}} /><br/>
      </label>
    </div>);

    if (this.state.feature) {
      feature_image = (
        <div>
          <input type="file" name="fileToUpload111" id="fileToUpload111"
            onChange={(e) => {this.setState({file: e.target.files[0]});
            this.changeImage(e.target.files[0], this.state.feature)}} style = {{display: 'none'}} />
          <label htmlFor="fileToUpload111">
            <Image src={this.state.feature} fluid id='image' style={{cursor: 'pointer'}} /><br/>
          </label>
        </div>
      );
    }
    let icon = (
      <div>
        <input type="file" name="fileToUpload" id="fileToUpload1"
          onChange={(e) => {this.setState({file1: e.target.files[0]});
          this.newImage(e.target.files[0], 'Icon')}} style = {{display: 'none'}} />
        <label htmlFor="fileToUpload1">
          <Image src={require('../../media/addicon.png')} fluid id='image' style={{cursor: 'pointer'}} /><br/>
        </label>
      </div>);
    if (this.state.icon) {
      icon = (
        <div>
          <input type="file" name="fileToUpload" id="fileToUpload"
            onChange={(e) => {this.setState({file1: e.target.files[0]});
            this.changeImage(e.target.files[0], this.state.icon)}} style = {{display: 'none'}} />
          <label htmlFor="fileToUpload">
            <Image src={this.state.icon} fluid id='image' style={{cursor: 'pointer'}} /><br/>
          </label>
        </div>
        );
    }

    return (
      <div>
        <br/><br/>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {icon}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            {feature_image}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Shop Name'}<br/>
            <Input fluid focus value={this.state.shop_name}
              onChange={this.onChangeHandle('shop_name')} placeholder='Shop Name' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Phone Number'}<br/>
            <Input fluid focus value={this.state.phone}
              onChange={this.onChangeHandle('phone')} placeholder='Phone Number' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Website'}<br/>
            <Input fluid focus value={this.state.website}
              onChange={this.onChangeHandle('website')} placeholder='Website' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Category'}<br/>
            <Input fluid focus value={this.state.category}
              onChange={this.onChangeHandle('category')} placeholder='Category' />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            {'Description'}<br/>
            <Form>
              <TextArea fluid focus placeholder='Description'
                value={this.state.description}
                onChange={this.onChangeHandle('description')}/>
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Address'}<br/>
            <Input fluid focus value={this.state.address}
                onChange={this.onChangeHandle('address')} placeholder='Address' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'City'}<br/>
            <Input fluid focus value={this.state.city}
                onChange={this.onChangeHandle('city')} placeholder='City' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'State'}<br/>
            <Input fluid focus value={this.state.state}
                onChange={this.onChangeHandle('state')} placeholder='State' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Country'}<br/>
            <Input fluid focus value={this.state.country}
                  onChange={this.onChangeHandle('country')} placeholder='Country' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Pincode'}<br/>
            <Input fluid focus value={this.state.pincode}
                onChange={this.onChangeHandle('pincode')} placeholder='Pincode' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'Delivery Kms'}<br/>
            <Input fluid focus value={this.state.delivery}
                onChange={this.onChangeHandle('delivery')} placeholder='Delivery Kms' />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={4}>
            {'GSTIN'}<br/>
            <Input fluid focus value={this.state.gstin}
              onChange={this.onChangeHandle('gstin')} placeholder='GSTIN' />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}><br/>
            <Checkbox
              checked={hide_address}
              onChange={this.changeCheckBox('hide_address')}
              inputProps={{
                'aria-label': 'uncontrolled-checkbox',
              }}
            /> Hide Address
            <Checkbox
              checked={prime}
              onChange={this.changeCheckBox('prime')}
              inputProps={{
                'aria-label': 'uncontrolled-checkbox',
              }}
            /> Prime
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <center>
              <Button primary onClick={ () => { this.props.close() }}>Close</Button>
              <Button primary onClick={this.submit.bind(this)}>Submit</Button>
            </center>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
