import React, { Component } from 'react';
import $ from "jquery";
import Title from '../../product/add_product/title';
import server from '../../functions/server';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, Grid, Loader } from 'semantic-ui-react';
import View from './view';
import UserId from '../../functions/userid';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { Header } from 'semantic-ui-react';
import NotificationsIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Call';
import PropTypes from 'prop-types';
import domain from '../../functions/domain'


const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10
  }
};


class Orders extends Component {

  state = {
    auth: false,
    anchorEl: null,
    orders: [],
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.orders();
  }

  orders() {
    let context = this;
    let userId = UserId();
    let shopId = this.props.shopId;
    let data1 = { 'shopId': shopId, 'userId': userId };
    context.setState({ loading: true });
    $.ajax({
      type: 'POST',
      data: data1,
      url: server() + '/order/vieworders.php',
      success: function(orders)
      {
        orders = JSON.parse(orders);
        console.log(orders);
        context.setState({
          loading: false,
          orders: orders
        });
      },
      error: function(err)
      {
      }
    });
  }

  updateOrders() {
    this.orders();
  }

  myorders() {
    let orders = [];
    this.state.orders.map((z) => {
      orders.push(
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <View customer_name={z.customer_name} customer_mobile={z.customer_mobile}
          orderon={z.timestamp} type={z.type} pickuptime={z.pickuptime}
          customer_address={z.customer_address}
          Accept={z.Accept} Packing={z.Packing} Shipping={z.Shipping} Delivered={z.Delivered}
          notaccept={z.notaccept} address={z.address} state={z.state} id={z.id}
          price={z.price} id={z.id} items={z.items} updateOrders={this.updateOrders.bind(this)} />
        </Grid.Column>
      );
    });
    return orders;
  }

  goProfile() {
    window.location.href = 'https://you.strackit.com/profile';
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.replace(domain());
  }

  render() {
    let context = this;
    let orders = (<div></div>);
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    orders = context.myorders();
    return (
      <div>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
          <Title shopDetails={this.props.data} shopId={this.props.data.id} title={"Online Orders"} menu={true}/><br/><br/>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={16}>
          <Grid>
            <br/><br/>{orders}
          </Grid>
          </Grid.Column>
        </Grid>
        <Dialog open={this.state.loading} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title" id="widthdialog">{"LOADING..."}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline='centered' />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Orders);
