import React from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import Load from '../load';
import server from '../functions/server';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import $ from 'jquery';

export default class Ledger extends React.Component {

  state = {
    addLedgerDialog: false,
  }

  onChangeHandle = (key) => event => {
    this.setState({ [key]: event.target.value })
  }

  addLedger() {
    let context = this;
    if (!this.state.ledgername) {
      alert('Enter Ledger Name Properly');
      return;
    }
    let data = { ledger: context.state.ledgername, shopId: context.props.shopId };
    let url = server()+'/ledger/add.php';
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      try {
        data = Number(data);
        if (data == 0) {
          alert('Error to Add Data');
        }
        else {
          context.props.updateItem('add', data, context.state.ledgername);
          context.setState({ ledgername: '', addLedgerDialog: false })
        }
      } catch (e) {
        alert('Error to Add Data');
      }
    });
  }


  render() {
    return (
      <div>
        <Button primary style={{marginTop: 5, marginLeft: 2}} onClick={ () => { this.setState({ addLedgerDialog: true }) }}>Add Ledger</Button>
          <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={this.state.addLedgerDialog}
            onClose={() => this.setState({ addLedgerDialog: false })}
            aria-labelledby="max-width-dialog-title"
          >
          <DialogTitle id="max-width-dialog-title">Add Ledger</DialogTitle>
          <DialogContent>
            <Input fluid placeholder="Ledger Name"
              value={this.state.ledgername} onChange={this.onChangeHandle('ledgername')} />
          </DialogContent>
          <DialogActions>
            <Button negative onClick={ () => { this.setState({ addLedgerDialog: false }) }}>Close</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={this.addLedger.bind(this)}/>
          </DialogActions>
        </Dialog>
        <Load load={this.state.load} message={this.state.message} />
      </div>
    );
  }
}
