import React, { Component } from 'react';
import { Table, Button, Grid, Input, Dropdown } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import $ from "jquery";

import serverName from '../functions/server';

class Specification extends Component {

  state = {
    specification: []
  }

  getSpecification() {
    let context = this;
    let data = { secondarycategory: this.props.secondaryCategory };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/specification/getspecificationbymasterandsecondary.php',
      success: function(data) {
        try {
          data = JSON.parse(data);
          context.setState({ specification: data, addSpecificationDialog: true });
        } catch (e) {
          alert('Error!!!')
        }
      },
    });
  }

  textHandle = (type) => event => { this.setState({ [type]: event.target.value }) }

  saveSpecification() {
    let context = this;
    if (!this.state.addspecificationKey) {
      alert('Enter Specification');
      return;
    }
    let data = { secondarycategory: this.props.secondaryCategory, addspecificationKey: this.state.addspecificationKey };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/specification/addspecification.php',
      success: function(data) {
        console.log(data);
        console.log(data);
        if (data == 1) {
          context.setState({ addSpecification: false, addspecificationKey: '' })
          context.getSpecification();
        }
      },
    });
  }

  removeSpecification(id) {
    let context = this;
    let data = { id };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/specification/removespecification.php',
      success: function(data) {
        console.log(data);
        if (data == 1) {
          context.getSpecification();
        }
      },
    });
  }


  onChangeSpecificationStatus = (e, { name, value }) => {
    let context = this;
    $.ajax({
      type: 'POST', data: { 'id': name, 'status': value }, url: serverName() + '/specification/changespecificationstatus.php',
      success: function(data) {
        if (data != 1) {
          alert('Error to change staus!!!')
        }
        else {
          context.getSpecification();
        }
      },
    });
  }


  render() {
    let status = [
      { key: 'Active', text: 'Active', value: '1' },
      { key: 'InActive', text: 'InActive', value: '0' }
    ];
    return (
      <div>
        <Button onClick={this.getSpecification.bind(this)}>Specification</Button>
        <Dialog onClose={ () => { this.setState({ addSpecificationDialog:false }) }} aria-labelledby="simple-dialog-title"  open={this.state.addSpecificationDialog}>
          <DialogTitle style={{width: '400px'}} id="simple-dialog-title">Specification</DialogTitle>
          <DialogContent>
            <Table>
              <Table.Row>
                <Table.Cell>Specification</Table.Cell>
                <Table.Cell><div style={{float: 'right'}}>Action</div></Table.Cell>
              </Table.Row>
              {
                this.state.specification.map((v) => {
                  let color = "#FFFFFF";
                  if (v.status == 0 || v.status == '0') { color = "#FED4CC"; }
                  if (!v.type) {
                    return (
                      <Table.Row style={{backgroundColor: color}}>
                        <Table.Cell>{v.name}</Table.Cell>
                        <Table.Cell style={{cursor: 'pointer'}}>
                        <Dropdown style={{float: 'right'}} inline name={v.id} header='' onChange={this.onChangeSpecificationStatus} options={status} defaultValue={v.status} />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }
                  else {
                    return (
                      <Table.Row>
                        <Table.Cell><Input onChange={this.textHandle('specification', v.id)} value={v.specification} /></Table.Cell>
                        <Table.Cell onClick={this.removeSpecification.bind(this, v.id)} style={{cursor: 'pointer'}}><div style={{float: 'left', color: 'red'}}>Remove</div></Table.Cell>
                      </Table.Row>
                    )
                  }
                })
              }
              <Table.Row>
                <Table.Cell colSpan='3'><Button fluid onClick={ () => { this.setState({ addSpecification: true }) }}>Add Specification</Button></Table.Cell>
              </Table.Row>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({ addSpecificationDialog:false }) }} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>


        <Dialog onClose={ () => { this.setState({ addSpecification:false }) }} aria-labelledby="simple-dialog-title"  open={this.state.addSpecification}>
          <DialogTitle style={{width: '400px'}} id="simple-dialog-title">Add Specification</DialogTitle>
          <DialogContent>
              <Input fluid onChange={this.textHandle('addspecificationKey')} value={this.state.addspecificationKey} />
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => { this.setState({ addSpecification:false }) }} color="primary">Cancel</Button>
            <Button onClick={this.saveSpecification.bind(this)} color="primary" autoFocus>Save</Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default Specification;
