import React from 'react';
import { Form, Grid, Button, Modal, Icon, Radio } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import $ from 'jquery';

import server from '../../functions/server';

export default class Add extends React.Component {

  state = { open: false, gstin: '', duedate: '', filter: this.props.isCustomer, toDay: this.getFormatedDate(new Date()), }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  handleChange = (key) => (e, { value }) => {
    this.setState({
      [key]: value,
      isCustomer: this.props.isCustomer
    })
  }

  componentDidMount() {
    if (this.props.customer) {
      this.setState({
        name: this.props.customer.name,
        mobile: this.props.customer.mobile,
        address: this.props.customer.address,
        gstin: this.props.customer.gstin,
        duedate: this.props.customer.duedate,
        filter: this.props.customer.is_customer
      });
    }
  }

  componentDidUpdate(props) {
    if (props.isCustomer != this.props.isCustomer) {
      this.setState({ filter: this.props.isCustomer })
    }
  }


  save = () => {
    let context = this;
    let customerName = this.state.name; let customerMobile = this.state.mobile;
    let customerAddress = this.state.address; let customerGSTIN = this.state.gstin;
    let dueDate = this.state.duedate;
    let isCustomer = 'All'
    if (isCustomer == this.state.filter) {
      alert('Please Select '+this.props.buttonName);
      return;
    }
    else {
      isCustomer = this.state.filter;
    }
    if (!customerName || customerName.length < 1) {
      alert("Invalid "+this.props.buttonName+" Name");
      return false;
    }
    if (!customerMobile || isNaN(customerMobile) || customerMobile.length !== 10) {
      alert(this.props.buttonName+" Mobile Number should be 10 Characters and It Should only have numbers");
      return false;
    }
    if (!customerAddress || customerAddress.length < 1) {
      alert("Enter "+this.props.buttonName+" Address");
      return false;
    }
    let url = server() + "/customers/addcustomer.php";
    let data = {
      name: customerName, mobile: customerMobile,
      address: customerAddress, gstin: customerGSTIN,
      dueDate: dueDate, isCustomer
    }
    if (this.props.customer) {
      url = server() + "/customers/editcustomer.php";
      data["id"] = this.props.customer.id;
    } else {
      data["id"] = this.props.data.id;
    }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      console.log(data);
      let open = true;
      if (data == "1") {
        open = false;
        context.props.refresh();
      }
      context.setState({
        load: false, open
      })
    });
  }

  handleChange1 = (name) => event => {
    this.setState({ [name]: event.target.value })
  }

  addCustomer() {
    let limitedOrder = this.props.data.primeLimitation;
    let context = this;
    let url = server() + '/customers/checklimit.php';
    let data = {shop_id: this.props.data.id, today: this.state.toDay};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            buttonLoading: true
          });
      }
    }).done((data) => {
      try {
        data = data.trim();
        data = context.convertIntoNumber(data);
        if (data < 0) {
          alert('Error to Add Customer \nPlease Contact +91 6383306541');
        }
        if (data <= limitedOrder) {
          context.setState({open: true, buttonLoading: false})
        }
        else {
          alert('Please upgrade your Prime pack!!!');
          context.setState({ buttonLoading: false })
          return;
        }
      } catch (error) {
        context.setState({ buttonLoading: false })
        alert('Error to Add Customer');
      }
    });
  }

  convertIntoNumber = (value) => {
    try {
      value = Number(value);
    } catch (error) {
      value = 0;
    }
    return value;
  }

  handleChangess(filter) { this.setState({ filter }) }

  render() {
    let customer = this.props.customer;
    let title = "Add "+this.props.buttonName;
    let button = (
      <Button loading={this.state.buttonLoading} color="green" onClick={this.addCustomer.bind(this)}>
        {this.props.buttonName}
      </Button>
    );
    if (customer) {
      title = "Edit "+this.props.buttonName;
      button = (
        <Button color="green" onClick={() => this.setState({open: true, filter: this.props.isCustomer})}>
          Edit
        </Button>
      );
    }
    return (
      <span>
        { button }


        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input fluid label={'Name'} placeholder={'Name'}
                    value={this.state.name} onChange={this.handleChange("name")}/>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input fluid label={'Mobile'} placeholder={'Mobile'}
                    value={this.state.mobile} onChange={this.handleChange("mobile")}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input fluid label={'Address'} placeholder={'Address'}
                    value={this.state.address} onChange={this.handleChange("address")}/>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input fluid label={'GSTIN'} placeholder={'GSTIN'}
                    value={this.state.gstin} onChange={this.handleChange("gstin")}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  Due Date<br/>
                  <input type='date' value={this.state.duedate} onChange={this.handleChange1("duedate")} />
                </Grid.Column>
                <Grid.Column>
                  {
                    this.props.buttonName == 'Add Customer/Vendor' ? 
                    <div>
                      <Radio label='Customer' name='radioGroup' value={0}
                        checked={this.state.filter === 0} onChange={this.handleChangess.bind(this, 0)} />
                      <Radio label={'Vendor'} name='radioGroup' value={1} style={{marginLeft: 5}}
                        checked={this.state.filter === 1} onChange={this.handleChangess.bind(this, 1)} />
                    </div>:
                    <div>
                      {
                        this.props.buttonName == 'Add Customer/Purchaser' ? 
                        <div>
                          <Radio label='Customer' name='radioGroup' value={0}
                            checked={this.state.filter === 0} onChange={this.handleChangess.bind(this, 0)} />
                          <Radio label={'Purchaser'} name='radioGroup' value={1} style={{marginLeft: 5}}
                            checked={this.state.filter === 1} onChange={this.handleChangess.bind(this, 1)} />
                        </div> : null
                      }
                    </div>
                  }
                  
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button negative onClick={() => this.setState({open: false})}>Cancel</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={this.save}/>
          </DialogActions>
        </Dialog>
      </span>
    );
  }

}
