import Joi from 'joi';

let Validation = {


    // PNS SERVICE
    pnsService: Joi.object({
        name: Joi.string().required().min(2),
        mobile: Joi.string().length(10).pattern(/^\d+$/).required(),
        shopId: Joi.number().required(),
    }),

    EditPNSService: Joi.object({
        name: Joi.string().required().min(2),
        mobile: Joi.string().length(10).pattern(/^\d+$/).required(),
        id: Joi.number().required(),
    }),

    // Check Number Or Not

    CheckNumber: Joi.object({
        number: Joi.string().pattern(/^\d+$/).required(),
        
    }),


    // Billing

    // Secoundary Category

    AddSecoundaryCategory: Joi.object({
        category: Joi.string().required().min(2),
        mid: Joi.number().required(),
        shopId: Joi.number().required(),
    }),


    // Sample

    createContest: Joi.object({
        name: Joi.string().required(),
        exchange_id: Joi.number().required(),
        description:Joi.string().required(),
        entry_fee:Joi.string().required(),
        status: Joi.number().required(),
        contest_date:Joi.date().required(),
        total_price:Joi.string().required(),
        max_limit:Joi.number().required()
    }),
}

export default Validation;
