import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Header, Input, Dropdown } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import MButton from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Load from '../../load';
import Add from '../add';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Item from './item';

export default class View extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
    customersearch: '', filter: 'All', filter2: 'All'
  }

  componentDidMount() {
    this.getAllStatements(this.state.customersearch);
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  getAllStatements(name) {
    let context = this;
    let url = server() + '/statements/getallstatementsbydate.php';
    let data = {id: this.props.data.id, fromDate: this.state.fromDate, toDate: this.state.toDate, cusomerName: name};
    let id;
    if (this.props.data.category == 'Transportation And Services') {
      url = server() + '/statements/getallstatementbyallcustomer.php';
      data = {'id': this.props.data.id, 'fromDate': this.state.fromDate, 'toDate': this.state.toDate, cusomerName: name };
    }
    try {
      id = this.props.match.params.id;
    } catch (e) {}
    if (id) {
      url = server() + '/statements/getallstatementsbycustomer.php';
      data = {'id': this.props.data.id, 'customer_id': id, 'fromDate': this.state.fromDate, 'toDate': this.state.toDate };
    }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {
        console.log(data);
      }
      context.setState({ out: out, load: false });
    });
  }

  onChangeHandler = name => event => { this.setState({ [name]: event.target.value }) }

  handleChange = (key) => (e, { value }) => { this.setState({ [key]: value }) }


  render() {
    let x = (<div></div>);
    let { data } = this.props;
    let balance = 0;
    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let count = 0;
      x = this.state.out.map((temp) => {
        if (this.state.filter2 == 'Credit' && temp.amount < 0 ) {
          if (this.state.filter == 'Order') {
            if (temp.order_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else if (this.state.filter == 'Purchase') {
            if (temp.purchase_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else {
            balance += parseFloat(temp.amount);
            return (<Item data={context.props.data} item={temp}
                      data={data} count={++count} filter={this.state.filter} />);
          }
        }
        if (this.state.filter2 == 'Debit' && temp.amount > 0) {
          if (this.state.filter == 'Order') {
            if (temp.order_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else if (this.state.filter == 'Purchase') {
            if (temp.purchase_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else {
            balance += parseFloat(temp.amount);
            return (<Item data={context.props.data} item={temp}
                      data={data} count={++count} filter={this.state.filter} />);
          }
        }
        if (this.state.filter2 == 'All') {
          if (this.state.filter == 'Order') {
            if (temp.order_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else if (this.state.filter == 'Purchase') {
            if (temp.purchase_id > 0) {
              balance += parseFloat(temp.amount);
              return (<Item data={context.props.data} item={temp}
                        data={data} count={++count} filter={this.state.filter} />);
            }
          }
          else {
            balance += parseFloat(temp.amount);
            return (<Item data={context.props.data} item={temp}
                      data={data} count={++count} filter={this.state.filter} />);
          }
        }
      });
    }
    let id;
    try {
      id = this.props.match.params.id;
    } catch (e) {}
    let filterOption = [
      {key: 'All', text: 'All', value: 'All'},
      {key: 'Order', text: 'Order', value: 'Order'},
      {key: 'Purchase', text: 'Purchase', value: 'Purchase'},
    ];
    let filterOption2 = [
      {key: 'All', text: 'All', value: 'All'},
      {key: 'Credit', text: 'Credit', value: 'Credit'},
      {key: 'Debit', text: 'Debit', value: 'Debit'},
    ];
    try {
      balance = Number(balance).toFixed(2);
    } catch (e) {
      balance = 0;
    }
    return (
      <div>
        <div>
          <div style={{margin: 20, float: 'left'}}>
            <TextField style={{marginLeft: 20}}
              label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
              defaultValue={this.state.fromDate} />
            <TextField style={{marginLeft: 20}}
              label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
              defaultValue={this.state.toDate} />
            { id ? '':(<Input placeholder='Search Customer' value={this.state.customersearch}
                onChange={this.onChangeHandler('customersearch')} />)
            }
            <Button variant="contained" color="primary" style={{marginLeft:20}}
              onClick={this.getAllStatements.bind(this, this.state.customersearch)}>
              Go
            </Button>
          </div>
          <div style={{margin: 20, float: 'right'}}>
            <Add data={this.props.data} refresh={this.getAllStatements.bind(this, this.state.customersearch)}/>

          </div>
          <div style={{margin: 20, float: 'right'}}>
            <div>
              <Header sub>Balance</Header>
              <span>₹{balance}</span>
            </div>
          </div>
        </div>
        <div style={{margin: 20, float: 'right'}}>
          {
            this.props.data.category == 'Transportation And Services' ? null : 
            <Dropdown selection onChange={this.handleChange('filter')} options={filterOption}
              placeholder='Filter' value={this.state.filter} />
          }
          
            <Dropdown selection onChange={this.handleChange('filter2')} options={filterOption2}
              placeholder='Filter' value={this.state.filter2} />
            <Button negative style={{marginLeft: 20}}
               onClick={this.getAllStatements.bind(this, this.state.customersearch)}>
              Refresh
            </Button>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.NO</Table.HeaderCell>
              {
                this.props.data.category == 'Transportation And Services' ? null : 
                <Table.HeaderCell>STATEMENT.No</Table.HeaderCell>
              }
              {
                this.props.data.category == 'Transportation And Services' ? null : 
                <Table.HeaderCell>INFO</Table.HeaderCell>
              }
              <Table.HeaderCell>INFO.NO</Table.HeaderCell>
              <Table.HeaderCell>CUSTOMER</Table.HeaderCell>
              <Table.HeaderCell>CREDIT</Table.HeaderCell>
              <Table.HeaderCell>DEBIT</Table.HeaderCell>
              <Table.HeaderCell>STATEMENT DATE</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {x}
          </Table.Body>
        </Table>
        <Load open={this.state.load}/>
      </div>
    );
  }

}
