import React from "react";
import { Table, Button } from "semantic-ui-react";
import server from "../functions/server";
import $ from "jquery";
import Buttons from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Load from "../loads";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class View extends React.Component {
  state = {
    reports: [],
    masterData: [],
  };

  getReport() {
    let context = this;
    let shopId = context.props.data.id;
    let url = server() + "/reports/getgstreport.php";
    let data = {
      shopId: shopId,
      fromDate: this.props.fromDate,
      toDate: this.props.toDate,
    };
    context.setState({ load: true });
    $.ajax({
      url: url,
      data,
      type: "POST",
    }).done((reports) => {
      console.log(reports);
      console.log("reports data");
      context.setState({ load: false });
      try {
        reports = JSON.parse(reports);
        console.log(reports);
        context.seprateData(reports);
      } catch (e) {
        alert("Try Again!!!");
      }
    });
  }

  seprateData(report) {
    this.setState({ masterData: report, open: true });
  }

  seprateDate = (value) => {
    let date = value.split(" ")[0];
    date =
      date.split("-")[2] + "-" + date.split("-")[1] + "-" + date.split("-")[0];
    return date;
  };

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimal(value) {
    try {
      value = Number(value).toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  export() {
    let downloadLink;
    let filename = "GstReport";
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.getElementById("export");
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
    filename = filename ? filename + ".xls" : "excel_data.xls";
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = "data:" + dataType + ", " + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

  render() {
    let lastInvoice = 0;
    return (
      <div>
        <Button primary onClick={this.getReport.bind(this)}>
          Report
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false });
          }}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ open: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              GST REPORT
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Load open={this.state.load} />
          <div style={{ paddingLeft: "1%" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="export"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Export"
            />
            <Table celled striped id="export">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Client GSTN</Table.HeaderCell>
                  <Table.HeaderCell>Invoice</Table.HeaderCell>
                  <Table.HeaderCell>HSN</Table.HeaderCell>
                  <Table.HeaderCell>Product Description</Table.HeaderCell>
                  <Table.HeaderCell>Qty</Table.HeaderCell>
                  <Table.HeaderCell>Sub Total</Table.HeaderCell>
                  <Table.HeaderCell>Final</Table.HeaderCell>
                  <Table.HeaderCell>CGSTN %</Table.HeaderCell>
                  <Table.HeaderCell>SGSTN %</Table.HeaderCell>
                  <Table.HeaderCell>TAX %</Table.HeaderCell>
                  <Table.HeaderCell>CGSTN Value</Table.HeaderCell>
                  <Table.HeaderCell>SGSTN Value</Table.HeaderCell>
                  <Table.HeaderCell>Tax Value</Table.HeaderCell>
                  <Table.HeaderCell>GSTN</Table.HeaderCell>
                  <Table.HeaderCell>Mode</Table.HeaderCell>
                  <Table.HeaderCell>TOTAL</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.masterData.map((r) => {
                  let date = this.seprateDate(r.timestamp);
                  let name = r.customer_name;
                  let gstn = r.customer_gstin;
                  let invoice_no = r.invoice_no;
                  let total = r.total;
                  let mode = r.mode;
                  let quantity = this.convertToNumber(r.quantity);
                  let price = this.convertToNumber(r.price);
                  let tax = this.convertToNumber(r.tax);
                  let cgstAndsgst = tax / 2;
                  let subTotal = price * quantity;
                  let final = price;
                  final = final + (final * tax) / 100;
                  let cgstAndsgstValue = subTotal;
                  cgstAndsgstValue = (cgstAndsgstValue * cgstAndsgst) / 100;
                  final = this.makeDecimal(final);
                  subTotal = this.makeDecimal(subTotal);
                  cgstAndsgstValue = this.makeDecimal(cgstAndsgstValue);
                  total = this.makeDecimal(total);
                  if (lastInvoice == r.invoice_no) {
                    date = "";
                    name = "";
                    gstn = "";
                    invoice_no = "";
                    total = "";
                    mode = "";
                  }
                  lastInvoice = r.invoice_no;

                  return (
                    <Table.Row>
                      <Table.HeaderCell>{date}</Table.HeaderCell>
                      <Table.HeaderCell>{name}</Table.HeaderCell>
                      <Table.HeaderCell>{gstn}</Table.HeaderCell>
                      <Table.HeaderCell>{invoice_no}</Table.HeaderCell>
                      <Table.HeaderCell>{r.hsn_code}</Table.HeaderCell>
                      <Table.HeaderCell>{r.product_name}</Table.HeaderCell>
                      <Table.HeaderCell>{r.quantity}</Table.HeaderCell>
                      <Table.HeaderCell>{subTotal}</Table.HeaderCell>
                      <Table.HeaderCell>{final}</Table.HeaderCell>
                      <Table.HeaderCell>{cgstAndsgst}</Table.HeaderCell>
                      <Table.HeaderCell>{cgstAndsgst}</Table.HeaderCell>
                      <Table.HeaderCell>{tax}</Table.HeaderCell>
                      <Table.HeaderCell>{cgstAndsgstValue}</Table.HeaderCell>
                      <Table.HeaderCell>{cgstAndsgstValue}</Table.HeaderCell>
                      <Table.HeaderCell>
                        {cgstAndsgstValue * 2}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{"GSTN"}</Table.HeaderCell>
                      <Table.HeaderCell>{mode}</Table.HeaderCell>
                      <Table.HeaderCell>{total}</Table.HeaderCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Dialog>
      </div>
    );
  }
}
