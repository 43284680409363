import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Header } from 'semantic-ui-react';
import server from '../../functions/server';
import userId from '../../functions/userid';
import $ from 'jquery';

export default class EditDescription extends React.Component {
  state = {
    open: false,
    description: ""
  };

  handleClose = () => {
    this.props.handleState({editdescription: false});
  };

  handleUpdate = () => {
    let description = this.state.description.trim();
    if (description.length < 50) {
      this.setState({
        message: "Minimum 50 characters long"
      });
      return;
    }
    if (description.length > 350) {
      this.setState({
        message: "Exceeded! Maximum 350 characters"
      });
      return;
    }
    let data = {
      id: this.props.id,
      owner: userId(),
      value: description,
      type: 'description'
    }
    console.log(data);
    let context = this;
    $.ajax({
      type: 'POST',
      url: server() + "/shop/update.php",
      data: data,
      beforeSend: function(x) {
          context.setState({
            dimmer: true,
            loadText: "Updating Description"
          });
      },
      success: function(data, status) {
        let editdescription = true;
        if (data == "1") {
          context.props.updateProps('description', description);
          editdescription = false;
        }
        context.props.handleState({
          dimmer: false,
          loadText: "", editdescription

        });
      },
      error: function(data, status) {
        context.props.handleState({
          dimmer: false,
          loadText: "",
          message: data
        });
      }
    });
  }

  componentDidCatch(error, info) {
    console.log(info);
  }

  render() {
    if (!this.state.description && this.props.desc) {
      this.setState({
        description: this.props.desc
      })
    }
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Update Description</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter minimum 50 characters and maximum of 350 characters
            </DialogContentText>
            <TextField autoFocus margin="dense" id="descriptionText"
              multiline="true" value={this.state.description} fullWidth
              onChange={(e) => {this.setState({description: e.target.value, message:""})}} />
            <Header as='h4' color='red'>
              {this.state.message}
            </Header>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
