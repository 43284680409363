import React from 'react';
import { Form, Grid, Button, Modal, Icon, Radio } from 'semantic-ui-react';
import $ from 'jquery';
import Load from '../../loads';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChooseCustomer from './choosecustomer';

import server from '../../functions/server';

export default class Add extends React.Component {

  state = { value: 'credit' }

  handleChange = (key) => (e, { value }) => { this.setState({ [key]: value }) }

  handleChanges = (e, { value }) => this.setState({ value })

  addReceipt = () => {
    let context = this;
    let id = this.props.data.id;
    if (!this.state.customer || !this.state.customer.id) { alert("Please Choose Customer"); return; }
    let customerId = this.state.customer.id;
    let option = this.state.value;
    let amount = this.state.amount;
    if (!option) { alert("Choose Amount Given or Received"); return; }
    try {
      parseFloat(amount);
      if (amount == 0 || !amount || amount < 0) {
        throw "";
      }
    } catch (e) {
      alert("Enter valid amount"); return;
    }
    if (option === 'credit') {
      amount = 0 - amount;
    }
    let url = server() + "/statements/addreceipt.php";
    let data = {id, customerId, amount, timestamp: ''};
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      if (data === "1") {
        context.props.refresh();
        context.setState({open: false, value: 'credit', customer: '', amount: '', load: false });
      } else {
        context.setState({ load: false })
        alert("Failed");
      }
      context.setState({load: false});
    });
  }

  render() {
    return (
      <span>
        <Button icon color="green" labelPosition='left' onClick={() => this.setState({open: true})}>
          <Icon name='add' />
          Add Receipt
        </Button>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.open}
          onClose={() => {this.setState({open: false})}}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Add Receipt</DialogTitle>
          <DialogContent>
            <ChooseCustomer data={this.props.data}
                updateItem={(key, value) => this.setState({[key]: value})}/>
              <br /><br />
            <div style={{marginLeft: 50, marginRight: 50}}>
              {
                this.props.data.category == 'Transportation And Services' ? null :
                <div>
                  <Radio
                    label='Amount Given'
                    name='radioGroup'
                    value='debit'
                    checked={this.state.value === 'debit'}
                    onChange={this.handleChanges}/><br />
                  <Radio
                    label='Amount Received'
                    name='radioGroup'
                    value='credit'
                    checked={this.state.value === 'credit'}
                    onChange={this.handleChanges}/>
                    <br /><br />
                </div>
              }
              
              <Form.Input fluid label='Amount' placeholder='Enter Amount' type="number"
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={this.state.amount} onChange={this.handleChange("amount")}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button negative onClick={() => this.setState({open: false})}>Cancel</Button>
            <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={this.addReceipt}/>
          </DialogActions>
        </Dialog>
        <Load open={this.state.load} />
      </span>
    );
  }

}
