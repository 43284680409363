import React from 'react';
import { Grid, Segment, Header, Icon } from 'semantic-ui-react';
import EditPhone from './editphone';
import EditAddress from './editaddress';
import EditCity from './editcity';
import EditState from './editstate';
import EditCountry from './editcountry';
import EditPincode from './editpincode';
import EditGSTIN from './editgstin';
import EditDeliverykms from './editdeliverykms';
import Domain from '../../functions/domain';
import EditDeliveryAmount from './editdeliveryamount';
import EditMaximumAmount from './editmaximumamount';
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import $ from 'jquery';
import server from '../../functions/server';

export default class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      is_online: false
    }
  }

  componentDidMount() {
    let value = false;
    if (this.props.data.is_online == 1 || this.props.data.is_online == '1') {
      value = true;
    }
    this.setState({
      is_online: value
    })
  }

  handleState = (s) => this.setState(s)

  updateProps(type, value) {
    this.props.data[type] = value;
    this.setState({
      [type]: value
    });
  }

  handleChange = event => {
    let context = this;
    let value = event.target.checked;
    let values = event.target.checked;
    if (value) { value = 1; }
    else { value = 0; }
    $.ajax({
      type: 'POST',
      url: server() + "/shop/shopgoestoonlineornot.php",
      data: { 'shopId': context.props.data.id, 'isonline': value  },
      beforeSend: function(x) {
        context.setState({ dimmer: false, loadText: "Updating Shop" });
      },
      success: function(data) {
        if (data == 1) {
          alert('Updated Successfully');
          context.setState({ is_online: values })
        }
      },
      error: function(data, status) {
        alert('Error to update')
      }
    });
  };

  smsBalance(balance) {
    try {
      balance = Number(balance).toFixed(2);
      return 'Rs '+balance;
    } catch (e) {
      return 'Rs '+0;
    }
    return 'Rs '+0;
  }

  render() {
    let mobile = 16;
    let tablet = 5;
    let computer = 5;
    let site = this.props.data.shop_name;
    site = site.split(" ").join("-");
    let siteShop = "https://shop.strackit.com/" + site;
    site = Domain() + site;
    return (
      <div style={{marginTop: 50}}>
        <center>
          <Header as='h2' color='teal'>
            <Icon name='address card' />
            <Header.Content>
              Contact Info
              <Header.Subheader>Edit your Contact Info</Header.Subheader>
            </Header.Content>
          </Header>
        </center>
        <Grid style={{marginTop: 50, marginLeft: '5%', marginRight: '1%'}}>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editphone: true})}}>
            <Header as='h3' attached='top' color='blue'>
              Phone Number
            </Header>
            <Segment attached>
              <center>{this.props.data.phone}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editaddress: true})}}>
            <Header as='h3' attached='top left' color='blue'>
              Street Address
            </Header>
            <Segment attached>
              <center>{this.props.data.address}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editcity: true})}}>
            <Header as='h3' attached='top' color='blue'>
              City
            </Header>
            <Segment attached>
              <center>{this.props.data.city}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editstate: true})}}>
            <Header as='h3' attached='top' color='blue'>
              State
            </Header>
            <Segment attached>
              <center>{this.props.data.state}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editcountry: true})}}>
            <Header as='h3' attached='top' color='blue'>
              Country
            </Header>
            <Segment attached>
              <center>{this.props.data.country}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editpincode: true})}}>
            <Header as='h3' attached='top' color='blue'>
              Pin Code
            </Header>
            <Segment attached>
              <center>{this.props.data.pincode}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editgstin: true})}}>
            <Header as='h3' attached='top' color='blue'>
              GSTIN
            </Header>
            <Segment attached>
              <center>{this.props.data.gstin}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editdelivery: true})}}>
            <Header as='h3' attached='top' color='blue'>
              Delivery Kms
            </Header>
            <Segment attached>
              <center>{this.props.data.delivery}</center>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}>
            <Link to='/sms'>
              <Header as='h3' attached='top' color='blue'>
                SMS Balance
              </Header>
              <Segment attached>
                <center style={{fontSize: 15, fontWeight: 'bold'}}>{this.smsBalance(this.props.data.balance)}</center>
              </Segment>
            </Link>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}>
          </Grid.Column>
          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}
            onClick={() => {this.setState({editdeliveryCharge: true})}}>
            <Header as='h3' attached='top' color='blue'>
              Delivery Charge / Below Amount
            </Header>
            <Segment attached>
              <center>{this.props.data.delivery_charge+'/'+this.props.data.delivery_maximum_amount}</center>
            </Segment>
          </Grid.Column>

          <Grid.Column mobile={mobile} tablet={tablet} computer={computer}></Grid.Column>
        </Grid>

        <center style={{marginTop: 50}}>
          <a href={site} target="_blank">
            <Header as='h3' icon='arrow right' content='View Your Business Site' color='teal'/>
          </a>
        </center>

        <center style={{marginTop: 50, paddingBottom: 50}}>
          <a href={siteShop} target="_blank">
            <Header as='h3' icon='arrow right' content='View Your Shopping Site' color='teal'/>
          </a>
        </center>

        <EditPhone open={this.state.editphone} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          phone={this.props.data.phone}/>

        <EditCity open={this.state.editcity} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          city={this.props.data.city}/>

        <EditState open={this.state.editstate} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          state={this.props.data.state}/>

        <EditCountry open={this.state.editcountry} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          country={this.props.data.country}/>

        <EditPincode open={this.state.editpincode} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          pincode={this.props.data.pincode}/>

        <EditGSTIN open={this.state.editgstin} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          pincode={this.props.data.gstin}/>

        <EditDeliverykms open={this.state.editdelivery} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          delivery={this.props.data.delivery}/>

        <EditAddress open={this.state.editaddress} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          address={this.props.data.address}/>

        <EditDeliveryAmount open={this.state.editdeliveryCharge} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          deliveryCharge={this.props.data.delivery_charge}
          deliveryMaximumAmount={this.props.data.delivery_maximum_amount} />

      </div>
    );
  }
}
