import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { Header, Grid } from 'semantic-ui-react';
import DashBoard from '../dashboard';

import Description from './description/description';
import Products from './products';
import EditMap from './contact/map';
import Contact from './contact';
import Payment from '../payment';
import NotificationsIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Call';
import Notifications from '@material-ui/icons/Notifications';
import domain from '../functions/domain';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import server from '../functions/server';
import NewDesign from './newdesign';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';


import Drawer from '../product/add_product/drawer';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10
  }
};

class Shop extends React.Component {
  state = {
    auth: false,
    anchorEl: null,
    notification: [],
    redirect: false,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClose1 = () => {
    this.setState({ open1: false });
  };

  openNotification = event => {
    this.setState({ anchorE2: event.currentTarget, open1: true });
  }

  getstocks() {
    let context = this;
    $.ajax({
      type: 'POST',
      url: server() + "/shop/stocknotification.php",
      data: { shopId: this.props.data.id },
      success: function(data, status) {
        try {
          data = JSON.parse(data);
          context.setState({ notification: data, openStockAlertList: true, open1: false });
        } catch (e) {
        }
      },
      error: function(data) { console.log(data); }
    });
  }


  goProfile() {
    window.open("https://you.strackit.com/profile", "_blank");
  }

  deleteAllCookies() {
    window.location.href = "https://strackit.com/session/logout.php?name=ualum&redirectto=" + window.location.hostname;
  }

  totalAlert() {
    let stockAlert = this.convertToNumber(this.props.data.count);
    let dueAlert = this.convertToNumber(this.props.data.duecount);
    let orderAlert = this.convertToNumber(this.props.data.ordercount);
    return (stockAlert + dueAlert + orderAlert);
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl, anchorE2 } = this.state;
    const open = Boolean(anchorEl);
    let link = 'order';
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="title" 
              color="inherit" 
              className={classes.grow} 
              style={{ marginTop: 10 }}>
              <Drawer shopDetails={this.props.data} shopId={this.props.shopId} from={'home'} />
              <div style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: '1%'}}>{this.props.data.shop_name}</div>
            </Typography>


            {!auth && (
              <div>
                <IconButton
                  aria-owns={this.state.open1 ? 'menu-appbar1' : undefined}
                  onClick={this.openNotification}
                  color="inherit"
                >
                <Badge badgeContent={this.totalAlert()} color="secondary">
                <Notifications />
                </Badge>
                </IconButton>
                  <Menu
                    id="menu-appbar1"
                    aria-haspopup="true"
                    anchorEl={anchorE2}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={this.state.open1}
                    onClose={this.handleClose1}
                  >
                  <MenuItem onClick={this.getstocks.bind(this)}><div style={{width: 150}}>{'Stock Alert'}</div><Badge badgeContent={this.props.data.count} max={9999} color="secondary" /></MenuItem>
                  <Link to={'/customers/duedate'}>
                    <MenuItem><div style={{width: 150}}>DueDate Alert</div><Badge badgeContent={this.props.data.duecount} color="secondary" /></MenuItem>
                  </Link>
                  <Link to={'/order'}>
                    <MenuItem><div style={{width: 150}}>New Orders</div><Badge badgeContent={this.props.data.ordercount} color="secondary" /></MenuItem>
                  </Link>
                </Menu>

                <Button style={{color: 'white'}} onClick={() => this.props.history.push("/order")}>
                  Online Orders
                </Button>
                <Button style={{color: 'white'}} onClick={() => this.props.history.push("/billing")}>
                  Billing
                </Button>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.goProfile.bind(this)}>Profile</MenuItem>
                  <MenuItem onClick={this.deleteAllCookies.bind(this)}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <div>
            <DashBoard  data={this.props.data} shopId={this.props.data.id} />
          </div>
        </div>
        <Dialog onClose={ () => { this.setState({ openStockAlertList: false }) }} aria-labelledby="simple-dialog-title"
          open={this.state.openStockAlertList}>
          <DialogTitle id="simple-dialog-title">Stock Alert</DialogTitle>
          <List>
            {this.state.notification.map((z) => {
              return(
                <ListItem style={{width: 300}}>
                  <ListItemText primary={z.product_name} />
                  <Badge badgeContent={z.no_stock} color="secondary" />
                </ListItem>
              )
            })}
          </List>
        </Dialog>
      </div>
    );
  }
}

Shop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Shop));
