import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Card, Radio, Divider } from 'semantic-ui-react';
import RazorPayment from '../razorpayment';
import server from '../functions/server';

export default class GstReport extends React.Component {
  

  updateData() {

  }

  dateSaprate(date) {
    try {
      date = date.split(' ')[0];
    } catch (e) {
      date = '';
    }
    return date;
  }

  render() {
    let goldDescriptionCurrentPlane = (
      <div>
        • Add upto 100 stock<br/>
        • Add upto 100 Bills Per day<br/>
        • Add upto 100 customer<br/>
        • Analyse Report For Sales<br/>
        • Analyse Report For Purchase<br/>
        • Analyse GST Report For Sales<br/>
        • Analyse GST Report For Purchase<br/><br/><br/><br/>
      </div>
    );
    let silverDescription = (
      <div>
        • Add upto 50 stock<br/>
        • Add upto 50 Bills Per day<br/>
        • Add upto 50 customer<br/>
        • Analyse Report For Sales<br/>
        • Analyse Report For Purchase<br/>
        • Analyse GST Report For Sales<br/>
        • Analyse GST Report For Purchase<br/><br/><br/><br/>
      </div>);
    let goldDescription = (
      <div>
        • Add upto 100 stock<br/>
        • Add upto 100 Bills Per day<br/>
        • Add upto 100 customer<br/>
        • Analyse Report For Sales<br/>
        • Analyse Report For Purchase<br/>
        • Analyse GST Report For Sales<br/>
        • Analyse GST Report For Purchase<br/><br/><br/><br/>
      </div>);
    let diamondDescription = (
      <div>
        • Add Unlimited stock<br/>
        • Add Unlimited Bills Per day<br/>
        • Add Unlimited customer<br/>
        • Analyse Report For Sales<br/>
        • Analyse Report For Purchase<br/>
        • Analyse GST Report For Sales<br/>
        • Analyse GST Report For Purchase<br/><br/><br/><br/>
      </div>);
    let enterpriseDescription = (
      <div>
        • Add Unlimited stock<br/>
        • Add Unlimited Bills Per day<br/>
        • Add Unlimited customer<br/>
        • Analyse Report For Sales<br/>
        • Analyse Report For Purchase<br/>
        • Analyse GST Report For Sales<br/>
        • Analyse GST Report For Purchase<br/>
        • Including Shopping website<br/>
        • Including Shopping Android APP<br/>
      </div>);
    let silverAmount = (
      <div>
        <center>
          <div style={{color: '#C5C5C2'}}>500 + GST</div><br/>
          <div style={{color: 'green', fontSize: 20}}>₹590</div>
          <div style={{fontSize: 10}}>One Year</div>
          <RazorPayment shopId={this.props.data.id}
            description={'Silver'}
            url={server()+'/prime/payment.php'}
            updateData={this.updateData.bind(this)}
            amount={59000} originalAmount={590} />
        </center>
      </div>
    );
    let goldAmountCurrentPlane = (
      <div>
          <div style={{color: 'yellow', fontSize: 20, textAlign: 'center'}}>₹1180</div><br/>
          <Grid style={{color: 'white'}}>
            <Grid.Column mobile={8} tablet={8} computer={8}>To Date</Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>{this.dateSaprate(this.props.data.till_date)}</Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>From Date</Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>{this.dateSaprate(this.props.data.till_date)}</Grid.Column>
          </Grid>
      </div>
    )
    let goldAmount = (
      <div>
        <center>
          <div style={{color: '#C5C5C2'}}>1000 + GST</div><br/>
          <div style={{color: 'green', fontSize: 20}}>₹1180</div>
          <div style={{fontSize: 10}}>One Year</div>
          <RazorPayment shopId={this.props.data.id}
            description={'Gold'}
            url={server()+'/prime/payment.php'}
             updateData={this.updateData.bind(this)}
            amount={118000} originalAmount={1180} />
        </center>
      </div>
    );
    let diamoundAmount = (
      <div>
        <center>
          <div style={{color: '#C5C5C2'}}>3000 + GST</div><br/>
          <div style={{color: 'green', fontSize: 20}}>₹3540</div>
          <div style={{fontSize: 10}}>One Year</div>
          <RazorPayment shopId={this.props.data.id} url={server()+'/prime/payment.php'}
            description={'Diamond'} updateData={this.updateData.bind(this)}
            amount={354000} originalAmount={3540} />
        </center>
      </div>
    );
    let enterpriseAmount = (
      <div>
        <center>
          <div style={{color: '#C5C5C2'}}>15000 + GST</div><br/>
          <div style={{color: 'green', fontSize: 20}}>₹17700</div>
          <div style={{fontSize: 10}}>One Year</div>
          <RazorPayment shopId={this.props.data.id}
            url={server()+'/prime/payment.php'}
            description={'Enterprise'}
             updateData={this.updateData.bind(this)}
            amount={1770000} originalAmount={17700} />
        </center>
      </div>
    );
    return (
      <div style={{paddingTop: '5%'}}>

        <center>
          <div style={{paddingTop: '1%'}}><b style={{fontSize: 20}}>Strackit Prime User</b></div><br/>
        </center>

        <Grid style={{marginLeft: '5%', marginTop: '2%'}}>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6} >
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Basic</div>
                    <div id="Amount">
                      <Radio
                        value={250}
                        checked={this.state.value === 250}
                        onChange={ (e, { value }) => { this.handleChange(value, 'month') }}
                      />
                      ₹ 250 / Per Months
                    </div>
                    <div id="Amount">
                      <Radio
                        value={2499}
                        checked={this.state.value === 2499}
                        onChange={ (e, { value }) => { this.handleChange(value, 'year') }}
                      />
                      ₹ 2499 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
              </Grid>
              <ul>
                <li>Add upto 50 stock</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add upto 50 Bills Per day</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add upto 50 customer</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Sales</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Purchase</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Sales</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Purchase</li>
              </ul>
            </Paper>
          </Grid.Column>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center><div id="package">
                    <div id="PackageName">Business</div>
                    <div id="Amount">
                      <Radio
                        value={750}
                        checked={this.state.value === 750}
                        onChange={ (e, { value }) => { this.handleChange(value, 'month') }}
                      />
                      ₹ 750 / Per Month
                    </div>
                    <div id="Amount">
                      <Radio
                        value={7499}
                        checked={this.state.value === 7499}
                        onChange={ (e, { value }) => { this.handleChange(value, 'year') }}
                      />
                      ₹ 7499 / Per Year
                    </div>
                  </div></center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
              </Grid>
              <ul>
                <li>Add upto 100 stock</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add upto 100 Bills Per day</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add upto 100 customer</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Sales</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Purchase</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Sales</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Sales</li>
              </ul>
            </Paper>
          </Grid.Column>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Professional</div>
                    <div id="Amount">
                      <Radio
                        value={2199}
                        checked={this.state.value === 2199}
                        onChange={ (e, { value }) => { this.handleChange(value, 'month') }}
                      />
                      ₹ 2199 / Per Month
                    </div>
                    <div id="Amount">
                      <Radio
                        value={21999}
                        checked={this.state.value === 21999}
                        onChange={ (e, { value }) => { this.handleChange(value, 'year') }}
                      />
                      ₹ 21999 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                  <img width={40} height={50} src={require('../media/No_1_Sales.png')} />
                </Grid.Column>
              </Grid>
              <ul>
                <li>Add Unlimited stock</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add Unlimited Bills Per day</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Add Unlimited customer</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Sales</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Purchase</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Sales</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Purchase</li>
              </ul>
            </Paper>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}
