import React from 'react';
import $ from 'jquery';
import { Table, Image, Button, Icon } from 'semantic-ui-react';

import server from '../functions/server';
import Title from '../product/add_product/title';
import Load from '../load';
import Add from './add';
import Edit from './edit';
import Delete from './delete';
import AddImage from '../media/addproduct.png';

export default class Banner extends React.Component {

  state = { banners: [], view: 'noimage', }

  componentDidMount() {
    this.getBanners();
  }

  getBanners = () => {
    let context = this;
    let url = server() + "/banners/get.php";
    let data = { shop_id: this.props.data.id };
    $.ajax({
      url: url, data: data, type: 'POST',
      beforeSend: function (x) { context.setState({ load: true }); }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        context.setState({ banners: data, load: false });
      } catch (e) { }
    });
  }

  updateImage(file, id) {
    let context = this;
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('type', 'banner');
    $.ajax({
      type: 'POST', processData: false, contentType: false,
      data: formData, url: server() + "/banners/updateimage.php",
      success: function (data) {
        context.getBanners();
      },
      error: function (data, status) {
        context.setState({ load: false });
        alert("Failed to Add Banner");
      }
    });
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    if (this.state.banners && this.state.banners !== 1) {
      x = this.state.banners.map((v) => {
        let color = '#FFFFFF';
        let status = v.status;
        if (status === 0 || status === '0') {
          status = "Inactive";
        }
        else {
          status = "Active";
        }
        if (v.status === '0' || v.status === 0) { color = '#FED4CC'; }
        return (
          <Table.Body style={{ backgroundColor: color }}>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.title}</Table.Cell>
            <Table.Cell>
              {!v.image ?
                (<div>
                  <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id); }} style={{ display: 'none' }} />
                  <label htmlFor={v.id}>
                    <Image src={AddImage} style={{ width: '60px', height: '60px', cursor: 'pointer' }} />
                  </label>
                </div>) :
                (
                  <div>
                    <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id); }} style={{ display: 'none' }} />
                    <label htmlFor={v.id}>
                      <Image src={v.image} style={{ width: '60px', height: '60px', cursor: 'pointer' }} />
                    </label>
                  </div>
                )
              }
            </Table.Cell>
            <Table.Cell>
              {status}
            </Table.Cell>
            <Table.Cell>{v.addedon}</Table.Cell>
            <Table.Cell>
              <Edit data={v} refresh={this.getBanners} />
            </Table.Cell>
            <Table.Cell>
              <Delete data={v} refresh={this.getBanners} />
            </Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Banners"} menu={true} />
        <Load open={this.state.load} message='Getting Banner' />
        <div style={{ margin: '1%' }}>
          <Button
            style={{ marginBottom: 10 }}
            floated='right'
            negative
            onClick={this.getBanners}>
            <Icon name='undo'/>
            Refresh
          </Button>
          <Add title={'Add Category'} getBanners={this.getBanners} shopId={this.props.data.id} />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Image</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Added On</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {x}
          </Table>
        </div>
      </div>
    );
  }

}
