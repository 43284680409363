import React, { Component } from 'react'
import { Loader, Dimmer, Header, Icon } from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class Load extends Component {

  render() {
    if (this.props.type === "circle" && this.props.open) {
      return (
        <center><CircularProgress /></center>
      );
    }
    return (
      <div>
        <Dimmer active={this.props.open} page>
          <Header as='h2' icon inverted>
            <Loader /><br /><br /><br />
            {this.props.message ? this.props.message : ""}
          </Header>
        </Dimmer>
      </div>
    )
  }
}
