import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import Shopdetails from './editshopdetails';
import shop from './shop.jpg';
import Products from '../products/index';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { Link } from "react-router-dom";

const style = {
  image: {
    position: 'relative',
    float: 'left',
    width:  '100%',
    minHeight: 200,
    maxHeight: 200
  },
  card: {
    textAlign: 'left',
  },
  description: {
    maxHeight: '2.6em',
    lineHeight: '1.3em',
    textOverflow: 'ellipsis',
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden'
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
    cursor: 'pointer'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: '2%',
    flex: 1,
  },
}

export default class ViewShop extends React.Component {

  state = {
    open: false,
    fullWidth: true,
    maxWidth: 'lg'
  }

  render() {
    let { shop } = this.props;
    let name = shop.shop_name;
    let shopId = shop.id;
    let description = shop.description;
    try {
      description = description.substring(0, 60);
      description = description+' .....';
    } catch (e) {

    } finally {

    }
    let products = shop.products;
    let image = shop.feature_image;
    return (
      <Card>
        { image ?
          (<img style={style.image} src={image}/>)
          :
          (<img style={style.image} src={require('./shop.jpg')}/>)
        }
        <Card.Content style={style.content} onClick={ () => { this.setState({ open: true }) }}>
          <Card.Header>{name}</Card.Header>
          <Card.Description style={style.description}>{description}</Card.Description><br/>
        </Card.Content>
        <Card.Content extra>
          <Link to={'/admin/products?shop='+shopId}>
            <a>
              <Icon name='boxes' />
              {products+ " Products"}
            </a>
          </Link>
        </Card.Content>

        <Dialog fullScreen  fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}  open={this.state.open}
          onClose={ () => { this.setState({ open: false }) }} aria-labelledby="max-width-dialog-title">

          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={ () => { this.setState({ open: false }) }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                <div style={{color: 'white'}}>{name}</div>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="max-width-dialog-title"></DialogTitle>
          <DialogContent>
            <Shopdetails shopData={this.props.shopData} search={this.props.search}
              updateDate={this.props.updateDate} 
              getShops={this.props.getShops} shop={this.props.shop} shopId={shopId} close={ () => { this.setState({ open: false }) } } />
          </DialogContent>
        </Dialog>
      </Card>
    );
  }

}
