import React from 'react';
import { Table } from 'semantic-ui-react';
import server from '../functions/server';
import $ from 'jquery';

export default class View extends React.Component {

  state = {
    reports: [],
    reportData: [],
  }

  componentDidUpdate(props) {
    if (props.fromDate != this.props.fromDate || props.toDate != this.props.toDate) {
      this.getReport();
    }
  }

  getReport() {
    let context = this;
    let shopId = context.props.data.id;
    let url = server()+'/reports/getreport.php';
    let data = { 'shopId': shopId, 'fromDate': this.props.fromDate,
                 'toDate': this.props.toDate };
    $.ajax({
      url: url,
      data, type: 'POST',
    }).done((reports) => {
      console.log(reports);
      context.setState({ load: false });
      try {
        reports = JSON.parse(reports);
        context.setState({ reports })
        context.separateData(reports);
      } catch (e) {
        alert('Try Again!!!');
      }
    });
    context.setState({ load: true, message: 'Getting Reports!!!' });
  }

  separateData(reports) {
    let taxs = [];
    let salesReport = [];
    let purchaseReport = [];
    reports.map((c) => {
      let value = 0;
      taxs.map((v) => {
        if (v.tax == c.tax) {
          value = 1;
        }
      })
      if (value == 0) {
        taxs.push({ tax: c.tax })
      }
      if (c.type == 'sales') {
        salesReport.push(c);
      }
      else {
        purchaseReport.push(c);
      }
    })
    salesReport = this.calculateSalesReport(taxs, salesReport);
    purchaseReport = this.calculatePurchaseReport(taxs, purchaseReport);
    let reportData = this.mergerData(taxs, salesReport, purchaseReport);
    this.setState({ reportData })
  }

  mergerData(taxs, salesReport, purchaseReport) {
    let reports = [];
    taxs.map((t) => {
      let salesPrice = 0;
      let salestotal = 0;
      let purchasePrice = 0;
      let purchaseTotal = 0;

      salesReport.map((s) => {
        if (t.tax == s.tax) {
          purchaseReport.map((p) => {
            if (t.tax == p.tax) {
              salesPrice = s.price;
              salestotal = s.total;
              purchasePrice = p.price;
              purchaseTotal = p.total;
              purchaseTotal = p.total;
            }
          })
        }
      })
      reports.push({ 'tax': t.tax, 'salesPrice': salesPrice, 'salestotal': salestotal,
        'purchasePrice': purchasePrice, 'purchaseTotal': purchaseTotal })
    })
    return reports;
  }

  calculatePurchaseReport(taxs, purchaseReport) {
    let purchase = [];
    taxs.map((t) => {
      let price = 0;
      let total = 0;
      let tax = t.tax;
      purchaseReport.map((s) => {
        if (t.tax == s.tax) {
          let amount = s.price;
          let quantity = s.quantity;
          try {
            amount = Number(amount);
            tax = Number(tax);
            quantity = Number(quantity);
          } catch (e) {
            tax = 1;
            amount = 0;
            quantity = 0;
          }
          price = price + (amount * quantity);
        }
      })
      total = price + (price * tax)/100;
      purchase.push({'tax': tax, 'price': price, 'total': total});
    })
    return purchase;
  }

  calculateSalesReport(taxs, salesReport) {
    let sales = [];
    taxs.map((t) => {
      let price = 0;
      let total = 0;
      let tax = t.tax;
      salesReport.map((s) => {
        if (t.tax == s.tax) {
          let amount = s.price;
          let quantity = s.quantity;
          try {
            amount = Number(amount);
            tax = Number(tax);
            quantity = Number(quantity);
          } catch (e) {
            tax = 1;
            amount = 0;
            quantity = 0;
          }
          price = price + (amount * quantity);
        }
      })
      total = price + (price * tax)/100;
      sales.push({'tax': tax, 'price': price, 'total': total});
    })
    return sales;
  }

  render() {
    let salesTotalPrice = 0;
    let salesTotalTax = 0;
    let salesTotalAmount = 0;
    let purchaseTotalPrice = 0;
    let purchaseTotalTax = 0;
    let purchaseTotalAmount = 0;
    return (
      <div>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan={2}>{}</Table.HeaderCell>
              <Table.HeaderCell colSpan={3}><center><b style={{color: 'blue'}}>SALES</b></center></Table.HeaderCell>
              <Table.HeaderCell colSpan={3}><center><b style={{color: 'blue'}}>PURCHASE</b></center></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan={2}>TAX(%)</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>{'Tax'}</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>{'Tax'}</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { this.state.reportData.map((r) => {
               let salesPrice = r.salesPrice;
               let tax = r.tax;
               let salestotal = r.salestotal;
               let purchasePrice = r.purchasePrice;
               let purchaseTotal = r.purchaseTotal;
               let quantity = r.quantity;
               let salesTaxAmount = 0;
               let purchaseTaxAmount = 0;
               try {
                 tax = Number(tax);
                 salesPrice = Number(salesPrice);
                 salesPrice = Number(salesPrice);
                 quantity = Number(quantity);
                 purchasePrice = Number(purchasePrice);
                 purchaseTotal = Number(purchaseTotal);
                 salesTaxAmount = ((salesPrice * tax)/100);
                 purchaseTaxAmount = ((purchasePrice * tax)/100);




                 salesTotalPrice = salesTotalPrice + salesPrice;
                 salesTotalTax = salesTotalTax + salesTaxAmount;
                 salesTotalAmount = salesTotalAmount + salestotal;
                 purchaseTotalPrice = purchaseTotalPrice + purchasePrice;
                 purchaseTotalTax = purchaseTotalTax + purchaseTaxAmount;
                 purchaseTotalAmount = purchaseTotalAmount + purchaseTotal;
               } catch (e) {

               }
                return (
                  <Table.Row>
                    <Table.Cell>{r.tax}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(salesPrice).toFixed(2)}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(salesTaxAmount).toFixed(2)}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(salestotal).toFixed(2)}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(purchasePrice).toFixed(2)}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(purchaseTaxAmount).toFixed(2)}</Table.Cell>
                    <Table.Cell>{'Rs '+Number(purchaseTotal).toFixed(2)}</Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><b style={{color: 'red'}}>TOTAL</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(salesTotalPrice).toFixed(2)}</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(salesTotalTax).toFixed(2)}</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(salesTotalAmount).toFixed(2)}</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(purchaseTotalPrice).toFixed(2)}</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(purchaseTotalTax).toFixed(2)}</b></Table.HeaderCell>
              <Table.HeaderCell><b style={{color: 'red'}}>{'Rs '+Number(purchaseTotalAmount).toFixed(2)}</b></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      </div>
    );
  }

}
