import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import $ from 'jquery';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Name from './name';
import Info from './info';
import More from './more';
import Complete from './complete';

const styles = theme => ({
  root: {
    width: '90%',
    padding: 20
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: 20
  },
});

function getSteps() {
  return ['Business Name', 'Basic Info', 'Options', 'Complete'];
}

class Steps extends React.Component {
  state = {
    activeStep: 0,
    values: {},
    load: false,
    nameVerify: false,
    errText: ""
  };

  getStepContent(step) {
    switch (step) {
      case 0:
        return (<Name ref="child" handleValues={this.handleValues} err={this.state.errText}/>);
      case 1:
        return (<Info ref="child" handleValues={this.handleValues}/>);
      case 2:
        return (<More ref="child" handleValues={this.handleValues}/>);
      case 3:
        return (<Complete values={this.state.values} err={this.state.errText}/>);
      default:
        return 'Unknown step';
    }
  }

  handleNext = () => {
    try {
      if (this.refs.child.validate() === false) {
        return;
      }
    } catch(e) {
      if (this.state.activeStep === 3) {
        this.handleFinish();
      }
    }
    if (this.state.nameVerify === false) {
      if (this.state.activeStep === 0) {
        this.nameVerify(this.state.values.name);
      }
      return;
    }
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  nameVerify(name) {
    let context = this;
    $.ajax({
      url: server() + '/shop/checkshop.php?shop=' + name,
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let errText = "";
      let nameVerify = true;
      let activeStep = ++this.state.activeStep;
      if (data == "true") {
        nameVerify = false;
        errText = "The business name was already taken. Please change it.";
        --activeStep;
      }
      context.setState({
        load: false,
        errText: errText,
        nameVerify: nameVerify,
        activeStep: activeStep
      })
    });
  }

  handleValues = (name,value) => {
    this.state.values[name] = value;
    if (name == 'name') {
      this.setState({
        nameVerify: false
      });
    }
  }

  handleFinish() {
    let context = this;
    let values = this.state.values;
    let hide_address = (values.address) ? 0 : 1;
    let delivery = (values.deliver) ? values.distance : 0;
    let reference = (values.reference) ? values.reference : 0;
    let data = {
      name: values.name,
      owner: userId(),
      category: values.category, state: values.state, country: values.country, address: values.street,
      hide_address: hide_address, delivery: delivery, city: values.city, pincode: values.pincode,
      latitude: 0, longitude: 0, website: 'http://'+values.website, phone: values.phone,
      gstin: values.gstin, reference: values.reference, area: values.area
    };
    console.log(data);
    console.log(server() + "/shop/addshop.php");
    $.ajax({
      type: 'POST',
      url: server() + "/shop/addshop.php",
      data: data,
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      },
      success: function(data, status) {
        if (data.trim() == "1") {
          window.location.reload();
        } else {
          context.setState({
            load: false,
            activeStep: --context.state.activeStep,
            errText: data
          });
        }
      },
      error: function(data, status) {
        console.log(data);
        context.setState({
          load: false,
          activeStep: --context.state.activeStep,
          errText: data
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    let x = (<div></div>);
    if (this.state.load) {
      x = (<div><LinearProgress /><br /></div>);
    }
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
              <br />
              {x}
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={this.handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Steps.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Steps);
