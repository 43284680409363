import React from 'react';
import TextField from '@material-ui/core/TextField';
import server from '../functions/server';
import userId from '../functions/userid';
import $ from 'jquery';
import Title from '../product/add_product/title';
import { Button, Grid, GridColumn, Input } from 'semantic-ui-react';

export default class Settings extends React.Component {

  state = {

  };

  getShipmentCost = () => {
    let context = this;
    let shopId = context.props.data.id;
    let url = server() + '/shipmentcost/get.php';
    let data = { shopId };
    $.ajax({
      url, data, type: 'POST',
    }).done((data) => {
      try {
        data = JSON.parse(data);
        let purchaseRange = data[data.length - 1].purchase_range;
        let price = data[data.length - 1].price;
        context.setState({ purchaseAmount: purchaseRange, shippingCost: price })
      } catch (error) {
      }
    });
  }

  componentDidMount() {
    let context = this;
    let shopId = context.props.data.id;
    let url = server() + '/settings/get.php';
    let data = { shopId };
    $.ajax({
      url, data, type: 'POST',
    }).done((data) => {
      try {
        data = JSON.parse(data);
        let googleAnalyticsKey = data[0].google_analytics;
        let razorpayKey = data[0].razorpay_key;
        let razorpaySecretKey = data[0].razorpay_secret_key;
        let phonePeMerchantId = data[0].phonepe_merchant_id;
        let phonepeApiKey = data[0].phonepe_api_key;
        context.setState({ googleAnalyticsKey, razorpayKey, razorpaySecretKey, phonePeMerchantId, phonepeApiKey })
      } catch (error) {

      }
    });
    this.getShipmentCost();
  }


  submit = () => {
    let context = this;
    let shopId = context.props.data.id;
    let { googleAnalyticsKey, razorpayKey, razorpaySecretKey, phonePeMerchantId, phonepeApiKey } = this.state;
    let url = server() + '/settings/add.php';
    let data = { shopId, googleAnalyticsKey, razorpayKey, razorpaySecretKey, phonePeMerchantId, phonePeAPIKey: phonepeApiKey };
    $.ajax({
      url, data, type: 'POST',
    }).done((data) => {
      if (data == 1 || data == '1') {
        alert("Success");
      }
      else {
        alert('Failed to save');
      }
    });
  }

  updateShipmentCost = () => {
    this.addShipmentCost();
  }

  addShipmentCost = () => {
    let context = this;
    let shopId = context.props.data.id;
    let { purchaseAmount, shippingCost } = this.state;
    let url = server() + '/shipmentcost/add.php';
    let data = { shopId, purchaseRange: purchaseAmount, price: shippingCost };
    try {
      $.ajax({
        url, data, type: 'POST',
      }).done((data) => {
        if (data == 1 || data == '1') {
          alert("Success");
        }
        else {
          alert('Failed to save');
        }
      });
    } catch (err) {
    }
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id}
          title={this.props.data.shop_name + " - Settings"} menu={true} />

        <div style={{ margin: '1%' }}>
        <br/>
          <Grid style={{ marginLeft: '2%' }}>
            <GridColumn mobile={16} tablet={5} computer={6}>
              <b>Google Analytics Key</b><br />
              <Input fluid
                placeholder="Google Analytics Key"
                value={this.state.googleAnalyticsKey}
                onChange={(e, { value }) => this.setState({ googleAnalyticsKey: value })} />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={5}>
              <b>RazorPay Key</b><br />
              <Input fluid
                placeholder="RazorPay Key"
                value={this.state.razorpayKey}
                onChange={(e, { value }) => this.setState({ razorpayKey: value })} />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={5}>
              <b>Razorpay Secret Key</b><br />
              <Input
                fluid
                placeholder="Razorpay Secret Key"
                value={this.state.razorpaySecretKey}
                onChange={(e, { value }) => this.setState({ razorpaySecretKey: value })}
              />
            </GridColumn>
            

            <GridColumn mobile={16} tablet={5} computer={9}>
              <b>PhonePe Merchant Id</b><br/>
              <Input
                fluid
                value={this.state.phonePeMerchantId}
                onChange={(e, { value }) => this.setState({ phonePeMerchantId: value })}
                placeholder='0'
              />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={4}>
              <b>PhonePe API Key</b><br/>
              <Input
                fluid
                value={this.state.phonepeApiKey}
                onChange={(e, { value }) => this.setState({ phonepeApiKey: value })}
                placeholder='0'
              />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={3}>
              <br/>
              <Button fluid onClick={this.submit}>Save Configuration</Button>
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={9}>
              <b>Include Shipment Cost If purchase Amount Less than</b><br/>
              <Input
                fluid
                value={this.state.purchaseAmount}
                onChange={(e, { value }) => this.setState({ purchaseAmount: value })}
                placeholder='0'
              />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={4}>
              <b>Shipment Cost</b><br/>
              <Input fluid
                value={this.state.shippingCost}
                onChange={(e, { value }) => this.setState({ shippingCost: value })}
                placeholder='0'
              />
            </GridColumn>
            <GridColumn mobile={16} tablet={5} computer={3}>
              <br/>
              <Button fluid onClick={this.updateShipmentCost}>Save Shipment Cost</Button>
            </GridColumn>
          </Grid>
        </div>
      </div >
    );
  }
}
