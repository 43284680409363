import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Load from '../../../load';
import server from '../../../functions/server';
import userId from '../../../functions/userid';
import Total from '../../add/total';
import Item from './item';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Purchases extends React.Component {

  state = {open: false}

  componentDidMount() {
    this.getPurchase();
  }

  getPurchase = () => {
    let context = this;
    let url = server() + '/purchases/getpurchasedetails.php';
    let data = {id: this.props.purchase.id};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({
        out: out,
        load: false
      });
    });
  }

  render() {
    let body = (<div></div>);
    if (this.state.out) {
      let count = 0;
      body = this.state.out.map((purchase) => {
        return (
          <Item item={purchase} count={++count} refresh={this.getPurchase}/>
        );
      });
    }
    return (
      <div>
        <Button animated='vertical' color='blue'
          onClick={() => this.setState({open: true})}>
          <Button.Content hidden>Bill</Button.Content>
          <Button.Content visible>
            <Icon name='unordered list' />
          </Button.Content>
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({open: false})} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" style={styles.flex}>
                Bill Number: {this.props.purchase.id}
              </Typography>
            </Toolbar>
          </AppBar>
          <Table>
            <Table.Header>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Tax</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Tax Price</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell style={{width: '20%'}}></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {body}
            </Table.Body>
          </Table>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Total stocks={this.state.out}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Load open={this.state.load}/>
        </Dialog>
      </div>
    );
  }

}
