import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Link } from "react-router-dom";
import domain from '../../functions/domain';
import Total from './total';
import Item from './item';
import ChoosePurchaser from './choosepurchaser';
import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Add extends React.Component {

  state = {
    open: false, stocks: [], receivedAmount: ''
  };

  componentDidMount() {
    this.getAllProducts();
    this.addNewProduct();
  }

  getAllProducts() {
    let context = this;
    let url = server() + '/stocks/getallstocks.php';
    let data = {id: this.props.data.id, toDay: ''};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      let out = 1;
      let stockOptions = [];
      try {
        out = JSON.parse(data);
        for (var i = 0; i < out.length; i++) {
          let stock = out[i];
          stockOptions.push({
            key: stock.id, value: stock.id,
            text: stock.item_number + " " + stock.product_name + " " + stock.local_name
          });
        }
      } catch(e) {}
      context.setState({ out: out, stockOptions, load: false });
    });
  }

  addNewProduct = () => {
    let stocks = this.state.stocks;
    let id = stocks.length + 1;
    stocks.push({id: id});
    this.setState({stocks})
  }

  removeItem = (id) => {
    let stocks = this.state.stocks;
    let newStocks = [];
    let count = 0;
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.id !== id) {
        stock["id"] = ++count;
        newStocks.push(stock);
      }
    }
    this.setState({stocks: newStocks});
  }

  getValueByKey = (id, key) => {
    console.log(this.state.out);
    for (var i = 0; i < this.state.out.length; i++) {
      let stock = this.state.out[i];
      if (stock.id === id) {
        return stock[key];
      }
    }
    return 0;
  }

  updateItem = (id, key, value) => {
    let stocks = this.state.stocks;
    let newStocks = [];
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.id === id) {
        stock[key] = value;
        if (key === 'item_id') {
          stock.price = this.getValueByKey(value, 'dnp');
          stock.tax = this.getValueByKey(value, 'tax');
          stock.no_stock = this.getValueByKey(value, 'no_stock');
        }
      }
      newStocks.push(stock);
    }
    this.setState({stocks: newStocks});
  }

  getStockData() {
    let stockData = [];
    let purchaserName = this.state.purchaserName;
    let purchaserMobile = this.state.purchaserMobile;
    let purchaserID = this.state.purchaserID;
    let receivedAmount = this.state.receivedAmount;
    if (!purchaserName || purchaserName.length < 1) {
      alert("Invalid Purchaser Name");
      return false;
    }
    if (!purchaserID || purchaserID < 1) {
      alert("Please Choose Purchaser"); return;
    }
    if (receivedAmount == 0 || receivedAmount.length < 1) {
      alert("Invalid Paid Amount"); return;
    }
    for (var i = 0; i < this.state.stocks.length; i++) {
      let stock = this.state.stocks[i];
      if (!stock.item_id || stock.item_id < 1) {
        alert("Invalid Stock Name");
        return false;
      }
      if (isNaN(stock.quantity) || stock.quantity == 0) {
        alert("Invalid Quantity");
        return false;
      }
      let price = (stock.quantity * stock.price);
      let total = price + price * stock.tax / 100;
      stockData.push({
        id: stock.item_id, quantity: stock.quantity, tax: stock.tax, price: stock.price, total
      });
    }
    return stockData;
  }

  saveAll = () => () => {
    let stockData = this.getStockData();
    let ledger = this.state.ledger;
    if (!stockData) { return; }
    let context = this;
    let url = server() + '/purchases/createpurchase.php';
    if (!this.state.ledger) {
      if (this.state.receivedAmount <= 0) {
        ledger = 0;
      }
      else {
        alert('Select Ledger!!!');
        return;
      }
    }
    let data = {
      shop_id: this.props.data.id, purchaser_id: this.state.purchaserID, data: stockData,
      purchaser_name: this.state.purchaserName, purchaser_mobile: this.state.purchaserMobile,
      purchaser_address: this.state.purchaserAddress, purchaser_gstin: this.state.purchaserGSTIN,
      amount: this.state.receivedAmount, ledger: ledger
    };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      if (data > 0) {
        alert("Success");
        document.getElementById('linkButton').click();
        context.setState({stocks: [], purchaserMobile: "", purchaserName: "", load: false});
        return;
      }
      alert("Failed");
      context.setState({load: false});
    });
  }

  render() {
    let total = this.state.stocks.length;
    let tableData = this.state.stocks.map((stock) => {
      let context = this;
      return (<Item data={context.props.data} item={stock} removeItem={context.removeItem}
                updateItem={context.updateItem} addNewProduct={context.addNewProduct} total={total}
                stockOptions={context.state.stockOptions} allStocks={context.state.out}/>);
    });
    return (
      <span>
        <AppBar style={styles.appBar}>
          <Toolbar>
            <Link to="/purchases">
              <IconButton id='linkButton' style={{color: 'white !important'}} aria-label="Close"><CloseIcon /></IconButton>
            </Link>
            <Typography variant="h6" color="inherit" style={styles.flex}>Purchase Details</Typography>
            <Button positive onClick={this.handleClose} style={{display: 'none'}}>Save Products</Button>
          </Toolbar>
        </AppBar>
        <Table style={{width: '100%'}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell style={{width: '30%'}}>Product</Table.HeaderCell>
              <Table.HeaderCell  style={{width: '10%'}}>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell style={{width: '10%'}}>Tax</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell>Taxable Price</Table.HeaderCell>
              <Table.HeaderCell>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData}
          </Table.Body>
        </Table>
        <Grid columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <ChoosePurchaser name={this.state.customerName} mobile={this.state.customerMobile}
              address={this.state.customerAddress} gstin={this.state.customerGSTIN}
              data={this.props.data} amount={this.state.receivedAmount}
              updateItem={(key, value) => this.setState({[key]: value})} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Total stocks={this.state.stocks}/>
          </Grid.Column>
        </Grid>
        <br />
        <Button fluid positive style={{marginRight: 40}} onClick={this.saveAll()}>Create Purchase Details</Button>
        <Load open={this.state.load}/>
      </span>
    );
  }

}
