import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Segment, Button, Icon } from 'semantic-ui-react';

import $ from 'jquery';
import Load from '../load';
import Server from '../functions/server';

const grid = 8;

export default class Category extends React.Component {

  state = {categories: [], isChanged: false};

  componentDidMount() {
    this.getCategories();
  }

  processCategories = (data) => {
    for (var i = 0; i < data.length; i++) {
      data[i].category_id = (i + 1) + "";
    }
    return data;
  }

  getCategories = () => {
    let context = this;
    let url = Server() + '/categories/getcategories.php';
    let data = {id: this.props.data.id };
    $.ajax({url, data, method: 'POST', beforeSend: () => {
        context.setState({ load: true });
      }, success: (data) => {
        try {
          data = JSON.parse(data);
          let categories = this.processCategories(data);
          this.setState({categories: data});
        } catch (e) { alert("Failed"); console.log(data); }
        this.setState({load: false});
      }, error: (e) => {
        alert("Error"); console.log(e);
        this.setState({load: false});
      }});
  }

  getItemStyle = (isDragging, draggableStyle) => {
    let style = {
      userSelect: "none", padding: grid * 2, margin: '0 0 ${grid}px 0',
      background: isDragging ? "lightgreen" : "grey", ...draggableStyle
    }
    return style;
  }

  getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? "lightblue" : "lightgrey", padding: grid, width: '100%'
    };
  }

  reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    let [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    for (var i = 0; i < result.length; i++) {
      result[i].new_id = (i + 1);
    }
    return result;
  }

  onDragEnd = (result) => {
    if (!result.destination) return ;
    let categories = this.reorder(this.state.categories, result.source.index, result.destination.index);
    this.setState({ categories, isChanged: true });
  }

  save = () => {
    let context = this;
    let url = Server() + '/categories/savecategories.php';
    let data = {id: this.props.data.id, categories: this.state.categories };
    $.ajax({url, data, method: 'POST', beforeSend: () => {
        context.setState({ load: true });
      }, success: (data) => {
        try {
          if (data === "1" || data === "true") {
            this.setState({isChanged: false, load: false});
            alert("Success"); return ;
          }
          throw data;
        } catch (e) { alert("Failed"); console.log(data); }
        this.setState({load: false});
      }, error: (e) => {
        alert("Error"); console.log(e);
        this.setState({load: false});
    }});
  }

  render() {
    let save = (
      <center>
        <Button icon labelPosition='left' onClick={this.save} color="green" style={{margin: 10}}>
          <Icon name='save' />
          Save Changes
        </Button>
      </center>
    );
    return (
      <div style={{marginTop: 20}}>
        {this.state.isChanged && save}
        <Load open={this.state.load} message='Getting Categories' />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver)}>
                {this.state.categories.map((item, index) => (
                  <Draggable key={item.category_id} draggableId={item.category_id} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                          style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <Segment clearing>
                            <Button animated='vertical' floated="right" color="green"
                              onClick={() => this.props.handleChange("category", item.category)}>
                              <Button.Content hidden>Products</Button.Content>
                              <Button.Content visible>
                                <Icon name='chevron circle right' />
                              </Button.Content>
                            </Button>
                            {item.category + " (" + item.product_count + ")"}
                          </Segment>
                        </div>
                      )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

}
