import React, { useState } from 'react';
import $ from 'jquery';
import { Segment, Button, Input } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';

export default function Add({ sync }) {

    const [remarks, setRemarks] = useState('');
    const [date, setDate] = useState(null);

    const add = () => {
        sync(date, '', remarks , () => {
            setRemarks('');
            setDate(null);
        });
    }

    return (
        <div>
            <Segment.Group>
                <Segment clearing>
                    <b>Add Holiday</b>
                    <Button color='green' icon='check' 
                        labelPosition='left'
                        content='Add'
                        floated='right'
                        onClick={() => add()}/>
                </Segment>
                <Segment>
                    <TextField type="date"
                        onChange={(e) => setDate(e.target.value)}
                        defaultValue={date} />
                    <Input style={{ marginLeft: 20, minWidth: 250 }}
                        placeholder="Holiday Reason"
                        onChange={(e, { value }) => setRemarks(value)}/>
                </Segment>
            </Segment.Group>
        </div>
    );

}