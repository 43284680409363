import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Shops from './shops/index';
import Products from './products';
import Addproduct from './products/addstock';
import Editproduct from './products/editstock';
import userId from '../functions/userid';
import Mapping from './userandshopmapping';

class Admin extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/admin" component={() => <Shops />} />
          <Route path="/admin/add-product" component={() => <Addproduct />} />
          <Route path="/admin/edit-product" component={() => <Editproduct />} />
          <Route path="/admin/products" component={() => <Products />} />
          <Route path="/admin/mapping" component={() => <Mapping />} />
        </Switch>
      </Router>
    );
  }
}

export default Admin;
