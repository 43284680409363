import React from 'react';
import $ from 'jquery';
import { Dropdown, Grid, Checkbox, Input, Label } from 'semantic-ui-react';

import server from '../../functions/server';
import userId from '../../functions/userid';

import Add from '../../customers/view/add';

export default class ChoosePurchaser extends React.Component {

  state = {
    customerName: '', toDay: this.getFormatedDate(new Date()),
    ledgerOption: [],
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }


  componentDidMount() {
    this.getAllCustomers();
    this.getLedger();
  }

  getLedger() {
    let context = this;
    let url = server() + '/ledger/getallledger.php';
    let data = {shopId: this.props.data.id };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      let out = [];
      let ledgerOption = [];
      try {
        out = JSON.parse(data);
        out.map((c) => {
          ledgerOption.push({ key: c.ledger, value: c.id, text: c.ledger });
        })

      } catch(e) {}
      context.setState({ load: false, ledgerOption });
    });
  }

  getAllCustomers = () => {
    let context = this;
    let url = server() + '/customers/getallcustomers.php';
    let data = {id: this.props.data.id, customerName: this.state.customerName, toDay: this.state.toDay, filter: 1 };
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) { }
      context.setState({ out: out, load: false });
    });
  }

  checkedChange = (e, data) => {
    if (data.checked) {
      this.props.updateItem("receivedAmount", "0");
    } else {
      this.props.updateItem("receivedAmount", "-1");
    }
  }

  handleAmount = (e, { value }) => {
    this.props.updateItem("receivedAmount", value);
  }

  handleChange = (e, { value }) => {
    try {
      let obj = this.state.out[value];
      this.props.updateItem("purchaserName", obj.name);
      this.props.updateItem("purchaserMobile", obj.mobile);
      this.props.updateItem("purchaserAddress", obj.address);
      this.props.updateItem("purchaserGSTIN", obj.gstin);
      this.props.updateItem("purchaserID", obj.id);
    } catch (e) {
      this.props.updateItem("purchaserName", "");
      this.props.updateItem("purchaserMobile", "");
      this.props.updateItem("purchaserAddress", "");
      this.props.updateItem("purchaserGSTIN", "");
      this.props.updateItem("purchaserID", "");
    }
  }

  handleChange1 = (e, { value }) => {
    this.props.updateItem("ledger", value);
  }

  render() {
    let options = [];
    if (this.state.out) {
      let i = 0;
      options = this.state.out.map((temp) => {
        return ({
          key: i, value: i++,
          text: temp.name + "->" + temp.mobile
        });
      });
    }
    return (
      <div style={{marginLeft: 50, marginRight: 50, marginTop: 10}}>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Dropdown
              placeholder='Select Purchaser' onChange={this.handleChange}
              fluid search selection options={options}/>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Add style={{marginLeft: 20}} buttonName={"Add Customer/Purchaser"}
              data={this.props.data} refresh={this.getAllCustomers} isCustomer={1} />
          </Grid.Column>
        </Grid><br />
      <Checkbox label='Paid Amount' onChange={this.checkedChange}
        defaultChecked={this.props.amount >= 0} />
      {
        this.props.amount >= 0 && (
          <div>
            <Input labelPosition='right' type='text' placeholder='Amount' style={{marginLeft: 20}}
              onChange={this.handleAmount}
              value={this.props.amount}>
              <Label basic>₹</Label>
              <input />
            </Input>
            <Dropdown placeholder='Select Ledger' search selection
              onChange={this.handleChange1}
              style={{marginLeft: 20}} options={this.state.ledgerOption}
              />
          </div>
        )
      }
      </div>
    );
  }

}
