import React from 'react';
import { Grid, Image, Header, List, Button, Icon } from 'semantic-ui-react';
import BusinessImage from '../media/business.jpg';
import $ from 'jquery';

export default class Online extends React.Component {

  setCookies() {
    if (true) {
      this.createCookie("ualum", "test", 7);
      return ;
    }
    let context = this;
    var email = 'balavinoth99@gmail.com';
    var password = '123456';
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email) { alert('Enter the Email!!!'); return; }
    if (!password) { alert('Enter the Password!!!'); return;
    }
    $.ajax({
    type: 'POST',
    data: { 'email':  email, 'password': password },
    url: 'http://localhost/ALUMNI/loginandsignup/loginwithpassword.php',
    success: function(data)
    {
      console.log(data);
      try {
        JSON.parse(data);
      }
      catch(e) {
        context.setState({ errorText: "Invalid Credentials", loading: false });
      }
    },
    error: function(err) { }
    });
  }

  createCookie(name,value,days) {
    var expires = "";
    alert("Comes")
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    console.log(name);
    console.log(value);
    console.log(expires);
    console.log('comes here');
    document.cookie = name + "=" + value + expires + ";path=/";
    console.log('comes here1');
    return;
    window.location.assign("http://localhost:3000");
  }


  render() {
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Image src={BusinessImage} />
          <Button onClick={this.setCookies.bind(this)} color='red'>Start Now</Button>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Header as="h1" color="blue">Reach your Business to the customers who are browsing online</Header>
          <br />
          <List divided relaxed>
            <List.Item>
              <List.Icon color='red' name='address card' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Free Business Website</List.Header>
                <List.Description>Convey about your business with free business Site</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='red' name='building' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Free Shopping Site</List.Header>
                <List.Description>Let your customers order your products online</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='red' name='fax' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Free Payment</List.Header>
                <List.Description>Receive payments from the customers</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='red' name='calculator' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Showcase Your Offers</List.Header>
                <List.Description>Showcase the products with offers in your shop</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='red' name='boxes' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>List Unlimited Products</List.Header>
                <List.Description>Add all the products as much you want in your Shopping site</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color='red' name='box' size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>Product with Categories</List.Header>
                <List.Description>Categorize your products so that customers can view it easily</List.Description>
              </List.Content>
            </List.Item>
          </List>
          <a href="https://you.strackit.com?redirectto=https://business.strackit.com">
            <Button color='red' inverted icon labelPosition='right'>
              Create Now
              <Icon name='right arrow' />
            </Button>
          </a>
        </Grid.Column>
      </Grid>
    );
  }

}
