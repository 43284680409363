import React from 'react';
import { Loader, Dimmer, Button, Menu, Header, Segment, Icon, Image, Label } from 'semantic-ui-react';
import EditDescription from './editdescription';

import AddImage from '../../media/addimage.jpg';

import AddIcon from '../../media/addicon.png';
import server from '../../functions/server';
import userId from '../../functions/userid';
import QRCode from './qrcode';
import $ from 'jquery';

const styles = {
  center: {
    maxWidth: 700
  },
  leftpad: {
    paddingLeft: 20
  },
  topPad: {
    marginTop: 30
  }
}

class Description extends React.Component {

  constructor() {
    super();
    this.state = {}
  }

  openDimmer = (loadText) => this.setState({ dimmer: true, loadText })
  closeDimmer = (loadText) => this.setState({ dimmer: false, loadText })
  handleFile = name => () => document.getElementById(name).click()
  handleState = (s) => this.setState(s)

  handleFileChange = name => e => {
    this.updateLocal(name, e.target.files[0]);
    this.updateDB(name, e.target.files[0]);
  }

  updateDB(type, file) {
    if (!type || !userId() || !this.props.data.id || !file) {
      console.log("err");
      return;
    }
    let context = this;
    var formData = new FormData();
    formData.append("owner", userId());
    formData.append("id", this.props.data.id);
    formData.append("type", type);
    formData.append("file", file);
    $.ajax({
      type: 'POST',
      url: server() + "/shop/updateimage.php",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function(x) {
          context.setState({
            dimmer: true,
            loadText: "Updating " + type
          });
      },
      success: function(data, status) {
        console.log(data);
        context.setState({
          dimmer: false,
          loadText: ""
        });
      },
      error: function(data, status) {
        console.log(data);
        context.setState({
          dimmer: false,
          loadText: ""
        });
      }
    });
  }

  updateLocal(type, value) {
    let reader  = new FileReader();
    let context = this;
    reader.addEventListener("load", function () {
      context.props.data[type] = reader.result;
      context.setState({
        [type]: value
      });
    }, false);
    if (value) {
      reader.readAsDataURL(value);
    }
  }

  updateProps(type, value) {
    this.props.data[type] = value;
    this.setState({
      [type]: value
    });
  }

  render() {

    let icon = (this.props.data.icon) ? this.props.data.icon : AddIcon;

    let feature_image = (this.props.data.feature_image) ? this.props.data.feature_image : AddImage;

    let description = (this.props.data.description) ? this.props.data.description : "Please add some catchy description. Users will love it to see and it will attract them.";

    return (
      <center style={{ marginLeft: '6%', marginRight: '6%'}}>

        <input onChange={this.handleFileChange('icon')} id="icon" type="file" style={{display: 'none'}} />
        <Image onClick={this.handleFile('icon')}
          label={{ as: 'a', color: 'teal', content: 'Change Icon', icon: 'image', ribbon: true }}
          src={icon} size='small' circular style={{cursor: 'pointer', marginTop: 70}}/>

        <Header as='h1' textAlign='center' style={styles.topPad} color='blue' content={this.props.data.shop_name}/>


        <Segment padded style={{...styles.center, ...styles.topPad}}
          onClick={() => this.setState({editdescription: true})}>
          <Label attached='top' color='blue'>Click to Edit Short Description</Label>
          <br />
          <b>{description}</b>
        </Segment>


        <input onChange={this.handleFileChange('feature_image')} id="feature_image" type="file" style={{display: 'none'}} />
        <Image onClick={this.handleFile('feature_image')}
          label={{ as: 'a', color: 'teal', content: 'Feature Image', icon: 'image', ribbon: true }}
          src={feature_image} size='massive' style={{cursor: 'pointer', ...styles.topPad}}/>


        <QRCode data={this.props.data}/>

        <Dimmer active={this.state.dimmer} page>
          <Loader>
            {this.state.loadText}
          </Loader>
        </Dimmer>

        <EditDescription open={this.state.editdescription} handleState={this.handleState}
          id={this.props.data.id} updateProps={this.updateProps.bind(this)}
          desc={this.props.data.description}/>
      </center>
    );
  }

}

export default Description;
