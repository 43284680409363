import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Header } from 'semantic-ui-react';
import server from '../../functions/server';
import userId from '../../functions/userid';
import $ from 'jquery';

export default class EditGSTIN extends React.Component {
  state = {
    open: false,
    delivery: ""
  };

  handleClose = () => {
    this.props.handleState({editmaximumamount: false});
  };

  handleUpdate = () => {
    let deliveryMaximumAmount = this.state.deliveryMaximumAmount.trim();
    if (deliveryMaximumAmount.length < 2) {
      this.setState({
        message: "Invalid GSTIN"
      });
      return;
    }
    let data = {
      id: this.props.id,
      owner: userId(),
      type: 'delivery_maximum_amount',
      value: deliveryMaximumAmount
    }
    console.log(data);
    let context = this;
    $.ajax({
      type: 'POST',
      url: server() + "/shop/update.php",
      data: data,
      beforeSend: function(x) {
          context.setState({
            dimmer: true,
            loadText: "Updating GSTIN"
          });
      },
      success: function(data, status) {
        let editmaximumamount = true;
        if (data == "1") {
          context.props.updateProps('delivery_maximum_amount', deliveryMaximumAmount);
          editmaximumamount = false;
        }
        context.props.handleState({
          dimmer: false,
          loadText: "", editmaximumamount
        });
      },
      error: function(data, status) {
        context.props.handleState({
          dimmer: false,
          loadText: "",
          message: data
        });
      }
    });
  }

  render() {
    if (!this.state.deliveryMaximumAmount) {
      let deliveryMaximumAmount = this.props.deliveryMaximumAmount;
      if (!deliveryMaximumAmount) {
        deliveryMaximumAmount = " ";
      }
      this.setState({deliveryMaximumAmount});
    }
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Update Maximum Amount</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Amount
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="descriptionText"
              value={this.state.deliveryMaximumAmount}
              onChange={(e) => {this.setState({deliveryMaximumAmount: e.target.value, message:""})}}
              fullWidth
            />
            <Header as='h4' color='red'>
              {this.state.message}
            </Header>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
