import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import $ from "jquery";

import serverName from '../../functions/server';


class MasterCategory extends Component {
  state = {
    categoryOption: [],
    value: ''
  };

  componentDidMount() {
    this.getcategory();
  }

  componentDidUpdate(props) {
    if (props.category != this.props.category) {
      this.setState({ value: this.props.category })
    }
  }

  getcategory() {
    let context = this;
    var url_string = window.location.href;
    var url = new URL(url_string);
    var shopId = url.searchParams.get("shopId");
    let data = { 'category': '', 'shopId': shopId };
    $.ajax({
      type: 'POST', data: data, url: serverName() + '/products/mastercategory.php',
      success: function(data) {
        try {
          data = JSON.parse(data);
          let x = [];
          let c = 0;
          data.map((y) => {
            if (y.category == context.props.category) {
              c = 1;
            }
            x.push({key: y.category, text: y.category, value: y.category})
          })
          if (c == 0) {
            context.props.add_product('mastercategory', '');
          }
          context.setState({ categoryOption: x });
        } catch (e) {
          alert('Error!!!')
        }
      },
    });
  }


  onCategoryChange = (f, { value }) => {
    this.props.add_product('mastercategory', value);
    this.setState({ value });
  }


  render() {
    const { value } = this.state
    return (
      <div>
        <Dropdown fluid placeholder='Master Category' onChange={this.onCategoryChange} search selection options={this.state.categoryOption} value={this.state.value}/>
      </div>
    );
  }
}

export default MasterCategory;
