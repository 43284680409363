import React from 'react';
import Title from '../product/add_product/title';
import { Grid, Card, Icon, Button, Divider, Radio } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import RazorPayment from '../razorpayment';
import server from '../functions/server';

export default class PrimeCurrent extends React.Component {
  updateData() {}

  dateSaprate(date, type) {
    if (type == "from") {
      try {
        date = date.split(" ")[0];
        date =
          date.split("-")[2] +
          "-" +
          date.split("-")[1] +
          "-" +
          Number(date.split("-")[0] - 1);
      } catch (e) {
        date = "";
      }
      return date;
    } else {
      try {
        date = date.split(" ")[0];
        date =
          date.split("-")[2] +
          "-" +
          date.split("-")[1] +
          "-" +
          Number(date.split("-")[0]);
      } catch (e) {
        date = "";
      }
      return date;
    }
  }

  render() {
    let primeData = this.props.data.prime;
    try {
      primeData = Number(primeData);
    } catch (e) {
      primeData = 0;
    }
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Prime"} menu={true}/>
        <Grid style={{marginLeft: '5%', marginTop: '2%'}}>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6} style={{backgroundColor: primeData == 1 ? '#E5E3E2' : '#FFFFFF'}}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Basic</div>
                    <div id="Amount">
                      ₹ 250 / Per Months
                    </div>
                    <div id="Amount">
                      ₹ 2499 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
              </Grid>
              <ul>
                <li>Unlimited Stock</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Unlimited Bills</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Unlimited Customers</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Sales</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse Report For Purchase</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Sales</li>
                <Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Analyse GST Report For Purchase</li>
              </ul>
              { primeData == 1 ?
                <center>
                  <h4 style={{color: 'green'}}>Subscribed</h4>
                  <Grid style={{color: 'green'}}>
                    <Grid.Column mobile={8} tablet={8} computer={8}>From Date<br/>{this.dateSaprate(this.props.data.till_date, 'from')}</Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>To Date<br/>{this.dateSaprate(this.props.data.till_date, 'to')}</Grid.Column>
                  </Grid>
                </center>:
                null
              }
              <br />
            </Paper>
          </Grid.Column>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6} style={{backgroundColor: primeData == 2 ? '#E5E3E2' : '#FFFFFF'}}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center><div id="package">
                    <div id="PackageName">Business</div>
                    <div id="Amount">
                      ₹ 850 / Per Month
                    </div>
                    <div id="Amount">
                      ₹ 8499 / Per Year
                    </div>
                  </div></center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
              </Grid>
              <ul>
                <li>All from Basic Plan</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>E-Commerce Shopping Portal</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>SEO and Google Ranking</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Multiple Reports</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Employee Management</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Order and Feedback Management</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Online Blogs</li>
              </ul>
              { primeData == 2 ?
                <center>
                  <h4 style={{color: 'green'}}>Subscribed</h4>
                  <Grid style={{color: 'green'}}>
                    <Grid.Column mobile={8} tablet={8} computer={8}>From Date<br/>{this.dateSaprate(this.props.data.till_date, 'from')}</Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>To Date<br/>{this.dateSaprate(this.props.data.till_date, 'to')}</Grid.Column>
                  </Grid>
                </center>:
                null
              }
              <br />
            </Paper>
          </Grid.Column>
          <Grid.Column mobile={16} computer={5} tablet={16}>
            <Paper elevation={6} style={{backgroundColor: primeData == 3 ? '#E5E3E2' : '#FFFFFF'}}>
              <Grid>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <center id="package">
                    <div id="PackageName">Professional</div>
                    <div id="Amount">
                      ₹ 3799 / Per Month
                    </div>
                    <div id="Amount">
                      ₹ 37999 / Per Year
                    </div>
                  </center>
                </Grid.Column>
                <Grid.Column mobile={4} tablet={4} computer={4}>
                  <img width={40} height={50} src={require('../media/No_1_Sales.png')} />
                </Grid.Column>
              </Grid>
              <ul>
                <li>All from Business Plan</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Platform in your business name</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Dedicated Relationship Manager</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Premium Shopping Site</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Google Ranking Expert Tips</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Customization Helps</li><Divider fitted style={{marginRight: '10%', marginTop:'3%'}} />
                <li>Much More coming soon</li>
              </ul>
              { primeData == 3 ?
                <center>
                  <h4 style={{color: 'green'}}>Subscribed</h4>
                  <Grid style={{color: 'green'}}>
                    <Grid.Column mobile={8} tablet={8} computer={8}>From Date<br/>{this.dateSaprate(this.props.data.till_date, 'from')}</Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>To Date<br/>{this.dateSaprate(this.props.data.till_date, 'to')}</Grid.Column>
                  </Grid>
                </center>:
                null
              }
              <br />
            </Paper>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
