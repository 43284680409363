import React from 'react';
import { Segment, Button, Checkbox } from 'semantic-ui-react';

export default function WeekOff(props) {

    let checkBoxStyle = { marginLeft: 20 }

    let days = [
        {day: "Sunday", value: 0},
        {day: "Monday", value: 1},
        {day: "Tuesday", value: 2},
        {day: "Wednesday", value: 3},
        {day: "Thursday", value: 4},
        {day: "Friday", value: 5},
        {day: "Saturday", value: 6},
    ]

    return (
        <div>
            <Segment.Group>
                <Segment clearing>
                    <b>Week Offs</b>
                    <Button color='green' icon='check' 
                        labelPosition='left'
                        content='Save'
                        floated='right'
                        onClick={() => props.sync(0, props.weekoff.join(''), '')}/>
                </Segment>
                <Segment>
                    {
                        days.map((temp) => {
                            return (
                                <Checkbox label={temp.day} style={checkBoxStyle}
                                    value={temp.value}
                                    checked={props.weekoff.indexOf(temp.value) > -1}
                                    onChange={(e, { value, checked }) => {
                                        props.update(checked, value);
                                    }}/>
                            );
                        })
                    }
                </Segment>
            </Segment.Group>
        </div>
    )
}