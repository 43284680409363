import React from 'react';
import $ from 'jquery';
import { Image, Table, Form, Button, Icon } from 'semantic-ui-react';

export default class Item extends React.Component {

  componentDidMount() {
    let context = this;
    $('#add_tax' + this.props.item.id).bind('keypress', function(e) {
      if(e.keyCode === 13){
          context.props.addNewProduct();
      }
    });
  }

  updateItem = (key) => (e, { value }) => {
    this.props.updateItem(this.props.item.id, key, value);
  }

  render() {
    let { item } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{this.props.sino}</Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='Item Number' value={item.item_number}
            onChange={this.updateItem('item_number')}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='Product Name' value={item.product_name}
            onChange={this.updateItem('product_name')}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='Local Name' value={item.local_name}
            onChange={this.updateItem('local_name')}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='HSN CODE' value={item.hsn_code}
            onChange={this.updateItem('hsn_code')}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='Available Stocks' type={'number'} value={item.no_stocks} min={0}
            onChange={this.updateItem('no_stocks')} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='DNP' type={'number'} value={item.dnp} min={0}
            onChange={this.updateItem('dnp')} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='MRP' type={'number'} value={item.prize} min={0}
            onChange={this.updateItem('prize')} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
        </Table.Cell>
        <Table.Cell>
          <Form.Input fluid placeholder='Tax' type={'number'} value={item.tax} id={"add_tax" + this.props.item.id} min={0}
            onChange={this.updateItem('tax')} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
        </Table.Cell>
        <Table.Cell>
          <Button animated='vertical' negative onClick={() => this.props.removeItem(this.props.item.id)}>
            <Button.Content hidden>Remove</Button.Content>
            <Button.Content visible>
              <Icon name='remove' />
            </Button.Content>
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

}
