import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import domain from '../../functions/domain';
import Item from './item';
import Load from '../../loads';
import server from '../../functions/server';
import Addproduct from '../../product/add_product/products'

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Add extends React.Component {

  state = {
    open: false,
    stocks: [{id: 0}]
  };



  componentDidUpdate(props) {
    if (props.out != this.props.out) {
      this.setState({ out: this.props.out })
    }
  }

  addNewProduct = () => {
    let stocks = this.state.stocks;
    let checkData = this.checkData();
    if (checkData == 1) {
      let id = stocks.length + 1;
      stocks.push({id: id});
      this.setState({stocks})
    }
    else {
      alert(checkData)
    }
  }

  stringConvertToLowercase(string) {
    try {
      string = string.toLowerCase();
    } catch (e) {
      string = ''
    }
    return string;
  }

  checkData() {
    let out = this.state.out;
    let stocks = this.state.stocks;
    let value = 1;
    stocks.map((c) => {
      out.map((v) => {
        let itemNumber1 = this.stringConvertToLowercase(c.item_number);
        let itemNumber2 = this.stringConvertToLowercase(v.item_number);
        let productName1 = this.stringConvertToLowercase(c.product_name);
        let productName2 = this.stringConvertToLowercase(v.product_name);
        if (itemNumber1 == itemNumber2) { value = "Item Number is Already present"; }
        if (productName1 == productName2) { value = "Product Name is Already present"; }
        if (itemNumber1 == itemNumber2 && productName1 == productName2) {
          value = "Product Name & Item Number is Already present";
        }
      })
    })
    stocks.map((s) => {
      stocks.map((ss) => {
        if (s.id != ss.id) {
          if (s.item_number == ss.item_number) {
            value = "Item Number is Already present";
          }
          if (s.product_name == ss.product_name) {
            value = "Product Name is Already present";
          }
        }
      })
    })
    return value;
  }

  removeItem = (id) => {
    let stocks = this.state.stocks;
    let newStocks = [];
    let count = 0;
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.id !== id) {
        stock["id"] = ++count;
        newStocks.push(stock);
      }
    }
    this.setState({stocks: newStocks});
  }

  checkData1() {
    let out = this.state.out;
    let stocks = this.state.stocks;
    let value = 1;
    for (var i = 0; i < stocks.length; i++) {
      for (var j = 0; j < out.length; j++) {
        let itemNumber1 = this.stringConvertToLowercase(stocks[i]["item_number"]);
        let itemNumber2 = this.stringConvertToLowercase(out[j]["item_number"]);
        let productName1 = this.stringConvertToLowercase(stocks[i]["product_name"]);
        let productName2 = this.stringConvertToLowercase(out[j]["product_name"]);
        if (itemNumber1 == itemNumber2) { value = "Item Number is Already present"; }
        if (productName1 == productName2) { value = "Product Name is Already present"; }
        if (itemNumber1 == itemNumber2 && productName1 == productName2) {
          value = "Product Name & Item Number is Already present";
        }
      }
    }
    for (var ii = 0; ii < stocks.length; ii++) {
      for (var iii = 0; iii < stocks.length; iii++) {
        if (stocks[ii]["id"] != stocks[iii]["id"]) {
          if (stocks[ii]["item_number"] == stocks[iii]["item_number"]) {
            value = "Item Number is Already present";
            return value;
          }
          if (stocks[ii]["product_name"] == stocks[iii]["product_name"]) {
            value = "Product Name is Already present";
            return value;
          }
        }
      }
    }
    return value;
  }

  updateItem = (id, key, value) => {
    let stocks = this.state.stocks;
    let newStocks = [];
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.id === id) { stock[key] = value; }
      newStocks.push(stock);
    }
    this.setState({stocks: newStocks});
  }

  validation() {
    for (var i = 0; i < this.state.stocks.length; i++) {
      let stock = this.state.stocks[i];
      if (!stock.product_name || stock.product_name.length < 1) { alert("Invalid Product Name"); return false; }
      if (isNaN(stock.no_stocks)) { alert("Invalid Stock"); return false; }
      if (isNaN(stock.prize)) { alert("Invalid Price"); return false; }
      if (isNaN(stock.tax)) { alert("Invalid Tax"); return false; }
      let value = this.removeSpaceBeforeAndAfter(stock.product_name);
      if (value == "Remove Extra Space Before Your Product Name" || value == "Remove Extra Space After Your Product Name") {
        alert(value); return false;
      }
      let value1 = this.removeSpaceBeforeAndAfter1(stock.item_number);
      if (value1 == "Remove Extra Space Before Your Item Code" || value1 == "Remove Extra Space After Your Item Code") {
        alert(value1); return false;
      }
    }
    return true;
  }

  removeSpaceBeforeAndAfter(string) {
    let stringLength = string.length;
    let beforeString = string.charAt(0);
    let afterString = string.charAt(stringLength - 1);
    if (beforeString == ' ') {
      return "Remove Extra Space Before Your Product Name";
    }
    if (afterString == ' ') {
      return "Remove Extra Space After Your Product Name";
    }
    return string;
  }

  removeSpaceBeforeAndAfter1(string) {
    let stringLength = string.length;
    let beforeString = string.charAt(0);
    let afterString = string.charAt(stringLength - 1);
    if (beforeString == ' ') {
      return "Remove Extra Space Before Your Item Code";
    }
    if (afterString == ' ') {
      return "Remove Extra Space After Your Item Code";
    }
    return string;
  }

  saveAll = () => {
    // let limitedOrder = this.props.data.primeLimitation;
    // limitedOrder = limitedOrder + this.state.stocks.length;
    // let TodaysAddedStock = this.props.TodaysAddedStock;
    // alert(TodaysAddedStock+" -> "+limitedOrder);
    // return;
    // if (TodaysAddedStock <= limitedOrder) {
    //   this.setState({open: true})
    // }
    // else {
    //   alert('Please upgrade your Prime pack!!!');
    //   return;
    // }


    // alert(limitedOrder);
    // return;
    if (!this.validation()) {
      return;
    }
    let checkData = this.checkData1();
    if (checkData != 1) {
      alert(checkData);
      return;
    }
    let context = this;
    let url = server() + '/stocks/addnewproducts.php';
    let stocks = this.state.stocks;
    stocks.map((c) => {
      let tax = c.tax;
      let prize = c.prize;
      let dnp = c.dnp;
      try {
        tax = Number(tax); prize = Number(prize); dnp = Number(dnp);
        if (tax < 10) { tax = '1.0'+tax; } else { tax = '1.'+tax; }
        prize = prize/tax; dnp = dnp/tax;
      } catch (e) {
        prize = 0; dnp = 0;
      }
      c.prize = prize; c.dnp = dnp;
    })
    let data = { id: this.props.data.id, data: this.state.stocks };
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      if (data) { alert("Success"); context.props.getAllStocks();
      context.setState({ open: false, stocks: [] }) }
      else { alert("Failed"); }
      context.setState({ load: false })
    });
  }


  handleDialog = () => {
    let prime = this.props.data.prime;
    let limitedOrder = this.props.data.primeLimitation;
    let TodaysAddedStock = this.props.TodaysAddedStock;
    if (TodaysAddedStock <= limitedOrder) {
      this.setState({open: true})
    }
    else {
      alert('Please upgrade your Prime pack!!!');
      return;
    }
  }

  render() {
    let i = 1;
    let tableData = this.state.stocks.map((stock) => {
      let context = this;
      return (<Item data={context.props.data} sino={i++} item={stock} removeItem={context.removeItem}
                updateItem={context.updateItem} addNewProduct={context.addNewProduct}/>);
      i++;
    });
    return (
      <span>
        <Load open={this.state.load}/>
        <Button icon labelPosition='right' positive onClick={this.handleDialog}>
          Add Products
          <Icon name='add' />
        </Button>
        <Dialog fullScreen open={this.state.open} onClose={() => this.setState({open: false})} TransitionComponent={Transition}>
          <AppBar style={styles.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.setState({open: false})} aria-label="Close"><CloseIcon /></IconButton>
              <Typography variant="h6" color="inherit" style={styles.flex}>Add Products</Typography>
              <Button positive onClick={this.handleClose} style={{display: 'none'}}>Save Products</Button>
            </Toolbar>
          </AppBar>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Item Code</Table.HeaderCell>
                <Table.HeaderCell>Part Name</Table.HeaderCell>
                <Table.HeaderCell>Local Name</Table.HeaderCell>
                <Table.HeaderCell>HSN CODE</Table.HeaderCell>
                <Table.HeaderCell>Stock</Table.HeaderCell>
                <Table.HeaderCell>DNP</Table.HeaderCell>
                <Table.HeaderCell>MRP</Table.HeaderCell>
                <Table.HeaderCell>Tax</Table.HeaderCell>
                <Table.HeaderCell>Remove</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            <Table.Body>{tableData}</Table.Body>
          </Table>
          <Button positive style={{margin: 20, float: 'right'}} onClick={this.addNewProduct}>Add Product</Button>
          <Button negative style={{margin: 20, float: 'right'}} onClick={this.saveAll}>Save All</Button>
        </Dialog>
      </span>
    );
  }

}
