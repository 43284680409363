import React from 'react';
import Title from '../product/add_product/title';
import $ from 'jquery';
import server from '../functions/server';
import Load from '../load';
import { Button, Card, Grid, Image, Modal } from 'semantic-ui-react';
import AddPost from './add';

export default class SocialMedia extends React.Component {

  state = {
    posts: [],
    products: [],
    toDay: this.getFormatedDate(new Date()),
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getData();
    this.getAllProduct();
  }

  getAllProduct = () => {
    let context = this;
    let url = server() + '/stocks/getallstock.php';
    let shopId = context.props.data.id;
    let data = {id: shopId, toDay: this.state.toDay};
    console.log(data);
    console.log(url);
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
        let products = [];
        out.map((c) => {
          products.push({ text: c.product_name, key: c.product_name, value: c.id })
        })
        context.setState({ products, load: false });
      } catch(e) {  }
      context.setState({ load: false });
    });
  }

  getData() {
    let context = this;
    let shopId = context.props.data.id;
    this.setState({ load: true, message: 'Getting Post' })
    $.ajax({
      type: 'POST', data: { 'shopid': shopId },
      url: server() + '/socialmedia/get.php',
      success: function(data) {
        try {
          data = JSON.parse(data);
          context.setState({ posts: data, load: false })
        } catch (e) {
          context.setState({ load: false })
          alert('Error to Get Data');
        }
      },
      error: function(err) {
        context.setState({ load: false })
        alert('Error to get Data')
      }
    });
  }

  delete() {
    let context = this;
    let posts = this.state.posts;
    this.setState({ load: true, message: 'Delete Post' })
    $.ajax({
      type: 'POST', data: { 'id': context.state.deleteId, 'image': context.state.deleteImage },
      url: server() + '/socialmedia/delete.php',
      success: function(data) {
        let newArray = [];
        if (data == 1) {
          posts.map((c) => {
            if (c.id != context.state.deleteId) {
              newArray.push(c);
            }
          })
          context.setState({ posts: newArray, deleteId: '', deleteconfirm: false, load: false })
        }
        else {
          context.setState({ load: false })
          alert('Error to Delete');
        }
      },
      error: function(err) {
        context.setState({ load: false })
        alert('Error to Delete')
      }
    });
  }

  openUrl(url) {
    window.open(url);
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Social Media"} menu={true} />
        <div style={{padding: '1%'}}>
          <AddPost products={this.state.products} getData={this.getData.bind(this)} types={'CREATE POST'} type='Create' shopId={this.props.data.id} />
          <Card.Group style={{paddingTop: '1%'}}>
            { this.state.posts.map((c) => {
                return (
                  <Card fluid>
                    <Card.Content>
                      <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={5}>
                          <Image style={{height: 280}} src={c.image} fluid />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={11}>
                          <Grid>
                            <Grid.Column mobile={16} tablet={16} computer={16} style={{height: 250}}>
                              <b style={{fontSize: 18}}>{c.title}</b>
                              <p style={{fontSize: 14}}>{c.description}</p>
                              { !c.url ?
                                (<div></div>):
                                (<div style={{cursor: 'pointer', color: 'blue'}}
                                    onClick={this.openUrl.bind(this, c.url)}>
                                    <u>{c.url}</u>
                                 </div>)
                              }
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={16} style={{display: 'flex'}}>
                              <AddPost products={this.state.products} types={'UPDATE POST'} type='Update' posts={this.state.posts} item={c} updateData={ (posts) => { this.setState({ posts }) }} />
                              <Button negative onClick={ () => { this.setState({ deleteconfirm: true, deleteId: c.id, deleteImage: c.image }) }}>Delete</Button>
                              <div style={{textAlign: 'right'}}>{c.timestamp}</div>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Card.Content>
                  </Card>
                )
              })
            }
          </Card.Group>
        </div>
        <Modal size={'mini'} open={this.state.deleteconfirm} onClose={ () => { this.setState({ deleteconfirm: false }) }}>
          <Modal.Header>Delete Post</Modal.Header>
          <Modal.Description style={{padding: '5%'}}>
            Are your comfirm to delete this post
          </Modal.Description>
          <Modal.Actions>
            <Button negative onClick={ () => { this.setState({ deleteconfirm: false }) }}>Cancel</Button>
            <Button positive onClick={this.delete.bind(this)} content='Delete' />
          </Modal.Actions>
        </Modal>
        <Load open={this.state.load} message={this.state.message} />
      </div>
    );
  }

}
