import React from 'react';
import Title from '../product/add_product/title';
import Load from '../load';
import $ from 'jquery';
import server from '../functions/server';
import { Table, Button, Icon, Dropdown } from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import './style.css';
import Orders from '../billing/view/orders';

export default class GstReport extends React.Component {

  state = {
    fromDate: this.getFormatedDate(new Date(), 7),
    toDate: this.getFormatedDate(new Date()),
    Report: [], rating: 0,
    ratingOptions: [
      {key: 'ALL', text: 'ALL', value: 0},
      {key: '1 Rating', text: '1 Rating', value: 1},
      {key: '2 Rating', text: '2 Rating', value: 2},
      {key: '3 Rating', text: '3 Rating', value: 3},
      {key: '4 Rating', text: '4 Rating', value: 4},
      {key: '5 Rating', text: '5 Rating', value: 5},
    ]
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month > 10) ? month : "0" + month;
    date = (date > 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
     this.getFeedbackReport();
  }

  getFeedbackReport = () => {
    let context = this;
    if (!this.state.fromDate) {
      alert('Enter From Date');
      return;
    }
    if (!this.state.toDate) {
      alert('Enter to date');
      return;
    }
    let url = server() + '/feedback/get.php';
    let data = { 'from_date': this.state.fromDate, 'to_date': this.state.toDate, 'rating': this.state.rating, 'shop_id': this.props.data.id }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      console.log(data);
      try {
        data = JSON.parse(data);
        console.log(data);
        context.setState({ Report: data, load: false })
      } catch (e) {
        alert('Error to Get')
        context.setState({ load: false })
      }
    });
  }

  updateItem = (e, { value }) => {
    this.setState({ rating: value })
  }

  seprateData = (value) => {
    let date = value.split(' ')[0];
    date = date.split('-')[2]+"-"+date.split('-')[1]+"-"+date.split('-')[0];
    return date;
  }

  render() {
    let i = 1;
    return (
      <div>
        <Load open={this.state.load}/>
        <Title shopDetails={this.props.data} title={this.props.data.shop_name + " - FeedBack Report"} menu={true}/>
        <div style={{padding: '0.5%'}}>
          <div style={{display: 'flex'}}>
            <TextField style={{marginTop: 5}}
              label="From" type="date" onChange={(e) => this.setState({fromDate: e.target.value})}
              defaultValue={this.state.fromDate} />
            <TextField style={{marginLeft: 20, marginTop: 5}}
              label="To" type="date" onChange={(e) => this.setState({toDate: e.target.value})}
              defaultValue={this.state.toDate} />
            <div style={{marginLeft: 20}}>
              Rating<br/>
              <Dropdown autoFocus placeholder='Rating'  search selection value={this.state.rating}
                options={this.state.ratingOptions} onChange={this.updateItem}/>
            </div>
            <Button variant="contained" color="primary" style={{marginLeft:20, marginTop: 5}} onClick={this.getFeedbackReport}>
              Go
            </Button>
          </div>
          <div style={{paddingTop: '0.5%'}}>
            <Table width='100%'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{width: '3%'}}><center>#</center></Table.HeaderCell>
                  <Table.HeaderCell style={{width: '8%'}}><center>Order Id</center></Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}><center>Date</center></Table.HeaderCell>
                  <Table.HeaderCell style={{width: '23%'}}><center>Customer Name</center></Table.HeaderCell>
                  <Table.HeaderCell style={{width: '5%'}}><center>Rating</center></Table.HeaderCell>
                  <Table.HeaderCell><center>FeedBack</center></Table.HeaderCell>
                  <Table.HeaderCell style={{width: '5%'}}><center>View</center></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { this.state.Report.map((c) => {
                    return (
                      <Table.Row>
                        <Table.Cell><center>{i++}</center></Table.Cell>
                        <Table.Cell><center>{c.id}</center></Table.Cell>
                        <Table.Cell>{this.seprateData(c.timestamp)}</Table.Cell>
                        <Table.Cell>{c.customer_name}</Table.Cell>
                        <Table.Cell><center>{c.rating}</center></Table.Cell>
                        <Table.Cell>{c.feedback}</Table.Cell>
                        <Table.Cell style={{cursor: 'pointer', color: 'blue'}}><center><Orders order={c}/></center></Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    );
  }

}
