import React from 'react';
import Button from '@material-ui/core/Button';

import Title from '../product/add_product/title';
import View from './view';

export default class Billing extends React.Component {

  getType() {
    if (window.location.pathname == '/customers') {
      return "Customer";
    }
    if (window.location.pathname == '/logistics-customers') {
      return "Customer";
    }
    if (window.location.pathname == '/logistics-vendor') {
      return "Vendor";
    }
  }

  render() {
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - "+this.getType()} menu={true}/>
        <View shopDetails={this.props.data} data={this.props.data} checked={this.props.checked}/>
      </div>
    );
  }

}
