import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddProduct from '../../product/add_product/addproduct';
import $ from 'jquery';
import server from '../../functions/server';

export default class Editproduct extends React.Component {


  state={
    product: []
  }

  componentDidMount() {
    this.getProduct();
  }

  getProduct() {
    let context = this;
    let x = window.location.href;
    var url = new URL(x);
    var edit = url.searchParams.get("id");
    $.ajax({
      type: 'POST',
      data: { 'product_id': edit },
      url: server() + '/products/get_edit_product.php',
      success: function(data)
      {
        console.log(data);
        try {
          data = JSON.parse(data);
          context.setState({ product: data, productId: edit })
          console.log(data);
        } catch (e) {
        }
      },
      error: function(err)
      {
        console.log(err);
      }
    });
  }

  render() {
    let image1, image2, image3, image4, image5;
    let data = this.state.product;
    let id = 1;
    data = data.map((c) => {
      if (c.type == 'images') {
        if (id == 1) {
          image1 = c.product_name;
        }
        else if (id == 2) {
          image2 = c.product_name;
        }
        else if (id == 3) {
          image3 = c.product_name;
        }
        else if (id == 4) {
          image4 = c.product_name;
        }
        else if (id == 5) {
          image5 = c.product_name;
        }
        id = id + 1
      }
    })
    return (
      <div>
        <AppBar>
          <Toolbar>
            <Typography variant="h6">
              <div style={{color: 'white'}}>Edit Products</div>
            </Typography>
          </Toolbar>
        </AppBar><br/><br/><br/>
        { this.state.product.length > 0 ?
          (
            <div>
              {
                this.state.product.map((c) => {
                  if (c.type == 'products') {
                    return (
                      <AddProduct from='edit' localname={c.local_name}
                        offerStocks={c.offerends} discount={c.discount}
                        tax={c.tax} itemNumber={c.item_number} category={c.category}
                        description={c.description} noStocks={c.no_stock}
                        price={c.prize} name={c.product_name}
                        featureImage={c.feature_image}
                        specification={c.specification} image1={image1}
                        image2={image2} image3={image3} image4={image4}
                        image5={image5} data={this.state.product}
                        productId={this.state.productId} userFrom={'admin'} />
                    )
                  }
                })
              }
            </div>
          ):''
        }
      </div>
    );
  }
}
