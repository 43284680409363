import React from 'react';
import { Table } from 'semantic-ui-react';
import $ from 'jquery';

import Title from '../../product/add_product/title';
import server from '../../functions/server';
import Load from '../../loads';
import Add from './add';

export default class Designation extends React.Component {

  state = {
    load: false,
    data: [],
  }

  componentDidMount() {
    this.getDesignations();
  }

  getDesignations() {
    let context = this;
    let url = server() + '/designations/getall.php';
    let data = { id: this.props.data.id };
    $.ajax({
      url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {  }
      context.setState({ out: out, load: false });
    });
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    if (this.state.out && this.state.out !== 1) {
      x = this.state.out.map((v) => {
        return (
          <Table.Body>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.designation}</Table.Cell>
            <Table.Cell>{v.count}</Table.Cell>
            <Table.Cell>{v.timestamp}</Table.Cell>
            <Table.Cell><Add getDesignations={this.getDesignations.bind(this)} item={v} title={'Edit'} /></Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Title shopId={this.props.data.id} shopDetails={this.props.data} title={this.props.data.shop_name + " - Designations"} menu={true}/>
        <Load load={this.state.load} message={this.state.message} />
        <div style={{margin: '1%'}}>
          <Add title={'Add'} getDesignations={this.getDesignations.bind(this)} shopId={this.props.data.id} />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Designation</Table.HeaderCell>
                <Table.HeaderCell>Employees</Table.HeaderCell>
                <Table.HeaderCell>Created On</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            {x}
          </Table>
        </div>
      </div>
    );
  }

}
