import React from "react";
import TextField from "@material-ui/core/TextField";
import server from "../functions/server";
import userId from "../functions/userid";
import $ from "jquery";
import Title from "../product/add_product/title";
import { Button, Input } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

export default class Settings extends React.Component {
  state = {};

  componentDidMount() {
    let context = this;
    let shopId = context.props.data.id;
    let url = server() + "/cms/get.php";
    let data = { shopId };
    $.ajax({
      url,
      data,
      type: "POST",
    }).done((data) => {
      try {
        data = JSON.parse(data);
        let cancellationPolicy = data[0].cancellation_policy;
        let paymentSecurity = data[0].payment_security;
        let privacyPolicy = data[0].privacy_policy;
        let returnsPolicy = data[0].returns_policy;
        let termsAndConditions = data[0].terms_and_conditions;
        let termsOfUse = data[0].terms_of_use;
        context.setState({
          termsAndConditions,
          cancellationPolicy,
          returnsPolicy,
          termsOfUse,
          privacyPolicy,
          paymentSecurity
        });
      } catch (error) {}
    });
  }

  submit = () => {
    let context = this;
    let shopId = context.props.data.id;
    let {
      termsAndConditions,
      cancellationPolicy,
      returnsPolicy,
      termsOfUse,
      privacyPolicy,
      paymentSecurity,
    } = this.state;
    let url = server() + "/cms/update.php";
    let data = { 
        shopId, 
        termsAndConditions,
        cancellationPolicy,
        returnsPolicy,
        termsOfUse,
        privacyPolicy,
        paymentSecurity 
      };
    $.ajax({
      url,
      data,
      type: "POST",
    }).done((data) => {
      if (data == 1 || data == "1") {
        alert("Success");
      } else {
        alert("Failed to save");
      }
    });
  };

  handleEditorChange = (html) => {
    this.setState({ longdescription: html });
  };

  render() {
    return (
      <div>
        <Title
          shopDetails={this.props.data}
          shopId={this.props.data.id}
          title={this.props.data.shop_name + " - CMS"}
          menu={true}
        />
        <div style={{ margin: "2%", display: "flex" }}>
          <div style={{ width: "49%" }}>
            <b>Terms And Conditions</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.termsAndConditions}
              onChange={(html) => {
                this.setState({ termsAndConditions: html });
              }}
              placeholder="Write something about Terms And Conditions"
            />
          </div>
          <div style={{ width: "49%", marginLeft: "2%" }}>
            <b>Cancellation Policy</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.cancellationPolicy}
              onChange={(html) => {
                this.setState({ cancellationPolicy: html });
              }}
              placeholder="Write something about Cancellation Policy"
            />
          </div>
        </div>
        <br />
        <div style={{ margin: "2%", display: "flex" }}>
          <div style={{ width: "49%" }}>
            <b>Returns Policy</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.returnsPolicy}
              onChange={(html) => {
                this.setState({ returnsPolicy: html });
              }}
              placeholder="Write something about Terms And Conditions"
            />
          </div>
          <div style={{ width: "49%", marginLeft: "2%" }}>
            <b>Terms Of Use</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.termsOfUse}
              onChange={(html) => {
                this.setState({ termsOfUse: html });
              }}
              placeholder="Write something about Terms Of Use"
            />
          </div>
        </div>
        <br />
        <div style={{ margin: "2%", display: "flex" }}>
          <div style={{ width: "49%" }}>
            <b>Payment Security</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.paymentSecurity}
              onChange={(html) => {
                this.setState({ paymentSecurity: html });
              }}
              placeholder="Write something about Payment Security"
            />
          </div>
          <div style={{ width: "49%", marginLeft: "2%" }}>
            <b>Privacy Policy</b>
            <br />
            <ReactQuill
              style={{ height: "140px" }}
              value={this.state.privacyPolicy}
              onChange={(html) => {
                this.setState({ privacyPolicy: html });
              }}
              placeholder="Write something about Privacy Policy"
            />
          </div>
        </div>
        <br />
        <br />
        <center>
          <Button onClick={this.submit}>Submit</Button>
        </center>
      </div>
    );
  }
}
