import React from 'react';
import $ from 'jquery';
import { Table, Button, Modal, Grid, Image } from 'semantic-ui-react';

import server from '../functions/server';
import Title from '../product/add_product/title';
import Load from '../load';
import Add from './add';
import Delete from './delete';
import Edit from './edit';
import AddImage from '../media/addproduct.png';

export default class SecoundaryCategory extends React.Component {

  state = { masterCategories: [], view: 'noimage',  }

  componentDidMount() {
    this.getMasterCategories();
  }

  getMasterCategories = () => {
    let context = this;
    let url = server() + "/categories/getmaster.php";
    let data = {shop_id: this.props.data.id};
    $.ajax({
      url: url, data: data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      console.log(data);
      console.log('errrorr is hereeeee');
      try {
        data = JSON.parse(data);
        console.log(data);
        context.setState({masterCategories: data, load: false});
      } catch(e) {}
    });
  }

  updateImage(file, id) {
    let context = this;
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('type', 'masterCategory');
    $.ajax({
    type: 'POST', processData: false, contentType: false,
    data: formData, url: server()+ "/categories/updateimage.php",
    success: function(data) {
      console.log(data);
      if (data == 1) {
        context.getMasterCategories();
      }
    },
    error: function(data, status) {
      context.setState({ load: false });
      alert("Failed to Add Product");
    }
    });
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    if (this.state.masterCategories && this.state.masterCategories !== 1) {
      x = this.state.masterCategories.map((v) => {
        let color = '#FFFFFF';
        let status = v.status;
        if (status == 0 || status == '0') {
          status = "Inactive";
        }
        else {
          status = "Active";
        }
        if (v.status == '0' || v.status == 0) { color = '#FED4CC'; }
        return (
          <Table.Body style={{backgroundColor: color}}>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.category}</Table.Cell>
            <Table.Cell>
            { !v.image ?
              (<div>
                <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id); }} style={{display: 'none'}}/>
                <label htmlFor={v.id}>
                  <Image src={AddImage} style={{width: '60px', height: '60px', cursor: 'pointer'}} />
                </label>
                </div>):
              (
                <div>
                  <input id={v.id} type="file" onChange={(e) => { this.updateImage(e.target.files[0], v.id); }} style={{display: 'none'}}/>
                  <label htmlFor={v.id}>
                    <Image src={v.image} style={{width: '60px', height: '60px', cursor: 'pointer'}} />
                  </label>
                  </div>
              )
            }
            </Table.Cell>
            <Table.Cell>
            {status}
            </Table.Cell>
            <Table.Cell>{v.category_count}</Table.Cell>
            <Table.Cell>{v.stock_count}</Table.Cell>
            <Table.Cell>{v.addedon}</Table.Cell>
            <Table.Cell>
              <Edit data={v} refresh={this.getMasterCategories}/>
            </Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Master Category"} menu={true}/>
        <Load open={this.state.load} message='Getting Master Category' />
        <div style={{margin: '1%'}}>
          <Add title={'Add Category'} getMasterCategories={this.getMasterCategories} shopId={this.props.data.id} />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Image</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Childrens</Table.HeaderCell>
                <Table.HeaderCell>Stocks</Table.HeaderCell>
                <Table.HeaderCell>Added On</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            {x}
          </Table>
        </div>
      </div>
    );
  }

}
