import React from 'react';
import { Grid, Segment, Header, Icon } from 'semantic-ui-react';
import Card from './card';
import Add from './add';

export default class Products extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  getCards(products) {
    return products.map((x) => {
      return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Card data={x} shop={this.props.data.shop_name}/>
        </Grid.Column>
      );
    });
  }




  

  render() {
    let { products } = this.props.data;
    let x = this.getCards(products);
    let y = (
      <Grid.Column mobile={16} tablet={8} computer={4} >
        <Add id={this.props.data.id}/>
      </Grid.Column>
    );
    let proUrl = "https://shop.strackit.com/" + this.props.data.shop_name.split(" ").join("-");

    return (
      <div style={{marginTop: 50}}>
        <center>
          <Header as='h2' color='teal'>
            <Icon name='cube' />
            <Header.Content>
              Products
              <Header.Subheader>Your Added Products</Header.Subheader>
            </Header.Content>
          </Header>
        </center>
        <Segment style={{marginTop: 50, marginLeft: '1%', marginRight: '1%', paddingBottom: 35}}>
          <Header color='blue' as='h3' floated='left'>Category View</Header>

          <a href={proUrl} target="_blank">
            <Header color='green' as='h4' floated='right' content="View More"/>
          </a>

        </Segment>
        <br />
        <Segment clearing style={{marginTop:'-2%', marginLeft: '1%', marginRight: '1%'}}>
          { this.props.from != 'view' ?
            (<Grid>{(!this.props.data.view)}{x}</Grid>):
            (<Grid >{x}</Grid>)
          }
        </Segment>
      </div>
    );
  }
}
