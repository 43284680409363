import React from 'react';
import { Table, Button, Modal, Grid } from 'semantic-ui-react';
import $ from 'jquery';

import Title from '../product/add_product/title';
import server from '../functions/server';
import Load from '../loads';
import Add from './add';



export default class PNSService extends React.Component {

  state = {
    load: false,
    data: [],
  }

  componentDidMount() {
    this.getBankDetails();
  }

  getBankDetails() {
    let context = this;
    let todaysOrder;
    let url = server() + '/bankdetails/get.php';
    let data = { shop_id: this.props.data.id };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
        console.log(out);
        console.log('popo');
      } catch(e) {  }
      context.setState({ out: out, load: false });
    });
  }

  delete() {
    let context = this;
    let url = server() + '/bankdetails/delete.php';
    let data = { id: this.state.deleteId };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
        if (data == 1) {
          context.setState({ open: false })
          this.getBankDetails();
        }
    });
  }

  deleteConfirm(id) {
    this.setState({ deleteId: id, open: true })
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    if (this.state.out && this.state.out !== 1) {
      x = this.state.out.map((v) => {
        return (
          <Table.Body>
            <Table.Cell>{i++}</Table.Cell>
            <Table.Cell>{v.bank}</Table.Cell>
            <Table.Cell>{v.beneficiary_name}</Table.Cell>
            <Table.Cell>{v.acc_no}</Table.Cell>
            <Table.Cell>{v.ifsc_code}</Table.Cell>
            <Table.Cell><Add getBankDetails={this.getBankDetails.bind(this)} item={v} title={'Edit Bank Deatils'} /></Table.Cell>
            <Table.Cell style={{cursor: 'pointer'}} onClick={this.deleteConfirm.bind(this, v.id)}>{'Delete'}</Table.Cell>
          </Table.Body>
        );
      });
    }
    return (
      <div>
        <Modal open={this.state.open}>
          <Modal.Header>{'Delete'}</Modal.Header>
            <Modal.Description>
              <div style={{margin:'2%'}}>
                <h3>Are Sure to Delete?</h3><br/>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Button fluid color="negative" onClick={ () => { this.setState({ open: false }) }}>No</Button>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Button fluid color="positive" onClick={this.delete.bind(this)}>Yes</Button>
                  </Grid.Column>
                </Grid>
              </div>
            </Modal.Description>
        </Modal>
        <Title shopId={this.props.data.id} shopDetails={this.props.data} title={this.props.data.shop_name + " - Bank Details"} menu={true}/>
        <Load load={this.state.load} message={this.state.message} />
        <div style={{margin: '1%'}}>
          <Add title={'Add Bank Deatils'} getBankDetails={this.getBankDetails.bind(this)} shopId={this.props.data.id} />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.NO</Table.HeaderCell>
                <Table.HeaderCell>BANK</Table.HeaderCell>
                <Table.HeaderCell>BENEFICIARY NAME</Table.HeaderCell>
                <Table.HeaderCell>A/C NO</Table.HeaderCell>
                <Table.HeaderCell>SWIFT / IFSC CODE</Table.HeaderCell>
                <Table.HeaderCell>EDIT</Table.HeaderCell>
                <Table.HeaderCell>DELETE</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            {x}
          </Table>
        </div>
      </div>
    );
  }

}
