import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import domain from '../functions/domain';
import Title from '../product/add_product/title';
import View from './view';

export default class Billing extends React.Component {

  componentDidMount() {
    if (this.props.data.category == 'Transportation And Services') {
      window.location = domain()+'logistics-billing';
    }
  }

  render() {

    let stocks = (
      <Link to="/stocks">
        <Button variant="contained" color="primary">View / Update Stocks</Button>
      </Link>
    );

    let purchase = (
      <Link to="/purchases">
        <Button variant="contained" color="primary">Purchase Details</Button>
      </Link>
    );

    return (
      <div>
      
        <Title shopDetails={this.props.data} menu={true} shopId={this.props.data.id} title={this.props.data.shop_name + " - Bills"} button1={stocks} button2={purchase}/>

        <View data={this.props.data}/>
      </div>
    );
  }

}
