import React from 'react';
import $ from 'jquery';
import server from '../../functions/server';
import ViewShop from './viewshop';
import { Grid, Button } from 'semantic-ui-react';
import AppBar from '@material-ui/core/AppBar';
import Buttons from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { Link } from "react-router-dom";
import userId from '../../functions/userid';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: '2%',
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class Shops extends React.Component {

  state = {
    shops: [],
    auth: false,
    anchorEl: false,
    search: '',
    result: 'Your are not allow to access another Shops'
  }

  componentDidMount() {
    this.getShops(this.state.search);
  }

  getShops(shopName) {
    let context = this;
    let out = [];
    let data = { 'shop_name': shopName, 'userId': userId() }
    let url = server() + '/admin/shops.php';
    $.ajax({
      url: url, data,
      type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      try {
        out = JSON.parse(data);
        if (out.length > 0) {
          context.setState({ shops: out, result: true })
        }
      } catch(e) {}
      context.setState({
        out: out,
        load: false
      });
    });
  }

  onChangeHandle = name => event => {
    this.setState({ [name]: event.target.value })
    this.getShops(event.target.value);
  }

  mapping() {
    let userIds = userId();
    if (userIds == '2N/VNQz6bkLZwzSqajc2cQ==' || userIds == 'LHdZPzD5B6tvT2JcgeWmYw==') {
      return (<Link to={"/admin/mapping"}><Buttons style={{color: 'white'}}>Mapping</Buttons></Link>)
    }
    else {
      return (<div></div>)
    }
  }

  updateDate(shops) {
    this.setState({ shops })
  }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let data = this.state.shops;
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Admin
            </Typography>
            <InputBase placeholder="Search…" style={{marginLeft: '70%', color: 'white'}}
              onChange={this.onChangeHandle('search')}
              inputProps={{ 'aria-label': 'search' }}
            />
            {this.mapping()}
          </Toolbar>
        </AppBar>
        <div style={{paddingTop: '6.5%', paddingLeft: '1%', paddingRight: '1%'}}>
          { this.state.result == 'Your are not allow to access another Shops' ?
            (<div style={{color: 'red', paddingTop: '20%'}}><center>{this.state.result}</center></div>):
            (
              <Grid>
                {data.map((c) => {
                  return (
                    <Grid.Column mobile={16} tablet={16} computer={4}>
                      <ViewShop updateDate={this.updateDate.bind(this)}
                        search={this.state.search} getShops={this.getShops.bind(this)}
                        shop={c} shopData={this.state.shops} />
                    </Grid.Column>
                  )
                })}
              </Grid>
            )
          }
        </div>
      </div>
    );
  }
}
