import React from 'react';
import $ from 'jquery';
import { Image, Table, Button, Icon } from 'semantic-ui-react';

import Update from './update';
import Load from '../../load';
import server from '../../functions/server';
import userId from '../../functions/userid';
import Orders from './orders';
import Customer from './customer';

export default class Item extends React.Component {

  state = {}

  sendWhatsAppMessage = () => {
    let message = "Thanks for visiting "+this.props.data.shop_name+".\n\n You can view and download your invoice anytime using the link\n\n https://bills.strackit.com/"+this.props.data.id+"/"+this.props.item.id;
    let url = "https://wa.me/91"+this.props.item.customer_mobile+"?text="+message;
    window.open(url, '_blank');
  }

  sendSms = () => {
    let context = this;
    let url = server() + '/orders/sendbill.php';
    let data = {
      id: userId(),
      order_id: this.props.item.id,
      mobile: this.props.item.customer_mobile,
      name: this.props.data.shop_name,
      shop_id: this.props.data.id
    };
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
          context.setState({
            load: true
          });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        if (data.status === 1) {
          alert("SMS Sent Successfully");
        } else {
          alert(data.message);
        }
      } catch (e) {
        alert(data);
      }
      context.setState({load: false});
    });
  }

  render() {
    let { item } = this.props;
    let isCustomer = 0;
    let customer = "";
    if (item.customer_name && item.customer_name.length > 0) {
      customer = item.customer_name; ++isCustomer;
    }
    if (item.customer_mobile && item.customer_mobile.length > 2) {
      customer += " (" + item.customer_mobile + ")"; ++isCustomer;
    }
    let url = "https://bills.strackit.com/" + this.props.data.id + "/" + item.id;
    return (
      <Table.Row>
        <Table.Cell>{this.props.count}</Table.Cell>
        <Table.Cell>{item.voucher_no}</Table.Cell>
        <Table.Cell><Customer item={item}/></Table.Cell>
        <Table.Cell>{item.timestamp}</Table.Cell>
        <Table.Cell>{item.parts}</Table.Cell>
        <Table.Cell>{item.stocks ? item.stocks : 0}</Table.Cell>
        <Table.Cell>{"Rs." + Number(item.price ? item.price : 0).toFixed(2)}</Table.Cell>
        <Table.Cell>{item.mode}</Table.Cell>
        <Table.Cell>
          <Orders order={item} data={this.props.data} />
        </Table.Cell>
        <Table.Cell style={{display: 'flex'}}>
          <a href={url} target="_blank">
            <Button animated='vertical' color='teal'>
              <Button.Content hidden style={{fontSize: '12px'}}>View Bill</Button.Content>
              <Button.Content visible>
                <Icon name='eye' />
              </Button.Content>
            </Button>
          </a>
          {
            (isCustomer === 2) ? (
              <div style={{display: 'flex'}}>
                <Button animated='vertical' negative onClick={this.sendSms}>
                  <Button.Content hidden style={{fontSize: '12px'}}>Send SMS</Button.Content>
                  <Button.Content visible>
                    <Icon name='mail' />
                  </Button.Content>
                </Button>
                <Button  animated='vertical' positive onClick={this.sendWhatsAppMessage}>
                <Button.Content hidden style={{fontSize: '12px'}}>WhatsApp</Button.Content>
                  <Button.Content visible>
                    <Icon name='whatsapp' />
                  </Button.Content>
                </Button>
              </div>
            ) : (<Update item={this.props.item} updateItem={this.props.updateItem}/>)
          }
        </Table.Cell>
        <Load open={this.state.load}/>
      </Table.Row>
    );
  }

}
