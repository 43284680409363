import React from "react";
import { Table, Button } from "semantic-ui-react";
import server from "../../functions/server";
import $ from "jquery";
import Buttons from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Load from "../../loads";
import { AddToHomeScreenOutlined } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class View extends React.Component {
  state = {
   out: []
  };

  getReport() {
    let context = this;
    let url = server() + '/stocks/getallstock.php';
    let data = {id: this.props.id, toDay: this.props.toDay};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out;
      try {
        out = JSON.parse(data);
      } catch(e) { alert("Failed") }
      context.setState({ out: out, load: false, open: true });
    });
  }
    
  export() {
    let downloadLink;
    let filename = "GstReport";
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.getElementById("export");
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
    filename = filename ? filename + ".xls" : "excel_data.xls";
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = "data:" + dataType + ", " + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

    render() {
     
    return (
      <div>
        <Button primary onClick={this.getReport.bind(this)}>
          Export
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false });
          }}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ open: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              GST REPORT
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Load open={this.state.load} />
          <div style={{ paddingLeft: "1%" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="export"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Export"
            />
            <Table celled striped id="export">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Item Code</Table.HeaderCell>
                  <Table.HeaderCell>Part Name</Table.HeaderCell>
                  <Table.HeaderCell>Stock</Table.HeaderCell>
                  <Table.HeaderCell>Min Stock</Table.HeaderCell>
                  <Table.HeaderCell>Dnp</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Tax</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.out.map((r) => {
                  let dnp = r.dnp;
                        let prize = r.prize;
                        let tax = r.tax;
                        try {
                        dnp = Number(dnp);
                        prize = Number(prize);
                        tax = Number(tax);
                        dnp = Number(dnp + ((dnp * tax)/100)).toFixed(2);
                        prize = Number(prize + ((prize * tax)/100)).toFixed(2);
                        } catch (e) {
                        prize = 0;
                        dnp = 0;
                  }
                  return (
                    <Table.Row>
                      <Table.HeaderCell>{r.item_number}</Table.HeaderCell>
                      <Table.HeaderCell>{r.product_name}</Table.HeaderCell>
                      <Table.HeaderCell>{r.no_stock}</Table.HeaderCell>
                      <Table.HeaderCell>{r.min_stock}</Table.HeaderCell>
                      <Table.HeaderCell>{dnp}</Table.HeaderCell>
                      <Table.HeaderCell>{prize}</Table.HeaderCell>
                      <Table.HeaderCell>{tax}</Table.HeaderCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Dialog>
      </div>
    );
  }
}
