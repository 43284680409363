import React from 'react';
import $ from 'jquery';
import server from '../functions/server';
import { Button, Grid } from 'semantic-ui-react'

import { Edit } from '@material-ui/icons/';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function FormDialog(props) {

  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState(props.data.category);
  const [position, setPosition] = React.useState(props.data.position);
  const [status, setStatus] = React.useState(props.data.status);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const edit = () => {
    if (!category.length) {
      alert('Enter Category Name!!');
      return;
    }
    if (!position.length) {
      alert('Enter Position!!');
      return;
    }
    $.ajax({type: "POST", url: server() + '/categories/updatemaster.php',
      data: { id: props.data.id, category, position, status },
      success: (data) => {
        console.log(data);
        if (data === "1") {
          setOpen(false);
          props.refresh();
        } else {
          alert("Failed");
        }
      }, error: (e) => {
        console.log(e);
        alert("Error");
      }
    });
  }

  return (
    <span>
      <div onClick={handleClickOpen} style={{cursor: 'pointer'}}>Edit</div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Master Category</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Master Category to use in Stock.</DialogContentText>
          <TextField autoFocus value={category} margin="dense" id="category" label="Category" type="name" onChange={(e) => setCategory(e.target.value)} fullWidth />
          <Grid>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <br/>
              <Select fullWidth label="Position" labelId="demo-simple-select-label" id="demo-simple-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <TextField autoFocus value={position} margin="dense" id="position" label="Position" type="number" onChange={(e) => setPosition(e.target.value)} fullWidth />
            </Grid.Column>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">Cancel</Button>
          <Button onClick={edit} color="primary">Edit</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
