import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Input, Table, Button, Dropdown } from 'semantic-ui-react';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import AddBill from './add';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import server from '../functions/server';
import Title from '../product/add_product/title';
import Tax from './tax';
import Load from '../load';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class LogisticsBilling extends React.Component {

  state = {
    items: [ { ids: 1, type: 'add' } ], amount: 0,
    customerName: '',  deleteItems: [],
    choosedCustomer: '', iecNo: '', contactPerson: '', shipperName: '', mode: '', tinNo: '', gstNo: '',
    consignee: '', BLNo: '', billOfEntry: this.getFormatedDate(new Date()), loading: '', destination: '', container: '',
    volumn: '', overAllDescription: '', email: '',
    overAllQuantity: '', grossWeight: '', billOfEntryNumber: '',
    date: this.getFormatedDate(new Date()),
  }

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  handleChange = (e, { value }) => { this.setState({ choosedCustomer: value }) }

  handleChange1 = (e, { value }) => { this.setState({ choosedBank: value }) }

  handleChange2 = (e, { value }) => { this.setState({ choosedLedger: value }) }

  handleChange3 = (key, id) => (e, { value }) => {
    let items = this.state.items;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.ids === id) {
        item[key] = value;
      }
    }
    this.setState({ items })
  }




  addItem = () => {
    let id = 0;
    this.state.items.map((c) => { id = c.ids; });
    let items = this.state.items;
    id = id + 1;
    items.push({ ids: id, type: 'add' });
    this.setState({ items })
  }

  deleteItem(deleteId) {
    let item = this.state.items;
    let items = [];
    let deleteItems = this.state.deleteItems;
    item.map((v) => {
      if (v.ids != deleteId) {
        items.push(v);
      }
      else {
        deleteItems.push(v);
      }
    });
    this.setState({ items, deleteItems })
  }

  onChangeText = (key) => event => { this.setState({ [key]: event.target.value }) }

  onChangeText1 = (key, id) => (e, { value }) => {
    let items = this.state.items;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.ids === id) {
          item[key] = value;
      }
    }
    this.setState({ items })
  }

  submit() {
    let context = this;
    let amount = this.state.amount;
    let choosedLedger = this.state.choosedLedger;
    if (!amount) {
      amount = 0;
    }
    if (!choosedLedger) {
      alert('Please Select Ledger');
      return;
    }
    let data = { customer_id: this.state.choosedCustomer, iec_no: this.state.iecNo, contact_person: this.state.contactPerson,
                 shipper_name: this.state.shipperName, mode: this.state.mode, tin_no: this.state.tinNo, email: this.state.email,
                 date: this.state.date, consignee: this.state.consignee, bl_no: this.state.BLNo, bill_of_entry: this.state.billOfEntry,
                 loading: this.state.loading, destination: this.state.destination, container: this.state.container,
                 volumn: this.state.volumn, items: this.state.items, over_all_description: this.state.overAllDescription,
                 over_all_quantity: this.state.overAllQuantity, gross_weight: this.state.grossWeight, shop_id: this.props.data.id,
                 bank_id: this.state.choosedBank, amount: amount, choosedLedger: choosedLedger, billOfEntryNumber: this.state.billOfEntryNumber,
                 customerInvoiceNumber: this.state.customerInvoiceNumber, creditDate: this.state.creditDate
                };
    let url = server() + '/orders/createlogisticsbill.php';
    this.setState({ load: true })
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      },
    }).done((data) => {
      context.setState({ load: false })
      console.log(data);
        if (data == 1) {
          alert('Sucess');
          context.props.getAllOrders();
          context.setState({ open: false })
        }
    });
  }

  viewBill() {
    let context = this;
    let orderId = this.props.item.mid;
    let data = { id: orderId };
    let url = server() + '/orders/viewlogisticsorders.php';
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      },
    }).done((data) => {
      let i = 1;
        try {
          data = JSON.parse(data);
          data.map((c) => { c.ids = i++; c.unit = c.qty; c.rate = c.price; c.taxable = c.taxable; c.type = 'update'; })
          this.setState({ load: false, items: data, open: true, choosedCustomer: this.props.item.customer_id, iecNo: this.props.item.IEC_NO,
                          contactPerson: this.props.item.contact_person, shipperName: this.props.item.shipper_name, mode: this.props.item.mode,
                          tinNo: this.props.item.tin_no, email: this.props.item.email, consignee: this.props.item.consignee,
                          BLNo: this.props.item.bill_no, billOfEntry: this.props.item.bill_of_entry, loading: this.props.item.loading,
                          destination: this.props.item.destination, container: this.props.item.container_name, volumn: this.props.item.volumn,
                          overAllDescription: this.props.item.over_all_description, overAllQuantity: this.props.item.over_all_qty, grossWeight: this.props.item.weight,
                          date: this.props.item.date, deleteItem: this.deleteItems, choosedBank: this.props.item.bank_id, billOfEntryNumber: this.props.item.bill_of_entry_no,
                          customerInvoiceNumber: this.props.item.customer_invoice_no, creditDate: this.props.item.credit_date })
        } catch (e) {
          alert('Error');
          this.setState({ load: false })
        }
    });
  }

  updateValue() {
    let context = this;
    let data = { iec_no: this.state.iecNo, contact_person: this.state.contactPerson, choosedCustomer: this.state.choosedCustomer,
                 shipper_name: this.state.shipperName, mode: this.state.mode, tin_no: this.state.tinNo, email: this.state.email,
                 date: this.state.date, consignee: this.state.consignee, bl_no: this.state.BLNo, bill_of_entry: this.state.billOfEntry,
                 loading: this.state.loading, destination: this.state.destination, container: this.state.container,
                 volumn: this.state.volumn, items: this.state.items, over_all_description: this.state.overAllDescription,
                 over_all_quantity: this.state.overAllQuantity, gross_weight: this.state.grossWeight, shop_id: this.props.data.id,
                 deleteItems: this.state.deleteItems, bank_id: this.state.choosedBank, orderId: this.props.item.mid, billOfEntryNumber: this.state.billOfEntryNumber,
                 customerInvoiceNumber: this.state.customerInvoiceNumber, creditDate: this.state.creditDate
                };
    let url = server() + '/orders/updatelogisticsbill.php';
    context.setState({ load: true })
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      },
    }).done((data) => {
        context.setState({ load: false })
        console.log(data);
        if (data == 1) {
          alert('Sucess');
          context.props.getAllOrders();
          context.setState({ open: false })
        }
    });
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  calculateTotal(qty, tax, taxable, rate) {
    qty = this.convertToNumber(qty);
    tax = this.convertToNumber(tax);
    rate = this.convertToNumber(rate);
    taxable = this.convertToNumber(taxable);
    taxable = taxable * rate;
    let amount = ((taxable + ((taxable * tax)/100)) * qty);
    amount = amount.toFixed(2);
    return amount;
  }


  render() {
    let sino = 1;
    let options = [];
    if (this.props.customer) {
      options = this.props.customer.map((temp) => {
        return ({ key: temp.id, value: temp.id, text: temp.name + "->" + temp.mobile });
      });
    }
    let bankOptions = [];
    if (this.props.bankDetails) {
      bankOptions = this.props.bankDetails.map((temp) => {
        return ({ key: temp.id, value: temp.id, text: temp.bank });
      });
    }
    let ledgerOption = [];
    if (this.props.ledger) {
      ledgerOption = this.props.ledger.map((temp) => {
        return ({ key: temp.id, value: temp.id, text: temp.ledger });
      });
    }
    let currencyOption = [
      {id: 'INR', value: 'INR', text: 'INR'},
      {id: 'USD', value: 'USD', text: 'USD' },
      {id: 'EURO', value: 'EURO', text: 'EURO' }
    ]
    return (
      <div>
        <Load open={this.state.load}/>
        { this.props.title == 'New Bill' ?
          (
            <Button variant="contained" color="primary" style={{marginLeft:20}} onClick={ () => { this.setState({ open: true }) }}>
              Add Bill
            </Button>
          ):
          (<div style={{cursor: 'pointer', color: 'blue'}} onClick={this.viewBill.bind(this)}>view</div>)
        }
        <Dialog fullScreen open={this.state.open} 
        onClose={ () => { this.setState({ open: false }) }} TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={ () => { this.setState({ open: false }) }} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {this.props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{marginLeft: '2%', marginRight: '2%', marginTop: '5%'}}>
          <div>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                To
                <Dropdown value={this.state.choosedCustomer} placeholder='Select Customer' onChange={this.handleChange}
                  fluid search selection options={options}/>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                IEC NO
                <Input fluid placeholder={'IEC NO'} onChange={this.onChangeText('iecNo')} value={this.state.iecNo}  />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Contact Person
                <Input fluid placeholder={'Contact Person'} onChange={this.onChangeText('contactPerson')} value={this.state.contactPerson} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Shipper
                <Input fluid placeholder={'Shipper'} onChange={this.onChangeText('shipperName')} value={this.state.shipperName} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Mode
                <Input fluid placeholder={'Mode'} onChange={this.onChangeText('mode')} value={this.state.mode} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Shippment Terms
                <Input fluid placeholder={'Shippment Terms'} onChange={this.onChangeText('tinNo')} value={this.state.tinNo} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Email ID
                <Input fluid placeholder={'Email ID'} onChange={this.onChangeText('email')} value={this.state.email} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Date
                <Input type="date" fluid placeholder={'Date'} onChange={this.onChangeText('date')} value={this.state.date} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Consignee
                <Input fluid placeholder={'Consignee'} onChange={this.onChangeText('consignee')} value={this.state.consignee} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                BL Number
                <Input fluid placeholder={'BL Number'} onChange={this.onChangeText('BLNo')} value={this.state.BLNo} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                SB/BE No.
                <Input fluid placeholder={'Bill of Entry'} onChange={this.onChangeText('billOfEntryNumber')} value={this.state.billOfEntryNumber} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                SB/BE Date
                <Input fluid type="date" placeholder={'Bill of Entry'} onChange={this.onChangeText('billOfEntry')} value={this.state.billOfEntry} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Port of Loading
                <Input fluid placeholder={'Port of Loading'} onChange={this.onChangeText('loading')} value={this.state.loading} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Port of Destination
                <Input fluid placeholder={'Port of Destination'} onChange={this.onChangeText('destination')} value={this.state.destination} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Container
                <Input fluid placeholder={'Container'} onChange={this.onChangeText('container')} value={this.state.container} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                Volumn
                <Input fluid placeholder={'Volumn'} onChange={this.onChangeText('volumn')} value={this.state.volumn} />
              </Grid.Column>

            </Grid>
          </div>
          <br/>
          <div>
            <Table attached='top' basic style={{width: '100%'}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{width: '4%'}}>Sino</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '40%'}}>Description</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '8%'}}>Unit</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}>Currency</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}>Ex.Rate</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '8%'}}>GST%</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}>Rate</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}>Total</Table.HeaderCell>
                  <Table.HeaderCell style={{width: '10%'}}>Delete</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              { this.state.items.map((v) => {
                  return (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{sino++}</Table.Cell>
                        <Table.Cell><Input fluid placeholder={'Description'} value={v.description} onChange={this.onChangeText1('description', v.ids)} /></Table.Cell>
                        <Table.Cell><Input fluid placeholder={'Unit'} value={v.unit} onChange={this.onChangeText1('unit', v.ids)} /></Table.Cell>
                        <Table.Cell>
                          <Dropdown value={v.currency} placeholder='Currency' onChange={this.handleChange3('currency', v.ids)}
                            fluid search selection options={currencyOption}/>
                        </Table.Cell>
                        <Table.Cell><Input fluid placeholder={'Ex.Rate'} value={v.rate} onChange={this.onChangeText1('rate', v.ids)} /></Table.Cell>
                        <Table.Cell><Input fluid placeholder={'Tax'} value={v.tax} onChange={this.onChangeText1('tax', v.ids)} /></Table.Cell>
                        <Table.Cell><Input fluid placeholder={'Taxable Amount'} value={v.taxable} onChange={this.onChangeText1('taxable', v.ids)} /></Table.Cell>
                        <Table.Cell>{this.calculateTotal(v.unit, v.tax, v.taxable, v.rate)}</Table.Cell>
                        <Table.Cell><Button onClick={this.deleteItem.bind(this, v.ids)}>Delete</Button></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  )
                })
              }
            </Table><br/>
            <Button primary onClick={this.addItem}>Add Item</Button>
          </div>
          <br/>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  Description
                  <Input fluid placeholder={'overAllDescription'} value={this.state.overAllDescription} onChange={this.onChangeText('overAllDescription')} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  Quantity
                  <Input fluid placeholder={'overAllQuantity'} value={this.state.overAllQuantity} onChange={this.onChangeText('overAllQuantity')} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  Gross Weight
                  <Input fluid placeholder={'Gross Weight'} value={this.state.grossWeight} onChange={this.onChangeText('grossWeight')} />
                </Grid.Column>
                { this.props.title == 'New Bill' ?
                  (
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                       Advance Amount Received
                      <Input fluid placeholder={'Advance Amount Received'} value={this.state.amount} onChange={this.onChangeText('amount')} />
                    </Grid.Column>
                  ):''
                }
                { this.props.title == 'New Bill' ?
                  (
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      Select Ledger
                      <Dropdown value={this.state.choosedLedger} placeholder='Select Ledger' onChange={this.handleChange2}
                        fluid search selection options={ledgerOption}/>
                    </Grid.Column>
                  ):''
                }
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Tax items={this.state.items} />
              <div>
                <br/>Bank
                <Dropdown value={this.state.choosedBank} placeholder='Select Bank' onChange={this.handleChange1}
                  fluid search selection options={bankOptions}/>
              </div>
              <Grid>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  Customer Invoice Number
                  <Input fluid placeholder={'Customer Invoice Number'} value={this.state.customerInvoiceNumber} onChange={this.onChangeText('customerInvoiceNumber')} />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  Credit Date
                  <Input fluid placeholder={'Credit Date'} value={this.state.creditDate} onChange={this.onChangeText('creditDate')} />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </div><br/>
        { this.props.title == 'New Bill' ?
          (
            !this.state.load && 
            <center><Button onClick={this.submit.bind(this)} primary>Submit</Button></center>
          ) :
          (
            !this.state.load &&
            <center><Button onClick={this.updateValue.bind(this)} primary>Update</Button></center>
          )
        }
        <Load type="circle" open={this.state.load}/>
        <br/>
      </Dialog>
      </div>
    );
  }

}
