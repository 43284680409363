import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CustomerEnquiry from '../customerenquery';
import Shop from './shop';
import Billing from '../billing';
import Banner from '../banner';
import MasterCategory from '../mastercategory';
import SecoundaryCategory from '../secoundarycategory';
import LogisticsBilling from '../logisticsbilling';
import BankDetails from '../bankdetail';
import ProductEnquiry from '../productenquiry';
import ViewBill from '../viewbill';
import SocialMedia from '../socialmedia';
import Products from '../product';
import VendorStatements from '../vendorstatement';
import PNSService from '../pnsservice';
import MonthlyExpenses from '../monthlyexpenses';
import Reports from '../reports';
import FilteredReports from '../reports/filtered';
import Prime from '../prime/current';
import Category from '../category';
import Sms from '../sms';
import GstReport from '../gstreport';
import EditProduct from '../product/edit_product/edit_product.js';
import LedgerTransaction from '../ledgertransaction';
import Ledger from '../ledger';
import Order from './order';
import Stocks from '../stocks';
import AddBilling from '../billing/add';
import Purchase from '../purchases';
import FeedBackReport from '../feedbackreport';
import AddPurchase from '../purchases/add';
import Customers from '../customers';
import Statements from '../statements';
import View from './view';

import Employees from '../employee/view';
import Designation from '../employee/designation';
import Attendance from '../employee/attendance';
import Holidays from '../employee/holiday';
import Accounts from '../employee/accounts';
import Settings from '../settings';
import CMS from '../cms';
import CouponCode from '../couponcode';

export default class ShopRoute extends React.Component {

  render() {
    let host = window.location.hostname.toLowerCase();
    const isBusiness = host.includes("business.strackit.com") || host.includes("localhost");
    const isEmployees = host.includes("employees.strackit.com");
    return (
      <Router>
        <Switch>
          {isBusiness && 
            <Route exact path="/" component={() => <Shop data={this.props.data} shopId={this.props.data.id} counts={this.props.counts} />} />}
          {isEmployees && 
            <Route exact path="/" component={(props) => <Employees data={this.props.data} {...props}/>}/>} 
          <Route exact path="/billing" component={() => <Billing data={this.props.data}/>} />
          <Route exact path="/monthly-expenses" component={() => <MonthlyExpenses data={this.props.data}/>} />
          <Route exact path="/banner" component={() => <Banner data={this.props.data}/>} />
          <Route exact path="/master-category" component={() => <MasterCategory data={this.props.data}/>} />
          <Route exact path="/secondary-category" component={() => <SecoundaryCategory data={this.props.data}/>} />
          <Route exact path="/logistics-billing" component={() => <LogisticsBilling data={this.props.data} />} />
          <Route exact path="/bank-details" component={() => <BankDetails data={this.props.data} />} />
          <Route exact path="/product-enquiry" component={() => <ProductEnquiry data={this.props.data} />} />
          <Route exact path="/coupon-code" component={(props) => <CouponCode shopId={this.props.data.id} data={this.props.data} {...props} />} />
          <Route exact path="/customer-enquiry" component={() => <CustomerEnquiry data={this.props.data} />} />
          <Route exact path="/bill" component={() => <ViewBill />} />
          <Route exact path="/blog" component={() => <SocialMedia data={this.props.data} />} />
          <Route exact path="/add-product" component={() => <Products shopId={this.props.data.id} from={'users'} />} />
          <Route exact path="/pns-service" component={() => <PNSService data={this.props.data} shopId={this.props.data.id} />} />
          <Route exact path="/reports" component={() => <Reports data={this.props.data} />} />
          <Route exact path="/reports/performance" component={() => <FilteredReports data={this.props.data} />} />
          <Route exact path="/prime" component={() => <Prime data={this.props.data} />} />
          <Route exact path="/category-alignment" component={() => <Category data={this.props.data} />} />
          <Route exact path="/sms" component={() => <Sms data={this.props.data} getShopDetails={this.props.getShopDetails} />} />
          <Route exact path="/gst-report" component={() => <GstReport data={this.props.data} />} />
          <Route exact path="/edit-product" component={() => <EditProduct data={this.props.data} />} />
          <Route exact path="/ledger-transaction" component={() => <LedgerTransaction data={this.props.data} shopId={this.props.data.id} />} />
          <Route exact path="/ledger" component={() => <Ledger data={this.props.data} shopId={this.props.data.id} />} />
          <Route exact path="/order" component={() => <Order data={this.props.data} shopId={this.props.data.id} />} />
          <Route exact path="/stocks" component={() => <Stocks data={this.props.data}/>} />
          <Route exact path="/billing/new" component={() => <AddBilling data={this.props.data}/>} />
          <Route exact path="/billing/edit" component={() => <AddBilling data={this.props.data} type={'edit'} />} />
          <Route exact path="/purchases" component={() => <Purchase data={this.props.data}/>} />
          <Route exact path="/feedback-report" component={() => <FeedBackReport data={this.props.data}/>} />
          <Route exact path="/purchases/new" component={() => <AddPurchase data={this.props.data}/>} />
          <Route exact path="/customers" component={() => <Customers data={this.props.data}/>} checked={false} />
          <Route exact path="/logistics-customers" render={() => <Customers type={0} data={this.props.data}/>} checked={false} />
          <Route exact path="/logistics-vendor" render={() => <Customers type={0} data={this.props.data}/>} checked={false} />
          <Route exact path="/customers/duedate" component={() => <Customers data={this.props.data} checked={true}/>} />
          <Route exact path="/statements" component={() => <Statements data={this.props.data}/>} />
          <Route exact path="/logistics-customers-statement" component={() => <Statements data={this.props.data}/>} />
          <Route exact path="/logistics-vendor-statement" component={() => <VendorStatements data={this.props.data}/>} />
          <Route exact path="/logistics-vendor-statement/:id" component={(props) => <VendorStatements data={this.props.data} {...props} />} />
          <Route exact path="/statements/:id" component={(props) => <Statements data={this.props.data} {...props}/>} />

          <Route exact path="/employees" component={(props) => <Employees data={this.props.data} {...props}/>}/>
          <Route exact path="/employees/designations" component={(props) => <Designation data={this.props.data} {...props}/>}/>
          <Route exact path="/employees/attendance" component={(props) => <Attendance data={this.props.data} {...props}/>}/>
          <Route exact path="/employees/holidays" component={(props) => <Holidays data={this.props.data} {...props}/>}/>
          <Route exact path="/employees/accounts" component={(props) => <Accounts data={this.props.data} {...props}/>}/>
          <Route exact path="/settings" component={(props) => <Settings data={this.props.data} {...props}/>}/>
          <Route exact path="/cms" component={(props) => <CMS data={this.props.data} {...props}/>}/>


          <Route exact path="*" component={() => <View data={this.props.data}/>} />
        </Switch>
      </Router>
    );
  }

}
