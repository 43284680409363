import React from 'react';
import { Card, Icon, Image, Header } from 'semantic-ui-react';
import Im from '../../media/addimage.jpg';

const style = {
  image: {
    position: 'relative',
    float: 'left',
    width:  '100%',
    minHeight: 200,
    maxHeight: 200
  },
  card: {
    textAlign: 'left',
    minHeight: 325,
    maxHeight: 325
  },
  description: {
    maxHeight: '2.6em',
    lineHeight: '1.3em',
    textOverflow: 'ellipsis',
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden'
  }
}

export default class Cards extends React.Component {

  render() {
    const { data } = this.props;
    let description = data.description;
    if (description.length > 60) {
      description = description.substring(0, 60) + "... " + "Read More"
    }
    description = data.category;
    // let url = "https://shop.strackit.com/" + this.props.shop.split(" ").join("-") + "/" + description.split(" ").join("-");

    return (
      <a  target="_blank">
        <center>
          <Card style={style.card}>
            <img style={style.image} src={data.feature_image}/>
            <Card.Content>
              <Card.Header>{description}</Card.Header>
              <Card.Meta>
                <span className='date'> </span>
              </Card.Meta>
              <Card.Description style={style.description}>{data.count + " Product(s)"}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <center><Header color='green' as='h4' content="View"/></center>
            </Card.Content>
          </Card>
        </center>
      </a>
    );
  }

}
